import { BASE_URL } from "../environment";
import makeRequest from "../makeRequest";

export default async function postTalkTalkOrder(body) {
  const results = await makeRequest(`${BASE_URL}/ttbplaceorder`, { method: 'POST', body });
  const {SendCommandResult} = results || null;

  if (typeof results === 'string' && results.includes('[Internal Server Error]')) {
    throw new Error(results);
  }

  else if (SendCommandResult?.Status?.HasErrors) {
    throw SendCommandResult?.Status?.Errors;
  }

  return SendCommandResult.CommandID;
}