import React, { useState } from 'react';
import { Form, Button, Message, Dropdown, FormGroup, Grid, GridColumn } from 'semantic-ui-react';
import { useFormik } from 'formik';
import Panel from './Panel';
import { useEthernetContext } from '../containers/EthernetProvider';
import { useThemeContext } from '../containers/ThemeProvider';

const POST_CODE_REGEX = /^([a-zA-Z]{1,2}\d[a-zA-Z\d]?) ?(\d[a-zA-Z]{2})$/;

export const PHONE_NUMBER_ERROR_MESSAGE = 'Please enter a valid UK landline number';
export const POST_CODE_ERROR_MESSAGE = 'Please enter a valid UK Post Code';
export const ADDRESS_SEARCH_NO_RESULTS =
  'We were unable to find any addresses for this post code please check it and try again.';
export const AVAILABLITY_CHECK_NO_RESULTS =
  'Unable to check availablity, please check the phone number and/or address and try again.';
export const SERVER_ERROR_MESSAGE = 'A server error occured, please try again.';


const validate = ({ postCode, address }) => {
  const errors = {};

  if (postCode && !postCode.match(POST_CODE_REGEX)) {
    errors.postCode = true;
  }

  return errors;
};

function selectErrorBannerMessage(addressSearch) {
  const addressError = addressSearch.error?.message;

  if (addressError === 'No Results') {
    return {
      header: 'No Results',
      content: ADDRESS_SEARCH_NO_RESULTS,
    };
  }

  if (addressError === 'Server Error') {
    return {
      header: 'Server Error',
      content: SERVER_ERROR_MESSAGE,
    };
  }

  return null;
}

export default function EthernetForm({ addressSearch, ethernetQuotesCheck, bandwidths, searchCallback, isLoading}) {
  const [Bandwidth, setbandwidth] = useState({ bandwidthField: "All" });
  const errorBannerMessage = selectErrorBannerMessage(addressSearch);
  const { suppliers, supplier, setSupplier } = useEthernetContext();
  const { assets } = useThemeContext();
  const { values, errors, touched, ...formik } = useFormik({
    validate,
    validateOnChange: true,
    validateOnMount: true,
    initialValues: {
      postCode: '',
      address: '',
      bandwidths: ''
    },
  });

  const handleChange = (event, result) => {
    const { name, value } = result || event.target;
    setbandwidth({ ...Bandwidth, [name]: value });
  };

  const handleSupplierChange = (event, result) => {
    const { value } = result || event.target;
    setSupplier(value);
  }

  async function onEthernetQuotingFormSubmit() {
    if (!errors.address) {
      const selectedAddress = addressSearch.data?.find(x => x.id === values.address);
      sessionStorage.setItem('customerDetails', JSON.stringify({ selectedAddress }));

      searchCallback(selectedAddress, Bandwidth?.bandwidthField, values.postCode);
    }
  }

  async function onPostCodeFormSubmit() {
    if (values.postCode && !errors.postCode) {
      // eslint-disable-next-line no-unused-vars
      const [_, area, street] = values.postCode.match(POST_CODE_REGEX);
      formik.setFieldTouched('postCode', true);
      formik.setFieldValue('address', '');
      await addressSearch.makeRequest(`${area} ${street}`);
    }
  }

  function showOrHideError(e) {
  }

  return (
    <>
      <Panel header='Ethernet Leased Line Quotation'>
        <p className='availability__help-text'>
          Enter a UK postcode to search for an address below to perform an Ethernet quote lookup. All available bandwidths for your selection will be displayed.
        </p>

        <Form onSubmit={onPostCodeFormSubmit} autoComplete='off'>
          <Form.Group>
            <Form.Input
              inline
              className={touched.postCode && errors.postCode ? 'error' : ''}
              label='Post Code'
              disabled={addressSearch.loading}
              onKeyDown={showOrHideError}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name='postCode'
              data-testid='postCode'
            />
            <Button
              className='button-primary'
              content='Find Address'
              loading={addressSearch.loading}
              disabled={!values.postCode || errors.postCode}
            />

          </Form.Group>
          {touched.postCode && errors.postCode && (
            <span className='availability__error'>{POST_CODE_ERROR_MESSAGE}</span>
          )}


        </Form>

        <Form onSubmit={onEthernetQuotingFormSubmit}>
          {addressSearch.data && (
            <select
              disabled={ethernetQuotesCheck.loading}
              size={10}
              name='address'
              value={values.address}
              onChange={formik.handleChange}
              data-testid='address'
            >
              <option value='' hidden></option>
              {addressSearch.data.map(({ id, address }) => (
                <option key={id} value={id}>
                  {address}
                </option>
              ))}
            </select>
          )}

        </Form>

        {errorBannerMessage && <Message error {...errorBannerMessage} />}

      </Panel>
      <Panel>
        <FormGroup>
          <Grid columns={assets.ethernet.showSupplier ? 2 : 1}>
            {assets.ethernet.showSupplier && (
              <>
                <GridColumn>
                  <Form.Field
                    label="Supplier"
                    required
                  />
                  <Dropdown
                    placeholder='Select Supplier'
                    fluid
                    search
                    selection
                    options={suppliers}
                    id='supplierField'
                    onChange={handleSupplierChange}
                    value={supplier}
                  />
                </GridColumn>
              </>
            )}

            <GridColumn>
              <Form.Field
                label="Bandwidth (Mbps)"
                required
              />
              <Dropdown
                placeholder='Select Service Bandwidth'
                fluid
                search
                selection
                options={bandwidths}
                label='bandwidthField'
                id='bandwidthField'
                name='bandwidthField'
                defaultValue={bandwidths[0].value}
                onChange={handleChange}
                value={Bandwidth.bandwidthField}
              />
            </GridColumn>
          </Grid>
        </FormGroup>
      </Panel>

      <Button
        className='button-primary'
        content={'Get Quotes'}
        loading={values.address && Bandwidth.bandwidthField && isLoading}
        disabled={(errors.address || errors.postCode || !values.address || !Bandwidth.bandwidthField) || isLoading}
        onClick={onEthernetQuotingFormSubmit}
        floated='right'
      />
    </>
  );
}
