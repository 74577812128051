import { BASE_URL } from "../environment";
import makeRequest from "../makeRequest";

export default async function postZenOrder(body) {
  const results = await makeRequest(`${BASE_URL}/zenplaceorder`, { method: 'POST', body });
  const {message} = results || null;
  const {modelState} = results || null;
  const {errors} = results || null;
  const {errorMessage} = results || null;

  if (message === "The request is invalid.") { // Model state contains a list of errors from Zen and a bad request
    throw modelState;
  }

  if(message){ // Message sometimes contains an error
    throw message;
  }

  if(errors && errors.length) // 200 response and errors such as expired availability
  {
    throw errors;
  }

  if(errorMessage && results.zenReference) // Order placed but email did not send
  {
    throw results.zenReference + " - " + "The order was successfully placed, however due to a Network error no email was sent"
  }

  if(errorMessage) // Other error message
  {
    throw "An error occured on the server, please contact support for assistance";
  }

  return results.zenReference; // success

}