import { BASE_URL } from "../environment";
import makeRequest from "../makeRequest";

export default async function usernameSearch(username, realm = 'dslnet') {
  const results = await makeRequest(`${BASE_URL}/username-check?username=${username}@${realm}`);
  const {availability} = results || null;
  if (typeof availability === 'undefined' || availability === null) {
    throw new Error('No Results');
  }

  return availability;
}