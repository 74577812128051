import { useEffect, useState } from "react";
import { Message } from "semantic-ui-react";
import EthernetTable from "../components/TTBQuoteResults/EthernetTable";
import { mapCityFibre, mapTtb } from "../libs/ethernetMapper";
import { useEthernetContext } from "./EthernetProvider";
import { useThemeContext } from "./ThemeProvider";

const EthernetResults = ({ ttb, cf }) => {
  const [ttbData, setTtbData] = useState([]);
  const [cfData, setCfData] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const { assets } = useThemeContext();
  const { supplier } = useEthernetContext();

  useEffect(() => {
    if (ttb) {
      setTtbData(mapTtb(ttb, supplier));
      setShowResults(true);
    }
  }, [ttb, supplier]);

  useEffect(() => {
    if (cf) {
      setCfData(mapCityFibre(cf, supplier, ttbData));
      setShowResults(true);
    }
  }, [cf, ttbData, supplier]);

  if (!showResults) return <></>;

  return (
    <div style={{marginTop:'75px'}}>
      {(ttbData.length + cfData.length) < 1 && <Message error header='No results returned for the current selection'></Message>}
      {(ttbData.length + cfData.length) > 1 && <Message info header='Please call for additional carrier pricing options'></Message>}

      {assets.ethernet.mergeTables && <EthernetTable quotes={ttbData.concat(cfData)} />}

      {!assets.ethernet.mergeTables && (
        <>
          {ttbData.length > 0 && <EthernetTable quotes={ttbData} />}
          {cfData.length > 0 && <EthernetTable quotes={cfData} />}
        </>
      )}
    </div>
  )
}

export default EthernetResults;