import React, { useContext } from 'react';
import { authContext } from './AuthProvider';
import { NavLink } from 'react-router-dom';
import { themeContext } from './ThemeProvider';

import './PageLayout.css';

export default function PageLayout({ children }) {
  const { logout, userLoggedIn, permissions } = useContext(authContext);
  const { assets } = useContext(themeContext);

  if (!userLoggedIn) {
    return children;
  }

  return (
    <div className={`page-layout__container ${assets.rootClass}`}>
      <div className='page-layout__side-bar'>
        <img className='page-layout__logo' src={assets.logo} alt='' style={assets.logoNavStyle} />
        <NavLink
          className='page-layout__side-bar-item'
          activeClassName='activated'
          to='/availability'
          isActive={(match, location) => {
            if (match) {
              return true;
            }
          }}
        >
          Check Availability
        </NavLink>
        {permissions.ethernetAccess && (
        <NavLink
          className='page-layout__side-bar-item'
          activeClassName='activated'
          to='/ethernetquoting'
          isActive={(match, location) => {
            if (match) {
              return true;
            }
          }}
        >
          Ethernet Quoting
        </NavLink>
        )}

        {permissions.orderAccess && (
          <NavLink
            className='page-layout__side-bar-item'
            activeClassName='activated'
            to='/SearchOrders'
            isActive={(match, location) => {
              if (match) {
                return true;
              }
            }}
          >
            Orders
          </NavLink>
        )}

        {permissions.servicesAccess && (
          <NavLink
            className='page-layout__side-bar-item'
            activeClassName='activated'
            to='/Services'
            isActive={(match, location) => {
              if (match) {
                return true;
              }
            }}
          >
            Services
          </NavLink>
        )}
        <div className='page-layout__side-bar-spacer' />
        <div className='page-layout__side-bar-item' onClick={logout}>
          <span>Logout</span>
        </div>
      </div>
      <div className='page-layout__content'>{children}</div>
    </div>
  );
}
