import React, { useContext, useEffect, useState } from 'react';
import Panel from '../Panel';
// import { searchOrders } from '../libs/requests/orderSearch';
import { authContext } from '../../containers/AuthProvider';
import { useParams } from 'react-router-dom';
import * as d3 from 'd3';
import ReactFauxDOM from 'react-faux-dom';
import { Message } from 'semantic-ui-react';
import './RadacctGraph.css';

const classMap = {
  conn: 'radacct_conn',
  reco: 'radacct_reco',
  diss: 'radacct_diss',
  unkn: 'radacct_default'
};

const margin = 50;
const fontSize = 10;
const tickLen = 5;
let heightOfItem, vertStart, vertEnd, width, height, widthOfItem, count, axis;

export default function RadacctGraph(props) {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(-1);

  const { reference } = useParams();
  const { permissions } = useContext(authContext);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       if (permissions.diagnosticsAccess) {
  //         // setIsLoading(true);
  //         // setService((await searchServices(reference))[0]);
  //         // setIsLoading(false);
  //         setTimeout(() => {
  //           setIsLoading(false);
  //         }, 200)
  //       }
  //     }
  //     catch (err) {
  //       console.error(err);
  //       setIsError(true);
  //       setIsLoading(false);
  //     }
  //   })();
  // }, []);


  if (!isLoading && !permissions.diagnosticsAccess) {
    return (
      <Panel>
        <Message error header="You don't have permission to view faults" />
      </Panel>
    )
  }

  const renderGraph = () => {
    const { data } = props;
    width = props.width;
    height = props.height;

    const node = ReactFauxDOM.createElement('div', width, height);

    heightOfItem = parseInt((height - margin * 2) / (24 * 4));
    vertStart = 10;
    vertEnd = vertStart + heightOfItem * (24 * 4);
    axis = { font: 'arial', fontSize: fontSize, tickLen: 5, colour: 'rgb(0,0,0)'};

    // console.log('radacct data', data);

    let { payload } = data;
    payload.sort((a, b) => {
      return a.id - b.id;
    })

    widthOfItem = (width - margin*2) / payload.length;

    console.log('width', width);
    console.log('height', height);
    console.log('heightOfItem', heightOfItem);
    console.log('vertStart', vertStart);
    console.log('vertEnd', vertEnd);
    console.log('axis', axis);
    console.log('payload', payload);

    let radacctGraph = createGraph(node);
    radacctGraph = renderGraphOutline(radacctGraph);
    console.log('rendered graph outline');

    payload.forEach(day => {
      renderDay(radacctGraph, day, day.id);
    });

    count = 0;
    for (let i = 0; i <= 96; i += 1) {
      renderYAxis(radacctGraph, i);
      count++;
    }

    return (
      <div className="graph" style={{width: width, height: height}}>
        {node.toReact()}
        {/* <div className="key"></div> TODO - legend */} 
      </div>
    )
  }

  const createGraph = (node) => {
    return d3.select(node)
    .append('svg:svg')
    .attr('width', width)
    .attr('height', height);
  }

  const renderGraphOutline = (radacctGraph) => {
    // bg
    radacctGraph.append('svg:react')
      .attr('x', 1)
      .attr('y', 1)
      .attr('width', width - 2)
      .attr('height', height - 2)
      .style('fill', 'rgb(255, 255, 255)');

      renderLineFromPoint(radacctGraph, { x: margin, y: vertStart}, {x: margin, y: vertEnd});

      const right = margin + widthOfItem * props.data.payload.length;
      console.log('render graph outline - right', right);
      renderLineFromPoint(radacctGraph, { x: right, y: vertStart}, {x: right, y: vertEnd});

      // time text on left
      radacctGraph
        .append('svg:text')
        .attr('text-anchor', 'start')
        .attr('class', 'raddact_legend')
        .attr('transform', `translate(${fontSize}, 275)rotate(270)`)
        .text('Time');

      return radacctGraph;
  }

  const renderLineFromPoint = (radacctGraph, start, end) => {
    radacctGraph.append('svg:line')
      .attr('x1', start.x)
      .attr('y1', start.y)
      .attr('x2', end.x)
      .attr('y2', end.y)
      .style('stroke', 'rgb(0,0,0)')
      .style('stroke-width', 0.5)
  }

  const renderAxisLabelFromPoint = (radacctGraph, point, text) => {
    console.log('render axis label from pt', point, text);
    radacctGraph.append('svg:text')
      .attr('x', point.x)
      .attr('y', point.y)
      .attr('font-size', fontSize)
      .attr('font-family', 'arial')
      .attr('text-anchor', 'end')
      .text(text);
  }

  const renderDay = (radacctGraph, day, idx) => {
    const { data } = day;
    data.sort((a, b) => b.id - a.id);

    const x = margin + idx * widthOfItem;

    // console.log('renderDay data', data);
    // console.log('renderDay x', x);

    radacctGraph
      .append('svg:text')
      .attr('font-size', fontSize)
      .attr('font-family', 'arial')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .attr('transform', `translate(${x + (widthOfItem * 0.75) / 2},${vertEnd + tickLen + 25})rotate(300)`)
      .attr(day.date);

      renderLineFromPoint(radacctGraph, { x, y: vertEnd }, {x, y: vertEnd + tickLen });

      let y = vertStart;
      data.forEach(item => {
        renderDataRect(radacctGraph, { x, y}, item);
        y += heightOfItem;
      });
  }

  const renderDataRect = (radacctGraph, point, status) => {
    radacctGraph
      .append('svg:rect')
      .attr('x', point.x)
      .attr('y', point.y)
      .attr('width', widthOfItem - 1)
      .attr('height', heightOfItem - 1)
      .attr('class', classMap[status.v] || 'radacct_default')
  }

  const renderYAxis = (radacctGraph, i) => {
    let x1 = margin;
    let x2 = width - margin;
    const y = vertStart + i * heightOfItem;

    console.log('renderYAxis count', count);
    console.log('renderYAxis x1, x2, y', x1, x2, y);

    if (count % 4 === 0) {
      x1 -= tickLen;
      x2 += tickLen;

      const label = 24 - count / 4;
      console.log('renderYAxis label', label);

      // y label left
      renderAxisLabelFromPoint(radacctGraph, { x: x1 - tickLen, y}, label);
      // y label right
      renderAxisLabelFromPoint(radacctGraph, { x: x2 + tickLen * 3, y }, label);
      // y tick line left
      renderLineFromPoint(radacctGraph, {x: x1, y}, { x: x1 + tickLen, y});
      // y tick line right
      renderLineFromPoint(radacctGraph, { x: x2, y}, { x: x2 - tickLen, y});
    }
  }

  return (
    <>
      {renderGraph()}
    </>
  )
}