import { add } from "lodash";
import { BASE_URL } from "../environment";
import makeRequest from "../makeRequest";
import { getCustomer } from "../sessionVariable";

export async function placeTtbQuote(address, Bandwidth, postCode) {
  if (!address) throw new Error("No address");
  if (!Bandwidth) throw new Error("No bandwidth");
  if (!postCode) throw new Error("No postcode");

  const body = createRequestBody(address, Bandwidth, postCode);
  const quotes = makeRequest(`${BASE_URL}/placequote`, {
    method: "POST",
    body,
  });

  quotes.then(function (data) {
    return Promise.all([quotes]);
  });
}

export async function retrieveTtbQuote(address, Bandwidth) {
  if (!address) {
    throw new Error("No address");
  }

  if (!Bandwidth) {
    throw new Error("No bandwidth");
  }


  const customer = await getCustomer();
  const { addressReference } = address;
  const { addressReferenceNumber, districtCode } = addressReference;
  const quoteKey = `-${addressReferenceNumber}-${districtCode}-${Bandwidth}-TTB-${customer}`;

  const response = await makeRequestWithRetryOnError(quoteKey, 10);

  let validResponse = "InvalidResponse";

  if (Array.isArray(response)) {
    [validResponse] = response;
  }

  if (validResponse == "InvalidResponse") {
    return { allQuotes: [] };
  }

  if (!response) {
    return { allQuotes: [] };
  }

  if (typeof response === "string") {
    return { allQuotes: [] };
  }
  const products = Object.values({
    ...validResponse,
  });
  return { allQuotes: products };
}

export async function retrieveCityFibreQuote(address, Bandwidth) {
  if (!address) throw new Error("No address");
  if (!Bandwidth) throw new Error("No bandwidth");

  const { addressReference } = address;
  const { addressReferenceNumber, districtCode } = addressReference;
  const customer = await getCustomer();
  const quoteKey = `-${addressReferenceNumber}-${districtCode}-${Bandwidth}-CF-${customer}`;
  let quotes = {};

  try {

   quotes = await makeRequest(
    `${BASE_URL}/retrievequote?QuoteKey=${quoteKey}`,
    { method: "GET" }
  );

    if (!quotes) {
      return {
        finished: false,
        products: [],
      };
    }

    if (quotes && !quotes.Products) {
      return {
        finished: true,
        products: [],
      };
    }

    return {
      finished: true,
      products: quotes.Products,
    };
  } catch (e) {
    return {
      finished: true,
      products: quotes.Products,
    };
  }
}

export default async function ttbEthernetQuotingResults(address, Bandwidth) {
  if (!address) {
    throw new Error("No address");
  }

  if (!Bandwidth) {
    throw new Error("No bandwidth");
  }
  const body = createRequestBody(address, Bandwidth);

  const quotes = makeRequest(`${BASE_URL}/talktalk-ethernet-pricing`, {
    method: "POST",
    body,
  });

  const [response] = await Promise.all([quotes]);

  if (!response) {
    return { allQuotes: [] };
  }

  if (typeof response === "string") {
    return { allQuotes: [] };
  }

  const products = Object.values({
    ...response,
  });

  return { allQuotes: products };
}

async function delay(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

async function makeRequestWithRetryOnError(quoteKey, retries) {
  try {
    const quotes = makeRequest(
      `${BASE_URL}/retrievequote?QuoteKey=${quoteKey}`,
      { method: "GET" }
    );
    return await Promise.all([quotes]);
  } catch (error) {
    retries = retries - 1;
    if (retries > 0) {
      await delay(2000);
      await makeRequestWithRetryOnError(quoteKey, retries);
    } else return "";
  }
}

function createRequestBody(address, Bandwidth, postCode) {
  if (!address) {
    throw new Error("No address provided");
  }
  const { addressReference } = address;
  const { addressReferenceNumber, districtCode } = addressReference;

  return JSON.stringify({
    GoldAddressKeyAvailabilityRequest: {
      phoneNumber: null,
      postCode: postCode,
      goldAddressKeyAvailabilityRequest: {
        addressReferenceNumber,
        districtCode,
      },
    },
    Bandwidth,
  });
}
