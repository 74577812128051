import addressSearchRequest from '../../libs/requests/addressSearch';
import usernameSearch from '../../libs/requests/usernameSearch';
import { getSupplier } from '../../libs/sessionVariable';
import { getIsLayer2 } from '../../libs/sessionVariable';

export const careLevels = [
    { key: 0, value: 'Standard', text: 'Standard', flag: 0 },
    { key: 1, value: 'Enhanced', text: 'Enhanced', flag: 1 },
    { key: 2, value: 'Critical', text: 'Critical', flag: 2 },
  ];

  export const trafficWeighting = [
    { key: 0, value: 'Standard', text: 'Standard', flag: 0 }
  ]

  export const ips = [
    { key: 1, value: 1, text: '1', flag: 1 },
    { key: 4, value: 4, text: '4', flag: 4 }
  ]

  // Get postcode
  export const getPostCode = (product) => {
    let btPostcode = product?.orderDetails?.lineCharacteristics?.btOpenreachPostCode ? product?.orderDetails?.lineCharacteristics?.btWholesalePostCode : product?.orderDetails?.lineCharacteristics?.btOpenreachPostCode;
    if (btPostcode) {
      return { 'postCode': btPostcode };
    }
    return { 'postCode': product?.orderDetails?.request?.postCode };
  }

  // Address Search
  export const addressSearch = async (postCode) => {
    try {
      return await addressSearchRequest(postCode);
    } catch (err) {
      console.log(err);
    }
  }

  export const getAddresses = async (postCode) => {
    const result = await addressSearch(postCode)
    const optionsList = [];

    result.forEach(function (element) {
      optionsList.push({ key: element.addressReference, value: element.addressReference, text: element.address, flag: element.addressReference })
    })
    return optionsList;
  }

  export const usernameCheck = async (username) => {
    try {
      return await usernameSearch(username);
    } catch (err) {
    }
  }

  export async function fetchSuppllier() {
    return await getSupplier('Zen');
  }

  export async function getAddressResults(postCode) {
    await getAddresses(postCode.postCode)
  }

  export async function isL2(){
    return await getIsLayer2();
  }
