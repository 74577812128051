import { BASE_URL } from "../environment";
import makeRequest from "../makeRequest";

export default async function addressSearchRequest(postCode) {
  const results = await makeRequest(`${BASE_URL}/addresslookup?postCode=${postCode}`);

  if (!results.length) {
    throw new Error('No Results');
  }

  return results.map(result => {
    const address = Object.values(result.address).filter(part => !!part).join(', ');
    return {
      ...result,
      address,
      id: result.addressReference.addressReferenceNumber,
      postCode: result.address.postCode
    }
  })
}