import React, { useLayoutEffect, useState } from 'react';
import Panel from '../Panel';
import { Form, FormInput, Grid, GridColumn, GridRow, Button, Message } from 'semantic-ui-react';
import { useFormik } from 'formik';
import postTTBEthernetOrder from '../../libs/requests/postTTBEthernetOrder';
import {getSupplier} from '../../libs/sessionVariable';

export default function PlaceEthernetOrder() {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] =  useState('');
  const [successMessage, setsuccessMessage] =  useState('');
  const selectedquote = sessionStorage.getItem('selectedQuote');
  const addressDetails = sessionStorage.getItem('customerDetails');
  const address = JSON.parse(addressDetails);
  const quote = JSON.parse(selectedquote);
  const prod = quote.selectedQuote.product;
  const serviceBandwidth = quote.selectedQuote.servicebandwidth;
  const years = quote.selectedQuote.years;
  const typicalleadtime = quote.selectedQuote.typicalleadtime;
  const installationcost = quote.selectedQuote.circuitinstallation;
  const circuitannual = quote.selectedQuote.circuitannual;
  const circuitmonthly = quote.selectedQuote.circuitmonthly;
  const postCode = address.selectedAddress.postCode;
  const orderPlacedBy = sessionStorage.email;
  const carrier = quote.selectedQuote.carrier || 'Talk Talk Business';
  const [supplier, setSupplier] = useState('Fibre1');
  
  useLayoutEffect(() => {
    async function fetchSuppllier(){
      const defaultSupplier = await getSupplier('TalkTalk Business');
      setSupplier(defaultSupplier);
    }
    fetchSuppllier();
  }, 
  )

  const { values, handleSubmit, isValid, errors, ...formik } = useFormik({
    onSubmit,
    validateOnChange: true,
    validateOnMount: true,
    initialValues: {
      Address: address.selectedAddress.address,
      Product: prod,
      ServiceBandwidth: serviceBandwidth,
      Carrier: carrier,
      Postcode: postCode,
      Years: years,
      TypicalLeadTime: typicalleadtime,
      InstallationCost: installationcost,
      CircuitAnnual: circuitannual,
      CircuitMonthly: circuitmonthly,
      OrderPlacedBy: orderPlacedBy
    }
  });

  async function onSubmit(Address, Product, ServiceBandwidth, Carrier, Postcode, Years, ORLeadTime, TypicalLeadTime, InstallationCost, CircuitAnnual,  CircuitMonthly, OrderPlacedBy) {
    
    try {
    const body = JSON.stringify(Address, Product, ServiceBandwidth, Carrier, Postcode, Years, ORLeadTime, TypicalLeadTime, InstallationCost, CircuitAnnual,  CircuitMonthly, OrderPlacedBy);
      setErrorMessage('');
      setIsLoading(true);
      const response = await postTTBEthernetOrder(body);
      setErrorMessage('');
      setsuccessMessage("Order Successfully Placed")
      setIsLoading(false);
      
    } catch (ex) {
      setsuccessMessage('');
          setErrorMessage("Failed to send order, please try again")
         setIsLoading(false);
    }
  }

  return (
    <>
      <Panel header={'Ethernet Leased Line Quotation'}>

      {errorMessage !== '' && <Message error header='There was an error on placing the order, please check and try again'
      />}

      {successMessage !== '' && <Message success header='Order successfully placed'
      />}

        <Form onSubmit={useFormik.handleSubmit} className='disabled'>
          <Panel header='Product Details'>
            <Grid>
              <GridRow>
                <GridColumn>

                  <FormInput
                    // style={{ width: "150px" }}
                    fluid
                    label='Address'
                    name='Address'
                    data-testid='Address'
                    disabled={true}
                    value={address.selectedAddress.address || ""}
                    id='Address'
                  />
                </GridColumn>
              </GridRow>
            </Grid>
            <Grid columns={4}>
              <GridRow>
                <GridColumn>
                  <FormInput
                    style={{ width: "150px" }}
                    inline
                    label='Product'
                    name='Product'
                    data-testid='Product'
                    disabled={true}
                    value={prod || ""}
                    id='Product'
                  />
                </GridColumn>
                <GridColumn>
                  <FormInput
                    style={{ width: "150px" }}
                    inline
                    label='Service Bandwidth'
                    name='ServiceBandwidth'
                    data-testid='ServiceBandwidth'
                    disabled={true}
                    value={serviceBandwidth || ""}
                    id='ServiceBandwidth'
                  />

                </GridColumn>
                <GridColumn>
                  <FormInput
                    style={{ width: "150px" }}
                    inline
                    label='Carrier'
                    name='Carrier'
                    data-testid='Carrier'
                    disabled={true}
                    value={supplier}
                    id='Carrier'
                  />
                </GridColumn>
                <GridColumn>
                  <FormInput
                    style={{ width: "150px" }}
                    inline
                    label='Postcode'
                    name='Postcode'
                    data-testid='Postcode'
                    disabled={true}
                    value={address.selectedAddress.postCode || ""}
                    id='Postcode'
                  />
                </GridColumn>
              </GridRow>
              <GridRow>
                <GridColumn>
                  <FormInput
                    style={{ width: "150px" }}
                    inline
                    label='Years'
                    name='Years'
                    data-testid='Years'
                    disabled={true}
                    value={years || ""}
                    id='Years'
                  />
                </GridColumn>
                <GridColumn>
                  <FormInput
                    style={{ width: "150px" }}
                    inline
                    label='Typical Lead Time'
                    name='TypicalLeadTime'
                    data-testid='TypicalLeadTime'
                    disabled={true}
                    value={typicalleadtime || ""}
                    id='TypicalLeadTime'
                  />
                </GridColumn>
              </GridRow>
            </Grid>
          </Panel>
          <Panel header="Ethernet Circuit Costs">
            <Grid columns={4}>
              <GridColumn>
                <FormInput
                  style={{ width: "150px" }}
                  inline
                  label='Installation Cost'
                  name='InstallationCost'
                  data-testid='InstallationCost'
                  disabled={true}
                  value={installationcost || ""}
                  id='InstallationCost'
                />
              </GridColumn>
              <GridColumn>
                <FormInput
                  style={{ width: "150px" }}
                  inline
                  label='Circuit Annual'
                  name='CircuitAnnual'
                  data-testid='CircuitAnnual'
                  disabled={true}
                  value={circuitannual || ""}
                  id='CircuitAnnual'
                />
              </GridColumn>

              <GridColumn>
                <FormInput
                  style={{ width: "150px" }}
                  inline
                  label='Circuit Monthly'
                  name='CircuitMonthly'
                  data-testid='CircuitMonthly'
                  disabled={true}
                  value={circuitmonthly || ""}
                  id='CircuitMonthly'
                />
              </GridColumn>
            </Grid>
          </Panel>

        </Form>
      </Panel>
      <Button
        disabled={successMessage}
        className='button-primary'
        floated='right'
        content='Place Order'
        onClick={handleSubmit}
        loading={isLoading}
      />
    </>
  )
}