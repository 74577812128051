import React, { useContext, useEffect, useState } from 'react';
import Panel from './Panel';
import { Dimmer, Input, Loader, Message, Segment, Table, Pagination, Icon, Dropdown } from 'semantic-ui-react';
import { searchServices } from '../libs/requests/serviceSearch';
import { Link } from 'react-router-dom';
import { authContext } from '../containers/AuthProvider';
import { getPartners } from '../libs/requests/getPartnerDetails';
import updatePartnerAsset from '../libs/requests/updatePartnerAsset';
import { Alert } from 'react-bootstrap';
import { useLoading, useServices } from './customHooks';
import { getSupplier } from '../libs/sessionVariable';

let timeout = null;

export default function SearchServices() {
  const [isLoading, setIsLoading] = useLoading(true);
  const [isError, setIsError] = useState(false);
  const [services, setServices] = useServices([]);
  const [partners, setPartners] = useState([]);
  const [info, setInfo] = useState({});
  const [page, setPage] = useState({
    index: 0,
    size: 10,
    count: 1,
    data: []
  });
  const { permissions } = useContext(authContext);
  const [isHightide, setIsHighttide] = useState(false);

  const highTidePartners = [
    {
      "Tenant": "Faelix",
      "Partner": "OneHelix",
      "Realms": [""]
    },
    {
      "Tenant": "Faelix",
      "Partner": "E-volve Solutions",
      "Realms": [""]
    },
    {
      "Tenant": "Faelix",
      "Partner": "IT4 Automation",
      "Realms": [""]
    },
    {
      "Tenant": "Faelix",
      "Partner": "Community Waves",
      "Realms": [""]
    }
  ];

  useEffect(() => {
    (async () => {
      try {
        setServices(await searchServices(''));

        if (permissions.admin) {
          setIsLoading(true);
          const supplier = await getSupplier(); // Fetch the supplier
          setIsHighttide(supplier === 'Hightide');

          if (supplier === 'Hightide') {
            setPartners(highTidePartners); // Use the hardcoded list if supplier is Hightide
            console.log('Using high tide partners:', highTidePartners);
          } else {
            const partnerList = await getPartners(); // Fetch partners otherwise
            // Normalize the partners to match the shape of highTidePartners
            const normalizedPartners = partnerList.map(partner => ({
              Tenant: '',         // Assume Tenant is empty or set it to something appropriate
              Partner: partner,   // Use the string from the array as the Partner
              Realms: ['']        // Keep Realms as an empty array for consistency
            }));
            setPartners(normalizedPartners);
            console.log('Fetched and normalized partners:', normalizedPartners);
          }
          
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (err) {
        setIsError(true);
        setIsLoading(false);
      }
    })();
  }, [permissions]);

  useEffect(() => {
    buildPage(1);
  }, [services]);

  const buildPage = (pageIndex) => {
    const { size } = page;
    const count = services.length;
    const noPages = count % size === 0 ? count / size : Math.trunc(count / size) + 1;
    const startIdx = (pageIndex - 1) * size;

    setPage({
      ...page,
      index: pageIndex,
      count: noPages,
      data: services.slice(startIdx, startIdx + size),
      isFirst: pageIndex === 1,
      isLast: pageIndex === noPages
    });
  };

  const handleSearchChange = (evt) => {
    clearTimeout(timeout);

    // debounce input to reduce the number of requests sent
    timeout = setTimeout(() => {
      (async () => {
        setIsLoading(true);
        setServices(await searchServices(evt.target.value));
        setIsLoading(false);
      })();
    }, 300);
  };

  const handlePaginationChange = (e, { activePage }) => {
    buildPage(activePage);
  };

  const showTable = () => !!services.length;

  const assign = (reference, partner) => {
    (async () => {
      setInfo({
        type: 'info',
        text: `Assigning ${reference} to ${partner}...`
      });
  
      partner = isHightide ? `Hightide|${partner}` : partner;
  
      // Determine the supplier based on the reference prefix
      let supplier;
      if (reference.toUpperCase().startsWith('Z')) {
        supplier = 'ZEN';
      } else if (reference.toUpperCase().startsWith('ORD')) {
        supplier = 'GIGANET';
      } else {
        // Handle the case where the reference doesn't match expected patterns
        setInfo({
          type: 'warning',
          text: `Invalid reference format for ${reference}.`
        });
        return;
      }
  
      const result = await updatePartnerAsset({
        reference,
        partner,
        supplier
      });
  
      setInfo({
        type: result ? 'success' : 'warning',
        text: result ? `Assigned ${reference} to ${partner}` : `Failed to assign ${reference} to ${partner}`
      });
    })();
  };  

  if (!isLoading && (!permissions.servicesAccess || !permissions || (permissions && !permissions.hasOwnProperty('admin')))) {
    return (
      <Panel>
        <Message error header="You don't have permission to view this page" />
      </Panel>
    );
  }

  if (isError) {
    return (
      <Panel>
        <Message error header="There was an error searching for services, please check and try again" />
      </Panel>
    );
  }

  const { data, index, isFirst, isLast, count } = page;

  return (
    <>
      <Panel header={'Services'}>
        {!!info.text && (
          <Alert bsStyle={info.type}>{info.text}</Alert>
        )}

        <Input
          data-testid='servicesSearchPanel'
          id='servicesSearchPanel'
          icon='search'
          placeholder='Search Services...'
          loading={isLoading}
          size="large"
          onChange={(evt) => handleSearchChange(evt)}
          style={{ width: '100%' }}
        />

        {!isLoading && !services.length && permissions.admin && <Message info header='No services found' />}

        {showTable() && (
          <Segment style={{ minHeight: '100px', width: '100%' }}>
            <Dimmer
              active={isLoading}
              inverted
              data-testid='spinner'>
              <Loader inverted> Loading </Loader>
            </Dimmer>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Reference</Table.HeaderCell>
                  <Table.HeaderCell>Service Status</Table.HeaderCell>
                  <Table.HeaderCell>CLI</Table.HeaderCell>
                  <Table.HeaderCell>Post Code</Table.HeaderCell>
                  <Table.HeaderCell>Customer Reference</Table.HeaderCell>
                  <Table.HeaderCell>Product</Table.HeaderCell>
                  <Table.HeaderCell>Network</Table.HeaderCell>
                  <Table.HeaderCell>Care Level</Table.HeaderCell>
                  <Table.HeaderCell>Traffic Weighting</Table.HeaderCell>
                  {permissions.admin && <Table.HeaderCell>Actions</Table.HeaderCell>}
                </Table.Row>
              </Table.Header>

              {!!services.length && (
                <>
                  <Table.Body>
                    {data.map(service => (
                      <Table.Row key={service.reference}>
                        <Table.Cell>
                          <Link to={`/Services/${service.reference}`}>
                            {service.reference}
                          </Link>
                        </Table.Cell>
                        <Table.Cell>{service.serviceStatus}</Table.Cell>
                        <Table.Cell>{service.phoneNumber}</Table.Cell>
                        <Table.Cell>{service.postCode}</Table.Cell>
                        <Table.Cell>{service.customerReference}</Table.Cell>
                        <Table.Cell>{service.productDescription}</Table.Cell>
                        <Table.Cell>{service.network}</Table.Cell>
                        <Table.Cell>{service.careLevel}</Table.Cell>
                        <Table.Cell>{service.trafficWeighting}</Table.Cell>
                        {permissions.admin && (
                          <>
                            <Table.Cell>
                              <Dropdown text='Assign'>
                                <Dropdown.Menu>
                                  {partners.map(o => (
                                    <Dropdown.Item
                                      text={o.Partner}
                                      key={o.Partner}
                                      onClick={() => assign(service.reference, o.Partner)}
                                    />
                                  ))}
                                </Dropdown.Menu>
                              </Dropdown>
                            </Table.Cell>
                          </>
                        )}
                      </Table.Row>
                    ))}
                  </Table.Body>
                </>
              )}
            </Table>

            {!!services.length && (
              <Pagination
                activePage={index}
                onPageChange={handlePaginationChange}
                totalPages={count}
                firstItem={null}
                lastItem={null}
                prevItem={{ content: <Icon name='angle left' disabled={isFirst} />, icon: true }}
                nextItem={{ content: <Icon name='angle right' disabled={isLast} />, icon: true }}
              />
            )}
          </Segment>
        )}
      </Panel>
    </>
  );
}
