import React, { useEffect, useState, Component } from 'react';
import { FormGroup, Panel } from 'react-bootstrap';
import { Form, Dropdown, GridColumn, Grid, GridRow, TextArea, FormInput, Button, Divider } from 'semantic-ui-react'
import '../OrderComponents/ZenMigration.css';


export const PortsComponent = (props) => {

  const ports = [];

  useEffect(() => {
  }, [props.isVisible])

  useEffect(() => {
  }, [props.ports])

  function setClassSelected(e) {
    for (var i = 0; i < ports?.length; i++) {
      if(document.getElementById(ports[i].props.id)) {
        document.getElementById(ports[i].props.id).classList.remove('uiButtonPortsActive');
        document.getElementById(ports[i].props.id).classList.remove('uiButtonPorts');
      }
    }

    e.currentTarget.className += ' uiButtonPortsActive';
  }

  const renderPorts = () => {

    for (var i = 0; i < props?.ports?.length; i++) {
      ports.push
        (
          <Button
            id={'portNumber' + props?.ports[i]?.Number}
            onClick={(e) => setClassSelected(e)}
            size='small'
            className='uiButtonPorts'> Port {props.ports[i]?.Number} {props?.ports[i]?.Status}
          </Button>
        )
    }

    return ports
  }

  if (props.isVisible) {
    return (
      <Grid>
        <GridRow>
          <GridColumn>
            <FormGroup>
              <FormInput inline label='Ports' name='ports' data-testid='ports'>
                {renderPorts()}
              </FormInput>
            </FormGroup>
            <Divider></Divider>
          </GridColumn>
        </GridRow>
      </Grid>
    )
  }
  else {
    return null;
  }
}



