import React from 'react';
import AvailabilityForm from '../components/AvailabilityForm';
import AvailabilityResults from '../components/AvailabilityResults';
import useRequest from '../libs/hooks/useRequest';
import availablityCheckRequest from '../libs/requests/availabilityCheck';
import addressSearchRequest from '../libs/requests/addressSearch';

export default function Availability() {
  const availablityCheck = useRequest(availablityCheckRequest);
  const addressSearch = useRequest(addressSearchRequest);

  return (
    <>
      <AvailabilityForm availablityCheck={availablityCheck} addressSearch={addressSearch} />
      {availablityCheck.data && <AvailabilityResults results={availablityCheck.data} />}
    </>
  );
}
