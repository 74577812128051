import ZenAvailabilityResults from "./ZenAvailabilityResults";
import LineDetails from "./ZenAvailabilityResults/LineDetails";

export default function AvailabilityResults({ results }) {
  return (
    <>
      <ZenAvailabilityResults results={results} />
      <LineDetails lineDetails={results.lineDetails} />
    </>
  )
}