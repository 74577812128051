import React, { useContext, useEffect, useState } from 'react';
import Panel from './Panel';
import { authContext } from '../containers/AuthProvider';
import { useParams } from 'react-router-dom';
import { Button, Dimmer, Grid, GridColumn, Label, Loader, Segment } from 'semantic-ui-react';
import Faults from './Diagnostics/Faults';
import ServiceTests from './Diagnostics/ServiceTests';
import RaiseFault from './Diagnostics/RaiseFault';
import './ViewDiagnostics.css';

export default function ViewDiagnostics() {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [service, setService] = useState({});
  const [showRaiseFault, setShowRaiseFault] = useState(false);

  const { reference } = useParams();
  const { permissions } = useContext(authContext);

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       if (permissions.diagnosticsAccess) {
  //         setIsLoading(true);
  //         setOrder((await searchOrders(reference))[0]);
  //         setIsLoading(false);
  //       }
  //     }
  //     catch (err) {
  //       console.error(err);
  //       setIsError(true);
  //       setIsLoading(false);
  //     }
  //   })();
  // }, []);

  useEffect(() => {
    (async () => {
      try {
        if (permissions.diagnosticsAccess) {
          // setIsLoading(true);
          // setService((await searchServices(reference))[0]);
          // setIsLoading(false);
        }
      }
      catch (err) {
        console.error(err);
        setIsError(true);
        setIsLoading(false);
      }
    })();
  }, []);

  // if (!isLoading && !permissions.diagnosticsAccess) {
  //   return (
  //     <Panel>
  //       <Message error header="You don't have permission to view this page" />
  //     </Panel>
  //   )
  // }

  return (
    <>
      <Segment style={{minWidth: '70em'}}>
        <Dimmer active={isLoading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
        {permissions.demoUser && (
          <>
            {showRaiseFault && <RaiseFault onCancel={() => setShowRaiseFault(false)} />}

            <Panel header={'Connection Details'} clearing>
              <div>
                <Button
                  icon='wrench'
                  content='Raise Fault'
                  color='red'
                  labelPosition='left'
                  floated='right'
                  onClick={() => setShowRaiseFault(true)}
                  disabled={showRaiseFault} 
                  style={{marginTop: '1.1em'}}
                />
                <Grid columns={3}>
                  <GridColumn>
                    <strong>CLI</strong><br />
                    0123123123
                  </GridColumn>
                  <GridColumn>
                    <strong>Customer ref</strong><br />
                    My customer ref
                  </GridColumn>
                  <GridColumn>
                    <strong>Postcode</strong><br />
                    AA1 1AA
                  </GridColumn>
                </Grid>
              </div>

            </Panel>

            <ServiceTests />
          </>
        )
        }
      </Segment>
    </>
  )
}