import React, { useEffect, useState, Component } from 'react';
import { FormGroup, Panel } from 'react-bootstrap';
import { Form, Dropdown, GridColumn, Grid, GridRow, TextArea, FormInput } from 'semantic-ui-react'
import "react-datepicker/dist/react-datepicker.css";


export const GiganetAppointmentComponent = (props) => {

        return (
            <Grid columns={2} style={{marginLeft:'2px'}}>
                <GridRow>
                    <GridColumn>
                <FormGroup>
                    <Form.Field
                        label='Appointment Details'
                        required
                    />
                </FormGroup>
                </GridColumn>
                </GridRow>
                        <GridRow>
                            <GridColumn>
                <FormGroup>
                    <TextArea
                        placeholder="Hazard Notes"
                        style={{ minHeight: 100, width: '900px' }}
                        fluid
                        className='ui textarea margin'
                        id="notes"
                        
                    />
                </FormGroup>
                </GridColumn>
                </GridRow>
            </Grid>
        )
    }

    

    

    