import { Auth } from 'aws-amplify';

export async function getSupplier(supplier = ""){
  const session = await Auth.currentSession();
  const tennant = session?.idToken?.payload["custom:tennant"];
  return tennant === "Faelix" ? "Hightide" : tennant;
}

export async function getCustomer(){
  const session = await Auth.currentSession();
  return session?.idToken?.payload["custom:customer"];
}

export async function getIsLayer2(){
  const session = await Auth.currentSession();
  console.log(session?.idToken?.payload["custom:L2"]);
  return session?.idToken?.payload["custom:L2"];
}

export async function getSuppliers(){
  const session = await Auth.currentSession();
  var suppliers = session?.idToken?.payload["custom:suppliers"];
  suppliers = JSON.parse(suppliers.replace(/(\w*):/g, '"$1":'))
  return suppliers != null ? suppliers["Onboarded"] : {};
}

export async function showCustomMessage(){
  const session = await Auth.currentSession();
  const tennant = session?.idToken?.payload["custom:tennant"];
  return (tennant === "Fibre1") ? true : false;
}

export async function getLoggedOnPartner(){
  const session = await Auth.currentSession();
  const partner = session?.idToken?.payload["custom:customer"];
  return partner;
}
