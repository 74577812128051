import { useState } from 'react';

export function useLoading(initialValue = true) {
  const [isLoading, setIsLoading] = useState(initialValue);
  return [isLoading, setIsLoading];
}

export function useServices(initialValue = []) {
  const [services, setServices] = useState(initialValue);
  return [services, setServices];
}

export function useOrders(initialValue = []) {
  const [orders, setOrders] = useState(initialValue);
  return [orders, setOrders];
}

export function useFilteredOrders(initialValue = []) {
  const [filtered, setFilteredOrders] = useState(initialValue);
  return [filtered, setFilteredOrders];
}