import React, { useContext } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom';
import NotFound from './containers/NotFound';
import Login from './containers/Login';
import Availability from './containers/Availability';
import PageLayout from './containers/PageLayout';
import AuthProvider, { authContext } from './containers/AuthProvider';
import ZenStandardOrder from './components/OrderComponents/ZenFTTCOrder';
import ZenSimProvideCopper from './components/OrderComponents/ZenSimProvideCopper';
import ZenFttpNewProvide from './components/OrderComponents/ZenFttpNewProvide';
import GiganetFttpSymmetrical from './components/OrderComponents/GiganetFttpSymmetrical';
import ZenFttpMigration from './components/OrderComponents/ZenFttpMigration';
import ZenFttpSuccessionOrMigration from './components/OrderComponents/ZenFttpSuccessionOrMigration';
import ZenFttpSuccessionProvide from './components/OrderComponents/ZenFttpSuccessionProvide';
import TTBStandardFTTCOverWLROrder from './components/OrderComponents/TTBStandardFTTCOverWLROrder';
import TTBFTTCOverWLRSimOrder from './components/OrderComponents/TTBFTTCOverWLRSimOrder';
import ZenFttpNewOrMigration from './components/OrderComponents/ZenFttpNewOrMigration';
import ZenSimProvideFTTC from './components/OrderComponents/ZenSimProvideFTTC';
import ZenFTTCOrder from './components/OrderComponents/ZenFTTCOrder';
import ZenCopperOrder from './components/OrderComponents/ZenCopperOrder';
import SogeaOrder from './components/OrderComponents/SogeaOrder';
import ZenGFastSIMProvideOrder from './components/OrderComponents/ZenGFastSIMProvideOrder';
import ZenGFastNewProvideOrder from './components/OrderComponents/ZenGFastNewProvideOrder';
import ZenGFastMigrationOrder from './components/OrderComponents/ZenGFastMigrationOrder';
import EthernetQuoting from './containers/EthernetQuoting';
import PlaceEthernetOrder from './components/OrderComponents/PlaceEthernetOrder';
import SearchOrders from './components/SearchOrders';
import ViewOrder from './components/ViewOrder';
import ViewDiagnostics from './components/ViewDiagnostics';
import Diagnostics from './components/Diagnostics';
import Services from './components/SearchServices';
import ViewService from './components/ViewService';
import ViewConnectionStatus from './components/ViewConnectionStatus';
import { ErrorBoundary } from 'react-error-boundary';
import SogeaRestartOrder from './components/OrderComponents/SogeaRestartOrder';

function ErrorModal({ showModal, onClose }) {
  if (!showModal) return null;

  return (
    <div style={{
      position: 'fixed',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      padding: '20px',
      zIndex: 1000,
      borderRadius: '10px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    }}>
      <h2>Oops! Something Went Wrong</h2>
      <p>We're sorry, but an unexpected error has occurred. Rest assured, the issue has been logged, and our team is actively working on a resolution. For now, you will be logged out for security reasons. Please refresh your browser and log in again. Should the problem continue, do not hesitate to reach out to our support team for assistance.</p>
      <button onClick={onClose} style={{ marginTop: '20px' }}>OK</button>
    </div>
  );
}

function ErrorFallback({ error, resetErrorBoundary }) {
  const { logout } = useContext(authContext); 
  const handleLogout = () => {
    console.error('Error occurred:', error);
    alert("We're sorry, but an unexpected error has occurred. Rest assured, the issue has been logged, and our team is actively working on a resolution. For now, you will be logged out for security reasons. Please refresh your browser and log in again. Should the problem continue, do not hesitate to reach out to our support team for assistance.");
    logout();
  };

  React.useEffect(() => {
    handleLogout();
  }, []);

  // You can render a minimal UI or redirect the user
  return <Redirect to="/login" />;
}

export default function Routes() {
  return (
    <AuthProvider>
      <PageLayout>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Switch>
          <Route exact path='/'>
            <Redirect to="/Availability"></Redirect>
          </Route>
          <Route exact path='/Login'>
            <Login />
          </Route>
          <Route exact path='/Availability'>
            <Availability />
          </Route>
          <Route exact path='/ethernetquoting'>
            <EthernetQuoting />
          </Route>
          <Route exact path='/PlaceZenFTTCOrder'>
            <ZenFTTCOrder />
          </Route> 
          <Route exact path='/PlaceZenCopperOrder'>
            <ZenCopperOrder />
          </Route> 
          <Route exact path='/PlaceZenSimProvideCopperOrder'>
            <ZenSimProvideCopper />
          </Route>
          <Route exact path='/PlaceZenSimProvideFTTCOrder'>
            <ZenSimProvideFTTC />
          </Route>
          <Route exact path='/PlaceFTTPNewProvideOrder'>
            <ZenFttpNewProvide />
          </Route>
          <Route exact path='/placefttpsymmetrical'>
          <GiganetFttpSymmetrical storage={sessionStorage} />
          </Route>
          <Route exact path='/PlaceFTTPMigrationOrder'>
            <ZenFttpMigration />
          </Route>
          <Route exact path='/PlaceFTTPSuccessionOrMigrationOrder'>
            <ZenFttpSuccessionOrMigration />
          </Route>
          <Route exact path='/PlaceFTTPSuccessionProvideOrder'>
            <ZenFttpSuccessionProvide />
          </Route>
          <Route exact path='/PlaceFTTPMigrationOrNewOrder'>
            <ZenFttpNewOrMigration />
          </Route>
          <Route exact path='/PlaceTtbFttcOverWlr'>
            <TTBStandardFTTCOverWLROrder />
          </Route>
          <Route exact path='/PlaceTtbFttcOverWlrSim'>
            <TTBFTTCOverWLRSimOrder />
          </Route>
          <Route exact path='/PlaceSOGEAOrder'>
            <SogeaOrder />
          </Route>
          <Route exact path='/PlaceSOGEARestartOrder'>
            <SogeaRestartOrder />
          </Route>
          <Route exact path='/PlaceGFastSIMProvideOrder'>
          <ZenGFastSIMProvideOrder />
          </Route>
          <Route exact path='/PlaceGFastNewProvideOrder'>
          <ZenGFastNewProvideOrder />
          </Route>
          <Route exact path='/PlaceGFastMigrationOrder'>
          <ZenGFastMigrationOrder />
          </Route>
          <Route exact path='/PlaceEthernetOrder'>
          <PlaceEthernetOrder />
          </Route>
          <Route exact path='/SearchOrders'>
            <SearchOrders />
          </Route>
          <Route exact path='/Order/:reference'>
            <ViewOrder />
          </Route>
          {/* <Route exact path='/Diagnostics'>
            <Diagnostics />
          </Route> */}
          <Route exact path='/Diagnostics/Connection/:reference'>
            <ViewConnectionStatus />
          </Route>
          <Route exact path='/Diagnostics/:reference'>
            <ViewDiagnostics />
          </Route>
          <Route exact path='/Services'>
            <Services />
          </Route>
          <Route exact path='/Services/:reference'>
            <ViewService />
          </Route>
          <Route>
            <NotFound />
          </Route>
        </Switch>
        </ErrorBoundary>
      </PageLayout>
    </AuthProvider>
  );
}

