import React, { useEffect, useState } from 'react';
import Panel from '../Panel';
import { Form, Message, FormInput, Grid, GridColumn, GridRow, FormGroup, Divider, Dropdown, Button } from 'semantic-ui-react';
import { useFormik } from 'formik';
import '../OrderComponents/ZenMigration.css';
import addressSearchRequest from '../../libs/requests/addressSearch';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';
import { GiganetAppointmentComponent } from './GiganetAppointmentComponent';
import { getLoggedOnPartner, getSupplier } from '../../libs/sessionVariable';
import postTTBEthernetOrder from '../../libs/requests/postTTBEthernetOrder';
import postGiganetOrder from '../../libs/requests/postGiganetOrder';
import handleAddressChange from './AddressChange';

export default function GiganetFttpSymmetrical({ storage }) {
  const [postCode, setPostCode] = useState("");
  const [addressReferenceNumber, setAddressReferenceNumber] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [level, setLevel] = useState({ careLevelField: "Standard" });
  const [weighting, setTrafficWeighting] = useState({ trafficWeightingField: "Standard" });
  const [zenReference, setZenReference] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [addressValue, setAddressValue] = useState({ addressField: { addressReferenceNumber: '', districtCode: '', qualifier: '' } });
  const [supplier, setSupplier] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [partner, setPartner] = useState('');
  const [productName, setProductName] = useState('');
  const [productCode, setProductCode] = useState('');
  const [requestPhone, setRequestPhone] = useState('');
  const [email, setEmail] = useState('');
  const [product, setProduct] = useState(storage);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [notes, setNotes] = useState('');

  useEffect(() => {
    if (storage && storage.selectedProduct) {
      const selectedProductObject = JSON.parse(storage.selectedProduct);
      const selectedPhone = JSON.parse(storage.customerDetails);
      const orderDetails = selectedProductObject.orderDetails;
      setProductName(selectedProductObject.productName);
      setProductCode(selectedProductObject.productCode);
      setRequestPhone(selectedPhone.phoneNumber);
      setEmail(storage.email);
      setProduct(selectedProductObject);
      setPostCode(getPostCode(orderDetails));
    }
  }, [storage]);

  useEffect(() => {
    setAddressReferenceNumber(product?.orderDetails?.goldAddressKeyAvailabilityRequest);
  }, [product]);

  useEffect(() => {
    async function fetchSupplier() {
      const defaultSupplier = await getSupplier('TalkTalk Business');
      setSupplier(defaultSupplier);
    }
    fetchSupplier();
  }, []);

  useEffect(() => {
    async function fetchPartner() {
      const partner = await getLoggedOnPartner();
      setPartner(partner);
    }
    fetchPartner();
  }, [partner]);

  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [startDate, setStartDate] = useState(new Date(tomorrow));

  const careLevels = [
    { key: 0, value: 'Standard', text: 'Standard', flag: 0 },
  ];

  const trafficWeighting = [
    { key: 0, value: 'Standard', text: 'Standard', flag: 0 }
  ];

  const handleCareLevelChange = (event, result) => {
    const { name, value } = result || event.target;
    setLevel({ ...level, [name]: value });
  };

  const handleTrafficWeightingChange = (event, result) => {
    const { name, value } = result || event.target;
    setTrafficWeighting({ ...weighting, [name]: value });
  };

  function getPostCode(orderDetails) {
    if (orderDetails?.requestPostCode) {
      setPostCode(orderDetails?.requestPostCode);
      return orderDetails?.requestPostCode;
    }
    return orderDetails?.lineCharacteristics?.btOpenreachPostCode || orderDetails?.lineCharacteristics?.btWholesalePostCode || '';
  }

  const addressSearch = async (postCode) => {
    try {
      return await addressSearchRequest(postCode);
    } catch (err) {
      console.log(err);
    }
  }

  const getAddresses = async (postCode) => {
    const result = await addressSearch(postCode);
    const optionsList = [];

    if (result) {
      result.forEach(function (element) {
        optionsList.push({ key: element.addressReference, value: element.addressReference, text: element.address, flag: element.addressReference });
      });
      return optionsList;
    }
  }

  const [addressResults, setAddressResults] = useState([]);

  useEffect(() => {
    async function getAddressResults() {
      if (postCode) {
        await getAddresses(postCode).then(data => setAddressResults(data));
      }
    }
    getAddressResults();
  }, [postCode]);

  const { values, handleChange, handleSubmit, setFieldValue, isValid, errors, ...formik } = useFormik({
    onSubmit,
    validate,
    validateOnChange: true,
    validateOnMount: true,
    initialValues: {
      siteInformation: '',
      addressField: { addressReferenceNumber: '', districtCode: '', qualifier: '' },
      orderRef: '',
      firstName: '',
      surName: '',
      emailAddrss: '',
      contactTel: ''
    }
  });

  function validate({ addressField, orderRef, contactTel, firstName, surName, emailAddrss }) {
    const errors = {};
    const address = addressField.qualifier === "Silver" ? addressField.uprn : addressField.addressReferenceNumber;

    if (!firstName.length) {
      errors.firstName = true;
    }

    if (!surName.length) {
      errors.surName = true;
    }

    if (!emailAddrss.length) {
      errors.emailAddrss = true;
    }

    if (!contactTel.length) {
      errors.contactTel = true;
    }

    if (!address) {
      errors.addressField = true;
    }

    if (!orderRef.length) {
      errors.orderRef = true;
    }

    return errors;
  }

  function generateShortID() {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 6; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  }

  async function onSubmit({ addressField, orderRef, firstName, surName, emailAddrss, contactTel, notes }) {
    try {
      let selectedAddress = addressResults?.find(x => x.flag.addressReferenceNumber === values.addressField.addressReferenceNumber && x.flag.qualifier === 'Gold');
      selectedAddress = typeof (selectedAddress) === "undefined" ? addressResults?.find(x => x.flag.uprn === values.addressField.uprn) : selectedAddress;
      
      const initialEmail = {
        "addressField": addressField,
        "orderRef": orderRef,
        "contactName": firstName + ' ' + surName,
        "Email": emailAddrss,
        "contactTel": contactTel,
        "orderType": "fttp order",
        orderPlacedBy: partner,
        loggedOnUser: email,
        "selectedAddress": selectedAddress.text,
        "product": productCode,
        "giganetPartnerRef": orderRef,
        "availabilityReference": product?.orderDetails?.availabilityReference,
        "Notes": notes
      }

      const addressDetails = selectedAddress.text;
      const address = addressDetails.split(',');

      const isPremiseNameOrThoroughfare = parseInt(address[0]);
      const usePremiseName = isNaN(isPremiseNameOrThoroughfare);
      const premiseName = usePremiseName ? address[0] : null;
      const thoroughFareNumber = !usePremiseName ? address[0] : null;

      const javaObject = {
        GiganetPlaceOrderRequest: {
          partner_reference: orderRef + "_" + generateShortID(),
          notes: notes,
          product_order_item: {
            quote_ulid: product?.orderDetails?.giganetQuoteUlid,
            partner_product_code: product?.supplierProductCode,
            address_key: selectedAddress?.value?.uprn,
            postcode: postCode,
            end_customer_contact: {
              first_name: firstName,
              last_name: surName,
              email: emailAddrss,
              phone: contactTel
            },
            install_asap: true
          }
        },
        address: {
          premiseName: premiseName,
          thoroughfareNumber: thoroughFareNumber,
          thoroughfare: address && address.length > 1 ? address[1] : "",
          postTown: address && address.length > 2 ? address[2].trim() : "",
          postCode: postCode ? postCode.trim() : ""
        },
        FriendlyProductCodeName: productCode,
        Cli: requestPhone,
        orderPlacedBy: email
      };

      const body = JSON.stringify(initialEmail);
      const body1 = JSON.stringify(javaObject);
      setErrorMessage('');
      setIsLoading(true);
      
      const response = await postGiganetOrder(body1);
      const emailResponse = await postTTBEthernetOrder(body);

      setErrorMessage('');
      setSuccessMessage(response);
      setIsLoading(false);

    } catch (ex) {
      setSuccessMessage('');
      setErrorMessage(ex.message || "Failed to send order, please try again");
      setIsLoading(false);
    }
  }

  return (
    <>
      <Panel header={supplier + ' ' + (product ? productName : '')}>
        {errorMessage &&
          <Message error header={errorMessage.split('\n').map((line, index) =>
            <span key={index}>{line}<br /></span>
          )}
          />}
        {successMessage && <Message success header={successMessage} />}
        <Form onSubmit={handleSubmit} className='disabled'>
          <Grid columns={1}>
            <GridRow>
              <GridColumn>
                <FormGroup>
                  <FormInput
                    style={{ width: "900px" }}
                    inline
                    label='Product'
                    name='productLabel'
                    data-testid='productCode'
                    disabled={true}
                    value={productCode || ''}
                  />
                </FormGroup>
                <Divider />
              </GridColumn>
            </GridRow>
          </Grid>
          <Grid>
            <GridRow>
              <GridColumn>
                <FormGroup>
                  <Form.Field
                    inline
                    name='addressLabel'
                    data-testid='addressDropDown'
                    label={'Address  - '.concat(postCode)}
                    disabled={true}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Dropdown
                    style={{ width: "900px" }}
                    placeholder='Select Address'
                    fluid
                    search
                    selection
                    options={addressResults || []}
                    label='addressField'
                    id="addressField"
                    name="addressField"
                    data-testid="addressField"
                    onChange={(e, val) => {
                      handleAddressChange(e, val, setAddressValue, setFieldValue, addressValue);
                      const selected = addressResults.find(x => x.value === val.value);
                      setSelectedAddress(selected);
                    }}
                    value={addressValue.addressField}
                  />
                </FormGroup>
                <Divider />
              </GridColumn>
            </GridRow>
          </Grid>
          <Grid>
            <GridRow>
              <GridColumn>
                <FormGroup>
                  <Form.Field>
                    <label>Unique Reference</label>
                    <Form.Input
                      style={{ width: "900px" }}
                      readOnly
                      value={product?.orderDetails?.giganetQuoteUlid || ''}
                    />
                  </Form.Field>
                </FormGroup>
                <FormGroup>
                  <Form.Field>
                    <label>Product Code</label>
                    <Form.Input
                      style={{ width: "900px" }}
                      readOnly
                      value={product?.supplierProductCode || ''}
                    />
                  </Form.Field>
                </FormGroup>
                <FormGroup>
                  <Form.Field>
                    <label>UPRN</label>
                    <Form.Input
                      style={{ width: "900px" }}
                      readOnly
                      value={selectedAddress?.value?.uprn || ''}
                    />
                  </Form.Field>
                </FormGroup>
                <Divider />
                {/* <FormGroup>
                  <Form.Field
                    label={'Product Options'}
                    name='optionsLabel'
                    data-testid='optionsLabel'
                  />
                </FormGroup>
                <FormGroup>
                  <Form.Field
                    label="Care Level"
                    required
                  />
                </FormGroup>
                <Grid>
                  <Grid.Row centered>
                    <Grid.Column width={12}>
                      <Form>
                        <FormGroup>
                          <Dropdown
                            style={{ width: "900px" }}
                            placeholder='Select Care Level'
                            fluid
                            search
                            selection
                            options={careLevels}
                            label='careLevelField'
                            id='careLevelField'
                            name='careLevelField'
                            defaultValue={careLevels[0]?.value}
                            onChange={handleCareLevelChange}
                            value={level.careLevelField}
                          />
                        </FormGroup>
                      </Form>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <FormGroup>
                  <Form.Field
                    label="Traffic Weighting"
                    required
                  />
                </FormGroup>
                <Grid>
                  <Grid.Row centered>
                    <Grid.Column width={12}>
                      <Form>
                        <FormGroup>
                          <Dropdown
                            style={{ width: "900px" }}
                            placeholder='Select Traffic Weighting'
                            fluid
                            search
                            selection
                            options={trafficWeighting}
                            label='trafficWeightingField'
                            id='trafficWeightingField'
                            name='trafficWeightingField'
                            defaultValue={trafficWeighting[0]?.value}
                            onChange={handleTrafficWeightingChange}
                            value={weighting.trafficWeightingField}
                          />
                        </FormGroup> */}
                      {/* </Form> */}
                    {/* </Grid.Column>
                  </Grid.Row> */}
                {/* </Grid>
                <Divider /> */}
                <FormGroup>
                  <Form.Field
                    label='Contact Details'
                    required
                  />
                </FormGroup>
                <Form.Field>
                  <Form.Input
                    style={{ width: "900px" }}
                    placeholder="Enter Firstname"
                    id="firstName"
                    data-testid='firstName'
                    name="firstName"
                    value={values.firstName}
                    onChange={handleChange}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    style={{ width: "900px" }}
                    placeholder="Enter Surname"
                    id="surName"
                    name="surName"
                    data-testid="surName"
                    value={values.surName}
                    onChange={handleChange}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    style={{ width: "900px" }}
                    placeholder="Enter Email"
                    data-testid="emailAddr"
                    id="emailAddrss"
                    name="emailAddrss"
                    value={values.emailAddrss}
                    onChange={handleChange}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    style={{ width: "900px" }}
                    placeholder="Enter Contact Telephone Number"
                    id="contactTel"
                    name="contactTel"
                    value={values.contactTel}
                    onChange={handleChange}
                    data-testid="contactTel"
                  />
                </Form.Field>
                <Form.Field>
                  <label>Notes</label>
                  <textarea
                    style={{ width: "900px" }}
                    placeholder="Enter any notes here"
                    id="notes"
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </Form.Field>
                <Divider />
                {<GiganetAppointmentComponent />}
                <FormGroup>
                  <Form.Field
                    inline
                    label='References'
                    name='references'
                    data-testid='referencesLabel'
                    disabled={true}
                  />
                </FormGroup>
                <FormGroup>
                  <FormInput
                    inline
                    label='Order Reference' // maps to partner_reference
                    name='orderRef'
                    data-testid='orderRefLabel'
                    disabled={false}
                    value={values.orderRef}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
                <Button
                  disabled={!isValid || zenReference}
                  className='button-primary'
                  floated='right'
                  content='Place Order'
                  onClick={handleSubmit}
                  loading={isLoading}
                />
              </GridColumn>
            </GridRow>
          </Grid>
        </Form>
      </Panel>
    </>
  );
}
