import React, { useState } from 'react';
import EthernetForm from '../components/EthernetForm';
import useInterval from '../libs/hooks/useInterval';
import useRequest from '../libs/hooks/useRequest';
import addressSearchRequest from '../libs/requests/addressSearch';
import ethernetquotesSearch, { placeTtbQuote, retrieveTtbQuote, retrieveCityFibreQuote } from '../libs/requests/ethernetQuotesCheck'
import EthernetProvider from './EthernetProvider';
import EthernetResults from './EthernetResults';
import { Message } from "semantic-ui-react";
import { isError } from 'lodash';

export default function EthernetQuoting() {
  const addressSearch = useRequest(addressSearchRequest);
  const ethernetquotesCheck = useRequest(ethernetquotesSearch);
  const [isLoadingQuote, setIsLoadingQuote] = useState(false);
  const [selectedInfo, setSelectedInfo] = useState({});
  const [ttbData, setTtbData] = useState(null);
  const [cfData, setCfData] = useState(null);
  const [errorMessage, setIsErrorMessage] = useState();

  const bandwidths = [
    { key: -1, value: -1, text: 'All', flag: 'All' },
    { key: 100, value: 100, text: 100, flag: 100 },
    { key: 200, value: 200, text: 200, flag: 200 },
    { key: 300, value: 300, text: 300, flag: 300 },
    { key: 500, value: 500, text: 500, flag: 500 },
    { key: 1000, value: 1000, text: "1000 (1GB)", flag: 1000 }
  ];

  useInterval(() => {
    (async () => {
      if (!selectedInfo.address) return;
      const { address, bandwidth } = selectedInfo;

      if (!ttbData) {
        try {
          setTtbData(await retrieveTtbQuote(address, bandwidth));
        } catch (e) {
        }
      }

      // console.log('cf data', cfData);
      if (!cfData && !cfData?.finished) {
        try {
          setCfData(await retrieveCityFibreQuote(address, bandwidth));
        } catch (e) {
          
        }
      }

      if (ttbData && cfData?.finished)
        setIsLoadingQuote(false);
    })();
  }, isLoadingQuote ? 1500 : null);

  const pollForResults = (selectedAddress, bandwidth, postcode) => {
    (async () => {
      setTtbData(null);
      setCfData(null);
      setSelectedInfo({});
      setIsLoadingQuote(true);

      try {
        setIsErrorMessage("");
        await placeTtbQuote(selectedAddress, bandwidth, postcode);
        await retrieveTtbQuote(selectedAddress, bandwidth, postcode);
      } catch (e) {
        console.log('failed parsing place quote json...');
        setIsLoadingQuote(false);
        setIsErrorMessage("No results returned for the current selection")
      }

      setSelectedInfo({
        address: selectedAddress,
        bandwidth
      });
    })();
  }

  return (
    <>
      <EthernetProvider>
        <EthernetForm
          addressSearch={addressSearch}
          ethernetQuotesCheck={ethernetquotesCheck}
          bandwidths={bandwidths}
          searchCallback={pollForResults}
          isLoading={isLoadingQuote}
        />
        {isLoadingQuote && <strong>Loading quotes...</strong>}
        {errorMessage &&  <strong>No results returned for the current selection</strong>}
        {!isLoadingQuote && <EthernetResults ttb={ttbData} cf={cfData?.products} />}
      </EthernetProvider>
    </>
  );
} 