import usernameSearch from "./requests/usernameSearch";

const validUsername = new RegExp('^(?=.{3,64}$)[a-z0-9.]{3,}@[a-z0-9.]{3,}$');

export const checkUsernameValid = async (user, realm) => {
  const username = `${user}@${realm}`;

  if (validUsername.test(username)) {
    try {
      return await usernameSearch(user, realm);
    } catch (err) {
      console.error(err);
    }
  }

  return "The username should be a minimum of 3 characters. Only numbers, lower case letters and ‘.’ are allowed";
}