import React, { useContext, useEffect, useState } from 'react';
import Panel from './Panel';
import { Button, Dimmer, Divider, Grid, GridColumn, Loader, Message, Segment, Label } from 'semantic-ui-react';
import { Link, useParams } from 'react-router-dom';
import { authContext } from '../containers/AuthProvider';
import { searchServices } from '../libs/requests/serviceSearch';
import Faults from './Diagnostics/Faults';

const fields = [
  { key: 'serviceId', label: 'ServiceId' },
  { key: 'phoneNumber', label: 'CLI' },
  { key: 'customerReference', label: 'Customer Ref' },
  { key: 'serviceStatus', label: 'Service Status' },
  { key: 'startDate', label: 'Service Start Date' },
  { divider: true },
  { key: 'productDescription', label: 'Product' },
  { key: 'careLevel', label: 'Care Level' },
  { key: 'trafficWeighting', label: 'Traffic Weighting' },
  { key: 'network', label: 'Network' },
  { key: 'upstreamSpeed', label: 'Upstream Speed' },
];

const addressFields = [
  { key: 'organisationName', label: 'Organisation' },
  { key: 'subPremiseName', label: 'Sub Premise' },
  { key: 'premiseName', label: 'Premise' },
  { key: 'thoroughfareNumber', label: 'House Number' },
  { key: 'thoroughfare', label: 'Street' },
  { key: 'dependentThoroughfare', label: 'Dependent Thoroughfare' },
  { key: 'postTown', label: 'Town' },
  { key: 'postCode', label: 'Post Code' },
];

const GridItem = ({ field, value }) => {
  const { divider, label } = field;

  const displayValue = value && field.format
    ? field.format(value)
    : value;

  if (divider)
    return (
      <GridColumn width={16}>
        <Divider />
      </GridColumn>
    );

  return (
    <GridColumn key={label}>
      <Grid columns={2}>
        <GridColumn>
          <strong>{label}</strong>
        </GridColumn>
        <GridColumn>
          {displayValue}
        </GridColumn>
      </Grid>
    </GridColumn>
  );
}

export default function ViewService() {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [service, setService] = useState({});

  const { reference } = useParams();
  const { permissions } = useContext(authContext);

  useEffect(() => {
    (async () => {
      try {
        if (permissions.servicesAccess) {
          setIsLoading(true);
          setService((await searchServices(reference))[0]);
          setIsLoading(false);
        }
      }
      catch (err) {
        console.error(err);
        setIsError(true);
        setIsLoading(false);
      }
    })();
  }, []);

  if (!isLoading && !permissions.servicesAccess) {
    return (
      <Panel>
        <Message error header="You don't have permission to view this page" />
      </Panel>
    )
  }

  const header = <>
    <span>{`Service Reference: ${reference}`}</span>
    <strong style={{ float: 'right' }}>{service ? service.status : ''}</strong>
  </>

  return (
    <>
      <Segment>
        <Dimmer active={isLoading} inverted>
          <Loader inverted>Loading</Loader>
        </Dimmer>
        {permissions.demoUser &&
          <Panel header={'Service Details'} {...<Link>Modify</Link>}>
            <Link to={`/Diagnostics/${reference}`}>
              <Button primary className="button-primary" content='Diagnostics' icon='wrench' labelPosition='left' floated='right' />
            </Link>
            <Grid columns={4} style={{padding: '20px 0'}}>
              <span>
                <Label style={{ color: 'black', backgroundColor: 'green', fontWeight: 'bold' }}>
                  Service Status: Active
                </Label>
              </span>
              <Link to={`/Diagnostics/Connection/${reference}`}>
                <Label style={{ color: 'black', backgroundColor: 'red', fontWeight: 'bold' }}>
                  Connection Status: Disconnected
                </Label>
                <br />
                <strong>View details</strong>
              </Link>
              <span>
              <Label style={{ color: 'black', backgroundColor: 'green', fontWeight: 'bold'}}>
                Exchange status: No known outages
              </Label>
              </span>
            </Grid>
          </Panel>
        }

        <Panel header={header}>
          {isError && <Message error header='There was an error trying to view this service, please try again later' />}

          {!service && !isError && <Message info header='The service could not be found' />}

          {!!service && (
            <Grid columns={3}>
              {fields.map(field => <GridItem field={field} value={service[field.key]} key={field.key} />)}
            </Grid>
          )}
        </Panel>

        <Panel header={'Installation Address'}>
          <Grid columns={3}>
            {addressFields.map(field => <GridItem field={field} value={service[field.key]} key={field.key} />)}
          </Grid>
        </Panel>
        {permissions.demoUser && <Panel header={'Connection Details'}>
          <Grid columns={3}>
            <Label>Username: c2176z@dslnet</Label>
            <Label>Password: nxlu80j2hmcb</Label>
            <Label>Router IP Address: 51.148.65.81</Label>
          </Grid>
        </Panel>
        }

        {permissions.demoUser && <Faults reference={reference} />}  

      </Segment>
      <Link to="/Services">
        <Button primary className='button-primary'>Back</Button>
      </Link>

      {permissions.demoUser &&
        <Link to="/testendpoint" style={{ float: 'right' }}>
          <Button primary style={{ backgroundColor: 'grey', borderColor: 'black' }}>
            Cease Service
          </Button>
        </Link>}

      {permissions.demoUser &&
        <Link to="/testendpoint" style={{ float: 'right' }}>
          <Button primary style={{ backgroundColor: 'green', borderColor: 'green' }}>
            Modify
          </Button>
        </Link>}
    </>
  )
}