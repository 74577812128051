import { Table, Image, Button } from 'semantic-ui-react';
import _ from 'lodash'
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {getSupplier} from '../../libs/sessionVariable';
import { useThemeContext } from '../../containers/ThemeProvider';
import { sortByProductSpeed } from '../../libs/ethernetMapper';

const tableSort = (data, columns) => {
  if (columns.indexOf('product') !== -1) {
    data.sort(sortByProductSpeed);
    return data;
  }

  return _.sortBy(data, columns);
}

export default function EthernetTable({ quotes }) {
  const { assets } = useThemeContext();
  const [supplierLogo, setSupplier] = useState('');
  const history = useHistory();

  useLayoutEffect(() => {
    async function fetchSupplier(){
      const defaultSupplier = await getSupplier('TalkTalk Business');
      const supplierLogo = defaultSupplier !== "TalkTalk Business" ? `.\\${assets.ethernet.quoteLogo}` : ".\\TalkTalk_Business.png";
      setSupplier(supplierLogo);
    }
    fetchSupplier();
  });

  useEffect(() => {
    dispatch({ type: 'STORE', data: quotes });
  }, [quotes]);

  
  const [state, dispatch] = React.useReducer(reducer, {
    column: 'product',
    data: [],
    direction: null,
  });
  const { column, data, direction } = state;

  function reducer(state, action) {
    switch (action.type) {
      case 'CHANGE_SORT':
        if (state.column === action.column) {
          return {
            ...state,
            data: state.data.slice().reverse(),
            direction:
              state.direction === 'ascending' ? 'descending' : 'ascending',
          }
        }

        return {
          column: action.column,
          data: tableSort(state.data, [action.column]),
          direction: 'ascending',
        };
      case 'STORE':
        return {
          ...state,
          data: action.data
        }
      default:
        throw new Error()
    }
  }

   function onEthernetQuoteSelect(e, index) {
     const selectedQuote = data[index];
     history.push('/PlaceEthernetOrder')
     sessionStorage.setItem('selectedQuote', JSON.stringify({ selectedQuote }));
  }

  return (
    <>
      <br />
      <br />
       
       {quotes.length > 0 &&
      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <Image src={supplierLogo} size="tiny" centred />
            </Table.HeaderCell>

            <Table.HeaderCell
              sorted={column === 'product' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'product' })}
            >
              Product
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'years' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'years' })}
            >
              Years
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'circuitinstallation' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'circuitinstallation' })}
            >
              Circuit Installation
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'circuitmonthly' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'circuitmonthly' })}
            >
              Circuit Monthly
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'circuitannual' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'circuitannual' })}
            >
              Circuit Annual
            </Table.HeaderCell>
            <Table.HeaderCell
              sorted={column === 'typicalleadtime' ? direction : null}
              onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'typicalleadtime' })}
            >
              Typical Lead Time
            </Table.HeaderCell>
            <Table.HeaderCell>
              &nbsp;
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.map(({product, accesssubtype, years, circuitinstallation, circuitmonthly, circuitannual, typicalleadtime }, index) => (
            <Table.Row key={index}>
              <Table.Cell></Table.Cell>
              <Table.Cell>{product + " " + (accesssubtype != null && !product.toUpperCase().includes("EAD") ? accesssubtype : "")}</Table.Cell>
              <Table.Cell>{years}</Table.Cell>
              <Table.Cell>{circuitinstallation}</Table.Cell>
              <Table.Cell>{circuitmonthly}</Table.Cell>
              <Table.Cell>{circuitannual}</Table.Cell>
              <Table.Cell>{typicalleadtime}</Table.Cell>
              <Table.Cell>
              <Button
              className='button-primary'
              content='Select'
              onClick={(e) => onEthernetQuoteSelect(e, index)}
            />
            </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
}
    </>
  );
}