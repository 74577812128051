import { Segment } from 'semantic-ui-react';
import './Panel.css';
import React from 'react';

export default function Panel({ header = '', className = '', children, style, clearing }) {
  return (
    <Segment.Group className={`border-primary ${className}`}>
      <Segment className='background-primary border-primary no-border-radius' style={style}>{header}</Segment>

      <Segment padded className="content" clearing={clearing}>{children}</Segment>
    </Segment.Group>
  );
}
