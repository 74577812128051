import { BASE_URL } from "../environment";
import makeRequest from "../makeRequest";

export async function getPartners() {
  try {
    const results = await makeRequest(`${BASE_URL}/getpartnerdetails?searchBy=tenant`, { method: 'GET' });
    return results.map(r => r.Partner);
  } catch (e) {
    console.error(e);
    return [];
  }
}

export async function getRealms() {
  try {
    const results = await makeRequest(`${BASE_URL}/getpartnerdetails?searchBy=partner`, { method: 'GET' });
    if (results.length) {
      return results[0].Realms.map((r, idx) => {
        return { key: idx, value: r, text: `@${r}` }
      });
    }
    return [{ key: 0, value: 'dslnet', text: '@dslnet' }];
  } catch (e) {
    console.error(e);
    return false;
  }
}