import { useState } from 'react';

export default function useRequest(requestFunction) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const makeRequest = async (...args) => {
    setLoading(true);
    setError(null);
    setData(null);

    try {
      const response = await requestFunction(...args);
      setLoading(false);
      setData(response);
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  }

  const reset = () => {
    setLoading(false);
    setError(false);
    setData(null);
  }

  return {
    makeRequest,
    loading,
    error,
    data,
    reset
  }
}