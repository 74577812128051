import React from "react";
import { useContext } from "react";
import FirstcomTheme from "./containers/theme/Firstcom";
import ThemeProvider, { Fibre1, FirstCom, Hightide,TechSmart, themeContext } from "./containers/ThemeProvider";
import Routes from "./Routes";
import Footer from './Footer';

const Fibre1Theme = React.lazy(() => import('./containers/theme/Fibre1'));
const TechSmartTheme = React.lazy(() => import('./containers/theme/TechSmart'));
const HightideTheme = React.lazy(() => import('./containers/theme/Hightide'));

const ThemeSelector = ({ children }) => {
  const { theme } = useContext(themeContext);

  console.log('theme', theme);

  return (
    <>
      <React.Suspense fallback={<></>}>
        {theme === TechSmart && <TechSmartTheme />}
        {theme === Fibre1 && <Fibre1Theme />}
        {theme === FirstCom && <FirstcomTheme />}
        {theme === Hightide && <HightideTheme />}
      </React.Suspense>
      {children}
      <Footer /> {/* Include Footer here to display it on all pages */}
    </>
  )
}

function App() {
  return (
    <ThemeProvider>
      <ThemeSelector>
        <Routes />
      </ThemeSelector>
    </ThemeProvider>
  );
}

export default App;