import { BASE_URL } from "../environment";
import makeRequest from "../makeRequest";

export default async function postGiganetOrder(body) {
  const results = await makeRequest(`${BASE_URL}/giganetplaceorder`, { method: 'POST', body });

  const {Code} = results || null;
  const {Status} = results || null;
  const {PartnerOrderReference} = results || null;
  const {Reason} = results || null;
  const {Message} = results || null;
  const {Errors} = results || null;

  if (Errors) {
    const errorMessages = Object.values(Errors).flat().join('\n');
    throw new Error(errorMessages);
  }

    if(Message){ // Message sometimes contains an error
    throw new Error(Message || "An error occured, please check and try again");
  }

  if(Code != 200) // 200 response and errors such as expired availability
  {
    throw new Error(Reason || "An error occured, please check and try again");
  }

  return `Order Accepted - ${PartnerOrderReference}`;

}