import React from 'react';
import { FormGroup } from 'react-bootstrap';
import { Form,  Grid, Button, Divider} from 'semantic-ui-react'
import { AppointmentComponent } from './ZenAppointmentComponent';


export const AppointmentRequiredComponent = (props) => {
    const [required, setRequired] = React.useState(false);

    React.useEffect (() => {
    }, [props.isVisible]);

    const handleAppointmentYes = (e) => {
        e.currentTarget.className += ' uiButtonProvideActive';
        document.getElementById("requiresAppointmentNo").classList.remove('uiButtonProvideActive');
        document.getElementById('requiresAppointmentNo').classList.add('uiButtonPorts');
        setRequired(true);
    }

    const handleAppointmentNo = (e) => {
        e.currentTarget.className += ' uiButtonProvideActive';
        document.getElementById("requiresAppointment").classList.remove('uiButtonProvideActive');
        document.getElementById('requiresAppointment').classList.add('uiButtonPorts');
        setRequired(false);
    }

    const renderAppointmentYes = () => {
        const buttons = [];
        buttons.push(<Button onClick={(e) => handleAppointmentYes(e)}
        id='requiresAppointment'
        style={{ width: "200px", height: '60px', marginLeft:'20px' }} className='uiButtonPorts'>
        {'Yes'}
      </Button>);
        return buttons
      }

      const rednerAppointmentNo = () => {
        const buttons = [];
        buttons.push(<Button onClick={(e) => handleAppointmentNo(e)}
        id='requiresAppointmentNo'
        style={{ width: "200px", height: '60px', marginLeft:'20px' }} className='uiButtonPorts'>
        {'No'}
      </Button>)
      return buttons;
      }

    if(props.isVisible === true)
    {
        return (
            <Grid style={{marginLeft:'10px', marginTop:'10px', marginBottom:'10px'}} columns='equal'>
               
                <FormGroup>
                  <Form.Field
                    inline
                    label='Appointment'
                    name='appointmentRequired'
                    data-testid='appointmentRequired'
                  />
                </FormGroup>
                {renderAppointmentYes()}
                {rednerAppointmentNo()}
                
                <FormGroup>
                <Divider></Divider>
                {<AppointmentComponent isVisible={required} today={new Date()} leadTimeDays={5} />}
                </FormGroup>
                </Grid>
        )
        }
        else return null;
    }

    

    

    