export default {
    apiGateway: {
      REGION: "YOUR_API_GATEWAY_REGION",
      URL: "YOUR_API_GATEWAY_URL"
    },
    cognito: {
      REGION: "eu-west-2",
      USER_POOL_ID: "eu-west-2_vaPn7u4QB",
      APP_CLIENT_ID: "qesf5oph2cbf5usugqc5koesh",
      IDENTITY_POOL_ID: "eu-west-2:fca2c6d9-c02c-45df-91a1-ed548ecd45eb"
    }
  };