const ttbAllowedSuppliers = ['All', 'TalkTalkCityFibre', 'TalkTalk']

export const mapTtb = (quotes, supplier = 'All') => {
  const rows = [];
  if (ttbAllowedSuppliers.indexOf(supplier) === -1)
    return rows;
    
  quotes.allQuotes.forEach(element => {
    if (element != null && element != typeof "undefined") {
      if (element.Products != null && element.Products != typeof "undefined" && element.Products.length > 0) {
        for (var i = 0; i < element.Products.length; i++) {
          const product = element.Products[i];

          rows.push({
            product: product.ProductCode, years: product.ContractTerm, 
            circuitinstallation: "£" + product.CircuitInstallation, circuitmonthly: "£" + product.CircuitMonthly,
            circuitannual: "£" + product.CircuitAnnual, typicalleadtime: product.TTLeadTime,
            orleadTime: product.ORLeadTime, servicebandwidth: product.ServiceBandwidth, 
            accessbandwidth: product.AccessBandwidth, capped: product.Capped, 
            efmupstreambandwidth: product.EFMUpstreamBandwidth, efmdownstreambandwidth: product.EFMDownstreamBandwidth,
            accesssubtype:  product.AccessSubType, availabilitysubtype: product.AvailabilityEADSubType,
            eadbandwidth: product.EADBandwidth, zone: product.Zone, efmestimatedownstreamspeed: product.EstimatedDownstreamSpeed,
            image: 'TTB'
          });
        }
      }
    }
  });

  rows.sort(sortByProductSpeed);
  return rows;
}

export const sortByProductSpeed = (a, b) => {
  if (a.accessbandwidth !== b.accessbandwidth) {
    return a.accessbandwidth - b.accessbandwidth;
  }

  if (a.servicebandwidth !== b.servicebandwidth) {
    return a.servicebandwidth - b.servicebandwidth;
  }

  return 0;
}

export const mapCityFibre = (quotes, supplier= 'All', ttbData) => {
  const rows = [];
  const hasTtbData = ttbData.length;

    quotes.forEach(element => {
      if (!element) return;
      if (hasTtbData && element.Carrier === 'TalkTalk') return;

      rows.push({
        product: element.Product,
        circuitinstallation: `£${element.InstallationCost}`,
        circuitannual: `£${element.CircuitAnnual}`,
        years: element.Years,
        typicalleadtime: element.LeadTime,
        circuitmonthly: `£${element.CircuitMonthly}`,
        servicebandwidth: element.ServiceBandwidth,
        carrier: element.Carrier,
        image: 'CityFibre'
      });
    });

    return supplier === 'All'
      ? rows
      : rows.filter(row => row.carrier === supplier);
}