import { BASE_URL } from "../environment";
import makeRequest from "../makeRequest";

export async function searchServices(searchTerm) {
  return await searchZenServices(searchTerm);
}

async function searchZenServices(searchTerm) {
  const results = await makeRequest(`${BASE_URL}/zensearchservices?searchTerm=${searchTerm}&pageSize=10000`);
  if (!results || !results.services.length) {
    return [];
  }

  const mappedResults = results.services.map(order => mapZenService(order));

  mappedResults.sort((a, b) => a.serviceStatus < b.zenReference ? 1 : -1);
  return mappedResults;
}

const mapZenService = (service) => {

  const serviceStatuses = {
    0: 'Unknown',
    1: 'Active',
    2: 'Provisioning',
    3: 'CancelledInFlight',
    4: 'CeasedWithSupplier',
    5: 'ActiveCeasePending',
    6: 'Ceased',
    7: 'ActiveModifyPending',
    8: 'ActiveModifyInProgress'
  };

  const networks = {
    0: "Unknown",
    1: "NetworkOnNet",
    2: "NetworkOffNet"
  }

  const trafficWeightings = {
    0: "None",
    1: "Standard",
    2: "Elevated"

  }

  const careLevels = {
    0: "None",
    1: "Standard",
    2: "Enhanced",
    3: "Critical"
  }

  const upstreamSpeeds = {
    0: "None",
    1: "Standard",
    2: "Elevated",
  }

  return {
    ...service,
    reference: service.zenReference,
    serviceStatus: serviceStatuses[service.serviceStatus],
    network: networks[service.network],
    cli: service.phoneNumber,
    customerReference: service.customerReference,
    product: service.productDescription,
    careLevel: careLevels[service.careLevel],
    trafficWeighting: trafficWeightings[service.trafficWeighting],
    upstreamSpeed: upstreamSpeeds[service.upstreamSpeed],
    startDate: new Date(service.startDate).toLocaleDateString(),
    organisationName: service.installationAddress.organisationName,
    subPremiseName: service.installationAddress.subPremiseName,
    premiseName: service.installationAddress.premiseName,
    thoroughfareNumber: service.installationAddress.thoroughfareNumber,
    thoroughfare: service.installationAddress.thoroughfare,
    dependentThoroughfare: service.installationAddress.dependentThoroughfare,
    postTown: service.installationAddress.postTown,
    postCode: service.installationAddress.postCode
  };
}