import React, { useEffect, useState, Component } from 'react';
import { FormGroup, Panel } from 'react-bootstrap';
import { Dropdown, GridColumn, Grid, GridRow, FormInput, Divider, Form } from 'semantic-ui-react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import { format, compareAsc } from 'date-fns'
import { useFormik, withFormik, useField } from 'formik';
import Holidays from 'date-holidays';
var moment = require("moment-business-days");


export const ActivationDate = (props) => {
  useEffect(() => {
  }, [props.show])

  const handleStartDateChange = (e, date) => {
    setStartDate(e);
    setFieldValue('activationDateField', e);
  };
  
  const [country, setCountry] = useState('GB')
  const hd = new Holidays(country);
  const holidays = hd.getHolidays();
  const leadTime = addLeadTime(new Date());

  const [startDate, setStartDate] = useState(new Date(leadTime));

  function addLeadTime(){
    moment.locale('en-gb');
    let originalLeadTime = moment(props.today, 'dd.MM.yyyy').businessAdd(props.leadTimeDays);

    holidays.forEach(element => {
        if(moment(element.date).isSame(originalLeadTime._d, 'day') && element.type === 'public' || moment(element.date).isSame(originalLeadTime._d, 'day') && element.type === 'bank'){
            originalLeadTime = moment(originalLeadTime, 'dd.MM.yyyy').businessAdd(1);
        }
    });
    
    return originalLeadTime;
}

const isWeekdayOrHoliday = (date) => {

    const day = date.getDay()
    var show = day !== 0 && day !== 6;

    holidays.forEach(element => {
        if(moment(date).isSame(element.date, 'day')){
            show = false;
        }
     })
    return show;
  }

   // Date Picker
   useEffect(() => {
    function getDatePicker() {
        const response = <DatePicker minDate={new Date(leadTime)} filterDate={isWeekdayOrHoliday} selected={format(startDate, 'dd.MM.yyyy')} onChange={(date) => setStartDate(format(date, 'dd.MM.yyyy'))} />
    }
    getDatePicker();
}, []);

  const { values, handleChange, handleSubmit, setFieldValue, isValid, errors, ...formik } = useFormik({
    validate,
    validateOnChange: true,
    validateOnMount: true,
    initialValues: {
      activationDateField: ''
    }
  });

  function validate({activationDateField}) {
    const errors = {};

    if (activationDateField < leadTime) {
      errors.activationDateField = true;
    }

    return errors;
  }

  if (props.show) {
    return (
      <Grid style={{ marginLeft: '2px' }}>
        <GridRow>
          <GridColumn>
          <FormGroup>
                                <FormInput inline label='Preferred Date' name='activationDateField' data-testid='activationDateField'  >
                                    <DatePicker
                                        selected={startDate} //when day is clicked
                                        onChange={(e, val) => handleStartDateChange(e, val, setFieldValue)}
                                        locale="enGB"
                                        dateFormat="dd/MM/yyyy"
                                        name="activationDateField"
                                        value={values.preferredDate}
                                        id="activationDateField"
                                        minDate={new Date(leadTime)}
                                        filterDate={isWeekdayOrHoliday}
                                        required
                                    />
                                </FormInput>
                                </FormGroup>
                <Divider></Divider>
          </GridColumn>
        </GridRow>
      </Grid>
    )
  }
  else {
    return null;
  }
}



