export function selectErrorBannerMessage(error) {

  const errorsList = JSON.stringify(error);
  const errors = [];

  if(typeof error !== 'string'){
    for (var item in error){
      for (var element in error[item])
      {
        errors.push(error[item][element]);
      }
    }
  }

  if(typeof error === 'string'){
    errors.push(error);
  }

  return errors.map(e => e);
}