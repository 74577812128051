import React, { useContext, useEffect, useState } from 'react';
import Panel from '../Panel';
// import { searchOrders } from '../libs/requests/orderSearch';
import { authContext } from '../../containers/AuthProvider';
import { Link, useParams } from 'react-router-dom';
import { Accordion, AccordionContent, AccordionTitle, Button, Comment, CommentAuthor, CommentContent, CommentGroup, CommentMetadata, CommentText, Dimmer, Divider, Form, FormTextArea, Grid, GridColumn, Icon, Label, Loader, Message, Segment } from 'semantic-ui-react';
import { searchServices } from '../../libs/requests/serviceSearch';
import { GridItem } from '../ViewOrder';
import { Header } from 'react-bootstrap/lib/Modal';

const fields = [
  { key: "reference", label: "Reference" },
  { key: "appointmentDate", label: "Appointment Date" },
  { key: "siteContactName", label: "Site Contact" },
  { key: "siteContactPhoneNumber", label: "Site Contact Number" },
  { key: "raisedDate", label: "Raised" },
  { key: "requestedBy", label: "Requested By" },
  { divider: true },
];

const lipsum = `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Eu ultrices vitae auctor eu augue ut lectus arcu bibendum. Egestas fringilla phasellus faucibus scelerisque eleifend donec. Purus viverra accumsan in nisl. Eu consequat ac felis donec et odio pellentesque diam volutpat. Volutpat sed cras ornare arcu dui vivamus. Nec ullamcorper sit amet risus nullam eget felis. Ac auctor augue mauris augue neque gravida. Facilisi etiam dignissim diam quis enim lobortis scelerisque fermentum. Eros donec ac odio tempor orci dapibus ultrices. Fermentum iaculis eu non diam phasellus vestibulum lorem. Nec sagittis aliquam malesuada bibendum arcu vitae elementum curabitur vitae. Ornare suspendisse sed nisi lacus sed viverra. Tellus elementum sagittis vitae et leo. Adipiscing tristique risus nec feugiat in fermentum posuere urna. Quisque non tellus orci ac auctor. Etiam erat velit scelerisque in dictum non. Facilisis mauris sit amet massa.

Dolor sit amet consectetur adipiscing elit. Eget lorem dolor sed viverra ipsum nunc. Dolor morbi non arcu risus quis varius quam quisque. Praesent semper feugiat nibh sed pulvinar proin. At augue eget arcu dictum varius duis at. Convallis tellus id interdum velit laoreet id donec ultrices. Eu tincidunt tortor aliquam nulla facilisi cras fermentum odio eu. Enim neque volutpat ac tincidunt vitae semper. Diam donec adipiscing tristique risus nec. Neque laoreet suspendisse interdum consectetur. Tincidunt augue interdum velit euismod in pellentesque massa placerat. Id volutpat lacus laoreet non curabitur gravida arcu.

Etiam non quam lacus suspendisse faucibus interdum posuere lorem. At urna condimentum mattis pellentesque id. Iaculis urna id volutpat lacus laoreet non curabitur gravida. Ullamcorper dignissim cras tincidunt lobortis feugiat vivamus at. Dictum varius duis at consectetur. Accumsan sit amet nulla facilisi morbi tempus iaculis. Est placerat in egestas erat imperdiet sed euismod. Turpis egestas integer eget aliquet nibh praesent. Amet consectetur adipiscing elit pellentesque habitant morbi. Duis ultricies lacus sed turpis tincidunt id aliquet. Ut consequat semper viverra nam libero. Nisl condimentum id venenatis a condimentum vitae. Nisl suscipit adipiscing bibendum est ultricies integer quis. Risus quis varius quam quisque id diam. Tempor nec feugiat nisl pretium fusce id velit ut tortor. Duis ultricies lacus sed turpis. Consequat nisl vel pretium lectus quam id leo. Mauris pharetra et ultrices neque ornare aenean euismod elementum nisi. Tortor id aliquet lectus proin nibh nisl condimentum. Eleifend donec pretium vulputate sapien nec sagittis aliquam malesuada bibendum.'`;

const dummyFault = {
  reference: 'TestFault',
  state: 'Open',
  requestedBy: 'Test Person',
  appointmentDate: '12/02/24',
  siteContactName: 'Joe Bloggs',
  siteContactPhoneNumber: '07766890086',
  description: lipsum,
  raisedDate: '12/02/24',
  comments: [
    {
      author: 'Zen',
      date: '12/02/24',
      text: 'Comment from Zen'
    },
    {
      author: 'Customer',
      date: '13/02/24',
      text: 'Comment from Customer'
    }
  ]
}

const Fault = ({ fault, isOpen }) => {
  return (
    <>
      <Grid columns={2}>
        {fields.map((field) => (
          <GridItem
            field={field}
            value={fault[field.key]}
            key={field.key}
          />
        ))}
      </Grid>
      <Grid width={1}>
        <GridColumn>
          <strong>Description</strong><br />
          {fault.description}
        </GridColumn>
      </Grid>
      <Grid>
        <GridColumn>
          <CommentGroup>
            <Header as='h3' dividing>
              Comments
            </Header>
            {fault.comments.map(comment => (
              <Comment>
                <CommentContent>
                  <CommentAuthor as='strong'>{comment.author}</CommentAuthor>
                  <CommentMetadata>
                    <span>{comment.date}</span>
                  </CommentMetadata>
                  <CommentText>{comment.text}</CommentText>
                </CommentContent>
              </Comment>
            ))}
          </CommentGroup>
        </GridColumn>
      </Grid>
      {isOpen && (
        <Grid>
          <GridColumn>
            <Form reply>
              <FormTextArea />
              <Button className="button-primary" content='Reply' labelPosition='left' icon='edit' primary />
            </Form>
          </GridColumn>
        </Grid>
      )}

    </>
  )
}

export default function Faults() {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [order, setOrder] = useState({});
  const [service, setService] = useState({});
  const [openFault, setOpenFault] = useState(dummyFault);
  const [closedFaults, setClosedFaults] = useState([dummyFault, dummyFault, dummyFault]);
  const [activeIndex, setActiveIndex] = useState(-1);

  const { reference } = useParams();
  const { permissions } = useContext(authContext);

  useEffect(() => {
    (async () => {
      try {
        if (permissions.diagnosticsAccess) {
          // setIsLoading(true);
          // setService((await searchServices(reference))[0]);
          // setIsLoading(false);
        }
      }
      catch (err) {
        console.error(err);
        setIsError(true);
        setIsLoading(false);
      }
    })();
  }, []);


  function handleClick(e, titleProps) {
    const { index } = titleProps
    // const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    // this.setState({ activeIndex: newIndex })
    setActiveIndex(newIndex);
  }

  if (!isLoading && !permissions.diagnosticsAccess) {
    return (
      <Panel>
        <Message error header="You don't have permission to view this page" />
      </Panel>
    )
  }

  // const header = <>
  //   <span>{`Reference ${reference}`}</span>
  //   <strong style={{ float: 'right' }}>{order ? order.status : ''}</strong>
  // </>

  return (
    <>
      {/* <Segment> */}
      <Dimmer active={isLoading} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      {permissions.demoUser && (
        <>
          {openFault && <Panel header={'Open Fault'}>
            <Fault fault={openFault} isOpen={true} />
          </Panel>}

          {closedFaults.length && <Panel header={'Recently Closed Faults'}>
            <Accordion fluid styled>

              {closedFaults.map((fault, idx) => {
                // return <Fault fault={fault} isOpen={false} />
                return (
                  <>
                    <AccordionTitle
                      active={activeIndex === idx}
                      index={idx}
                      onClick={handleClick}
                      key={fault.reference}
                    >
                      <Label ribbon color='red'>Closed</Label>
                      <Icon name='dropdown' />
                      {fault.reference} ({fault.raisedDate})
                    </AccordionTitle>
                    <AccordionContent active={activeIndex === idx}>
                      <Fault fault={fault} isOpen={false} />
                    </AccordionContent>
                  </>
                )
              })}
            </Accordion>

          </Panel>}
        </>
      )
      }
      {/* </Segment> */}
    </>
  )
}