import React, { useEffect, useState } from "react";
import Panel from "../Panel";
import {
  Form,
  FormInput,
  Grid,
  GridColumn,
  GridRow,
  FormGroup,
  Divider,
  Dropdown,
  Button,
  Message,
  FormField,
} from "semantic-ui-react";
import { useFormik } from "formik";
import "../OrderComponents/ZenMigration.css";
import "react-datepicker/dist/react-datepicker.css";
import { AppointmentComponent } from "./ZenAppointmentComponent";
import { ActivationDate } from "./ActivationDate";
import postZenOrder from "../../libs/requests/postZenOrder";
import { selectErrorBannerMessage } from "../../libs/zenErrorBannerMessage";
import * as utilities from "./Utilities";
import { AppointmentRequiredComponent } from "./ZenAppointmentRequired";
import addressSearchRequest from "../../libs/requests/addressSearch";
import { getRealms } from "../../libs/requests/getPartnerDetails";
import { checkUsernameValid } from "../../libs/utils";

export default function SogeaRestartOrder() {
  const selectedProduct = sessionStorage.getItem("selectedProduct");
  const product = JSON.parse(selectedProduct);
  const [isLoading, setIsLoading] = React.useState(false);
  const [level, setLevel] = React.useState({ careLevelField: "Standard" });
  const [weighting, setTrafficWeighting] = React.useState({
    trafficWeightingField: "Standard",
  });
  const [zenReference, setZenReference] = React.useState();
  const [errorMessage, setErrorMessage] = React.useState([]);
  const [addressValue, setAddressValue] = React.useState({
    addressField: {
      addressReferenceNumber: "",
      districtCode: "",
      qualifier: "",
    },
  });
  const [usernameMessage, setUsernameMessageResults] = React.useState("");
  const [usernameValue, setUsernameValue] = React.useState("");
  const [radioButtons, setRadioButtons] = React.useState("0");
  const [addressResults, setAddressResults] = React.useState([]);
  const [supplier, setSupplier] = React.useState("");
  const [isAppointmentRequired, setIsAppointmentRequired] =
    React.useState(true);
  const [provisionType, setProvisionType] = React.useState(0);
  const { addressReferenceNumber } = product?.orderDetails?.request
    ?.goldAddressKeyAvailabilityRequest
    ? product?.orderDetails?.request?.goldAddressKeyAvailabilityRequest
    : "";
  const postCode = product?.orderDetails?.requestPostCode
    ? { postCode: product?.orderDetails?.requestPostCode }
    : {
        postCode: !product?.orderDetails?.lineCharacteristics
          ?.btOpenreachPostCode
          ? product?.orderDetails?.lineCharacteristics?.btWholesalePostCode
          : product?.orderDetails?.lineCharacteristics?.btOpenreachPostCode,
      };
  const [realm, setRealm] = useState("dslnet");
  const [realms, setRealms] = useState(["dslnet"]);
  const [isL2Customer, setIsL2Customer] = useState(false);

  React.useEffect(() => {
    setProvisionType(product?.orderDetails?.provision?.provisionType);
  }, [product]);

  useEffect(() => {
    (async () => {
      const defaultSupplier = await utilities.fetchSuppllier();
      setSupplier(defaultSupplier);
      await utilities.fetchSuppllier();

      const l2 = await utilities.isL2();

      if (l2) {
        setIsL2Customer(l2);
      }

      if (!isL2Customer) {
        const realms = await getRealms();
        setRealms(realms);
        setRealm(realms[0]?.value);
      }
    })();
  }, []);

  useEffect(() => {
    async function getAddressResults() {
      const response = await getAddresses(postCode.postCode).then((data) => setAddressResults(data));
    }
    getAddressResults();
  }, []);

  const getAddresses = async (postCode) => {
    const result = await addressSearch(postCode);
    const optionsList = [];

    if (addressReferenceNumber) {
      result.forEach(function (element) {
        if (
          Object.values(element.addressReference).indexOf(
            addressReferenceNumber
          ) > -1
        ) {
          optionsList.push({
            key: element.addressReference,
            value: element.addressReference,
            text: element.address,
            flag: element.addressReference,
          });
        }
      });
    } else {
      result.forEach(function (element) {
        optionsList.push({
          key: element.addressReference,
          value: element.addressReference,
          text: element.address,
          flag: element.addressReference,
        });
      });
    }
    return optionsList;
  };

  const addressSearch = async (postCode) => {
    try {
      return await addressSearchRequest(postCode);
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddressChange = (event, result) => {
    const { name, value } = result || event.target;
    setAddressValue({ ...addressValue, [name]: value });
    setFieldValue("addressField", value);
  };

  const handleCareLevelChange = (event, result) => {
    const { name, value } = result || event.target;
    setLevel({ ...level, [name]: value });
  };

  const handleTrafficWeightingChange = (event, result) => {
    const { name, value } = result || event.target;
    setTrafficWeighting({ ...weighting, [name]: value });
  };

  const handleRealmChange = async (event, result) => {
    const { value } = result || event.target;
    setRealm(value);
    setFieldValue("realm", value);

    setUsernameMessageResults(await checkUsernameValid(usernameValue, value));
  };

  const handleUsernameChange = async (e, result) => {
    setUsernameValue(e.currentTarget.value);
    setFieldValue("usernameField", e.currentTarget.value);

    setUsernameMessageResults(
      await checkUsernameValid(e.currentTarget.value, realm)
    );
  };

  // Render New Provide and Appointment section on click

  const [isAppointmentVisible, setAppointmentVisible] = useState(false);
  const [isActivationDateVisible, setActivationDateVisible] = useState(true);

  function setStyle(e) {
    e.currentTarget.className += " uiButtonProvideActive";
  }

  const renderTextDisabledButton = () => {
    const buttons = [];

    buttons.push(
      <Button
        id="newProvideButton"
        onClick={(e) => setStyle(e)}
        style={{ width: "350px", height: "150px" }}
        className="uiButtonPorts"
      >
        {product?.orderDetails?.accessLines[0]?.accessLineId
          ? "LineID - ".concat(product.orderDetails.accessLines[0].accessLineId)
          : "LineID"}
        <br />
        <br />
        {product?.orderDetails?.accessLines[0]?.serviceType
          ? "Service Type - ".concat(
              product?.orderDetails?.accessLines[0]?.serviceType
            )
          : "Service Type"}
        <br />
        <br />
        {product?.orderDetails?.accessLines[0]?.lineNumber
          ? "Line Number - ".concat(
              product?.orderDetails?.accessLines[0]?.lineNumber
            )
          : "Line Number"}
        <br />
        <br />
        {product?.orderDetails?.accessLines[0]?.last2Digits
          ? "Last 2 Digits - ".concat(
              product?.orderDetails?.accessLines[0]?.last2Digits
            )
          : "Last 2 Digits"}
        <br />
        <br />
      </Button>
    );

    return buttons;
  };

  const handleRadioChange = (e) => {
    setRadioButtons(e.currentTarget.value);
    setFieldValue("noOfIps", e.currentTarget.value);
  };

  const {
    values,
    handleChange,
    handleSubmit,
    setFieldValue,
    isValid,
    errors,
    ...formik
  } = useFormik({
    onSubmit,
    validate,
    validateOnChange: true,
    validateOnMount: true,
    initialValues: {
      siteInformation: "",
      addressField: {
        addressReferenceNumber: "",
        districtCode: "",
        qualifier: "",
      },
      noOfIps: "1",
      activationDateField: "",
      orderRef: "",
      contactName: "",
      contactTel: "",
      usernameField: "",
      password: "",
      rid: "",
    },
  });

  function validate({
    contactName,
    contactTel,
    usernameField,
    password,
    addressField,
    noOfIps,
    orderRef,
    rid,
  }) {
    const errors = {};
    const address =
      addressField.qualifier === "Silver"
        ? addressField.uprn
        : addressField.addressReferenceNumber;

    if (typeof rid == "undefined" || !rid.length) {
      errors.rid = true;
    }

    if (typeof contactName == "undefined" || !contactName.length) {
      errors.addressField = true;
    }

    if (typeof contactTel == "undefined" || !contactTel.length) {
      errors.addressField = true;
    }

    if (!address) {
      errors.addressField = true;
    }

    if (
      (typeof noOfIps == "undefined" && !isL2Customer) ||
      (!noOfIps.length && !isL2Customer)
    ) {
      errors.noOfIps = true;
    }

    if (typeof orderRef == "undefined" || !orderRef.length) {
      errors.orderRef = true;
    }

    if (!usernameField && !isL2Customer) {
      errors.username = true;
    }

    if (
      (!isL2Customer && typeof password == "undefined") ||
      (!isL2Customer && !password.length)
    ) {
      errors.password = true;
    }

    return errors;
  }

  async function onSubmit({
    usernameField,
    password,
    addressField,
    noOfIps,
    activationDateField,
    orderRef,
    contactName,
    contactTel,
    rid,
  }) {
    setIsLoading(true);

    const selectedAddress =
      addressField?.qualifier === "Gold"
        ? addressResults.find(
            (element) =>
              element.key.addressReferenceNumber ===
              addressField?.addressReferenceNumber
          )
        : addressResults.find(
            (element) => element.key.uprn === addressField?.uprn
          );

    const addressDetails = selectedAddress.text;
    const address = addressDetails.split(",");
    const isPremiseNameOrThoroughfare = parseInt(address[0]);
    const usePremiseName = isNaN(isPremiseNameOrThoroughfare);
    const premiseName = usePremiseName ? address[0] : null;
    const thoroughFareNumber = !usePremiseName ? address[0] : null;
    let activationDate = !document.getElementById("activationDateField") ? "" : document.getElementById("activationDateField").defaultValue;
    let year = activationDate.substring(6, 10);
    let month = activationDate.substring(3, 5) - 1; // JavaScript months are 0-indexed
    let day = activationDate.substring(0, 2);
    let dateObject = new Date(year, month, day, 12, 0, 0);
    activationDate = dateObject.toISOString();
    let timeSlot =
      !document.getElementById("preferredTimeslotField") ||
      document.getElementById("preferredTimeslotField").innerText === "Any"
        ? 0
        : document.getElementById("preferredTimeslotField").innerText;
    let hazardNotes = !document.getElementById("siteSafetyNotesField")
      ? ""
      : document.getElementById("siteSafetyNotesField").value;
    let notes = !document.getElementById("sitevisitNotesField")
      ? ""
      : document.getElementById("sitevisitNotesField").value;
    let specialArrangementNotes = !document.getElementById(
      "specialArrangementNotesField"
    )
      ? ""
      : document.getElementById("specialArrangementNotesField").value;
    let isNewProvide = false;

    if (timeSlot === "AM") {
      timeSlot = 1;
    }

    if (timeSlot === "PM") {
      timeSlot = 2;
    }

    if (document.getElementsByClassName("uiButtonProvideActive")) {
      var elemProvide = document.getElementsByClassName(
        "uiButtonProvideActive"
      );
      var provideType = elemProvide[0].id;
      isNewProvide = provideType === "newProvideButton";
    }

    try {
      const javaObject = {
        availabilityReference: product.orderDetails.availabilityReference,
        installationDetails: {
          phoneNumber:
            typeof product.orderDetails.accessLines != "undefined" &&
            product.orderDetails.accessLines.length > 0
              ? product.orderDetails.accessLines[0].lineNumber
              : "",
          goldAddressKey:
            typeof addressField?.addressReferenceNumber != "undefined"
              ? addressField?.addressReferenceNumber
              : "",
          districtCode: addressField.districtCode,
          address: {
            premiseName: premiseName,
            thoroughfareNumber: thoroughFareNumber,
            thoroughfare: address[1],
            postTown: address[2].trim(),
            postCode: postCode.postCode.trim(),
          },
          uprn:
            typeof addressField?.uprn != "undefined" ? addressField?.uprn : ""
        },
        productCode: product.productCode,
        options: [
          {
            type: 1,
            value: level.careLevelField
          },
          {
            type: 2,
            value: weighting.trafficWeightingField
          },
        ],
        preferredActivationDate: activationDate,
        orderReference: orderRef,
        rid: rid,
        endUserContact: {
          fullName: contactName,
          phoneNumber: contactTel,
        },
        ...(!isL2Customer
          ? {
              broadbandCredentials: {
                username: `${usernameField}@${realm}`,
                password: password,
                numberOfIps: parseInt(noOfIps),
              },
            }
          : {}),
        accessLineId:
          typeof product.orderDetails.accessLines != "undefined" &&
          product.orderDetails.accessLines.length > 0
            ? product.orderDetails.accessLines[0].accessLineId
            : "",
      };

      // Conditionally add the appointment section if isNewProvide or isAppointmentRequired is true
      if (isNewProvide || isAppointmentRequired) {
        javaObject.appointment = {
          hazardNotes: hazardNotes,
          notes: notes,
          specialArrangementNotes: specialArrangementNotes,
          timeSlot: isNaN(parseInt(timeSlot)) ? 0 : parseInt(timeSlot),
        };
      }

      const body = JSON.stringify(javaObject);
      setErrorMessage([]);
      setZenReference(await postZenOrder(body));
      setErrorMessage([]);
      setIsLoading(false);
    } catch (ex) {
      console.log(ex);
      setErrorMessage([]);
      const errors = selectErrorBannerMessage(ex);
      setErrorMessage(errors);
      setIsLoading(false);
    }
  }

  return (
    <>
      <Panel header={supplier + " " + product.productName}>
        {typeof errorMessage != "undefined" && errorMessage.length !== 0 && (
          <Message
            error
            header="There was some errors with your submission"
            list={errorMessage}
          />
        )}

        {zenReference && (
          <Message
            success
            header="Your submission was successful"
            content={"Order reference: " + zenReference}
          />
        )}
        <Form onSubmit={useFormik.handleSubmit} className="disabled">
          <Grid columns={1}>
            <GridRow>
              <GridColumn>
                <FormGroup>
                  <FormInput
                    style={{ width: "250px" }}
                    inline
                    label="Product"
                    name="productLabel"
                    data-testid="productCode"
                    disabled={true}
                    value={product.productCode}
                    required
                  />
                </FormGroup>
                <Divider></Divider>
              </GridColumn>
            </GridRow>
            <GridRow>
              <GridColumn>
                <FormGroup>
                  <Form.Field
                    inline
                    label="Provision Type"
                    name="provisionLabel"
                    data-testid="provisioningType"
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Form.Field style={{ margin: "auto" }}>
                    {renderTextDisabledButton()}
                  </Form.Field>
                </FormGroup>
                <Divider></Divider>
              </GridColumn>
            </GridRow>
          </Grid>
          <Grid>
            <GridRow>
              <GridColumn>
                <FormGroup>
                  <Form.Field
                    inline
                    name="addressLabel"
                    data-testid="addressDropDown"
                    label={"Address  - ".concat(postCode.postCode)}
                    disabled={true}
                    required
                  ></Form.Field>
                </FormGroup>
                <FormGroup>
                  <Dropdown
                    placeholder="Select Address"
                    fluid
                    search
                    selection
                    options={addressResults || []}
                    label="addressField"
                    id="addressField"
                    name="addressField"
                    onChange={(e, val) =>
                      handleAddressChange(e, val, setFieldValue)
                    }
                    value={addressValue.addressField}
                  />
                </FormGroup>
                <Divider></Divider>
              </GridColumn>
            </GridRow>
          </Grid>
          <Grid>
            <GridRow>
              <GridColumn>
                <FormGroup>
                  <Form.Field
                    inline
                    label={"Product Options"}
                    name="optionsLabel"
                    data-testid="optionsLabel"
                  />
                </FormGroup>
                <FormGroup>
                  <Form.Field label="Care Level" required />
                </FormGroup>
                <Grid>
                  <Grid.Row centered>
                    <Grid.Column width={12}>
                      <Form>
                        <FormGroup>
                          <Dropdown
                            inline
                            placeholder="Select Care Level"
                            fluid
                            search
                            selection
                            options={utilities.careLevels}
                            label="careLevelField"
                            id="careLevelField"
                            name="careLevelField"
                            defaultValue={utilities.careLevels[0].value}
                            onChange={handleCareLevelChange}
                            value={level.careLevelField}
                          />
                        </FormGroup>
                      </Form>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <FormGroup>
                  <Form.Field label="Traffic Weighting" required />
                </FormGroup>
                <Grid>
                  <Grid.Row centered>
                    <Grid.Column width={12}>
                      <Form>
                        <FormGroup>
                          <Dropdown
                            placeholder="Select Traffic Weighting"
                            fluid
                            search
                            selection
                            options={utilities.trafficWeighting}
                            label="trafficWeightingField"
                            id="trafficWeightingField"
                            name="trafficWeightingField"
                            defaultValue={utilities.trafficWeighting[0].value}
                            onChange={handleTrafficWeightingChange}
                            value={weighting.trafficWeightingField}
                          />
                        </FormGroup>
                      </Form>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                {!isL2Customer && (
                  <>
                    <Divider></Divider>
                    <FormGroup>
                      <Form.Field
                        style={{ width: "250px" }}
                        inline
                        label="Credentials"
                        name="credentials"
                        data-testid="credentialsLabel"
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormInput
                        style={{ width: "250px" }}
                        inline
                        label="Username"
                        name="usernameField"
                        data-testid="usernameField"
                        disabled={false}
                        onChange={handleUsernameChange}
                        values={values.usernameField}
                        required
                      />
                      <Dropdown
                        style={{
                          height: "38px",
                          padding: "7px",
                          minWidth: "200px",
                        }}
                        options={realms}
                        id="realm"
                        name="realm"
                        defaultValue={realm}
                        onChange={handleRealmChange}
                        value={realm}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormInput
                        style={{ width: "250px" }}
                        inline
                        label="Password"
                        name="password"
                        id="password"
                        data-testid="password"
                        value={values.password}
                        onChange={handleChange}
                        required
                      />
                    </FormGroup>
                    {usernameMessage.includes(
                      "The username should be a minimum of 3 characters"
                    ) && (
                      <span className="login__error">{usernameMessage}</span>
                    )}
                    {(usernameMessage.includes("Available") ||
                      usernameMessage.includes("NotAvailable") ||
                      usernameMessage.includes("Invalid")) && (
                      <span className="login__success">{usernameMessage}</span>
                    )}
                  </>
                )}
                {isAppointmentRequired && <Divider> </Divider>}
                <FormGroup>
                  <GridRow columns={1} centered>
                    <GridColumn>
                      {
                        <AppointmentRequiredComponent
                          isVisible={isAppointmentRequired}
                        />
                      }
                    </GridColumn>
                  </GridRow>
                </FormGroup>

                {!isL2Customer && (
                  <>
                    <Divider></Divider>
                    <FormGroup>
                      <Form.Field
                        inline
                        label="No of IP Addresses"
                        name="ipsLabel"
                        data-testid="ipsLabel"
                        required
                      />
                    </FormGroup>
                    <FormGroup>
                      <Form.Field>
                        <input
                          type="radio"
                          name="noOfIps"
                          value={utilities.ips[0].value}
                          defaultChecked
                          className="ui.radio"
                          onChange={(e) => handleRadioChange(e, setFieldValue)}
                        />
                      </Form.Field>
                      <label>1</label>
                      <Form.Field>
                        <input
                          type="radio"
                          name="noOfIps"
                          value={utilities.ips[1].value}
                          className="ui.radio"
                          onChange={(e, val) =>
                            handleRadioChange(e, val, setFieldValue)
                          }
                        />
                      </Form.Field>
                      <label>4</label>
                    </FormGroup>
                  </>
                )}
                <Divider></Divider>
                <FormGroup>
                  <Form.Field label="Contact Details" required />
                </FormGroup>
                <Form.Field>
                  <Form.Input
                    placeholder="Enter Contact Name"
                    id="contactName"
                    name="contactName"
                    value={values.contactName}
                    onChange={handleChange}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Input
                    placeholder="Enter Contact Telephone Number"
                    defaultCountry="GB"
                    id="contactTel"
                    name="contactTel"
                    value={values.contactTel}
                    onChange={handleChange}
                  />
                </Form.Field>
                <Divider></Divider>
                {
                  <ActivationDate
                    show={isActivationDateVisible}
                    today={new Date()}
                    leadTimeDays={10}
                  />
                }
                {
                  <AppointmentComponent
                    isVisible={isAppointmentVisible}
                    today={new Date()}
                    leadTimeDays={5}
                  />
                }
                <FormGroup>
                  <Form.Field
                    inline
                    label="References"
                    name="references"
                    data-testid="referencesLabel"
                    disabled={true}
                  />
                </FormGroup>
                <FormGroup>
                  <FormInput
                    inline
                    label="Order Reference"
                    name="orderRef"
                    data-testid="orderRefLabel"
                    disabled={false}
                    value={values.orderRef}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <FormInput
                    inline
                    label="RID"
                    name="rid"
                    data-testid="ridLabel"
                    disabled={false}
                    value={values.rid}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
                <Button
                  disabled={!isValid || zenReference}
                  className="button-primary"
                  floated="right"
                  content="Place Order"
                  onClick={handleSubmit}
                  loading={isLoading}
                />
              </GridColumn>
            </GridRow>
          </Grid>
        </Form>
      </Panel>
    </>
  );
}
