import React, { useContext, useState } from 'react';
import { Button, Form, Grid, Header, Image, Segment } from 'semantic-ui-react';
import '../../src/containers/Login.css';
import { useFormik } from 'formik';
import { authContext } from '../containers/AuthProvider';
import { themeContext } from '../containers/ThemeProvider';
import './LoginForm.css';


export default function LoginForm() {
  const [isLoading, setIsLoading] = useState(false);
  const { login, signInError } = useContext(authContext);
  const { assets } = useContext(themeContext);

  function validate({ email, password }) {
    const errors = {};

    if (!email.length) {
      errors.email = true;
    }

    if (!password.length) {
      errors.password = true;
    }

    return errors;
  }

  async function onSubmit({ email, password }) {
    setIsLoading(true);

    try {
      await login(email, password);
    } catch (ex) {
      console.log('Error', ex);
      setIsLoading(false);
    }
  }

  const { values, handleChange, handleSubmit, isValid } = useFormik({
    onSubmit,
    validate,
    validateOnChange: true,
    validateOnMount: true,
    initialValues: {
      email: '',
      password: ''
    }
  });

  return (
    <Grid textAlign='center' style={{ height: '100vh', paddingTop: '7.5em' }}>
      <Grid.Column style={{ maxWidth: 450 }}>
        <Image src={assets.loginLogo || assets.logo} size={assets.logoSize} centered style={assets.logoLoginStyle} />
        <Header as='h2' color={assets.headerColour} textAlign='center' className='login header'>
          Log-in to your account
        </Header>
        <Form onSubmit={handleSubmit} size='large'>
          <Segment stacked>
            <Form.Input
              id='email'
              name='email'
              type='email'
              value={values.email}
              onChange={handleChange}
              fluid
              icon='user'
              iconPosition='left'
              placeholder='E-mail address'

            />
            <Form.Input

              id='password'
              name='password'
              fluid
              icon='lock'
              iconPosition='left'
              placeholder='Password'
              type='password'
              value={values.password}
              onChange={handleChange}
            />
            {(
              <span className='login__error'>{signInError}</span>
            )}

            <Button
              color={assets.headerColour}
              fluid
              size='large'
              disabled={!isValid}
              type='submit'
              loading={isLoading}
              className='login login-button'
            >
              Login
            </Button>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
}
