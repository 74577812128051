import { BASE_URL } from "../environment";
import makeRequest from "../makeRequest";

export default async function updatePartnerAsset(body) {
  try {
    const result = await makeRequest(`${BASE_URL}/updatepartnerasset`, { method: 'POST', body: JSON.stringify(body) });
    return result.Success;
  } catch (e) {
    console.error(e);
    return false;
  }
}