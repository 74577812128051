import React, { useContext, useEffect, useState } from 'react';
import Panel from '../Panel';
// import { searchOrders } from '../libs/requests/orderSearch';
import { authContext } from '../../containers/AuthProvider';
import { Link, useParams } from 'react-router-dom';
import { Accordion, AccordionContent, AccordionTitle, Button, Comment, CommentAuthor, CommentContent, CommentGroup, CommentMetadata, CommentText, Dimmer, Divider, Form, FormTextArea, Grid, GridColumn, Icon, Label, Loader, Message, Segment, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow } from 'semantic-ui-react';
import { searchServices } from '../../libs/requests/serviceSearch';
import { GridItem } from '../ViewOrder';

const syncFields = [
  { key: "syncStatus", label: "Sync status" },
  { key: "currentSyncTime", label: "Current sync time" },
  { key: "dataFrom", label: "Test run" },
  { key: "userName", label: "Test run by" },
  { key: "retrainsDetected", label: "Retrains detected?" },
  { key: "unavailableSecondsLast15Minutes", label: "Unavailable Seconds (last 15 mins)" },
];

const serviceTestFields = [
  { key: "voiceLineTestResult", label: "Voice Line Test Result" },
  { key: "testOutcome", label: "Test outcome" },
  { key: "mainFaultLocation", label: "Main Fault Location" },
  { key: "testOutcomeDescription", label: "Test outcome description" },
  { key: "bridgeTap", label: "Bridge Tap" },
  { key: "crossTalk", label: "Cross Talk" },
  { key: "ntePowerStatus", label: "NTE Power Status" },
  { key: "radioFrequencyIngress", label: "Radio Frequency Ingress" },
  { key: "homeWiringProblem", label: "Home Wiring Problem" },
  { key: "repetitiveElectricalImpulseNoise", label: "Repetitive Electrical Impulse Noise" },
]

const profileFields = [
  { key: "profileDownstream", label: "Profile (Downstream)" },
  { key: "profileUpstream", label: "Profile (Upstream)" },
  { key: "dateOfLastProfileChange", label: "Date of Last Change" },
  { key: "currentPolicy", label: "Current Policy" },
]

const result = {
  xdslStatusCheck: {
    syncStatus: 'In Sync',// 1,
    currentSyncTime: "100",
    retrainsDetected: 'No', //0,
    unavailableSecondsLast15Minutes: 0,
    unavailableSecondsLast15MinutesStartDate: "2024-02-0516:41:38",
    orderedProductDownstream: 80,
    orderedProductUpstream: 20,
    estimatedSpeedAtProvisioningDownstream: 80,
    estimatedSpeedAtProvisioningUpstream: 20,
    syncSpeedDownstream: 75,
    syncSpeedUpstream: 15,
    // not part of this obj in api response
    dataFrom: '2024-02-05T16:41:38',
    userName: 'test@person.com'
  },
  copperLineTest: {
    ntePowerStatus: 'On', // 0
    testOutcome: 'Pass', // 0
    testOutcomeDescription: "Pass",
    mainFaultLocation: 'Customer Equipment',
    bridgeTap: 'N/A',
    voiceLineTestResult: 'Pass',
    crossTalk: 'N/A',
    radioFrequencyIngress: 'N/A',
    homeWiringProblem: 'N/A',
    repetitiveElectricalImpulseNoise: 'N/A'
  },
  profileData: {
    profileDownstream: "0.128M - 6.1M Downstream, Retransmission Low",
    profileUpstream: "0.128M - 6.1M Upstream, Retransmission Low",
    dateOfLastProfileChange: "2024-02-0516:41:38",
    currentPolicy: 'Standard'
  },
  lineHistory: {
    downstreamRate: {
      min: 1,
      max: 10,
      avg: 5
    },
    upstreamRate: {
      min: 1,
      max: 10,
      avg: 5
    },
    upstream: {
      min: 1,
      max: 10,
      avg: 5
    },
    retrains: {
      min: 1,
      max: 10,
      avg: 5
    }
  },
  dataFrom: "2024-02-0516:41:38",
  userName: "test@test.com",
  errorMessage: "whoops",
  state: 0
};

const ServiceTest = ({ syncInfo, lineTest, profile, history }) => {
  const { downstreamRate, upstreamRate, upstream, retrains } = history;
  return (
    <>
      <Panel header="Sync Info">
        <Grid columns={2}>
          {syncFields.map((field) => (
            <GridItem
              field={field}
              value={syncInfo[field.key]}
              key={field.key}
            />
          ))}
        </Grid>
        <Table celled>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>&nbsp;</TableHeaderCell>
              <TableHeaderCell>Downstream</TableHeaderCell>
              <TableHeaderCell>Upstream</TableHeaderCell>
            </TableRow>
          </TableHeader>

          <TableBody>
            <TableRow>
              <TableCell>
                {/* <Label ribbon>First</Label> */}
                Ordered Product Speed (Mbps)
              </TableCell>
              <TableCell>{syncInfo.orderedProductDownstream}</TableCell>
              <TableCell>{syncInfo.orderedProductUpstream}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Estimated Speed at Provisioning (Mbps)</TableCell>
              <TableCell>{syncInfo.estimatedSpeedAtProvisioningDownstream}</TableCell>
              <TableCell>{syncInfo.estimatedSpeedAtProvisioningUpstream}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Sync Speed (Mbps)</TableCell>
              <TableCell>{syncInfo.syncSpeedDownstream}</TableCell>
              <TableCell>{syncInfo.syncSpeedUpstream}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Panel>

      <Panel header="Service Tests">
        <Grid columns={2}>
          {serviceTestFields.map((field) => (
            <GridItem
              field={field}
              value={lineTest[field.key]}
              key={field.key}
            />
          ))}
        </Grid>
      </Panel>

      <Panel header="Profile & Policy">
        <Grid columns={2}>
          {profileFields.map((field) => (
            <GridItem
              field={field}
              value={profile[field.key]}
              key={field.key}
            />
          ))}
        </Grid>
      </Panel>

      <Panel header="28-Day History">
        <Table celled>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>&nbsp;</TableHeaderCell>
              <TableHeaderCell>Min</TableHeaderCell>
              <TableHeaderCell>Max</TableHeaderCell>
              <TableHeaderCell>Avg</TableHeaderCell>
            </TableRow>
          </TableHeader>

          <TableBody>
            <TableRow>
              <TableCell>
                Downstream Rate (Mbps)
              </TableCell>
              <TableCell>{downstreamRate.min}</TableCell>
              <TableCell>{downstreamRate.max}</TableCell>
              <TableCell>{downstreamRate.avg}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Upstream Rate (Mbps)</TableCell>
              <TableCell>{upstreamRate.min}</TableCell>
              <TableCell>{upstreamRate.max}</TableCell>
              <TableCell>{upstreamRate.avg}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Uptime (sec)</TableCell>
              <TableCell>{upstream.min}</TableCell>
              <TableCell>{upstream.max}</TableCell>
              <TableCell>{upstream.avg}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Retrains</TableCell>
              <TableCell>{retrains.min}</TableCell>
              <TableCell>{retrains.max}</TableCell>
              <TableCell>{retrains.avg}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Panel>
      </>
  )
}

export default function ServiceTests() {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [service, setService] = useState({});
  const [activeIndex, setActiveIndex] = useState(-1);

  const { reference } = useParams();
  const { permissions } = useContext(authContext);

  useEffect(() => {
    (async () => {
      try {
        if (permissions.diagnosticsAccess) {
          // setIsLoading(true);
          // setService((await searchServices(reference))[0]);
          // setIsLoading(false);
          setTimeout(() => {
            setIsLoading(false);
          }, 200)
        }
      }
      catch (err) {
        console.error(err);
        setIsError(true);
        setIsLoading(false);
      }
    })();
  }, []);


  function handleClick(e, titleProps) {
    const { index } = titleProps
    const newIndex = activeIndex === index ? -1 : index

    setActiveIndex(newIndex);
  }

  if (!isLoading && !permissions.diagnosticsAccess) {
    return (
      <Panel>
        <Message error header="You don't have permission to view faults" />
      </Panel>
    )
  }

  // const header = <>
  //   <span>{`Reference ${reference}`}</span>
  //   <strong style={{ float: 'right' }}>{order ? order.status : ''}</strong>
  // </>

  return (
    <>
      {/* <Segment> */}
      <Dimmer active={isLoading} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
      {permissions.demoUser && (
        <>
          <Panel header={'Service Tests'}>
            <Accordion fluid styled>
              <>
                <AccordionTitle
                  active={activeIndex === 0}
                  index={0}
                  onClick={handleClick}
                >
                  <Icon name='dropdown' />
                  Service Test
                </AccordionTitle>
                <AccordionContent active={activeIndex === 0}>
                  <ServiceTest syncInfo={result.xdslStatusCheck} lineTest={result.copperLineTest} profile={result.profileData} history={result.lineHistory} />
                </AccordionContent>
                <AccordionTitle
                  active={activeIndex === 1}
                  index={1}
                  onClick={handleClick}
                >
                  <Icon name='dropdown' />
                  Service Test 2
                </AccordionTitle>
                <AccordionContent active={activeIndex === 1}>
                  <ServiceTest syncInfo={result.xdslStatusCheck} lineTest={result.copperLineTest} profile={result.profileData} history={result.lineHistory} />
                </AccordionContent>
              </>
            </Accordion>

          </Panel>
        </>
      )
      }
      {/* </Segment> */}
    </>
  )
}