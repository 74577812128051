import { BASE_URL } from "../environment";
import makeRequest from "../makeRequest";

export default async function postTTBEthernetOrder(body) {
  const results = await makeRequest(`${BASE_URL}/send-email`, { method: 'POST', body });
  
  if (typeof results === 'string' && results.includes('[Internal Server Error]')) {
    throw "Failed to send email";
  }

  return "Order Successfully Placed";
}