import { useState } from "react";
import Panel from "../Panel";
import classnames from "classnames";
import { Message } from "semantic-ui-react";

export const ON_NET_TEXT = "On-net";
export const OFF_NET_TEXT = "Off-net";

export default function ZenBroadbandGroup({
  broadbandGroup,
  selectedProduct,
  selectProduct,
  lineDetails,
}) {
  const { products, broadbandType, onNet, provider } = broadbandGroup;
  const networkType = onNet ? ON_NET_TEXT : OFF_NET_TEXT;
  let message = "";

  if (broadbandType.toUpperCase() === "FTTC") {
    message = lineDetails?.fttc?.availabilityDescription
      ? lineDetails?.fttc?.availabilityDescription
      : lineDetails?.fttc?.ragDescription;
  } else if (broadbandType.toUpperCase() === "SOGEA") {
    message = lineDetails?.sogea?.availabilityDescription
      ? lineDetails?.sogea?.availabilityDescription
      : lineDetails?.sogea?.ragDescription;
  } else if (
    broadbandType.toUpperCase() === "COPPER" &&
    lineDetails?.adsl2Plus
  ) {
    message = lineDetails?.adsl2Plus?.availabilityDescription
      ? lineDetails?.adsl2Plus?.availabilityDescription
      : lineDetails?.adsl2Plus?.ragDescription;
  } else if (
    broadbandType.toUpperCase() === "COPPER" &&
    lineDetails?.adsl2PlusAnnexM
  ) {
    message = lineDetails?.adsl2PlusAnnexM?.availabilityDescription
      ? lineDetails?.adsl2PlusAnnexM?.availabilityDescription
      : lineDetails?.adsl2PlusAnnexM?.ragDescription;
  } else if (broadbandType.toUpperCase() === "GFAST") {
    message = lineDetails?.gFast?.availabilityDescription
      ? lineDetails?.gFast?.availabilityDescription
      : lineDetails?.gFast?.ragDescription;
  } else if (broadbandType.toUpperCase() === "FTTP") {
    message = lineDetails?.fttp?.availabilityDescription
      ? lineDetails?.fttp?.availabilityDescription
      : lineDetails?.fttp?.ragDescription;
  } else if (broadbandType.toUpperCase() === "FTTP SYMMETRICAL") {
    message = lineDetails?.FttpSymmetrical?.ragDescription
      ? lineDetails?.FttpSymmetrical?.ragDescription
      : lineDetails?.FttpSymmetrical?.ragDescription;
  }

  return (
    <>
      {message && <Message info header={message}></Message>}
      <Panel header={broadbandType} className="zen-broadband-group">
        {products.map((product) => (
          <div
            key={product.productCode}
            onClick={() => selectProduct(product)}
            className={classnames("product", {
              selected: selectedProduct === product,
            })}
          >
            <p className="product__title">
              {product.productName.replace(" ", "\n")}
            </p>
            <div className="product__info">
              {product && product.marketLocation && product.marketLocation.toLowerCase() !== "unavailable" ? (
                <p>Market Location: {product.marketLocation}</p>
              ) : (
                ""
              )}
              <p>
                {networkType} {broadbandType} is available
              </p>
              <p>Provider: {provider}</p>
            </div>
          </div>
        ))}
      </Panel>
    </>
  );
}
