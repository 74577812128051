import React from 'react';
import { Table } from 'semantic-ui-react';

const ZenUpdatesTable = ({ updates }) => {
  const updatesKey = Object.keys(updates)[0];
  const specificUpdates = updates[updatesKey];

  if (!specificUpdates || specificUpdates.length === 0) {
    return null;
  }

  // Function to format the date to dd/mm/yyyy
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Date Issued</Table.HeaderCell>
          <Table.HeaderCell>Author</Table.HeaderCell>
          <Table.HeaderCell>Message</Table.HeaderCell>
          {/* Add other header cells as needed */}
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {specificUpdates.map((update, index) => (
          <Table.Row key={index}>
            <Table.Cell>{formatDate(update.dateIssued)}</Table.Cell>
            <Table.Cell>{update.author}</Table.Cell>
            <Table.Cell>{update.payload.message}</Table.Cell>
            {/* Add other cells as needed */}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

export default ZenUpdatesTable;
