import { Auth } from 'aws-amplify';

export default async function makeRequest(url, { headers = {}, ...options } = {}) {
  const session = await Auth.currentSession();
  const jwtToken = session.getIdToken().getJwtToken();
  const requestHeaders = {
    ...headers,
    'Authorization': jwtToken
  };

  return fetch(url, { ...options, headers: requestHeaders }).then(res => res.json());
}