import React, { useContext, useEffect, useState } from 'react';
import { getSuppliers } from '../libs/sessionVariable';

const ethernetContext = React.createContext({});

const suppliersMap = {
  'CityFibre': 'CityFibre',
  'EntanetOnNetCityFibre': 'Entanet',
  'TalkTalkCityFibre': 'TalkTalk',
  'VirginCityFibre': 'Virgin',
  'TalkTalk': 'TalkTalk',
}

export default function EthernetProvider({ children }) {
  const [suppliers, setSuppliers] = useState([{key: 'All', value: 'All'}]);
  const [supplier, setSupplier] = useState('All');

  useEffect(() => {
    (async () => {
      await buildSuppliers();
    })();
  }, []);

  const buildSuppliers = async () => {
    const result = [{ key: 'All', value: 'All', text: 'All', flag: 'All'}];
    const resultText = [];

    const suppliers = await getSuppliers();
    
    Object.keys(suppliers).forEach(supplierKey => {
      var isOnboarded = suppliers[supplierKey] === 1;
      var text = suppliersMap[supplierKey];

      if (isOnboarded && resultText.indexOf(text) === -1) {
        result.push({ key: text, text, value: supplierKey, flag: text  });
        resultText.push(text);
      }
    });

    setSuppliers(result);
  }

  const context = {
    suppliers,
    supplier,
    setSupplier
  };

  return <ethernetContext.Provider value={context}>{children}</ethernetContext.Provider>;
}

export const useEthernetContext = () => useContext(ethernetContext);