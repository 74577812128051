import React, { useEffect, useState, Component } from 'react';
import { FormGroup, Panel } from 'react-bootstrap';
import { Form, Dropdown, GridColumn, Grid, GridRow, TextArea, FormInput } from 'semantic-ui-react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from 'date-fns/locale/es';
import { format, compareAsc } from 'date-fns'
import { useFormik } from 'formik';
import Holidays from 'date-holidays';
import { add } from 'lodash';
var moment = require("moment-business-days");


export const AppointmentComponent = (props) => {

    const timeSlots = [
        { key: '0', value: '0', text: 'Any', flag: 'Any' },
        { key: '1', value: '1', text: 'AM', flag: 'AM' },
        { key: '2', value: '2', text: 'PM', flag: 'PM' }
    ];

    const [country, setCountry] = useState('GB')
    const hd = new Holidays(country);
    const holidays = hd.getHolidays();
    const leadTime = addLeadTime(new Date());

    const [startDate, setStartDate] = useState(new Date(leadTime));
    const [timeslot, setTimeSlot] = useState({ preferredTimeslotField: "" });

    function addLeadTime(){
        moment.locale('en-gb');
        let originalLeadTime = moment(props.today, 'dd.MM.yyyy').businessAdd(props.leadTimeDays);

        holidays.forEach(element => {
            if(moment(element.date).isSame(originalLeadTime._d, 'day') && element.type === 'public' || moment(element.date).isSame(originalLeadTime._d, 'day') && element.type === 'bank'){
                originalLeadTime = moment(originalLeadTime, 'dd.MM.yyyy').businessAdd(1);
            }
        });
        
        return originalLeadTime;
    }

    const isWeekdayOrHoliday = (date) => {

        const day = date.getDay()
        var show = day !== 0 && day !== 6;

        holidays.forEach(element => {
            if(moment(date).isSame(element.date, 'day')){
                show = false;
            }
         })
        return show;
      }

    // Date Picker
    useEffect(() => {
        function getDatePicker() {
            const response = <DatePicker minDate={new Date(leadTime)} filterDate={isWeekdayOrHoliday} selected={format(startDate, 'dd.MM.yyyy')} onChange={(date) => setStartDate(format(date, 'dd.MM.yyyy'))} />
        }
        getDatePicker();
    }, []);

    const { values, handleChange, handleSubmit, setFieldValue, isValid, errors, ...formik } = useFormik({
        validate,
        validateOnChange: true,
        validateOnMount: true,
        initialValues: {
          preferredDateField: '',
          preferredTimeslotField: ''
        }
      });
    
      function validate({preferredDateField, preferredTimeslotField }) {
        const errors = {};
    
        if (preferredDateField < todaysDate) {
          errors.preferredDateField = true;
        }

        if (!preferredTimeslotField.length) {
            errors.preferredTimeslotField = true;
          }
    
        return errors;
      }

    useEffect (() => {
    }, [props.isVisible])

    const handleStartDateChange = (e, date) => {
        setStartDate(e);
        setFieldValue('activationDateField', e);
      };

    const todaysDate = new Date();

    const handleTimeslotChange = (event, result) => {
        const { name, value } = result || event.target;
        setTimeSlot({ ...timeslot, [name]: value });
      };

    if(props.isVisible === true)
    {
        return (
            <Grid columns={2} style={{marginLeft:'2px'}} columns='equal'>
                <GridRow>
                    <GridColumn>
                <FormGroup>
                    <Form.Field
                        label='Appointment Details'
                    />
                </FormGroup>
                </GridColumn>
                </GridRow>
                <GridRow>
                    <GridColumn>
                            <FormGroup>
                                <FormInput inline label='Preferred Date' name='activationDateField' data-testid='activationDateField'  >
                                    <DatePicker
                                        selected={startDate} //when day is clicked
                                        onChange={(e, val) => handleStartDateChange(e, val, setFieldValue)}
                                        locale="enGB"
                                        dateFormat="dd/MM/yyyy"
                                        name="activationDateField"
                                        value={values.preferredDate}
                                        id="activationDateField"
                                        minDate={new Date(leadTime)}
                                        filterDate={isWeekdayOrHoliday}
                                    />
                                </FormInput>
                                </FormGroup>
                                </GridColumn>
                                <GridColumn>
                                <FormGroup>
                                <FormInput
                                    inline
                                    label='Preferred Timeslot'
                                    name='preferredTimeSlotLabel'
                                    data-testid='preferredTimeSlotLabel'
                                >
                                <Dropdown
                                    placeholder='Select Preferred Timeslot'
                                    search
                                    selection
                                    options={timeSlots}
                                    label='Preferred Timeslot'
                                    name="preferredTimeslotField"
                                    value={timeslot.timeSlotField}
                                    id="preferredTimeslotField"
                                    onChange={handleTimeslotChange}
                                />
                                </FormInput>
                                </FormGroup>
                                </GridColumn>
                            </GridRow>
                        <GridRow>
                            <GridColumn>
                <FormGroup>
                    <TextArea
                        placeholder="Site safety notes"
                        style={{ minHeight: 100 }}
                        fluid
                        className='ui textarea margin'
                        id="siteSafetyNotesField"
                    />
                </FormGroup>
                </GridColumn>
                </GridRow>
                <GridRow>
                <GridColumn>
                <FormGroup>
                    <TextArea
                        placeholder="Site visit notes"
                        style={{ minHeight: 100 }}
                        fluid
                        className='ui textarea margin'
                        id="sitevisitNotesField"
                    />
                </FormGroup>
                </GridColumn>
                </GridRow>
                <GridRow>
                    <GridColumn>
                <FormGroup>
                    <TextArea
                        placeholder="Site special arrangement notes"
                        style={{ minHeight: 100 }}
                        fluid
                        className='ui textarea margin'
                        id="specialArrangementNotesField"
                    />
                </FormGroup>
                </GridColumn>
                </GridRow>
            </Grid>
        )
        }
        else return null;
    }

    

    

    