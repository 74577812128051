import React, { useContext, useEffect, useState } from 'react';
import Panel from './Panel';
import { authContext } from '../containers/AuthProvider';
import { Link, useParams } from 'react-router-dom';
import { Button, Dimmer, Grid, GridColumn, Header, Label, Loader, Message, Segment } from 'semantic-ui-react';
import CanvasJSReact from '@canvasjs/react-charts';
import { searchServices } from '../libs/requests/serviceSearch';
import RadacctGraph from './Diagnostics/RadacctGraph';

// var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function ViewConnectionStatus() {
	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	// const [order, setOrder] = useState({});
	const [service, setService] = useState({});
	// const [service, setService] = useState({});
	const [accordionState, setAccordionState] = useState(-1);

	const { reference } = useParams();
	const { permissions } = useContext(authContext);

	// useEffect(() => {
	//   (async () => {
	//     try {
	//       if (permissions.diagnosticsAccess) {
	//         setIsLoading(true);
	//         setOrder((await searchOrders(reference))[0]);
	//         setIsLoading(false);
	//       }
	//     }
	//     catch (err) {
	//       console.error(err);
	//       setIsError(true);
	//       setIsLoading(false);
	//     }
	//   })();
	// }, []);

	useEffect(() => {
		(async () => {
			try {
				if (permissions.diagnosticsAccess) {
					// setIsLoading(true);
					// setService((await searchServices(reference))[0]);
					// setIsLoading(false);
				}
			}
			catch (err) {
				console.error(err);
				setIsError(true);
				setIsLoading(false);
			}
		})();
	}, []);

	if (!isLoading && !permissions.diagnosticsAccess) {
		return (
			<Panel>
				<Message error header="You don't have permission to view this page" />
			</Panel>
		)
	}

	return (
		<>
			<Segment>
				<Dimmer active={isLoading} inverted>
					<Loader inverted>Loading</Loader>
				</Dimmer>
				{permissions.demoUser && (
					<>
						<Panel>
							<Grid columns={4}>
								<GridColumn>
									<strong>CLI</strong><br />
									0123123123
								</GridColumn>
								<GridColumn>
									<strong>Customer ref</strong><br />
									My customer ref
								</GridColumn>
								<GridColumn>
									<strong>Postcode</strong><br />
									AA1 1AA
								</GridColumn>
								<GridColumn>
									<Link to={'/Diagnostics/123'}>
										<Button content='Diagnose Fault' icon='wrench' labelPosition='right' primary className='button-primary' />
									</Link>
								</GridColumn>
							</Grid>
						</Panel>

						<Panel header={'Connection Status'}>
							<Grid columns={4}>
								<GridColumn>
									<strong>Connection Status</strong><br />
									<Label color='green'>Connected</Label>
								</GridColumn>
								<GridColumn>
									<strong>Uptime</strong><br />
									123d 01h 12m 34s
								</GridColumn>
								<GridColumn>
									<strong>Expected username</strong><br />
									user@realm
								</GridColumn>
								<GridColumn>
									<strong>Expected password</strong><br />
									password
								</GridColumn>
							</Grid>
						</Panel>

						<Panel header={'Session Information'}>
							<Segment>
								<Header>Radius Session Status</Header>
								<RadacctGraph data={dropsOverTime.model} width={870} height={580} />
							</Segment>
							<Segment>
								<Header>Drops by date</Header>
								<CanvasJSChart options={dropsByDateOptions} />
							</Segment>
							<Segment>
								<Header>Drops by time</Header>
								<CanvasJSChart options={dropsByTimeOptions} />
							</Segment>
						</Panel>
					</>
				)
				}
			</Segment>
		</>
	)
}

const dropsOverTime = {
	"model": {
		"dateStart": "02/17/2024 00:00:00",
		"dateEnd": "03/17/2024 00:00:00",
		"payload": [
			{
				"id": 0,
				"date": "17 Feb 2024",
				"data": [
					{
						"id": 0,
						"s": "2024-02-17T00:00:00",
						"e": "2024-02-17T00:15:00",
						"v": "conn"
					},
					{
						"id": 1,
						"s": "2024-02-17T00:15:00",
						"e": "2024-02-17T00:30:00",
						"v": "conn"
					},
					{
						"id": 2,
						"s": "2024-02-17T00:30:00",
						"e": "2024-02-17T00:45:00",
						"v": "conn"
					},
					{
						"id": 3,
						"s": "2024-02-17T00:45:00",
						"e": "2024-02-17T01:00:00",
						"v": "conn"
					},
					{
						"id": 4,
						"s": "2024-02-17T01:00:00",
						"e": "2024-02-17T01:15:00",
						"v": "conn"
					},
					{
						"id": 5,
						"s": "2024-02-17T01:15:00",
						"e": "2024-02-17T01:30:00",
						"v": "conn"
					},
					{
						"id": 6,
						"s": "2024-02-17T01:30:00",
						"e": "2024-02-17T01:45:00",
						"v": "conn"
					},
					{
						"id": 7,
						"s": "2024-02-17T01:45:00",
						"e": "2024-02-17T02:00:00",
						"v": "conn"
					},
					{
						"id": 8,
						"s": "2024-02-17T02:00:00",
						"e": "2024-02-17T02:15:00",
						"v": "conn"
					},
					{
						"id": 9,
						"s": "2024-02-17T02:15:00",
						"e": "2024-02-17T02:30:00",
						"v": "conn"
					},
					{
						"id": 10,
						"s": "2024-02-17T02:30:00",
						"e": "2024-02-17T02:45:00",
						"v": "conn"
					},
					{
						"id": 11,
						"s": "2024-02-17T02:45:00",
						"e": "2024-02-17T03:00:00",
						"v": "conn"
					},
					{
						"id": 12,
						"s": "2024-02-17T03:00:00",
						"e": "2024-02-17T03:15:00",
						"v": "conn"
					},
					{
						"id": 13,
						"s": "2024-02-17T03:15:00",
						"e": "2024-02-17T03:30:00",
						"v": "conn"
					},
					{
						"id": 14,
						"s": "2024-02-17T03:30:00",
						"e": "2024-02-17T03:45:00",
						"v": "conn"
					},
					{
						"id": 15,
						"s": "2024-02-17T03:45:00",
						"e": "2024-02-17T04:00:00",
						"v": "conn"
					},
					{
						"id": 16,
						"s": "2024-02-17T04:00:00",
						"e": "2024-02-17T04:15:00",
						"v": "conn"
					},
					{
						"id": 17,
						"s": "2024-02-17T04:15:00",
						"e": "2024-02-17T04:30:00",
						"v": "conn"
					},
					{
						"id": 18,
						"s": "2024-02-17T04:30:00",
						"e": "2024-02-17T04:45:00",
						"v": "conn"
					},
					{
						"id": 19,
						"s": "2024-02-17T04:45:00",
						"e": "2024-02-17T05:00:00",
						"v": "conn"
					},
					{
						"id": 20,
						"s": "2024-02-17T05:00:00",
						"e": "2024-02-17T05:15:00",
						"v": "conn"
					},
					{
						"id": 21,
						"s": "2024-02-17T05:15:00",
						"e": "2024-02-17T05:30:00",
						"v": "conn"
					},
					{
						"id": 22,
						"s": "2024-02-17T05:30:00",
						"e": "2024-02-17T05:45:00",
						"v": "conn"
					},
					{
						"id": 23,
						"s": "2024-02-17T05:45:00",
						"e": "2024-02-17T06:00:00",
						"v": "conn"
					},
					{
						"id": 24,
						"s": "2024-02-17T06:00:00",
						"e": "2024-02-17T06:15:00",
						"v": "conn"
					},
					{
						"id": 25,
						"s": "2024-02-17T06:15:00",
						"e": "2024-02-17T06:30:00",
						"v": "conn"
					},
					{
						"id": 26,
						"s": "2024-02-17T06:30:00",
						"e": "2024-02-17T06:45:00",
						"v": "conn"
					},
					{
						"id": 27,
						"s": "2024-02-17T06:45:00",
						"e": "2024-02-17T07:00:00",
						"v": "conn"
					},
					{
						"id": 28,
						"s": "2024-02-17T07:00:00",
						"e": "2024-02-17T07:15:00",
						"v": "conn"
					},
					{
						"id": 29,
						"s": "2024-02-17T07:15:00",
						"e": "2024-02-17T07:30:00",
						"v": "conn"
					},
					{
						"id": 30,
						"s": "2024-02-17T07:30:00",
						"e": "2024-02-17T07:45:00",
						"v": "conn"
					},
					{
						"id": 31,
						"s": "2024-02-17T07:45:00",
						"e": "2024-02-17T08:00:00",
						"v": "conn"
					},
					{
						"id": 32,
						"s": "2024-02-17T08:00:00",
						"e": "2024-02-17T08:15:00",
						"v": "conn"
					},
					{
						"id": 33,
						"s": "2024-02-17T08:15:00",
						"e": "2024-02-17T08:30:00",
						"v": "conn"
					},
					{
						"id": 34,
						"s": "2024-02-17T08:30:00",
						"e": "2024-02-17T08:45:00",
						"v": "conn"
					},
					{
						"id": 35,
						"s": "2024-02-17T08:45:00",
						"e": "2024-02-17T09:00:00",
						"v": "conn"
					},
					{
						"id": 36,
						"s": "2024-02-17T09:00:00",
						"e": "2024-02-17T09:15:00",
						"v": "conn"
					},
					{
						"id": 37,
						"s": "2024-02-17T09:15:00",
						"e": "2024-02-17T09:30:00",
						"v": "conn"
					},
					{
						"id": 38,
						"s": "2024-02-17T09:30:00",
						"e": "2024-02-17T09:45:00",
						"v": "conn"
					},
					{
						"id": 39,
						"s": "2024-02-17T09:45:00",
						"e": "2024-02-17T10:00:00",
						"v": "conn"
					},
					{
						"id": 40,
						"s": "2024-02-17T10:00:00",
						"e": "2024-02-17T10:15:00",
						"v": "conn"
					},
					{
						"id": 41,
						"s": "2024-02-17T10:15:00",
						"e": "2024-02-17T10:30:00",
						"v": "conn"
					},
					{
						"id": 42,
						"s": "2024-02-17T10:30:00",
						"e": "2024-02-17T10:45:00",
						"v": "conn"
					},
					{
						"id": 43,
						"s": "2024-02-17T10:45:00",
						"e": "2024-02-17T11:00:00",
						"v": "conn"
					},
					{
						"id": 44,
						"s": "2024-02-17T11:00:00",
						"e": "2024-02-17T11:15:00",
						"v": "conn"
					},
					{
						"id": 45,
						"s": "2024-02-17T11:15:00",
						"e": "2024-02-17T11:30:00",
						"v": "conn"
					},
					{
						"id": 46,
						"s": "2024-02-17T11:30:00",
						"e": "2024-02-17T11:45:00",
						"v": "conn"
					},
					{
						"id": 47,
						"s": "2024-02-17T11:45:00",
						"e": "2024-02-17T12:00:00",
						"v": "conn"
					},
					{
						"id": 48,
						"s": "2024-02-17T12:00:00",
						"e": "2024-02-17T12:15:00",
						"v": "conn"
					},
					{
						"id": 49,
						"s": "2024-02-17T12:15:00",
						"e": "2024-02-17T12:30:00",
						"v": "conn"
					},
					{
						"id": 50,
						"s": "2024-02-17T12:30:00",
						"e": "2024-02-17T12:45:00",
						"v": "conn"
					},
					{
						"id": 51,
						"s": "2024-02-17T12:45:00",
						"e": "2024-02-17T13:00:00",
						"v": "conn"
					},
					{
						"id": 52,
						"s": "2024-02-17T13:00:00",
						"e": "2024-02-17T13:15:00",
						"v": "conn"
					},
					{
						"id": 53,
						"s": "2024-02-17T13:15:00",
						"e": "2024-02-17T13:30:00",
						"v": "conn"
					},
					{
						"id": 54,
						"s": "2024-02-17T13:30:00",
						"e": "2024-02-17T13:45:00",
						"v": "conn"
					},
					{
						"id": 55,
						"s": "2024-02-17T13:45:00",
						"e": "2024-02-17T14:00:00",
						"v": "conn"
					},
					{
						"id": 56,
						"s": "2024-02-17T14:00:00",
						"e": "2024-02-17T14:15:00",
						"v": "conn"
					},
					{
						"id": 57,
						"s": "2024-02-17T14:15:00",
						"e": "2024-02-17T14:30:00",
						"v": "conn"
					},
					{
						"id": 58,
						"s": "2024-02-17T14:30:00",
						"e": "2024-02-17T14:45:00",
						"v": "conn"
					},
					{
						"id": 59,
						"s": "2024-02-17T14:45:00",
						"e": "2024-02-17T15:00:00",
						"v": "conn"
					},
					{
						"id": 60,
						"s": "2024-02-17T15:00:00",
						"e": "2024-02-17T15:15:00",
						"v": "conn"
					},
					{
						"id": 61,
						"s": "2024-02-17T15:15:00",
						"e": "2024-02-17T15:30:00",
						"v": "conn"
					},
					{
						"id": 62,
						"s": "2024-02-17T15:30:00",
						"e": "2024-02-17T15:45:00",
						"v": "conn"
					},
					{
						"id": 63,
						"s": "2024-02-17T15:45:00",
						"e": "2024-02-17T16:00:00",
						"v": "conn"
					},
					{
						"id": 64,
						"s": "2024-02-17T16:00:00",
						"e": "2024-02-17T16:15:00",
						"v": "conn"
					},
					{
						"id": 65,
						"s": "2024-02-17T16:15:00",
						"e": "2024-02-17T16:30:00",
						"v": "conn"
					},
					{
						"id": 66,
						"s": "2024-02-17T16:30:00",
						"e": "2024-02-17T16:45:00",
						"v": "conn"
					},
					{
						"id": 67,
						"s": "2024-02-17T16:45:00",
						"e": "2024-02-17T17:00:00",
						"v": "conn"
					},
					{
						"id": 68,
						"s": "2024-02-17T17:00:00",
						"e": "2024-02-17T17:15:00",
						"v": "conn"
					},
					{
						"id": 69,
						"s": "2024-02-17T17:15:00",
						"e": "2024-02-17T17:30:00",
						"v": "conn"
					},
					{
						"id": 70,
						"s": "2024-02-17T17:30:00",
						"e": "2024-02-17T17:45:00",
						"v": "conn"
					},
					{
						"id": 71,
						"s": "2024-02-17T17:45:00",
						"e": "2024-02-17T18:00:00",
						"v": "conn"
					},
					{
						"id": 72,
						"s": "2024-02-17T18:00:00",
						"e": "2024-02-17T18:15:00",
						"v": "conn"
					},
					{
						"id": 73,
						"s": "2024-02-17T18:15:00",
						"e": "2024-02-17T18:30:00",
						"v": "conn"
					},
					{
						"id": 74,
						"s": "2024-02-17T18:30:00",
						"e": "2024-02-17T18:45:00",
						"v": "conn"
					},
					{
						"id": 75,
						"s": "2024-02-17T18:45:00",
						"e": "2024-02-17T19:00:00",
						"v": "conn"
					},
					{
						"id": 76,
						"s": "2024-02-17T19:00:00",
						"e": "2024-02-17T19:15:00",
						"v": "conn"
					},
					{
						"id": 77,
						"s": "2024-02-17T19:15:00",
						"e": "2024-02-17T19:30:00",
						"v": "conn"
					},
					{
						"id": 78,
						"s": "2024-02-17T19:30:00",
						"e": "2024-02-17T19:45:00",
						"v": "conn"
					},
					{
						"id": 79,
						"s": "2024-02-17T19:45:00",
						"e": "2024-02-17T20:00:00",
						"v": "conn"
					},
					{
						"id": 80,
						"s": "2024-02-17T20:00:00",
						"e": "2024-02-17T20:15:00",
						"v": "conn"
					},
					{
						"id": 81,
						"s": "2024-02-17T20:15:00",
						"e": "2024-02-17T20:30:00",
						"v": "conn"
					},
					{
						"id": 82,
						"s": "2024-02-17T20:30:00",
						"e": "2024-02-17T20:45:00",
						"v": "conn"
					},
					{
						"id": 83,
						"s": "2024-02-17T20:45:00",
						"e": "2024-02-17T21:00:00",
						"v": "conn"
					},
					{
						"id": 84,
						"s": "2024-02-17T21:00:00",
						"e": "2024-02-17T21:15:00",
						"v": "conn"
					},
					{
						"id": 85,
						"s": "2024-02-17T21:15:00",
						"e": "2024-02-17T21:30:00",
						"v": "conn"
					},
					{
						"id": 86,
						"s": "2024-02-17T21:30:00",
						"e": "2024-02-17T21:45:00",
						"v": "conn"
					},
					{
						"id": 87,
						"s": "2024-02-17T21:45:00",
						"e": "2024-02-17T22:00:00",
						"v": "conn"
					},
					{
						"id": 88,
						"s": "2024-02-17T22:00:00",
						"e": "2024-02-17T22:15:00",
						"v": "conn"
					},
					{
						"id": 89,
						"s": "2024-02-17T22:15:00",
						"e": "2024-02-17T22:30:00",
						"v": "conn"
					},
					{
						"id": 90,
						"s": "2024-02-17T22:30:00",
						"e": "2024-02-17T22:45:00",
						"v": "conn"
					},
					{
						"id": 91,
						"s": "2024-02-17T22:45:00",
						"e": "2024-02-17T23:00:00",
						"v": "conn"
					},
					{
						"id": 92,
						"s": "2024-02-17T23:00:00",
						"e": "2024-02-17T23:15:00",
						"v": "conn"
					},
					{
						"id": 93,
						"s": "2024-02-17T23:15:00",
						"e": "2024-02-17T23:30:00",
						"v": "conn"
					},
					{
						"id": 94,
						"s": "2024-02-17T23:30:00",
						"e": "2024-02-17T23:45:00",
						"v": "conn"
					},
					{
						"id": 95,
						"s": "2024-02-17T23:45:00",
						"e": "2024-02-18T00:00:00",
						"v": "conn"
					}
				]
			},
			{
				"id": 1,
				"date": "18 Feb 2024",
				"data": [
					{
						"id": 0,
						"s": "2024-02-18T00:00:00",
						"e": "2024-02-18T00:15:00",
						"v": "conn"
					},
					{
						"id": 1,
						"s": "2024-02-18T00:15:00",
						"e": "2024-02-18T00:30:00",
						"v": "conn"
					},
					{
						"id": 2,
						"s": "2024-02-18T00:30:00",
						"e": "2024-02-18T00:45:00",
						"v": "conn"
					},
					{
						"id": 3,
						"s": "2024-02-18T00:45:00",
						"e": "2024-02-18T01:00:00",
						"v": "conn"
					},
					{
						"id": 4,
						"s": "2024-02-18T01:00:00",
						"e": "2024-02-18T01:15:00",
						"v": "conn"
					},
					{
						"id": 5,
						"s": "2024-02-18T01:15:00",
						"e": "2024-02-18T01:30:00",
						"v": "conn"
					},
					{
						"id": 6,
						"s": "2024-02-18T01:30:00",
						"e": "2024-02-18T01:45:00",
						"v": "conn"
					},
					{
						"id": 7,
						"s": "2024-02-18T01:45:00",
						"e": "2024-02-18T02:00:00",
						"v": "conn"
					},
					{
						"id": 8,
						"s": "2024-02-18T02:00:00",
						"e": "2024-02-18T02:15:00",
						"v": "conn"
					},
					{
						"id": 9,
						"s": "2024-02-18T02:15:00",
						"e": "2024-02-18T02:30:00",
						"v": "conn"
					},
					{
						"id": 10,
						"s": "2024-02-18T02:30:00",
						"e": "2024-02-18T02:45:00",
						"v": "conn"
					},
					{
						"id": 11,
						"s": "2024-02-18T02:45:00",
						"e": "2024-02-18T03:00:00",
						"v": "conn"
					},
					{
						"id": 12,
						"s": "2024-02-18T03:00:00",
						"e": "2024-02-18T03:15:00",
						"v": "conn"
					},
					{
						"id": 13,
						"s": "2024-02-18T03:15:00",
						"e": "2024-02-18T03:30:00",
						"v": "conn"
					},
					{
						"id": 14,
						"s": "2024-02-18T03:30:00",
						"e": "2024-02-18T03:45:00",
						"v": "conn"
					},
					{
						"id": 15,
						"s": "2024-02-18T03:45:00",
						"e": "2024-02-18T04:00:00",
						"v": "conn"
					},
					{
						"id": 16,
						"s": "2024-02-18T04:00:00",
						"e": "2024-02-18T04:15:00",
						"v": "conn"
					},
					{
						"id": 17,
						"s": "2024-02-18T04:15:00",
						"e": "2024-02-18T04:30:00",
						"v": "conn"
					},
					{
						"id": 18,
						"s": "2024-02-18T04:30:00",
						"e": "2024-02-18T04:45:00",
						"v": "conn"
					},
					{
						"id": 19,
						"s": "2024-02-18T04:45:00",
						"e": "2024-02-18T05:00:00",
						"v": "conn"
					},
					{
						"id": 20,
						"s": "2024-02-18T05:00:00",
						"e": "2024-02-18T05:15:00",
						"v": "conn"
					},
					{
						"id": 21,
						"s": "2024-02-18T05:15:00",
						"e": "2024-02-18T05:30:00",
						"v": "conn"
					},
					{
						"id": 22,
						"s": "2024-02-18T05:30:00",
						"e": "2024-02-18T05:45:00",
						"v": "conn"
					},
					{
						"id": 23,
						"s": "2024-02-18T05:45:00",
						"e": "2024-02-18T06:00:00",
						"v": "conn"
					},
					{
						"id": 24,
						"s": "2024-02-18T06:00:00",
						"e": "2024-02-18T06:15:00",
						"v": "conn"
					},
					{
						"id": 25,
						"s": "2024-02-18T06:15:00",
						"e": "2024-02-18T06:30:00",
						"v": "conn"
					},
					{
						"id": 26,
						"s": "2024-02-18T06:30:00",
						"e": "2024-02-18T06:45:00",
						"v": "conn"
					},
					{
						"id": 27,
						"s": "2024-02-18T06:45:00",
						"e": "2024-02-18T07:00:00",
						"v": "conn"
					},
					{
						"id": 28,
						"s": "2024-02-18T07:00:00",
						"e": "2024-02-18T07:15:00",
						"v": "conn"
					},
					{
						"id": 29,
						"s": "2024-02-18T07:15:00",
						"e": "2024-02-18T07:30:00",
						"v": "conn"
					},
					{
						"id": 30,
						"s": "2024-02-18T07:30:00",
						"e": "2024-02-18T07:45:00",
						"v": "conn"
					},
					{
						"id": 31,
						"s": "2024-02-18T07:45:00",
						"e": "2024-02-18T08:00:00",
						"v": "conn"
					},
					{
						"id": 32,
						"s": "2024-02-18T08:00:00",
						"e": "2024-02-18T08:15:00",
						"v": "conn"
					},
					{
						"id": 33,
						"s": "2024-02-18T08:15:00",
						"e": "2024-02-18T08:30:00",
						"v": "conn"
					},
					{
						"id": 34,
						"s": "2024-02-18T08:30:00",
						"e": "2024-02-18T08:45:00",
						"v": "conn"
					},
					{
						"id": 35,
						"s": "2024-02-18T08:45:00",
						"e": "2024-02-18T09:00:00",
						"v": "conn"
					},
					{
						"id": 36,
						"s": "2024-02-18T09:00:00",
						"e": "2024-02-18T09:15:00",
						"v": "conn"
					},
					{
						"id": 37,
						"s": "2024-02-18T09:15:00",
						"e": "2024-02-18T09:30:00",
						"v": "conn"
					},
					{
						"id": 38,
						"s": "2024-02-18T09:30:00",
						"e": "2024-02-18T09:45:00",
						"v": "conn"
					},
					{
						"id": 39,
						"s": "2024-02-18T09:45:00",
						"e": "2024-02-18T10:00:00",
						"v": "conn"
					},
					{
						"id": 40,
						"s": "2024-02-18T10:00:00",
						"e": "2024-02-18T10:15:00",
						"v": "conn"
					},
					{
						"id": 41,
						"s": "2024-02-18T10:15:00",
						"e": "2024-02-18T10:30:00",
						"v": "conn"
					},
					{
						"id": 42,
						"s": "2024-02-18T10:30:00",
						"e": "2024-02-18T10:45:00",
						"v": "conn"
					},
					{
						"id": 43,
						"s": "2024-02-18T10:45:00",
						"e": "2024-02-18T11:00:00",
						"v": "conn"
					},
					{
						"id": 44,
						"s": "2024-02-18T11:00:00",
						"e": "2024-02-18T11:15:00",
						"v": "conn"
					},
					{
						"id": 45,
						"s": "2024-02-18T11:15:00",
						"e": "2024-02-18T11:30:00",
						"v": "conn"
					},
					{
						"id": 46,
						"s": "2024-02-18T11:30:00",
						"e": "2024-02-18T11:45:00",
						"v": "conn"
					},
					{
						"id": 47,
						"s": "2024-02-18T11:45:00",
						"e": "2024-02-18T12:00:00",
						"v": "conn"
					},
					{
						"id": 48,
						"s": "2024-02-18T12:00:00",
						"e": "2024-02-18T12:15:00",
						"v": "conn"
					},
					{
						"id": 49,
						"s": "2024-02-18T12:15:00",
						"e": "2024-02-18T12:30:00",
						"v": "conn"
					},
					{
						"id": 50,
						"s": "2024-02-18T12:30:00",
						"e": "2024-02-18T12:45:00",
						"v": "conn"
					},
					{
						"id": 51,
						"s": "2024-02-18T12:45:00",
						"e": "2024-02-18T13:00:00",
						"v": "conn"
					},
					{
						"id": 52,
						"s": "2024-02-18T13:00:00",
						"e": "2024-02-18T13:15:00",
						"v": "conn"
					},
					{
						"id": 53,
						"s": "2024-02-18T13:15:00",
						"e": "2024-02-18T13:30:00",
						"v": "conn"
					},
					{
						"id": 54,
						"s": "2024-02-18T13:30:00",
						"e": "2024-02-18T13:45:00",
						"v": "conn"
					},
					{
						"id": 55,
						"s": "2024-02-18T13:45:00",
						"e": "2024-02-18T14:00:00",
						"v": "conn"
					},
					{
						"id": 56,
						"s": "2024-02-18T14:00:00",
						"e": "2024-02-18T14:15:00",
						"v": "conn"
					},
					{
						"id": 57,
						"s": "2024-02-18T14:15:00",
						"e": "2024-02-18T14:30:00",
						"v": "conn"
					},
					{
						"id": 58,
						"s": "2024-02-18T14:30:00",
						"e": "2024-02-18T14:45:00",
						"v": "conn"
					},
					{
						"id": 59,
						"s": "2024-02-18T14:45:00",
						"e": "2024-02-18T15:00:00",
						"v": "conn"
					},
					{
						"id": 60,
						"s": "2024-02-18T15:00:00",
						"e": "2024-02-18T15:15:00",
						"v": "conn"
					},
					{
						"id": 61,
						"s": "2024-02-18T15:15:00",
						"e": "2024-02-18T15:30:00",
						"v": "conn"
					},
					{
						"id": 62,
						"s": "2024-02-18T15:30:00",
						"e": "2024-02-18T15:45:00",
						"v": "conn"
					},
					{
						"id": 63,
						"s": "2024-02-18T15:45:00",
						"e": "2024-02-18T16:00:00",
						"v": "conn"
					},
					{
						"id": 64,
						"s": "2024-02-18T16:00:00",
						"e": "2024-02-18T16:15:00",
						"v": "conn"
					},
					{
						"id": 65,
						"s": "2024-02-18T16:15:00",
						"e": "2024-02-18T16:30:00",
						"v": "conn"
					},
					{
						"id": 66,
						"s": "2024-02-18T16:30:00",
						"e": "2024-02-18T16:45:00",
						"v": "conn"
					},
					{
						"id": 67,
						"s": "2024-02-18T16:45:00",
						"e": "2024-02-18T17:00:00",
						"v": "conn"
					},
					{
						"id": 68,
						"s": "2024-02-18T17:00:00",
						"e": "2024-02-18T17:15:00",
						"v": "conn"
					},
					{
						"id": 69,
						"s": "2024-02-18T17:15:00",
						"e": "2024-02-18T17:30:00",
						"v": "conn"
					},
					{
						"id": 70,
						"s": "2024-02-18T17:30:00",
						"e": "2024-02-18T17:45:00",
						"v": "conn"
					},
					{
						"id": 71,
						"s": "2024-02-18T17:45:00",
						"e": "2024-02-18T18:00:00",
						"v": "conn"
					},
					{
						"id": 72,
						"s": "2024-02-18T18:00:00",
						"e": "2024-02-18T18:15:00",
						"v": "conn"
					},
					{
						"id": 73,
						"s": "2024-02-18T18:15:00",
						"e": "2024-02-18T18:30:00",
						"v": "conn"
					},
					{
						"id": 74,
						"s": "2024-02-18T18:30:00",
						"e": "2024-02-18T18:45:00",
						"v": "conn"
					},
					{
						"id": 75,
						"s": "2024-02-18T18:45:00",
						"e": "2024-02-18T19:00:00",
						"v": "conn"
					},
					{
						"id": 76,
						"s": "2024-02-18T19:00:00",
						"e": "2024-02-18T19:15:00",
						"v": "conn"
					},
					{
						"id": 77,
						"s": "2024-02-18T19:15:00",
						"e": "2024-02-18T19:30:00",
						"v": "conn"
					},
					{
						"id": 78,
						"s": "2024-02-18T19:30:00",
						"e": "2024-02-18T19:45:00",
						"v": "conn"
					},
					{
						"id": 79,
						"s": "2024-02-18T19:45:00",
						"e": "2024-02-18T20:00:00",
						"v": "conn"
					},
					{
						"id": 80,
						"s": "2024-02-18T20:00:00",
						"e": "2024-02-18T20:15:00",
						"v": "conn"
					},
					{
						"id": 81,
						"s": "2024-02-18T20:15:00",
						"e": "2024-02-18T20:30:00",
						"v": "conn"
					},
					{
						"id": 82,
						"s": "2024-02-18T20:30:00",
						"e": "2024-02-18T20:45:00",
						"v": "conn"
					},
					{
						"id": 83,
						"s": "2024-02-18T20:45:00",
						"e": "2024-02-18T21:00:00",
						"v": "conn"
					},
					{
						"id": 84,
						"s": "2024-02-18T21:00:00",
						"e": "2024-02-18T21:15:00",
						"v": "conn"
					},
					{
						"id": 85,
						"s": "2024-02-18T21:15:00",
						"e": "2024-02-18T21:30:00",
						"v": "conn"
					},
					{
						"id": 86,
						"s": "2024-02-18T21:30:00",
						"e": "2024-02-18T21:45:00",
						"v": "conn"
					},
					{
						"id": 87,
						"s": "2024-02-18T21:45:00",
						"e": "2024-02-18T22:00:00",
						"v": "conn"
					},
					{
						"id": 88,
						"s": "2024-02-18T22:00:00",
						"e": "2024-02-18T22:15:00",
						"v": "conn"
					},
					{
						"id": 89,
						"s": "2024-02-18T22:15:00",
						"e": "2024-02-18T22:30:00",
						"v": "conn"
					},
					{
						"id": 90,
						"s": "2024-02-18T22:30:00",
						"e": "2024-02-18T22:45:00",
						"v": "conn"
					},
					{
						"id": 91,
						"s": "2024-02-18T22:45:00",
						"e": "2024-02-18T23:00:00",
						"v": "conn"
					},
					{
						"id": 92,
						"s": "2024-02-18T23:00:00",
						"e": "2024-02-18T23:15:00",
						"v": "conn"
					},
					{
						"id": 93,
						"s": "2024-02-18T23:15:00",
						"e": "2024-02-18T23:30:00",
						"v": "conn"
					},
					{
						"id": 94,
						"s": "2024-02-18T23:30:00",
						"e": "2024-02-18T23:45:00",
						"v": "conn"
					},
					{
						"id": 95,
						"s": "2024-02-18T23:45:00",
						"e": "2024-02-19T00:00:00",
						"v": "conn"
					}
				]
			},
			{
				"id": 2,
				"date": "19 Feb 2024",
				"data": [
					{
						"id": 0,
						"s": "2024-02-19T00:00:00",
						"e": "2024-02-19T00:15:00",
						"v": "conn"
					},
					{
						"id": 1,
						"s": "2024-02-19T00:15:00",
						"e": "2024-02-19T00:30:00",
						"v": "conn"
					},
					{
						"id": 2,
						"s": "2024-02-19T00:30:00",
						"e": "2024-02-19T00:45:00",
						"v": "conn"
					},
					{
						"id": 3,
						"s": "2024-02-19T00:45:00",
						"e": "2024-02-19T01:00:00",
						"v": "conn"
					},
					{
						"id": 4,
						"s": "2024-02-19T01:00:00",
						"e": "2024-02-19T01:15:00",
						"v": "conn"
					},
					{
						"id": 5,
						"s": "2024-02-19T01:15:00",
						"e": "2024-02-19T01:30:00",
						"v": "conn"
					},
					{
						"id": 6,
						"s": "2024-02-19T01:30:00",
						"e": "2024-02-19T01:45:00",
						"v": "conn"
					},
					{
						"id": 7,
						"s": "2024-02-19T01:45:00",
						"e": "2024-02-19T02:00:00",
						"v": "conn"
					},
					{
						"id": 8,
						"s": "2024-02-19T02:00:00",
						"e": "2024-02-19T02:15:00",
						"v": "conn"
					},
					{
						"id": 9,
						"s": "2024-02-19T02:15:00",
						"e": "2024-02-19T02:30:00",
						"v": "conn"
					},
					{
						"id": 10,
						"s": "2024-02-19T02:30:00",
						"e": "2024-02-19T02:45:00",
						"v": "conn"
					},
					{
						"id": 11,
						"s": "2024-02-19T02:45:00",
						"e": "2024-02-19T03:00:00",
						"v": "conn"
					},
					{
						"id": 12,
						"s": "2024-02-19T03:00:00",
						"e": "2024-02-19T03:15:00",
						"v": "conn"
					},
					{
						"id": 13,
						"s": "2024-02-19T03:15:00",
						"e": "2024-02-19T03:30:00",
						"v": "conn"
					},
					{
						"id": 14,
						"s": "2024-02-19T03:30:00",
						"e": "2024-02-19T03:45:00",
						"v": "conn"
					},
					{
						"id": 15,
						"s": "2024-02-19T03:45:00",
						"e": "2024-02-19T04:00:00",
						"v": "conn"
					},
					{
						"id": 16,
						"s": "2024-02-19T04:00:00",
						"e": "2024-02-19T04:15:00",
						"v": "conn"
					},
					{
						"id": 17,
						"s": "2024-02-19T04:15:00",
						"e": "2024-02-19T04:30:00",
						"v": "conn"
					},
					{
						"id": 18,
						"s": "2024-02-19T04:30:00",
						"e": "2024-02-19T04:45:00",
						"v": "conn"
					},
					{
						"id": 19,
						"s": "2024-02-19T04:45:00",
						"e": "2024-02-19T05:00:00",
						"v": "conn"
					},
					{
						"id": 20,
						"s": "2024-02-19T05:00:00",
						"e": "2024-02-19T05:15:00",
						"v": "conn"
					},
					{
						"id": 21,
						"s": "2024-02-19T05:15:00",
						"e": "2024-02-19T05:30:00",
						"v": "conn"
					},
					{
						"id": 22,
						"s": "2024-02-19T05:30:00",
						"e": "2024-02-19T05:45:00",
						"v": "conn"
					},
					{
						"id": 23,
						"s": "2024-02-19T05:45:00",
						"e": "2024-02-19T06:00:00",
						"v": "conn"
					},
					{
						"id": 24,
						"s": "2024-02-19T06:00:00",
						"e": "2024-02-19T06:15:00",
						"v": "conn"
					},
					{
						"id": 25,
						"s": "2024-02-19T06:15:00",
						"e": "2024-02-19T06:30:00",
						"v": "conn"
					},
					{
						"id": 26,
						"s": "2024-02-19T06:30:00",
						"e": "2024-02-19T06:45:00",
						"v": "conn"
					},
					{
						"id": 27,
						"s": "2024-02-19T06:45:00",
						"e": "2024-02-19T07:00:00",
						"v": "conn"
					},
					{
						"id": 28,
						"s": "2024-02-19T07:00:00",
						"e": "2024-02-19T07:15:00",
						"v": "conn"
					},
					{
						"id": 29,
						"s": "2024-02-19T07:15:00",
						"e": "2024-02-19T07:30:00",
						"v": "conn"
					},
					{
						"id": 30,
						"s": "2024-02-19T07:30:00",
						"e": "2024-02-19T07:45:00",
						"v": "conn"
					},
					{
						"id": 31,
						"s": "2024-02-19T07:45:00",
						"e": "2024-02-19T08:00:00",
						"v": "conn"
					},
					{
						"id": 32,
						"s": "2024-02-19T08:00:00",
						"e": "2024-02-19T08:15:00",
						"v": "conn"
					},
					{
						"id": 33,
						"s": "2024-02-19T08:15:00",
						"e": "2024-02-19T08:30:00",
						"v": "conn"
					},
					{
						"id": 34,
						"s": "2024-02-19T08:30:00",
						"e": "2024-02-19T08:45:00",
						"v": "conn"
					},
					{
						"id": 35,
						"s": "2024-02-19T08:45:00",
						"e": "2024-02-19T09:00:00",
						"v": "conn"
					},
					{
						"id": 36,
						"s": "2024-02-19T09:00:00",
						"e": "2024-02-19T09:15:00",
						"v": "conn"
					},
					{
						"id": 37,
						"s": "2024-02-19T09:15:00",
						"e": "2024-02-19T09:30:00",
						"v": "conn"
					},
					{
						"id": 38,
						"s": "2024-02-19T09:30:00",
						"e": "2024-02-19T09:45:00",
						"v": "conn"
					},
					{
						"id": 39,
						"s": "2024-02-19T09:45:00",
						"e": "2024-02-19T10:00:00",
						"v": "conn"
					},
					{
						"id": 40,
						"s": "2024-02-19T10:00:00",
						"e": "2024-02-19T10:15:00",
						"v": "conn"
					},
					{
						"id": 41,
						"s": "2024-02-19T10:15:00",
						"e": "2024-02-19T10:30:00",
						"v": "conn"
					},
					{
						"id": 42,
						"s": "2024-02-19T10:30:00",
						"e": "2024-02-19T10:45:00",
						"v": "conn"
					},
					{
						"id": 43,
						"s": "2024-02-19T10:45:00",
						"e": "2024-02-19T11:00:00",
						"v": "conn"
					},
					{
						"id": 44,
						"s": "2024-02-19T11:00:00",
						"e": "2024-02-19T11:15:00",
						"v": "conn"
					},
					{
						"id": 45,
						"s": "2024-02-19T11:15:00",
						"e": "2024-02-19T11:30:00",
						"v": "conn"
					},
					{
						"id": 46,
						"s": "2024-02-19T11:30:00",
						"e": "2024-02-19T11:45:00",
						"v": "conn"
					},
					{
						"id": 47,
						"s": "2024-02-19T11:45:00",
						"e": "2024-02-19T12:00:00",
						"v": "conn"
					},
					{
						"id": 48,
						"s": "2024-02-19T12:00:00",
						"e": "2024-02-19T12:15:00",
						"v": "conn"
					},
					{
						"id": 49,
						"s": "2024-02-19T12:15:00",
						"e": "2024-02-19T12:30:00",
						"v": "conn"
					},
					{
						"id": 50,
						"s": "2024-02-19T12:30:00",
						"e": "2024-02-19T12:45:00",
						"v": "conn"
					},
					{
						"id": 51,
						"s": "2024-02-19T12:45:00",
						"e": "2024-02-19T13:00:00",
						"v": "conn"
					},
					{
						"id": 52,
						"s": "2024-02-19T13:00:00",
						"e": "2024-02-19T13:15:00",
						"v": "conn"
					},
					{
						"id": 53,
						"s": "2024-02-19T13:15:00",
						"e": "2024-02-19T13:30:00",
						"v": "conn"
					},
					{
						"id": 54,
						"s": "2024-02-19T13:30:00",
						"e": "2024-02-19T13:45:00",
						"v": "conn"
					},
					{
						"id": 55,
						"s": "2024-02-19T13:45:00",
						"e": "2024-02-19T14:00:00",
						"v": "conn"
					},
					{
						"id": 56,
						"s": "2024-02-19T14:00:00",
						"e": "2024-02-19T14:15:00",
						"v": "conn"
					},
					{
						"id": 57,
						"s": "2024-02-19T14:15:00",
						"e": "2024-02-19T14:30:00",
						"v": "conn"
					},
					{
						"id": 58,
						"s": "2024-02-19T14:30:00",
						"e": "2024-02-19T14:45:00",
						"v": "conn"
					},
					{
						"id": 59,
						"s": "2024-02-19T14:45:00",
						"e": "2024-02-19T15:00:00",
						"v": "conn"
					},
					{
						"id": 60,
						"s": "2024-02-19T15:00:00",
						"e": "2024-02-19T15:15:00",
						"v": "conn"
					},
					{
						"id": 61,
						"s": "2024-02-19T15:15:00",
						"e": "2024-02-19T15:30:00",
						"v": "conn"
					},
					{
						"id": 62,
						"s": "2024-02-19T15:30:00",
						"e": "2024-02-19T15:45:00",
						"v": "conn"
					},
					{
						"id": 63,
						"s": "2024-02-19T15:45:00",
						"e": "2024-02-19T16:00:00",
						"v": "conn"
					},
					{
						"id": 64,
						"s": "2024-02-19T16:00:00",
						"e": "2024-02-19T16:15:00",
						"v": "conn"
					},
					{
						"id": 65,
						"s": "2024-02-19T16:15:00",
						"e": "2024-02-19T16:30:00",
						"v": "conn"
					},
					{
						"id": 66,
						"s": "2024-02-19T16:30:00",
						"e": "2024-02-19T16:45:00",
						"v": "conn"
					},
					{
						"id": 67,
						"s": "2024-02-19T16:45:00",
						"e": "2024-02-19T17:00:00",
						"v": "conn"
					},
					{
						"id": 68,
						"s": "2024-02-19T17:00:00",
						"e": "2024-02-19T17:15:00",
						"v": "conn"
					},
					{
						"id": 69,
						"s": "2024-02-19T17:15:00",
						"e": "2024-02-19T17:30:00",
						"v": "conn"
					},
					{
						"id": 70,
						"s": "2024-02-19T17:30:00",
						"e": "2024-02-19T17:45:00",
						"v": "conn"
					},
					{
						"id": 71,
						"s": "2024-02-19T17:45:00",
						"e": "2024-02-19T18:00:00",
						"v": "conn"
					},
					{
						"id": 72,
						"s": "2024-02-19T18:00:00",
						"e": "2024-02-19T18:15:00",
						"v": "conn"
					},
					{
						"id": 73,
						"s": "2024-02-19T18:15:00",
						"e": "2024-02-19T18:30:00",
						"v": "conn"
					},
					{
						"id": 74,
						"s": "2024-02-19T18:30:00",
						"e": "2024-02-19T18:45:00",
						"v": "conn"
					},
					{
						"id": 75,
						"s": "2024-02-19T18:45:00",
						"e": "2024-02-19T19:00:00",
						"v": "conn"
					},
					{
						"id": 76,
						"s": "2024-02-19T19:00:00",
						"e": "2024-02-19T19:15:00",
						"v": "conn"
					},
					{
						"id": 77,
						"s": "2024-02-19T19:15:00",
						"e": "2024-02-19T19:30:00",
						"v": "conn"
					},
					{
						"id": 78,
						"s": "2024-02-19T19:30:00",
						"e": "2024-02-19T19:45:00",
						"v": "conn"
					},
					{
						"id": 79,
						"s": "2024-02-19T19:45:00",
						"e": "2024-02-19T20:00:00",
						"v": "conn"
					},
					{
						"id": 80,
						"s": "2024-02-19T20:00:00",
						"e": "2024-02-19T20:15:00",
						"v": "conn"
					},
					{
						"id": 81,
						"s": "2024-02-19T20:15:00",
						"e": "2024-02-19T20:30:00",
						"v": "conn"
					},
					{
						"id": 82,
						"s": "2024-02-19T20:30:00",
						"e": "2024-02-19T20:45:00",
						"v": "conn"
					},
					{
						"id": 83,
						"s": "2024-02-19T20:45:00",
						"e": "2024-02-19T21:00:00",
						"v": "conn"
					},
					{
						"id": 84,
						"s": "2024-02-19T21:00:00",
						"e": "2024-02-19T21:15:00",
						"v": "conn"
					},
					{
						"id": 85,
						"s": "2024-02-19T21:15:00",
						"e": "2024-02-19T21:30:00",
						"v": "conn"
					},
					{
						"id": 86,
						"s": "2024-02-19T21:30:00",
						"e": "2024-02-19T21:45:00",
						"v": "conn"
					},
					{
						"id": 87,
						"s": "2024-02-19T21:45:00",
						"e": "2024-02-19T22:00:00",
						"v": "conn"
					},
					{
						"id": 88,
						"s": "2024-02-19T22:00:00",
						"e": "2024-02-19T22:15:00",
						"v": "conn"
					},
					{
						"id": 89,
						"s": "2024-02-19T22:15:00",
						"e": "2024-02-19T22:30:00",
						"v": "conn"
					},
					{
						"id": 90,
						"s": "2024-02-19T22:30:00",
						"e": "2024-02-19T22:45:00",
						"v": "conn"
					},
					{
						"id": 91,
						"s": "2024-02-19T22:45:00",
						"e": "2024-02-19T23:00:00",
						"v": "conn"
					},
					{
						"id": 92,
						"s": "2024-02-19T23:00:00",
						"e": "2024-02-19T23:15:00",
						"v": "conn"
					},
					{
						"id": 93,
						"s": "2024-02-19T23:15:00",
						"e": "2024-02-19T23:30:00",
						"v": "conn"
					},
					{
						"id": 94,
						"s": "2024-02-19T23:30:00",
						"e": "2024-02-19T23:45:00",
						"v": "conn"
					},
					{
						"id": 95,
						"s": "2024-02-19T23:45:00",
						"e": "2024-02-20T00:00:00",
						"v": "conn"
					}
				]
			},
			{
				"id": 3,
				"date": "20 Feb 2024",
				"data": [
					{
						"id": 0,
						"s": "2024-02-20T00:00:00",
						"e": "2024-02-20T00:15:00",
						"v": "conn"
					},
					{
						"id": 1,
						"s": "2024-02-20T00:15:00",
						"e": "2024-02-20T00:30:00",
						"v": "conn"
					},
					{
						"id": 2,
						"s": "2024-02-20T00:30:00",
						"e": "2024-02-20T00:45:00",
						"v": "conn"
					},
					{
						"id": 3,
						"s": "2024-02-20T00:45:00",
						"e": "2024-02-20T01:00:00",
						"v": "conn"
					},
					{
						"id": 4,
						"s": "2024-02-20T01:00:00",
						"e": "2024-02-20T01:15:00",
						"v": "conn"
					},
					{
						"id": 5,
						"s": "2024-02-20T01:15:00",
						"e": "2024-02-20T01:30:00",
						"v": "conn"
					},
					{
						"id": 6,
						"s": "2024-02-20T01:30:00",
						"e": "2024-02-20T01:45:00",
						"v": "conn"
					},
					{
						"id": 7,
						"s": "2024-02-20T01:45:00",
						"e": "2024-02-20T02:00:00",
						"v": "conn"
					},
					{
						"id": 8,
						"s": "2024-02-20T02:00:00",
						"e": "2024-02-20T02:15:00",
						"v": "conn"
					},
					{
						"id": 9,
						"s": "2024-02-20T02:15:00",
						"e": "2024-02-20T02:30:00",
						"v": "conn"
					},
					{
						"id": 10,
						"s": "2024-02-20T02:30:00",
						"e": "2024-02-20T02:45:00",
						"v": "conn"
					},
					{
						"id": 11,
						"s": "2024-02-20T02:45:00",
						"e": "2024-02-20T03:00:00",
						"v": "conn"
					},
					{
						"id": 12,
						"s": "2024-02-20T03:00:00",
						"e": "2024-02-20T03:15:00",
						"v": "conn"
					},
					{
						"id": 13,
						"s": "2024-02-20T03:15:00",
						"e": "2024-02-20T03:30:00",
						"v": "conn"
					},
					{
						"id": 14,
						"s": "2024-02-20T03:30:00",
						"e": "2024-02-20T03:45:00",
						"v": "conn"
					},
					{
						"id": 15,
						"s": "2024-02-20T03:45:00",
						"e": "2024-02-20T04:00:00",
						"v": "conn"
					},
					{
						"id": 16,
						"s": "2024-02-20T04:00:00",
						"e": "2024-02-20T04:15:00",
						"v": "conn"
					},
					{
						"id": 17,
						"s": "2024-02-20T04:15:00",
						"e": "2024-02-20T04:30:00",
						"v": "conn"
					},
					{
						"id": 18,
						"s": "2024-02-20T04:30:00",
						"e": "2024-02-20T04:45:00",
						"v": "conn"
					},
					{
						"id": 19,
						"s": "2024-02-20T04:45:00",
						"e": "2024-02-20T05:00:00",
						"v": "conn"
					},
					{
						"id": 20,
						"s": "2024-02-20T05:00:00",
						"e": "2024-02-20T05:15:00",
						"v": "conn"
					},
					{
						"id": 21,
						"s": "2024-02-20T05:15:00",
						"e": "2024-02-20T05:30:00",
						"v": "conn"
					},
					{
						"id": 22,
						"s": "2024-02-20T05:30:00",
						"e": "2024-02-20T05:45:00",
						"v": "conn"
					},
					{
						"id": 23,
						"s": "2024-02-20T05:45:00",
						"e": "2024-02-20T06:00:00",
						"v": "conn"
					},
					{
						"id": 24,
						"s": "2024-02-20T06:00:00",
						"e": "2024-02-20T06:15:00",
						"v": "conn"
					},
					{
						"id": 25,
						"s": "2024-02-20T06:15:00",
						"e": "2024-02-20T06:30:00",
						"v": "conn"
					},
					{
						"id": 26,
						"s": "2024-02-20T06:30:00",
						"e": "2024-02-20T06:45:00",
						"v": "conn"
					},
					{
						"id": 27,
						"s": "2024-02-20T06:45:00",
						"e": "2024-02-20T07:00:00",
						"v": "conn"
					},
					{
						"id": 28,
						"s": "2024-02-20T07:00:00",
						"e": "2024-02-20T07:15:00",
						"v": "conn"
					},
					{
						"id": 29,
						"s": "2024-02-20T07:15:00",
						"e": "2024-02-20T07:30:00",
						"v": "conn"
					},
					{
						"id": 30,
						"s": "2024-02-20T07:30:00",
						"e": "2024-02-20T07:45:00",
						"v": "conn"
					},
					{
						"id": 31,
						"s": "2024-02-20T07:45:00",
						"e": "2024-02-20T08:00:00",
						"v": "conn"
					},
					{
						"id": 32,
						"s": "2024-02-20T08:00:00",
						"e": "2024-02-20T08:15:00",
						"v": "conn"
					},
					{
						"id": 33,
						"s": "2024-02-20T08:15:00",
						"e": "2024-02-20T08:30:00",
						"v": "conn"
					},
					{
						"id": 34,
						"s": "2024-02-20T08:30:00",
						"e": "2024-02-20T08:45:00",
						"v": "conn"
					},
					{
						"id": 35,
						"s": "2024-02-20T08:45:00",
						"e": "2024-02-20T09:00:00",
						"v": "conn"
					},
					{
						"id": 36,
						"s": "2024-02-20T09:00:00",
						"e": "2024-02-20T09:15:00",
						"v": "conn"
					},
					{
						"id": 37,
						"s": "2024-02-20T09:15:00",
						"e": "2024-02-20T09:30:00",
						"v": "conn"
					},
					{
						"id": 38,
						"s": "2024-02-20T09:30:00",
						"e": "2024-02-20T09:45:00",
						"v": "conn"
					},
					{
						"id": 39,
						"s": "2024-02-20T09:45:00",
						"e": "2024-02-20T10:00:00",
						"v": "conn"
					},
					{
						"id": 40,
						"s": "2024-02-20T10:00:00",
						"e": "2024-02-20T10:15:00",
						"v": "conn"
					},
					{
						"id": 41,
						"s": "2024-02-20T10:15:00",
						"e": "2024-02-20T10:30:00",
						"v": "conn"
					},
					{
						"id": 42,
						"s": "2024-02-20T10:30:00",
						"e": "2024-02-20T10:45:00",
						"v": "conn"
					},
					{
						"id": 43,
						"s": "2024-02-20T10:45:00",
						"e": "2024-02-20T11:00:00",
						"v": "conn"
					},
					{
						"id": 44,
						"s": "2024-02-20T11:00:00",
						"e": "2024-02-20T11:15:00",
						"v": "conn"
					},
					{
						"id": 45,
						"s": "2024-02-20T11:15:00",
						"e": "2024-02-20T11:30:00",
						"v": "conn"
					},
					{
						"id": 46,
						"s": "2024-02-20T11:30:00",
						"e": "2024-02-20T11:45:00",
						"v": "conn"
					},
					{
						"id": 47,
						"s": "2024-02-20T11:45:00",
						"e": "2024-02-20T12:00:00",
						"v": "conn"
					},
					{
						"id": 48,
						"s": "2024-02-20T12:00:00",
						"e": "2024-02-20T12:15:00",
						"v": "conn"
					},
					{
						"id": 49,
						"s": "2024-02-20T12:15:00",
						"e": "2024-02-20T12:30:00",
						"v": "conn"
					},
					{
						"id": 50,
						"s": "2024-02-20T12:30:00",
						"e": "2024-02-20T12:45:00",
						"v": "conn"
					},
					{
						"id": 51,
						"s": "2024-02-20T12:45:00",
						"e": "2024-02-20T13:00:00",
						"v": "conn"
					},
					{
						"id": 52,
						"s": "2024-02-20T13:00:00",
						"e": "2024-02-20T13:15:00",
						"v": "conn"
					},
					{
						"id": 53,
						"s": "2024-02-20T13:15:00",
						"e": "2024-02-20T13:30:00",
						"v": "conn"
					},
					{
						"id": 54,
						"s": "2024-02-20T13:30:00",
						"e": "2024-02-20T13:45:00",
						"v": "conn"
					},
					{
						"id": 55,
						"s": "2024-02-20T13:45:00",
						"e": "2024-02-20T14:00:00",
						"v": "conn"
					},
					{
						"id": 56,
						"s": "2024-02-20T14:00:00",
						"e": "2024-02-20T14:15:00",
						"v": "conn"
					},
					{
						"id": 57,
						"s": "2024-02-20T14:15:00",
						"e": "2024-02-20T14:30:00",
						"v": "conn"
					},
					{
						"id": 58,
						"s": "2024-02-20T14:30:00",
						"e": "2024-02-20T14:45:00",
						"v": "conn"
					},
					{
						"id": 59,
						"s": "2024-02-20T14:45:00",
						"e": "2024-02-20T15:00:00",
						"v": "conn"
					},
					{
						"id": 60,
						"s": "2024-02-20T15:00:00",
						"e": "2024-02-20T15:15:00",
						"v": "conn"
					},
					{
						"id": 61,
						"s": "2024-02-20T15:15:00",
						"e": "2024-02-20T15:30:00",
						"v": "conn"
					},
					{
						"id": 62,
						"s": "2024-02-20T15:30:00",
						"e": "2024-02-20T15:45:00",
						"v": "conn"
					},
					{
						"id": 63,
						"s": "2024-02-20T15:45:00",
						"e": "2024-02-20T16:00:00",
						"v": "conn"
					},
					{
						"id": 64,
						"s": "2024-02-20T16:00:00",
						"e": "2024-02-20T16:15:00",
						"v": "conn"
					},
					{
						"id": 65,
						"s": "2024-02-20T16:15:00",
						"e": "2024-02-20T16:30:00",
						"v": "conn"
					},
					{
						"id": 66,
						"s": "2024-02-20T16:30:00",
						"e": "2024-02-20T16:45:00",
						"v": "conn"
					},
					{
						"id": 67,
						"s": "2024-02-20T16:45:00",
						"e": "2024-02-20T17:00:00",
						"v": "conn"
					},
					{
						"id": 68,
						"s": "2024-02-20T17:00:00",
						"e": "2024-02-20T17:15:00",
						"v": "conn"
					},
					{
						"id": 69,
						"s": "2024-02-20T17:15:00",
						"e": "2024-02-20T17:30:00",
						"v": "conn"
					},
					{
						"id": 70,
						"s": "2024-02-20T17:30:00",
						"e": "2024-02-20T17:45:00",
						"v": "conn"
					},
					{
						"id": 71,
						"s": "2024-02-20T17:45:00",
						"e": "2024-02-20T18:00:00",
						"v": "conn"
					},
					{
						"id": 72,
						"s": "2024-02-20T18:00:00",
						"e": "2024-02-20T18:15:00",
						"v": "conn"
					},
					{
						"id": 73,
						"s": "2024-02-20T18:15:00",
						"e": "2024-02-20T18:30:00",
						"v": "conn"
					},
					{
						"id": 74,
						"s": "2024-02-20T18:30:00",
						"e": "2024-02-20T18:45:00",
						"v": "conn"
					},
					{
						"id": 75,
						"s": "2024-02-20T18:45:00",
						"e": "2024-02-20T19:00:00",
						"v": "conn"
					},
					{
						"id": 76,
						"s": "2024-02-20T19:00:00",
						"e": "2024-02-20T19:15:00",
						"v": "conn"
					},
					{
						"id": 77,
						"s": "2024-02-20T19:15:00",
						"e": "2024-02-20T19:30:00",
						"v": "conn"
					},
					{
						"id": 78,
						"s": "2024-02-20T19:30:00",
						"e": "2024-02-20T19:45:00",
						"v": "conn"
					},
					{
						"id": 79,
						"s": "2024-02-20T19:45:00",
						"e": "2024-02-20T20:00:00",
						"v": "conn"
					},
					{
						"id": 80,
						"s": "2024-02-20T20:00:00",
						"e": "2024-02-20T20:15:00",
						"v": "conn"
					},
					{
						"id": 81,
						"s": "2024-02-20T20:15:00",
						"e": "2024-02-20T20:30:00",
						"v": "conn"
					},
					{
						"id": 82,
						"s": "2024-02-20T20:30:00",
						"e": "2024-02-20T20:45:00",
						"v": "conn"
					},
					{
						"id": 83,
						"s": "2024-02-20T20:45:00",
						"e": "2024-02-20T21:00:00",
						"v": "conn"
					},
					{
						"id": 84,
						"s": "2024-02-20T21:00:00",
						"e": "2024-02-20T21:15:00",
						"v": "conn"
					},
					{
						"id": 85,
						"s": "2024-02-20T21:15:00",
						"e": "2024-02-20T21:30:00",
						"v": "conn"
					},
					{
						"id": 86,
						"s": "2024-02-20T21:30:00",
						"e": "2024-02-20T21:45:00",
						"v": "conn"
					},
					{
						"id": 87,
						"s": "2024-02-20T21:45:00",
						"e": "2024-02-20T22:00:00",
						"v": "conn"
					},
					{
						"id": 88,
						"s": "2024-02-20T22:00:00",
						"e": "2024-02-20T22:15:00",
						"v": "conn"
					},
					{
						"id": 89,
						"s": "2024-02-20T22:15:00",
						"e": "2024-02-20T22:30:00",
						"v": "conn"
					},
					{
						"id": 90,
						"s": "2024-02-20T22:30:00",
						"e": "2024-02-20T22:45:00",
						"v": "conn"
					},
					{
						"id": 91,
						"s": "2024-02-20T22:45:00",
						"e": "2024-02-20T23:00:00",
						"v": "conn"
					},
					{
						"id": 92,
						"s": "2024-02-20T23:00:00",
						"e": "2024-02-20T23:15:00",
						"v": "conn"
					},
					{
						"id": 93,
						"s": "2024-02-20T23:15:00",
						"e": "2024-02-20T23:30:00",
						"v": "conn"
					},
					{
						"id": 94,
						"s": "2024-02-20T23:30:00",
						"e": "2024-02-20T23:45:00",
						"v": "conn"
					},
					{
						"id": 95,
						"s": "2024-02-20T23:45:00",
						"e": "2024-02-21T00:00:00",
						"v": "conn"
					}
				]
			},
			{
				"id": 4,
				"date": "21 Feb 2024",
				"data": [
					{
						"id": 0,
						"s": "2024-02-21T00:00:00",
						"e": "2024-02-21T00:15:00",
						"v": "conn"
					},
					{
						"id": 1,
						"s": "2024-02-21T00:15:00",
						"e": "2024-02-21T00:30:00",
						"v": "conn"
					},
					{
						"id": 2,
						"s": "2024-02-21T00:30:00",
						"e": "2024-02-21T00:45:00",
						"v": "conn"
					},
					{
						"id": 3,
						"s": "2024-02-21T00:45:00",
						"e": "2024-02-21T01:00:00",
						"v": "conn"
					},
					{
						"id": 4,
						"s": "2024-02-21T01:00:00",
						"e": "2024-02-21T01:15:00",
						"v": "conn"
					},
					{
						"id": 5,
						"s": "2024-02-21T01:15:00",
						"e": "2024-02-21T01:30:00",
						"v": "conn"
					},
					{
						"id": 6,
						"s": "2024-02-21T01:30:00",
						"e": "2024-02-21T01:45:00",
						"v": "conn"
					},
					{
						"id": 7,
						"s": "2024-02-21T01:45:00",
						"e": "2024-02-21T02:00:00",
						"v": "conn"
					},
					{
						"id": 8,
						"s": "2024-02-21T02:00:00",
						"e": "2024-02-21T02:15:00",
						"v": "conn"
					},
					{
						"id": 9,
						"s": "2024-02-21T02:15:00",
						"e": "2024-02-21T02:30:00",
						"v": "conn"
					},
					{
						"id": 10,
						"s": "2024-02-21T02:30:00",
						"e": "2024-02-21T02:45:00",
						"v": "conn"
					},
					{
						"id": 11,
						"s": "2024-02-21T02:45:00",
						"e": "2024-02-21T03:00:00",
						"v": "conn"
					},
					{
						"id": 12,
						"s": "2024-02-21T03:00:00",
						"e": "2024-02-21T03:15:00",
						"v": "conn"
					},
					{
						"id": 13,
						"s": "2024-02-21T03:15:00",
						"e": "2024-02-21T03:30:00",
						"v": "conn"
					},
					{
						"id": 14,
						"s": "2024-02-21T03:30:00",
						"e": "2024-02-21T03:45:00",
						"v": "conn"
					},
					{
						"id": 15,
						"s": "2024-02-21T03:45:00",
						"e": "2024-02-21T04:00:00",
						"v": "conn"
					},
					{
						"id": 16,
						"s": "2024-02-21T04:00:00",
						"e": "2024-02-21T04:15:00",
						"v": "conn"
					},
					{
						"id": 17,
						"s": "2024-02-21T04:15:00",
						"e": "2024-02-21T04:30:00",
						"v": "conn"
					},
					{
						"id": 18,
						"s": "2024-02-21T04:30:00",
						"e": "2024-02-21T04:45:00",
						"v": "conn"
					},
					{
						"id": 19,
						"s": "2024-02-21T04:45:00",
						"e": "2024-02-21T05:00:00",
						"v": "conn"
					},
					{
						"id": 20,
						"s": "2024-02-21T05:00:00",
						"e": "2024-02-21T05:15:00",
						"v": "conn"
					},
					{
						"id": 21,
						"s": "2024-02-21T05:15:00",
						"e": "2024-02-21T05:30:00",
						"v": "conn"
					},
					{
						"id": 22,
						"s": "2024-02-21T05:30:00",
						"e": "2024-02-21T05:45:00",
						"v": "conn"
					},
					{
						"id": 23,
						"s": "2024-02-21T05:45:00",
						"e": "2024-02-21T06:00:00",
						"v": "conn"
					},
					{
						"id": 24,
						"s": "2024-02-21T06:00:00",
						"e": "2024-02-21T06:15:00",
						"v": "conn"
					},
					{
						"id": 25,
						"s": "2024-02-21T06:15:00",
						"e": "2024-02-21T06:30:00",
						"v": "conn"
					},
					{
						"id": 26,
						"s": "2024-02-21T06:30:00",
						"e": "2024-02-21T06:45:00",
						"v": "conn"
					},
					{
						"id": 27,
						"s": "2024-02-21T06:45:00",
						"e": "2024-02-21T07:00:00",
						"v": "conn"
					},
					{
						"id": 28,
						"s": "2024-02-21T07:00:00",
						"e": "2024-02-21T07:15:00",
						"v": "conn"
					},
					{
						"id": 29,
						"s": "2024-02-21T07:15:00",
						"e": "2024-02-21T07:30:00",
						"v": "conn"
					},
					{
						"id": 30,
						"s": "2024-02-21T07:30:00",
						"e": "2024-02-21T07:45:00",
						"v": "conn"
					},
					{
						"id": 31,
						"s": "2024-02-21T07:45:00",
						"e": "2024-02-21T08:00:00",
						"v": "conn"
					},
					{
						"id": 32,
						"s": "2024-02-21T08:00:00",
						"e": "2024-02-21T08:15:00",
						"v": "conn"
					},
					{
						"id": 33,
						"s": "2024-02-21T08:15:00",
						"e": "2024-02-21T08:30:00",
						"v": "conn"
					},
					{
						"id": 34,
						"s": "2024-02-21T08:30:00",
						"e": "2024-02-21T08:45:00",
						"v": "conn"
					},
					{
						"id": 35,
						"s": "2024-02-21T08:45:00",
						"e": "2024-02-21T09:00:00",
						"v": "conn"
					},
					{
						"id": 36,
						"s": "2024-02-21T09:00:00",
						"e": "2024-02-21T09:15:00",
						"v": "conn"
					},
					{
						"id": 37,
						"s": "2024-02-21T09:15:00",
						"e": "2024-02-21T09:30:00",
						"v": "conn"
					},
					{
						"id": 38,
						"s": "2024-02-21T09:30:00",
						"e": "2024-02-21T09:45:00",
						"v": "conn"
					},
					{
						"id": 39,
						"s": "2024-02-21T09:45:00",
						"e": "2024-02-21T10:00:00",
						"v": "conn"
					},
					{
						"id": 40,
						"s": "2024-02-21T10:00:00",
						"e": "2024-02-21T10:15:00",
						"v": "conn"
					},
					{
						"id": 41,
						"s": "2024-02-21T10:15:00",
						"e": "2024-02-21T10:30:00",
						"v": "conn"
					},
					{
						"id": 42,
						"s": "2024-02-21T10:30:00",
						"e": "2024-02-21T10:45:00",
						"v": "conn"
					},
					{
						"id": 43,
						"s": "2024-02-21T10:45:00",
						"e": "2024-02-21T11:00:00",
						"v": "conn"
					},
					{
						"id": 44,
						"s": "2024-02-21T11:00:00",
						"e": "2024-02-21T11:15:00",
						"v": "conn"
					},
					{
						"id": 45,
						"s": "2024-02-21T11:15:00",
						"e": "2024-02-21T11:30:00",
						"v": "conn"
					},
					{
						"id": 46,
						"s": "2024-02-21T11:30:00",
						"e": "2024-02-21T11:45:00",
						"v": "conn"
					},
					{
						"id": 47,
						"s": "2024-02-21T11:45:00",
						"e": "2024-02-21T12:00:00",
						"v": "conn"
					},
					{
						"id": 48,
						"s": "2024-02-21T12:00:00",
						"e": "2024-02-21T12:15:00",
						"v": "conn"
					},
					{
						"id": 49,
						"s": "2024-02-21T12:15:00",
						"e": "2024-02-21T12:30:00",
						"v": "conn"
					},
					{
						"id": 50,
						"s": "2024-02-21T12:30:00",
						"e": "2024-02-21T12:45:00",
						"v": "conn"
					},
					{
						"id": 51,
						"s": "2024-02-21T12:45:00",
						"e": "2024-02-21T13:00:00",
						"v": "conn"
					},
					{
						"id": 52,
						"s": "2024-02-21T13:00:00",
						"e": "2024-02-21T13:15:00",
						"v": "conn"
					},
					{
						"id": 53,
						"s": "2024-02-21T13:15:00",
						"e": "2024-02-21T13:30:00",
						"v": "conn"
					},
					{
						"id": 54,
						"s": "2024-02-21T13:30:00",
						"e": "2024-02-21T13:45:00",
						"v": "conn"
					},
					{
						"id": 55,
						"s": "2024-02-21T13:45:00",
						"e": "2024-02-21T14:00:00",
						"v": "conn"
					},
					{
						"id": 56,
						"s": "2024-02-21T14:00:00",
						"e": "2024-02-21T14:15:00",
						"v": "conn"
					},
					{
						"id": 57,
						"s": "2024-02-21T14:15:00",
						"e": "2024-02-21T14:30:00",
						"v": "conn"
					},
					{
						"id": 58,
						"s": "2024-02-21T14:30:00",
						"e": "2024-02-21T14:45:00",
						"v": "conn"
					},
					{
						"id": 59,
						"s": "2024-02-21T14:45:00",
						"e": "2024-02-21T15:00:00",
						"v": "conn"
					},
					{
						"id": 60,
						"s": "2024-02-21T15:00:00",
						"e": "2024-02-21T15:15:00",
						"v": "conn"
					},
					{
						"id": 61,
						"s": "2024-02-21T15:15:00",
						"e": "2024-02-21T15:30:00",
						"v": "conn"
					},
					{
						"id": 62,
						"s": "2024-02-21T15:30:00",
						"e": "2024-02-21T15:45:00",
						"v": "conn"
					},
					{
						"id": 63,
						"s": "2024-02-21T15:45:00",
						"e": "2024-02-21T16:00:00",
						"v": "conn"
					},
					{
						"id": 64,
						"s": "2024-02-21T16:00:00",
						"e": "2024-02-21T16:15:00",
						"v": "conn"
					},
					{
						"id": 65,
						"s": "2024-02-21T16:15:00",
						"e": "2024-02-21T16:30:00",
						"v": "conn"
					},
					{
						"id": 66,
						"s": "2024-02-21T16:30:00",
						"e": "2024-02-21T16:45:00",
						"v": "conn"
					},
					{
						"id": 67,
						"s": "2024-02-21T16:45:00",
						"e": "2024-02-21T17:00:00",
						"v": "conn"
					},
					{
						"id": 68,
						"s": "2024-02-21T17:00:00",
						"e": "2024-02-21T17:15:00",
						"v": "conn"
					},
					{
						"id": 69,
						"s": "2024-02-21T17:15:00",
						"e": "2024-02-21T17:30:00",
						"v": "conn"
					},
					{
						"id": 70,
						"s": "2024-02-21T17:30:00",
						"e": "2024-02-21T17:45:00",
						"v": "conn"
					},
					{
						"id": 71,
						"s": "2024-02-21T17:45:00",
						"e": "2024-02-21T18:00:00",
						"v": "conn"
					},
					{
						"id": 72,
						"s": "2024-02-21T18:00:00",
						"e": "2024-02-21T18:15:00",
						"v": "conn"
					},
					{
						"id": 73,
						"s": "2024-02-21T18:15:00",
						"e": "2024-02-21T18:30:00",
						"v": "conn"
					},
					{
						"id": 74,
						"s": "2024-02-21T18:30:00",
						"e": "2024-02-21T18:45:00",
						"v": "conn"
					},
					{
						"id": 75,
						"s": "2024-02-21T18:45:00",
						"e": "2024-02-21T19:00:00",
						"v": "conn"
					},
					{
						"id": 76,
						"s": "2024-02-21T19:00:00",
						"e": "2024-02-21T19:15:00",
						"v": "conn"
					},
					{
						"id": 77,
						"s": "2024-02-21T19:15:00",
						"e": "2024-02-21T19:30:00",
						"v": "conn"
					},
					{
						"id": 78,
						"s": "2024-02-21T19:30:00",
						"e": "2024-02-21T19:45:00",
						"v": "conn"
					},
					{
						"id": 79,
						"s": "2024-02-21T19:45:00",
						"e": "2024-02-21T20:00:00",
						"v": "conn"
					},
					{
						"id": 80,
						"s": "2024-02-21T20:00:00",
						"e": "2024-02-21T20:15:00",
						"v": "conn"
					},
					{
						"id": 81,
						"s": "2024-02-21T20:15:00",
						"e": "2024-02-21T20:30:00",
						"v": "conn"
					},
					{
						"id": 82,
						"s": "2024-02-21T20:30:00",
						"e": "2024-02-21T20:45:00",
						"v": "conn"
					},
					{
						"id": 83,
						"s": "2024-02-21T20:45:00",
						"e": "2024-02-21T21:00:00",
						"v": "conn"
					},
					{
						"id": 84,
						"s": "2024-02-21T21:00:00",
						"e": "2024-02-21T21:15:00",
						"v": "conn"
					},
					{
						"id": 85,
						"s": "2024-02-21T21:15:00",
						"e": "2024-02-21T21:30:00",
						"v": "conn"
					},
					{
						"id": 86,
						"s": "2024-02-21T21:30:00",
						"e": "2024-02-21T21:45:00",
						"v": "conn"
					},
					{
						"id": 87,
						"s": "2024-02-21T21:45:00",
						"e": "2024-02-21T22:00:00",
						"v": "conn"
					},
					{
						"id": 88,
						"s": "2024-02-21T22:00:00",
						"e": "2024-02-21T22:15:00",
						"v": "conn"
					},
					{
						"id": 89,
						"s": "2024-02-21T22:15:00",
						"e": "2024-02-21T22:30:00",
						"v": "conn"
					},
					{
						"id": 90,
						"s": "2024-02-21T22:30:00",
						"e": "2024-02-21T22:45:00",
						"v": "conn"
					},
					{
						"id": 91,
						"s": "2024-02-21T22:45:00",
						"e": "2024-02-21T23:00:00",
						"v": "conn"
					},
					{
						"id": 92,
						"s": "2024-02-21T23:00:00",
						"e": "2024-02-21T23:15:00",
						"v": "conn"
					},
					{
						"id": 93,
						"s": "2024-02-21T23:15:00",
						"e": "2024-02-21T23:30:00",
						"v": "conn"
					},
					{
						"id": 94,
						"s": "2024-02-21T23:30:00",
						"e": "2024-02-21T23:45:00",
						"v": "conn"
					},
					{
						"id": 95,
						"s": "2024-02-21T23:45:00",
						"e": "2024-02-22T00:00:00",
						"v": "conn"
					}
				]
			},
			{
				"id": 5,
				"date": "22 Feb 2024",
				"data": [
					{
						"id": 0,
						"s": "2024-02-22T00:00:00",
						"e": "2024-02-22T00:15:00",
						"v": "conn"
					},
					{
						"id": 1,
						"s": "2024-02-22T00:15:00",
						"e": "2024-02-22T00:30:00",
						"v": "conn"
					},
					{
						"id": 2,
						"s": "2024-02-22T00:30:00",
						"e": "2024-02-22T00:45:00",
						"v": "conn"
					},
					{
						"id": 3,
						"s": "2024-02-22T00:45:00",
						"e": "2024-02-22T01:00:00",
						"v": "conn"
					},
					{
						"id": 4,
						"s": "2024-02-22T01:00:00",
						"e": "2024-02-22T01:15:00",
						"v": "conn"
					},
					{
						"id": 5,
						"s": "2024-02-22T01:15:00",
						"e": "2024-02-22T01:30:00",
						"v": "conn"
					},
					{
						"id": 6,
						"s": "2024-02-22T01:30:00",
						"e": "2024-02-22T01:45:00",
						"v": "conn"
					},
					{
						"id": 7,
						"s": "2024-02-22T01:45:00",
						"e": "2024-02-22T02:00:00",
						"v": "conn"
					},
					{
						"id": 8,
						"s": "2024-02-22T02:00:00",
						"e": "2024-02-22T02:15:00",
						"v": "conn"
					},
					{
						"id": 9,
						"s": "2024-02-22T02:15:00",
						"e": "2024-02-22T02:30:00",
						"v": "conn"
					},
					{
						"id": 10,
						"s": "2024-02-22T02:30:00",
						"e": "2024-02-22T02:45:00",
						"v": "conn"
					},
					{
						"id": 11,
						"s": "2024-02-22T02:45:00",
						"e": "2024-02-22T03:00:00",
						"v": "conn"
					},
					{
						"id": 12,
						"s": "2024-02-22T03:00:00",
						"e": "2024-02-22T03:15:00",
						"v": "conn"
					},
					{
						"id": 13,
						"s": "2024-02-22T03:15:00",
						"e": "2024-02-22T03:30:00",
						"v": "conn"
					},
					{
						"id": 14,
						"s": "2024-02-22T03:30:00",
						"e": "2024-02-22T03:45:00",
						"v": "conn"
					},
					{
						"id": 15,
						"s": "2024-02-22T03:45:00",
						"e": "2024-02-22T04:00:00",
						"v": "conn"
					},
					{
						"id": 16,
						"s": "2024-02-22T04:00:00",
						"e": "2024-02-22T04:15:00",
						"v": "conn"
					},
					{
						"id": 17,
						"s": "2024-02-22T04:15:00",
						"e": "2024-02-22T04:30:00",
						"v": "conn"
					},
					{
						"id": 18,
						"s": "2024-02-22T04:30:00",
						"e": "2024-02-22T04:45:00",
						"v": "conn"
					},
					{
						"id": 19,
						"s": "2024-02-22T04:45:00",
						"e": "2024-02-22T05:00:00",
						"v": "conn"
					},
					{
						"id": 20,
						"s": "2024-02-22T05:00:00",
						"e": "2024-02-22T05:15:00",
						"v": "conn"
					},
					{
						"id": 21,
						"s": "2024-02-22T05:15:00",
						"e": "2024-02-22T05:30:00",
						"v": "conn"
					},
					{
						"id": 22,
						"s": "2024-02-22T05:30:00",
						"e": "2024-02-22T05:45:00",
						"v": "conn"
					},
					{
						"id": 23,
						"s": "2024-02-22T05:45:00",
						"e": "2024-02-22T06:00:00",
						"v": "conn"
					},
					{
						"id": 24,
						"s": "2024-02-22T06:00:00",
						"e": "2024-02-22T06:15:00",
						"v": "conn"
					},
					{
						"id": 25,
						"s": "2024-02-22T06:15:00",
						"e": "2024-02-22T06:30:00",
						"v": "conn"
					},
					{
						"id": 26,
						"s": "2024-02-22T06:30:00",
						"e": "2024-02-22T06:45:00",
						"v": "conn"
					},
					{
						"id": 27,
						"s": "2024-02-22T06:45:00",
						"e": "2024-02-22T07:00:00",
						"v": "conn"
					},
					{
						"id": 28,
						"s": "2024-02-22T07:00:00",
						"e": "2024-02-22T07:15:00",
						"v": "conn"
					},
					{
						"id": 29,
						"s": "2024-02-22T07:15:00",
						"e": "2024-02-22T07:30:00",
						"v": "conn"
					},
					{
						"id": 30,
						"s": "2024-02-22T07:30:00",
						"e": "2024-02-22T07:45:00",
						"v": "conn"
					},
					{
						"id": 31,
						"s": "2024-02-22T07:45:00",
						"e": "2024-02-22T08:00:00",
						"v": "conn"
					},
					{
						"id": 32,
						"s": "2024-02-22T08:00:00",
						"e": "2024-02-22T08:15:00",
						"v": "conn"
					},
					{
						"id": 33,
						"s": "2024-02-22T08:15:00",
						"e": "2024-02-22T08:30:00",
						"v": "conn"
					},
					{
						"id": 34,
						"s": "2024-02-22T08:30:00",
						"e": "2024-02-22T08:45:00",
						"v": "conn"
					},
					{
						"id": 35,
						"s": "2024-02-22T08:45:00",
						"e": "2024-02-22T09:00:00",
						"v": "conn"
					},
					{
						"id": 36,
						"s": "2024-02-22T09:00:00",
						"e": "2024-02-22T09:15:00",
						"v": "conn"
					},
					{
						"id": 37,
						"s": "2024-02-22T09:15:00",
						"e": "2024-02-22T09:30:00",
						"v": "conn"
					},
					{
						"id": 38,
						"s": "2024-02-22T09:30:00",
						"e": "2024-02-22T09:45:00",
						"v": "conn"
					},
					{
						"id": 39,
						"s": "2024-02-22T09:45:00",
						"e": "2024-02-22T10:00:00",
						"v": "conn"
					},
					{
						"id": 40,
						"s": "2024-02-22T10:00:00",
						"e": "2024-02-22T10:15:00",
						"v": "conn"
					},
					{
						"id": 41,
						"s": "2024-02-22T10:15:00",
						"e": "2024-02-22T10:30:00",
						"v": "conn"
					},
					{
						"id": 42,
						"s": "2024-02-22T10:30:00",
						"e": "2024-02-22T10:45:00",
						"v": "conn"
					},
					{
						"id": 43,
						"s": "2024-02-22T10:45:00",
						"e": "2024-02-22T11:00:00",
						"v": "conn"
					},
					{
						"id": 44,
						"s": "2024-02-22T11:00:00",
						"e": "2024-02-22T11:15:00",
						"v": "conn"
					},
					{
						"id": 45,
						"s": "2024-02-22T11:15:00",
						"e": "2024-02-22T11:30:00",
						"v": "conn"
					},
					{
						"id": 46,
						"s": "2024-02-22T11:30:00",
						"e": "2024-02-22T11:45:00",
						"v": "conn"
					},
					{
						"id": 47,
						"s": "2024-02-22T11:45:00",
						"e": "2024-02-22T12:00:00",
						"v": "conn"
					},
					{
						"id": 48,
						"s": "2024-02-22T12:00:00",
						"e": "2024-02-22T12:15:00",
						"v": "conn"
					},
					{
						"id": 49,
						"s": "2024-02-22T12:15:00",
						"e": "2024-02-22T12:30:00",
						"v": "conn"
					},
					{
						"id": 50,
						"s": "2024-02-22T12:30:00",
						"e": "2024-02-22T12:45:00",
						"v": "conn"
					},
					{
						"id": 51,
						"s": "2024-02-22T12:45:00",
						"e": "2024-02-22T13:00:00",
						"v": "conn"
					},
					{
						"id": 52,
						"s": "2024-02-22T13:00:00",
						"e": "2024-02-22T13:15:00",
						"v": "conn"
					},
					{
						"id": 53,
						"s": "2024-02-22T13:15:00",
						"e": "2024-02-22T13:30:00",
						"v": "conn"
					},
					{
						"id": 54,
						"s": "2024-02-22T13:30:00",
						"e": "2024-02-22T13:45:00",
						"v": "conn"
					},
					{
						"id": 55,
						"s": "2024-02-22T13:45:00",
						"e": "2024-02-22T14:00:00",
						"v": "conn"
					},
					{
						"id": 56,
						"s": "2024-02-22T14:00:00",
						"e": "2024-02-22T14:15:00",
						"v": "conn"
					},
					{
						"id": 57,
						"s": "2024-02-22T14:15:00",
						"e": "2024-02-22T14:30:00",
						"v": "conn"
					},
					{
						"id": 58,
						"s": "2024-02-22T14:30:00",
						"e": "2024-02-22T14:45:00",
						"v": "conn"
					},
					{
						"id": 59,
						"s": "2024-02-22T14:45:00",
						"e": "2024-02-22T15:00:00",
						"v": "conn"
					},
					{
						"id": 60,
						"s": "2024-02-22T15:00:00",
						"e": "2024-02-22T15:15:00",
						"v": "conn"
					},
					{
						"id": 61,
						"s": "2024-02-22T15:15:00",
						"e": "2024-02-22T15:30:00",
						"v": "conn"
					},
					{
						"id": 62,
						"s": "2024-02-22T15:30:00",
						"e": "2024-02-22T15:45:00",
						"v": "conn"
					},
					{
						"id": 63,
						"s": "2024-02-22T15:45:00",
						"e": "2024-02-22T16:00:00",
						"v": "conn"
					},
					{
						"id": 64,
						"s": "2024-02-22T16:00:00",
						"e": "2024-02-22T16:15:00",
						"v": "conn"
					},
					{
						"id": 65,
						"s": "2024-02-22T16:15:00",
						"e": "2024-02-22T16:30:00",
						"v": "conn"
					},
					{
						"id": 66,
						"s": "2024-02-22T16:30:00",
						"e": "2024-02-22T16:45:00",
						"v": "conn"
					},
					{
						"id": 67,
						"s": "2024-02-22T16:45:00",
						"e": "2024-02-22T17:00:00",
						"v": "conn"
					},
					{
						"id": 68,
						"s": "2024-02-22T17:00:00",
						"e": "2024-02-22T17:15:00",
						"v": "conn"
					},
					{
						"id": 69,
						"s": "2024-02-22T17:15:00",
						"e": "2024-02-22T17:30:00",
						"v": "conn"
					},
					{
						"id": 70,
						"s": "2024-02-22T17:30:00",
						"e": "2024-02-22T17:45:00",
						"v": "conn"
					},
					{
						"id": 71,
						"s": "2024-02-22T17:45:00",
						"e": "2024-02-22T18:00:00",
						"v": "conn"
					},
					{
						"id": 72,
						"s": "2024-02-22T18:00:00",
						"e": "2024-02-22T18:15:00",
						"v": "conn"
					},
					{
						"id": 73,
						"s": "2024-02-22T18:15:00",
						"e": "2024-02-22T18:30:00",
						"v": "conn"
					},
					{
						"id": 74,
						"s": "2024-02-22T18:30:00",
						"e": "2024-02-22T18:45:00",
						"v": "conn"
					},
					{
						"id": 75,
						"s": "2024-02-22T18:45:00",
						"e": "2024-02-22T19:00:00",
						"v": "conn"
					},
					{
						"id": 76,
						"s": "2024-02-22T19:00:00",
						"e": "2024-02-22T19:15:00",
						"v": "conn"
					},
					{
						"id": 77,
						"s": "2024-02-22T19:15:00",
						"e": "2024-02-22T19:30:00",
						"v": "conn"
					},
					{
						"id": 78,
						"s": "2024-02-22T19:30:00",
						"e": "2024-02-22T19:45:00",
						"v": "conn"
					},
					{
						"id": 79,
						"s": "2024-02-22T19:45:00",
						"e": "2024-02-22T20:00:00",
						"v": "conn"
					},
					{
						"id": 80,
						"s": "2024-02-22T20:00:00",
						"e": "2024-02-22T20:15:00",
						"v": "conn"
					},
					{
						"id": 81,
						"s": "2024-02-22T20:15:00",
						"e": "2024-02-22T20:30:00",
						"v": "conn"
					},
					{
						"id": 82,
						"s": "2024-02-22T20:30:00",
						"e": "2024-02-22T20:45:00",
						"v": "conn"
					},
					{
						"id": 83,
						"s": "2024-02-22T20:45:00",
						"e": "2024-02-22T21:00:00",
						"v": "conn"
					},
					{
						"id": 84,
						"s": "2024-02-22T21:00:00",
						"e": "2024-02-22T21:15:00",
						"v": "conn"
					},
					{
						"id": 85,
						"s": "2024-02-22T21:15:00",
						"e": "2024-02-22T21:30:00",
						"v": "conn"
					},
					{
						"id": 86,
						"s": "2024-02-22T21:30:00",
						"e": "2024-02-22T21:45:00",
						"v": "conn"
					},
					{
						"id": 87,
						"s": "2024-02-22T21:45:00",
						"e": "2024-02-22T22:00:00",
						"v": "conn"
					},
					{
						"id": 88,
						"s": "2024-02-22T22:00:00",
						"e": "2024-02-22T22:15:00",
						"v": "conn"
					},
					{
						"id": 89,
						"s": "2024-02-22T22:15:00",
						"e": "2024-02-22T22:30:00",
						"v": "conn"
					},
					{
						"id": 90,
						"s": "2024-02-22T22:30:00",
						"e": "2024-02-22T22:45:00",
						"v": "conn"
					},
					{
						"id": 91,
						"s": "2024-02-22T22:45:00",
						"e": "2024-02-22T23:00:00",
						"v": "conn"
					},
					{
						"id": 92,
						"s": "2024-02-22T23:00:00",
						"e": "2024-02-22T23:15:00",
						"v": "conn"
					},
					{
						"id": 93,
						"s": "2024-02-22T23:15:00",
						"e": "2024-02-22T23:30:00",
						"v": "conn"
					},
					{
						"id": 94,
						"s": "2024-02-22T23:30:00",
						"e": "2024-02-22T23:45:00",
						"v": "conn"
					},
					{
						"id": 95,
						"s": "2024-02-22T23:45:00",
						"e": "2024-02-23T00:00:00",
						"v": "conn"
					}
				]
			},
			{
				"id": 6,
				"date": "23 Feb 2024",
				"data": [
					{
						"id": 0,
						"s": "2024-02-23T00:00:00",
						"e": "2024-02-23T00:15:00",
						"v": "conn"
					},
					{
						"id": 1,
						"s": "2024-02-23T00:15:00",
						"e": "2024-02-23T00:30:00",
						"v": "conn"
					},
					{
						"id": 2,
						"s": "2024-02-23T00:30:00",
						"e": "2024-02-23T00:45:00",
						"v": "conn"
					},
					{
						"id": 3,
						"s": "2024-02-23T00:45:00",
						"e": "2024-02-23T01:00:00",
						"v": "conn"
					},
					{
						"id": 4,
						"s": "2024-02-23T01:00:00",
						"e": "2024-02-23T01:15:00",
						"v": "conn"
					},
					{
						"id": 5,
						"s": "2024-02-23T01:15:00",
						"e": "2024-02-23T01:30:00",
						"v": "conn"
					},
					{
						"id": 6,
						"s": "2024-02-23T01:30:00",
						"e": "2024-02-23T01:45:00",
						"v": "conn"
					},
					{
						"id": 7,
						"s": "2024-02-23T01:45:00",
						"e": "2024-02-23T02:00:00",
						"v": "conn"
					},
					{
						"id": 8,
						"s": "2024-02-23T02:00:00",
						"e": "2024-02-23T02:15:00",
						"v": "conn"
					},
					{
						"id": 9,
						"s": "2024-02-23T02:15:00",
						"e": "2024-02-23T02:30:00",
						"v": "conn"
					},
					{
						"id": 10,
						"s": "2024-02-23T02:30:00",
						"e": "2024-02-23T02:45:00",
						"v": "conn"
					},
					{
						"id": 11,
						"s": "2024-02-23T02:45:00",
						"e": "2024-02-23T03:00:00",
						"v": "conn"
					},
					{
						"id": 12,
						"s": "2024-02-23T03:00:00",
						"e": "2024-02-23T03:15:00",
						"v": "conn"
					},
					{
						"id": 13,
						"s": "2024-02-23T03:15:00",
						"e": "2024-02-23T03:30:00",
						"v": "conn"
					},
					{
						"id": 14,
						"s": "2024-02-23T03:30:00",
						"e": "2024-02-23T03:45:00",
						"v": "conn"
					},
					{
						"id": 15,
						"s": "2024-02-23T03:45:00",
						"e": "2024-02-23T04:00:00",
						"v": "conn"
					},
					{
						"id": 16,
						"s": "2024-02-23T04:00:00",
						"e": "2024-02-23T04:15:00",
						"v": "conn"
					},
					{
						"id": 17,
						"s": "2024-02-23T04:15:00",
						"e": "2024-02-23T04:30:00",
						"v": "conn"
					},
					{
						"id": 18,
						"s": "2024-02-23T04:30:00",
						"e": "2024-02-23T04:45:00",
						"v": "conn"
					},
					{
						"id": 19,
						"s": "2024-02-23T04:45:00",
						"e": "2024-02-23T05:00:00",
						"v": "conn"
					},
					{
						"id": 20,
						"s": "2024-02-23T05:00:00",
						"e": "2024-02-23T05:15:00",
						"v": "conn"
					},
					{
						"id": 21,
						"s": "2024-02-23T05:15:00",
						"e": "2024-02-23T05:30:00",
						"v": "conn"
					},
					{
						"id": 22,
						"s": "2024-02-23T05:30:00",
						"e": "2024-02-23T05:45:00",
						"v": "conn"
					},
					{
						"id": 23,
						"s": "2024-02-23T05:45:00",
						"e": "2024-02-23T06:00:00",
						"v": "conn"
					},
					{
						"id": 24,
						"s": "2024-02-23T06:00:00",
						"e": "2024-02-23T06:15:00",
						"v": "conn"
					},
					{
						"id": 25,
						"s": "2024-02-23T06:15:00",
						"e": "2024-02-23T06:30:00",
						"v": "conn"
					},
					{
						"id": 26,
						"s": "2024-02-23T06:30:00",
						"e": "2024-02-23T06:45:00",
						"v": "conn"
					},
					{
						"id": 27,
						"s": "2024-02-23T06:45:00",
						"e": "2024-02-23T07:00:00",
						"v": "conn"
					},
					{
						"id": 28,
						"s": "2024-02-23T07:00:00",
						"e": "2024-02-23T07:15:00",
						"v": "conn"
					},
					{
						"id": 29,
						"s": "2024-02-23T07:15:00",
						"e": "2024-02-23T07:30:00",
						"v": "conn"
					},
					{
						"id": 30,
						"s": "2024-02-23T07:30:00",
						"e": "2024-02-23T07:45:00",
						"v": "conn"
					},
					{
						"id": 31,
						"s": "2024-02-23T07:45:00",
						"e": "2024-02-23T08:00:00",
						"v": "conn"
					},
					{
						"id": 32,
						"s": "2024-02-23T08:00:00",
						"e": "2024-02-23T08:15:00",
						"v": "conn"
					},
					{
						"id": 33,
						"s": "2024-02-23T08:15:00",
						"e": "2024-02-23T08:30:00",
						"v": "conn"
					},
					{
						"id": 34,
						"s": "2024-02-23T08:30:00",
						"e": "2024-02-23T08:45:00",
						"v": "conn"
					},
					{
						"id": 35,
						"s": "2024-02-23T08:45:00",
						"e": "2024-02-23T09:00:00",
						"v": "conn"
					},
					{
						"id": 36,
						"s": "2024-02-23T09:00:00",
						"e": "2024-02-23T09:15:00",
						"v": "conn"
					},
					{
						"id": 37,
						"s": "2024-02-23T09:15:00",
						"e": "2024-02-23T09:30:00",
						"v": "conn"
					},
					{
						"id": 38,
						"s": "2024-02-23T09:30:00",
						"e": "2024-02-23T09:45:00",
						"v": "conn"
					},
					{
						"id": 39,
						"s": "2024-02-23T09:45:00",
						"e": "2024-02-23T10:00:00",
						"v": "conn"
					},
					{
						"id": 40,
						"s": "2024-02-23T10:00:00",
						"e": "2024-02-23T10:15:00",
						"v": "conn"
					},
					{
						"id": 41,
						"s": "2024-02-23T10:15:00",
						"e": "2024-02-23T10:30:00",
						"v": "conn"
					},
					{
						"id": 42,
						"s": "2024-02-23T10:30:00",
						"e": "2024-02-23T10:45:00",
						"v": "conn"
					},
					{
						"id": 43,
						"s": "2024-02-23T10:45:00",
						"e": "2024-02-23T11:00:00",
						"v": "conn"
					},
					{
						"id": 44,
						"s": "2024-02-23T11:00:00",
						"e": "2024-02-23T11:15:00",
						"v": "conn"
					},
					{
						"id": 45,
						"s": "2024-02-23T11:15:00",
						"e": "2024-02-23T11:30:00",
						"v": "conn"
					},
					{
						"id": 46,
						"s": "2024-02-23T11:30:00",
						"e": "2024-02-23T11:45:00",
						"v": "conn"
					},
					{
						"id": 47,
						"s": "2024-02-23T11:45:00",
						"e": "2024-02-23T12:00:00",
						"v": "conn"
					},
					{
						"id": 48,
						"s": "2024-02-23T12:00:00",
						"e": "2024-02-23T12:15:00",
						"v": "conn"
					},
					{
						"id": 49,
						"s": "2024-02-23T12:15:00",
						"e": "2024-02-23T12:30:00",
						"v": "conn"
					},
					{
						"id": 50,
						"s": "2024-02-23T12:30:00",
						"e": "2024-02-23T12:45:00",
						"v": "conn"
					},
					{
						"id": 51,
						"s": "2024-02-23T12:45:00",
						"e": "2024-02-23T13:00:00",
						"v": "conn"
					},
					{
						"id": 52,
						"s": "2024-02-23T13:00:00",
						"e": "2024-02-23T13:15:00",
						"v": "conn"
					},
					{
						"id": 53,
						"s": "2024-02-23T13:15:00",
						"e": "2024-02-23T13:30:00",
						"v": "conn"
					},
					{
						"id": 54,
						"s": "2024-02-23T13:30:00",
						"e": "2024-02-23T13:45:00",
						"v": "conn"
					},
					{
						"id": 55,
						"s": "2024-02-23T13:45:00",
						"e": "2024-02-23T14:00:00",
						"v": "conn"
					},
					{
						"id": 56,
						"s": "2024-02-23T14:00:00",
						"e": "2024-02-23T14:15:00",
						"v": "conn"
					},
					{
						"id": 57,
						"s": "2024-02-23T14:15:00",
						"e": "2024-02-23T14:30:00",
						"v": "conn"
					},
					{
						"id": 58,
						"s": "2024-02-23T14:30:00",
						"e": "2024-02-23T14:45:00",
						"v": "conn"
					},
					{
						"id": 59,
						"s": "2024-02-23T14:45:00",
						"e": "2024-02-23T15:00:00",
						"v": "conn"
					},
					{
						"id": 60,
						"s": "2024-02-23T15:00:00",
						"e": "2024-02-23T15:15:00",
						"v": "conn"
					},
					{
						"id": 61,
						"s": "2024-02-23T15:15:00",
						"e": "2024-02-23T15:30:00",
						"v": "conn"
					},
					{
						"id": 62,
						"s": "2024-02-23T15:30:00",
						"e": "2024-02-23T15:45:00",
						"v": "conn"
					},
					{
						"id": 63,
						"s": "2024-02-23T15:45:00",
						"e": "2024-02-23T16:00:00",
						"v": "conn"
					},
					{
						"id": 64,
						"s": "2024-02-23T16:00:00",
						"e": "2024-02-23T16:15:00",
						"v": "conn"
					},
					{
						"id": 65,
						"s": "2024-02-23T16:15:00",
						"e": "2024-02-23T16:30:00",
						"v": "conn"
					},
					{
						"id": 66,
						"s": "2024-02-23T16:30:00",
						"e": "2024-02-23T16:45:00",
						"v": "conn"
					},
					{
						"id": 67,
						"s": "2024-02-23T16:45:00",
						"e": "2024-02-23T17:00:00",
						"v": "conn"
					},
					{
						"id": 68,
						"s": "2024-02-23T17:00:00",
						"e": "2024-02-23T17:15:00",
						"v": "conn"
					},
					{
						"id": 69,
						"s": "2024-02-23T17:15:00",
						"e": "2024-02-23T17:30:00",
						"v": "conn"
					},
					{
						"id": 70,
						"s": "2024-02-23T17:30:00",
						"e": "2024-02-23T17:45:00",
						"v": "conn"
					},
					{
						"id": 71,
						"s": "2024-02-23T17:45:00",
						"e": "2024-02-23T18:00:00",
						"v": "conn"
					},
					{
						"id": 72,
						"s": "2024-02-23T18:00:00",
						"e": "2024-02-23T18:15:00",
						"v": "conn"
					},
					{
						"id": 73,
						"s": "2024-02-23T18:15:00",
						"e": "2024-02-23T18:30:00",
						"v": "conn"
					},
					{
						"id": 74,
						"s": "2024-02-23T18:30:00",
						"e": "2024-02-23T18:45:00",
						"v": "conn"
					},
					{
						"id": 75,
						"s": "2024-02-23T18:45:00",
						"e": "2024-02-23T19:00:00",
						"v": "conn"
					},
					{
						"id": 76,
						"s": "2024-02-23T19:00:00",
						"e": "2024-02-23T19:15:00",
						"v": "conn"
					},
					{
						"id": 77,
						"s": "2024-02-23T19:15:00",
						"e": "2024-02-23T19:30:00",
						"v": "conn"
					},
					{
						"id": 78,
						"s": "2024-02-23T19:30:00",
						"e": "2024-02-23T19:45:00",
						"v": "conn"
					},
					{
						"id": 79,
						"s": "2024-02-23T19:45:00",
						"e": "2024-02-23T20:00:00",
						"v": "conn"
					},
					{
						"id": 80,
						"s": "2024-02-23T20:00:00",
						"e": "2024-02-23T20:15:00",
						"v": "conn"
					},
					{
						"id": 81,
						"s": "2024-02-23T20:15:00",
						"e": "2024-02-23T20:30:00",
						"v": "conn"
					},
					{
						"id": 82,
						"s": "2024-02-23T20:30:00",
						"e": "2024-02-23T20:45:00",
						"v": "conn"
					},
					{
						"id": 83,
						"s": "2024-02-23T20:45:00",
						"e": "2024-02-23T21:00:00",
						"v": "conn"
					},
					{
						"id": 84,
						"s": "2024-02-23T21:00:00",
						"e": "2024-02-23T21:15:00",
						"v": "conn"
					},
					{
						"id": 85,
						"s": "2024-02-23T21:15:00",
						"e": "2024-02-23T21:30:00",
						"v": "conn"
					},
					{
						"id": 86,
						"s": "2024-02-23T21:30:00",
						"e": "2024-02-23T21:45:00",
						"v": "conn"
					},
					{
						"id": 87,
						"s": "2024-02-23T21:45:00",
						"e": "2024-02-23T22:00:00",
						"v": "conn"
					},
					{
						"id": 88,
						"s": "2024-02-23T22:00:00",
						"e": "2024-02-23T22:15:00",
						"v": "conn"
					},
					{
						"id": 89,
						"s": "2024-02-23T22:15:00",
						"e": "2024-02-23T22:30:00",
						"v": "conn"
					},
					{
						"id": 90,
						"s": "2024-02-23T22:30:00",
						"e": "2024-02-23T22:45:00",
						"v": "conn"
					},
					{
						"id": 91,
						"s": "2024-02-23T22:45:00",
						"e": "2024-02-23T23:00:00",
						"v": "conn"
					},
					{
						"id": 92,
						"s": "2024-02-23T23:00:00",
						"e": "2024-02-23T23:15:00",
						"v": "conn"
					},
					{
						"id": 93,
						"s": "2024-02-23T23:15:00",
						"e": "2024-02-23T23:30:00",
						"v": "conn"
					},
					{
						"id": 94,
						"s": "2024-02-23T23:30:00",
						"e": "2024-02-23T23:45:00",
						"v": "conn"
					},
					{
						"id": 95,
						"s": "2024-02-23T23:45:00",
						"e": "2024-02-24T00:00:00",
						"v": "conn"
					}
				]
			},
			{
				"id": 7,
				"date": "24 Feb 2024",
				"data": [
					{
						"id": 0,
						"s": "2024-02-24T00:00:00",
						"e": "2024-02-24T00:15:00",
						"v": "conn"
					},
					{
						"id": 1,
						"s": "2024-02-24T00:15:00",
						"e": "2024-02-24T00:30:00",
						"v": "conn"
					},
					{
						"id": 2,
						"s": "2024-02-24T00:30:00",
						"e": "2024-02-24T00:45:00",
						"v": "conn"
					},
					{
						"id": 3,
						"s": "2024-02-24T00:45:00",
						"e": "2024-02-24T01:00:00",
						"v": "conn"
					},
					{
						"id": 4,
						"s": "2024-02-24T01:00:00",
						"e": "2024-02-24T01:15:00",
						"v": "conn"
					},
					{
						"id": 5,
						"s": "2024-02-24T01:15:00",
						"e": "2024-02-24T01:30:00",
						"v": "conn"
					},
					{
						"id": 6,
						"s": "2024-02-24T01:30:00",
						"e": "2024-02-24T01:45:00",
						"v": "conn"
					},
					{
						"id": 7,
						"s": "2024-02-24T01:45:00",
						"e": "2024-02-24T02:00:00",
						"v": "conn"
					},
					{
						"id": 8,
						"s": "2024-02-24T02:00:00",
						"e": "2024-02-24T02:15:00",
						"v": "conn"
					},
					{
						"id": 9,
						"s": "2024-02-24T02:15:00",
						"e": "2024-02-24T02:30:00",
						"v": "conn"
					},
					{
						"id": 10,
						"s": "2024-02-24T02:30:00",
						"e": "2024-02-24T02:45:00",
						"v": "conn"
					},
					{
						"id": 11,
						"s": "2024-02-24T02:45:00",
						"e": "2024-02-24T03:00:00",
						"v": "conn"
					},
					{
						"id": 12,
						"s": "2024-02-24T03:00:00",
						"e": "2024-02-24T03:15:00",
						"v": "conn"
					},
					{
						"id": 13,
						"s": "2024-02-24T03:15:00",
						"e": "2024-02-24T03:30:00",
						"v": "conn"
					},
					{
						"id": 14,
						"s": "2024-02-24T03:30:00",
						"e": "2024-02-24T03:45:00",
						"v": "conn"
					},
					{
						"id": 15,
						"s": "2024-02-24T03:45:00",
						"e": "2024-02-24T04:00:00",
						"v": "conn"
					},
					{
						"id": 16,
						"s": "2024-02-24T04:00:00",
						"e": "2024-02-24T04:15:00",
						"v": "conn"
					},
					{
						"id": 17,
						"s": "2024-02-24T04:15:00",
						"e": "2024-02-24T04:30:00",
						"v": "conn"
					},
					{
						"id": 18,
						"s": "2024-02-24T04:30:00",
						"e": "2024-02-24T04:45:00",
						"v": "conn"
					},
					{
						"id": 19,
						"s": "2024-02-24T04:45:00",
						"e": "2024-02-24T05:00:00",
						"v": "conn"
					},
					{
						"id": 20,
						"s": "2024-02-24T05:00:00",
						"e": "2024-02-24T05:15:00",
						"v": "conn"
					},
					{
						"id": 21,
						"s": "2024-02-24T05:15:00",
						"e": "2024-02-24T05:30:00",
						"v": "conn"
					},
					{
						"id": 22,
						"s": "2024-02-24T05:30:00",
						"e": "2024-02-24T05:45:00",
						"v": "conn"
					},
					{
						"id": 23,
						"s": "2024-02-24T05:45:00",
						"e": "2024-02-24T06:00:00",
						"v": "conn"
					},
					{
						"id": 24,
						"s": "2024-02-24T06:00:00",
						"e": "2024-02-24T06:15:00",
						"v": "conn"
					},
					{
						"id": 25,
						"s": "2024-02-24T06:15:00",
						"e": "2024-02-24T06:30:00",
						"v": "conn"
					},
					{
						"id": 26,
						"s": "2024-02-24T06:30:00",
						"e": "2024-02-24T06:45:00",
						"v": "conn"
					},
					{
						"id": 27,
						"s": "2024-02-24T06:45:00",
						"e": "2024-02-24T07:00:00",
						"v": "conn"
					},
					{
						"id": 28,
						"s": "2024-02-24T07:00:00",
						"e": "2024-02-24T07:15:00",
						"v": "conn"
					},
					{
						"id": 29,
						"s": "2024-02-24T07:15:00",
						"e": "2024-02-24T07:30:00",
						"v": "conn"
					},
					{
						"id": 30,
						"s": "2024-02-24T07:30:00",
						"e": "2024-02-24T07:45:00",
						"v": "conn"
					},
					{
						"id": 31,
						"s": "2024-02-24T07:45:00",
						"e": "2024-02-24T08:00:00",
						"v": "conn"
					},
					{
						"id": 32,
						"s": "2024-02-24T08:00:00",
						"e": "2024-02-24T08:15:00",
						"v": "conn"
					},
					{
						"id": 33,
						"s": "2024-02-24T08:15:00",
						"e": "2024-02-24T08:30:00",
						"v": "conn"
					},
					{
						"id": 34,
						"s": "2024-02-24T08:30:00",
						"e": "2024-02-24T08:45:00",
						"v": "conn"
					},
					{
						"id": 35,
						"s": "2024-02-24T08:45:00",
						"e": "2024-02-24T09:00:00",
						"v": "conn"
					},
					{
						"id": 36,
						"s": "2024-02-24T09:00:00",
						"e": "2024-02-24T09:15:00",
						"v": "conn"
					},
					{
						"id": 37,
						"s": "2024-02-24T09:15:00",
						"e": "2024-02-24T09:30:00",
						"v": "conn"
					},
					{
						"id": 38,
						"s": "2024-02-24T09:30:00",
						"e": "2024-02-24T09:45:00",
						"v": "conn"
					},
					{
						"id": 39,
						"s": "2024-02-24T09:45:00",
						"e": "2024-02-24T10:00:00",
						"v": "conn"
					},
					{
						"id": 40,
						"s": "2024-02-24T10:00:00",
						"e": "2024-02-24T10:15:00",
						"v": "conn"
					},
					{
						"id": 41,
						"s": "2024-02-24T10:15:00",
						"e": "2024-02-24T10:30:00",
						"v": "conn"
					},
					{
						"id": 42,
						"s": "2024-02-24T10:30:00",
						"e": "2024-02-24T10:45:00",
						"v": "conn"
					},
					{
						"id": 43,
						"s": "2024-02-24T10:45:00",
						"e": "2024-02-24T11:00:00",
						"v": "conn"
					},
					{
						"id": 44,
						"s": "2024-02-24T11:00:00",
						"e": "2024-02-24T11:15:00",
						"v": "conn"
					},
					{
						"id": 45,
						"s": "2024-02-24T11:15:00",
						"e": "2024-02-24T11:30:00",
						"v": "conn"
					},
					{
						"id": 46,
						"s": "2024-02-24T11:30:00",
						"e": "2024-02-24T11:45:00",
						"v": "conn"
					},
					{
						"id": 47,
						"s": "2024-02-24T11:45:00",
						"e": "2024-02-24T12:00:00",
						"v": "conn"
					},
					{
						"id": 48,
						"s": "2024-02-24T12:00:00",
						"e": "2024-02-24T12:15:00",
						"v": "conn"
					},
					{
						"id": 49,
						"s": "2024-02-24T12:15:00",
						"e": "2024-02-24T12:30:00",
						"v": "conn"
					},
					{
						"id": 50,
						"s": "2024-02-24T12:30:00",
						"e": "2024-02-24T12:45:00",
						"v": "conn"
					},
					{
						"id": 51,
						"s": "2024-02-24T12:45:00",
						"e": "2024-02-24T13:00:00",
						"v": "conn"
					},
					{
						"id": 52,
						"s": "2024-02-24T13:00:00",
						"e": "2024-02-24T13:15:00",
						"v": "conn"
					},
					{
						"id": 53,
						"s": "2024-02-24T13:15:00",
						"e": "2024-02-24T13:30:00",
						"v": "conn"
					},
					{
						"id": 54,
						"s": "2024-02-24T13:30:00",
						"e": "2024-02-24T13:45:00",
						"v": "conn"
					},
					{
						"id": 55,
						"s": "2024-02-24T13:45:00",
						"e": "2024-02-24T14:00:00",
						"v": "conn"
					},
					{
						"id": 56,
						"s": "2024-02-24T14:00:00",
						"e": "2024-02-24T14:15:00",
						"v": "conn"
					},
					{
						"id": 57,
						"s": "2024-02-24T14:15:00",
						"e": "2024-02-24T14:30:00",
						"v": "conn"
					},
					{
						"id": 58,
						"s": "2024-02-24T14:30:00",
						"e": "2024-02-24T14:45:00",
						"v": "conn"
					},
					{
						"id": 59,
						"s": "2024-02-24T14:45:00",
						"e": "2024-02-24T15:00:00",
						"v": "conn"
					},
					{
						"id": 60,
						"s": "2024-02-24T15:00:00",
						"e": "2024-02-24T15:15:00",
						"v": "conn"
					},
					{
						"id": 61,
						"s": "2024-02-24T15:15:00",
						"e": "2024-02-24T15:30:00",
						"v": "conn"
					},
					{
						"id": 62,
						"s": "2024-02-24T15:30:00",
						"e": "2024-02-24T15:45:00",
						"v": "conn"
					},
					{
						"id": 63,
						"s": "2024-02-24T15:45:00",
						"e": "2024-02-24T16:00:00",
						"v": "conn"
					},
					{
						"id": 64,
						"s": "2024-02-24T16:00:00",
						"e": "2024-02-24T16:15:00",
						"v": "conn"
					},
					{
						"id": 65,
						"s": "2024-02-24T16:15:00",
						"e": "2024-02-24T16:30:00",
						"v": "conn"
					},
					{
						"id": 66,
						"s": "2024-02-24T16:30:00",
						"e": "2024-02-24T16:45:00",
						"v": "conn"
					},
					{
						"id": 67,
						"s": "2024-02-24T16:45:00",
						"e": "2024-02-24T17:00:00",
						"v": "conn"
					},
					{
						"id": 68,
						"s": "2024-02-24T17:00:00",
						"e": "2024-02-24T17:15:00",
						"v": "conn"
					},
					{
						"id": 69,
						"s": "2024-02-24T17:15:00",
						"e": "2024-02-24T17:30:00",
						"v": "conn"
					},
					{
						"id": 70,
						"s": "2024-02-24T17:30:00",
						"e": "2024-02-24T17:45:00",
						"v": "conn"
					},
					{
						"id": 71,
						"s": "2024-02-24T17:45:00",
						"e": "2024-02-24T18:00:00",
						"v": "conn"
					},
					{
						"id": 72,
						"s": "2024-02-24T18:00:00",
						"e": "2024-02-24T18:15:00",
						"v": "conn"
					},
					{
						"id": 73,
						"s": "2024-02-24T18:15:00",
						"e": "2024-02-24T18:30:00",
						"v": "conn"
					},
					{
						"id": 74,
						"s": "2024-02-24T18:30:00",
						"e": "2024-02-24T18:45:00",
						"v": "conn"
					},
					{
						"id": 75,
						"s": "2024-02-24T18:45:00",
						"e": "2024-02-24T19:00:00",
						"v": "conn"
					},
					{
						"id": 76,
						"s": "2024-02-24T19:00:00",
						"e": "2024-02-24T19:15:00",
						"v": "conn"
					},
					{
						"id": 77,
						"s": "2024-02-24T19:15:00",
						"e": "2024-02-24T19:30:00",
						"v": "conn"
					},
					{
						"id": 78,
						"s": "2024-02-24T19:30:00",
						"e": "2024-02-24T19:45:00",
						"v": "reco"
					},
					{
						"id": 79,
						"s": "2024-02-24T19:45:00",
						"e": "2024-02-24T20:00:00",
						"v": "conn"
					},
					{
						"id": 80,
						"s": "2024-02-24T20:00:00",
						"e": "2024-02-24T20:15:00",
						"v": "conn"
					},
					{
						"id": 81,
						"s": "2024-02-24T20:15:00",
						"e": "2024-02-24T20:30:00",
						"v": "conn"
					},
					{
						"id": 82,
						"s": "2024-02-24T20:30:00",
						"e": "2024-02-24T20:45:00",
						"v": "conn"
					},
					{
						"id": 83,
						"s": "2024-02-24T20:45:00",
						"e": "2024-02-24T21:00:00",
						"v": "conn"
					},
					{
						"id": 84,
						"s": "2024-02-24T21:00:00",
						"e": "2024-02-24T21:15:00",
						"v": "conn"
					},
					{
						"id": 85,
						"s": "2024-02-24T21:15:00",
						"e": "2024-02-24T21:30:00",
						"v": "conn"
					},
					{
						"id": 86,
						"s": "2024-02-24T21:30:00",
						"e": "2024-02-24T21:45:00",
						"v": "conn"
					},
					{
						"id": 87,
						"s": "2024-02-24T21:45:00",
						"e": "2024-02-24T22:00:00",
						"v": "conn"
					},
					{
						"id": 88,
						"s": "2024-02-24T22:00:00",
						"e": "2024-02-24T22:15:00",
						"v": "conn"
					},
					{
						"id": 89,
						"s": "2024-02-24T22:15:00",
						"e": "2024-02-24T22:30:00",
						"v": "conn"
					},
					{
						"id": 90,
						"s": "2024-02-24T22:30:00",
						"e": "2024-02-24T22:45:00",
						"v": "conn"
					},
					{
						"id": 91,
						"s": "2024-02-24T22:45:00",
						"e": "2024-02-24T23:00:00",
						"v": "conn"
					},
					{
						"id": 92,
						"s": "2024-02-24T23:00:00",
						"e": "2024-02-24T23:15:00",
						"v": "conn"
					},
					{
						"id": 93,
						"s": "2024-02-24T23:15:00",
						"e": "2024-02-24T23:30:00",
						"v": "conn"
					},
					{
						"id": 94,
						"s": "2024-02-24T23:30:00",
						"e": "2024-02-24T23:45:00",
						"v": "conn"
					},
					{
						"id": 95,
						"s": "2024-02-24T23:45:00",
						"e": "2024-02-25T00:00:00",
						"v": "conn"
					}
				]
			},
			{
				"id": 8,
				"date": "25 Feb 2024",
				"data": [
					{
						"id": 0,
						"s": "2024-02-25T00:00:00",
						"e": "2024-02-25T00:15:00",
						"v": "conn"
					},
					{
						"id": 1,
						"s": "2024-02-25T00:15:00",
						"e": "2024-02-25T00:30:00",
						"v": "conn"
					},
					{
						"id": 2,
						"s": "2024-02-25T00:30:00",
						"e": "2024-02-25T00:45:00",
						"v": "conn"
					},
					{
						"id": 3,
						"s": "2024-02-25T00:45:00",
						"e": "2024-02-25T01:00:00",
						"v": "conn"
					},
					{
						"id": 4,
						"s": "2024-02-25T01:00:00",
						"e": "2024-02-25T01:15:00",
						"v": "conn"
					},
					{
						"id": 5,
						"s": "2024-02-25T01:15:00",
						"e": "2024-02-25T01:30:00",
						"v": "conn"
					},
					{
						"id": 6,
						"s": "2024-02-25T01:30:00",
						"e": "2024-02-25T01:45:00",
						"v": "conn"
					},
					{
						"id": 7,
						"s": "2024-02-25T01:45:00",
						"e": "2024-02-25T02:00:00",
						"v": "conn"
					},
					{
						"id": 8,
						"s": "2024-02-25T02:00:00",
						"e": "2024-02-25T02:15:00",
						"v": "conn"
					},
					{
						"id": 9,
						"s": "2024-02-25T02:15:00",
						"e": "2024-02-25T02:30:00",
						"v": "conn"
					},
					{
						"id": 10,
						"s": "2024-02-25T02:30:00",
						"e": "2024-02-25T02:45:00",
						"v": "conn"
					},
					{
						"id": 11,
						"s": "2024-02-25T02:45:00",
						"e": "2024-02-25T03:00:00",
						"v": "conn"
					},
					{
						"id": 12,
						"s": "2024-02-25T03:00:00",
						"e": "2024-02-25T03:15:00",
						"v": "conn"
					},
					{
						"id": 13,
						"s": "2024-02-25T03:15:00",
						"e": "2024-02-25T03:30:00",
						"v": "conn"
					},
					{
						"id": 14,
						"s": "2024-02-25T03:30:00",
						"e": "2024-02-25T03:45:00",
						"v": "conn"
					},
					{
						"id": 15,
						"s": "2024-02-25T03:45:00",
						"e": "2024-02-25T04:00:00",
						"v": "conn"
					},
					{
						"id": 16,
						"s": "2024-02-25T04:00:00",
						"e": "2024-02-25T04:15:00",
						"v": "conn"
					},
					{
						"id": 17,
						"s": "2024-02-25T04:15:00",
						"e": "2024-02-25T04:30:00",
						"v": "conn"
					},
					{
						"id": 18,
						"s": "2024-02-25T04:30:00",
						"e": "2024-02-25T04:45:00",
						"v": "conn"
					},
					{
						"id": 19,
						"s": "2024-02-25T04:45:00",
						"e": "2024-02-25T05:00:00",
						"v": "conn"
					},
					{
						"id": 20,
						"s": "2024-02-25T05:00:00",
						"e": "2024-02-25T05:15:00",
						"v": "conn"
					},
					{
						"id": 21,
						"s": "2024-02-25T05:15:00",
						"e": "2024-02-25T05:30:00",
						"v": "conn"
					},
					{
						"id": 22,
						"s": "2024-02-25T05:30:00",
						"e": "2024-02-25T05:45:00",
						"v": "conn"
					},
					{
						"id": 23,
						"s": "2024-02-25T05:45:00",
						"e": "2024-02-25T06:00:00",
						"v": "conn"
					},
					{
						"id": 24,
						"s": "2024-02-25T06:00:00",
						"e": "2024-02-25T06:15:00",
						"v": "conn"
					},
					{
						"id": 25,
						"s": "2024-02-25T06:15:00",
						"e": "2024-02-25T06:30:00",
						"v": "conn"
					},
					{
						"id": 26,
						"s": "2024-02-25T06:30:00",
						"e": "2024-02-25T06:45:00",
						"v": "conn"
					},
					{
						"id": 27,
						"s": "2024-02-25T06:45:00",
						"e": "2024-02-25T07:00:00",
						"v": "conn"
					},
					{
						"id": 28,
						"s": "2024-02-25T07:00:00",
						"e": "2024-02-25T07:15:00",
						"v": "conn"
					},
					{
						"id": 29,
						"s": "2024-02-25T07:15:00",
						"e": "2024-02-25T07:30:00",
						"v": "conn"
					},
					{
						"id": 30,
						"s": "2024-02-25T07:30:00",
						"e": "2024-02-25T07:45:00",
						"v": "conn"
					},
					{
						"id": 31,
						"s": "2024-02-25T07:45:00",
						"e": "2024-02-25T08:00:00",
						"v": "conn"
					},
					{
						"id": 32,
						"s": "2024-02-25T08:00:00",
						"e": "2024-02-25T08:15:00",
						"v": "conn"
					},
					{
						"id": 33,
						"s": "2024-02-25T08:15:00",
						"e": "2024-02-25T08:30:00",
						"v": "conn"
					},
					{
						"id": 34,
						"s": "2024-02-25T08:30:00",
						"e": "2024-02-25T08:45:00",
						"v": "conn"
					},
					{
						"id": 35,
						"s": "2024-02-25T08:45:00",
						"e": "2024-02-25T09:00:00",
						"v": "conn"
					},
					{
						"id": 36,
						"s": "2024-02-25T09:00:00",
						"e": "2024-02-25T09:15:00",
						"v": "conn"
					},
					{
						"id": 37,
						"s": "2024-02-25T09:15:00",
						"e": "2024-02-25T09:30:00",
						"v": "conn"
					},
					{
						"id": 38,
						"s": "2024-02-25T09:30:00",
						"e": "2024-02-25T09:45:00",
						"v": "conn"
					},
					{
						"id": 39,
						"s": "2024-02-25T09:45:00",
						"e": "2024-02-25T10:00:00",
						"v": "conn"
					},
					{
						"id": 40,
						"s": "2024-02-25T10:00:00",
						"e": "2024-02-25T10:15:00",
						"v": "conn"
					},
					{
						"id": 41,
						"s": "2024-02-25T10:15:00",
						"e": "2024-02-25T10:30:00",
						"v": "conn"
					},
					{
						"id": 42,
						"s": "2024-02-25T10:30:00",
						"e": "2024-02-25T10:45:00",
						"v": "conn"
					},
					{
						"id": 43,
						"s": "2024-02-25T10:45:00",
						"e": "2024-02-25T11:00:00",
						"v": "conn"
					},
					{
						"id": 44,
						"s": "2024-02-25T11:00:00",
						"e": "2024-02-25T11:15:00",
						"v": "conn"
					},
					{
						"id": 45,
						"s": "2024-02-25T11:15:00",
						"e": "2024-02-25T11:30:00",
						"v": "conn"
					},
					{
						"id": 46,
						"s": "2024-02-25T11:30:00",
						"e": "2024-02-25T11:45:00",
						"v": "conn"
					},
					{
						"id": 47,
						"s": "2024-02-25T11:45:00",
						"e": "2024-02-25T12:00:00",
						"v": "conn"
					},
					{
						"id": 48,
						"s": "2024-02-25T12:00:00",
						"e": "2024-02-25T12:15:00",
						"v": "conn"
					},
					{
						"id": 49,
						"s": "2024-02-25T12:15:00",
						"e": "2024-02-25T12:30:00",
						"v": "conn"
					},
					{
						"id": 50,
						"s": "2024-02-25T12:30:00",
						"e": "2024-02-25T12:45:00",
						"v": "conn"
					},
					{
						"id": 51,
						"s": "2024-02-25T12:45:00",
						"e": "2024-02-25T13:00:00",
						"v": "conn"
					},
					{
						"id": 52,
						"s": "2024-02-25T13:00:00",
						"e": "2024-02-25T13:15:00",
						"v": "conn"
					},
					{
						"id": 53,
						"s": "2024-02-25T13:15:00",
						"e": "2024-02-25T13:30:00",
						"v": "conn"
					},
					{
						"id": 54,
						"s": "2024-02-25T13:30:00",
						"e": "2024-02-25T13:45:00",
						"v": "conn"
					},
					{
						"id": 55,
						"s": "2024-02-25T13:45:00",
						"e": "2024-02-25T14:00:00",
						"v": "conn"
					},
					{
						"id": 56,
						"s": "2024-02-25T14:00:00",
						"e": "2024-02-25T14:15:00",
						"v": "conn"
					},
					{
						"id": 57,
						"s": "2024-02-25T14:15:00",
						"e": "2024-02-25T14:30:00",
						"v": "conn"
					},
					{
						"id": 58,
						"s": "2024-02-25T14:30:00",
						"e": "2024-02-25T14:45:00",
						"v": "conn"
					},
					{
						"id": 59,
						"s": "2024-02-25T14:45:00",
						"e": "2024-02-25T15:00:00",
						"v": "conn"
					},
					{
						"id": 60,
						"s": "2024-02-25T15:00:00",
						"e": "2024-02-25T15:15:00",
						"v": "conn"
					},
					{
						"id": 61,
						"s": "2024-02-25T15:15:00",
						"e": "2024-02-25T15:30:00",
						"v": "conn"
					},
					{
						"id": 62,
						"s": "2024-02-25T15:30:00",
						"e": "2024-02-25T15:45:00",
						"v": "conn"
					},
					{
						"id": 63,
						"s": "2024-02-25T15:45:00",
						"e": "2024-02-25T16:00:00",
						"v": "conn"
					},
					{
						"id": 64,
						"s": "2024-02-25T16:00:00",
						"e": "2024-02-25T16:15:00",
						"v": "conn"
					},
					{
						"id": 65,
						"s": "2024-02-25T16:15:00",
						"e": "2024-02-25T16:30:00",
						"v": "conn"
					},
					{
						"id": 66,
						"s": "2024-02-25T16:30:00",
						"e": "2024-02-25T16:45:00",
						"v": "conn"
					},
					{
						"id": 67,
						"s": "2024-02-25T16:45:00",
						"e": "2024-02-25T17:00:00",
						"v": "conn"
					},
					{
						"id": 68,
						"s": "2024-02-25T17:00:00",
						"e": "2024-02-25T17:15:00",
						"v": "conn"
					},
					{
						"id": 69,
						"s": "2024-02-25T17:15:00",
						"e": "2024-02-25T17:30:00",
						"v": "conn"
					},
					{
						"id": 70,
						"s": "2024-02-25T17:30:00",
						"e": "2024-02-25T17:45:00",
						"v": "conn"
					},
					{
						"id": 71,
						"s": "2024-02-25T17:45:00",
						"e": "2024-02-25T18:00:00",
						"v": "conn"
					},
					{
						"id": 72,
						"s": "2024-02-25T18:00:00",
						"e": "2024-02-25T18:15:00",
						"v": "conn"
					},
					{
						"id": 73,
						"s": "2024-02-25T18:15:00",
						"e": "2024-02-25T18:30:00",
						"v": "conn"
					},
					{
						"id": 74,
						"s": "2024-02-25T18:30:00",
						"e": "2024-02-25T18:45:00",
						"v": "conn"
					},
					{
						"id": 75,
						"s": "2024-02-25T18:45:00",
						"e": "2024-02-25T19:00:00",
						"v": "conn"
					},
					{
						"id": 76,
						"s": "2024-02-25T19:00:00",
						"e": "2024-02-25T19:15:00",
						"v": "conn"
					},
					{
						"id": 77,
						"s": "2024-02-25T19:15:00",
						"e": "2024-02-25T19:30:00",
						"v": "conn"
					},
					{
						"id": 78,
						"s": "2024-02-25T19:30:00",
						"e": "2024-02-25T19:45:00",
						"v": "conn"
					},
					{
						"id": 79,
						"s": "2024-02-25T19:45:00",
						"e": "2024-02-25T20:00:00",
						"v": "conn"
					},
					{
						"id": 80,
						"s": "2024-02-25T20:00:00",
						"e": "2024-02-25T20:15:00",
						"v": "conn"
					},
					{
						"id": 81,
						"s": "2024-02-25T20:15:00",
						"e": "2024-02-25T20:30:00",
						"v": "conn"
					},
					{
						"id": 82,
						"s": "2024-02-25T20:30:00",
						"e": "2024-02-25T20:45:00",
						"v": "conn"
					},
					{
						"id": 83,
						"s": "2024-02-25T20:45:00",
						"e": "2024-02-25T21:00:00",
						"v": "conn"
					},
					{
						"id": 84,
						"s": "2024-02-25T21:00:00",
						"e": "2024-02-25T21:15:00",
						"v": "conn"
					},
					{
						"id": 85,
						"s": "2024-02-25T21:15:00",
						"e": "2024-02-25T21:30:00",
						"v": "conn"
					},
					{
						"id": 86,
						"s": "2024-02-25T21:30:00",
						"e": "2024-02-25T21:45:00",
						"v": "conn"
					},
					{
						"id": 87,
						"s": "2024-02-25T21:45:00",
						"e": "2024-02-25T22:00:00",
						"v": "conn"
					},
					{
						"id": 88,
						"s": "2024-02-25T22:00:00",
						"e": "2024-02-25T22:15:00",
						"v": "conn"
					},
					{
						"id": 89,
						"s": "2024-02-25T22:15:00",
						"e": "2024-02-25T22:30:00",
						"v": "conn"
					},
					{
						"id": 90,
						"s": "2024-02-25T22:30:00",
						"e": "2024-02-25T22:45:00",
						"v": "conn"
					},
					{
						"id": 91,
						"s": "2024-02-25T22:45:00",
						"e": "2024-02-25T23:00:00",
						"v": "conn"
					},
					{
						"id": 92,
						"s": "2024-02-25T23:00:00",
						"e": "2024-02-25T23:15:00",
						"v": "conn"
					},
					{
						"id": 93,
						"s": "2024-02-25T23:15:00",
						"e": "2024-02-25T23:30:00",
						"v": "conn"
					},
					{
						"id": 94,
						"s": "2024-02-25T23:30:00",
						"e": "2024-02-25T23:45:00",
						"v": "conn"
					},
					{
						"id": 95,
						"s": "2024-02-25T23:45:00",
						"e": "2024-02-26T00:00:00",
						"v": "conn"
					}
				]
			},
			{
				"id": 9,
				"date": "26 Feb 2024",
				"data": [
					{
						"id": 0,
						"s": "2024-02-26T00:00:00",
						"e": "2024-02-26T00:15:00",
						"v": "conn"
					},
					{
						"id": 1,
						"s": "2024-02-26T00:15:00",
						"e": "2024-02-26T00:30:00",
						"v": "conn"
					},
					{
						"id": 2,
						"s": "2024-02-26T00:30:00",
						"e": "2024-02-26T00:45:00",
						"v": "conn"
					},
					{
						"id": 3,
						"s": "2024-02-26T00:45:00",
						"e": "2024-02-26T01:00:00",
						"v": "conn"
					},
					{
						"id": 4,
						"s": "2024-02-26T01:00:00",
						"e": "2024-02-26T01:15:00",
						"v": "conn"
					},
					{
						"id": 5,
						"s": "2024-02-26T01:15:00",
						"e": "2024-02-26T01:30:00",
						"v": "conn"
					},
					{
						"id": 6,
						"s": "2024-02-26T01:30:00",
						"e": "2024-02-26T01:45:00",
						"v": "conn"
					},
					{
						"id": 7,
						"s": "2024-02-26T01:45:00",
						"e": "2024-02-26T02:00:00",
						"v": "conn"
					},
					{
						"id": 8,
						"s": "2024-02-26T02:00:00",
						"e": "2024-02-26T02:15:00",
						"v": "conn"
					},
					{
						"id": 9,
						"s": "2024-02-26T02:15:00",
						"e": "2024-02-26T02:30:00",
						"v": "conn"
					},
					{
						"id": 10,
						"s": "2024-02-26T02:30:00",
						"e": "2024-02-26T02:45:00",
						"v": "conn"
					},
					{
						"id": 11,
						"s": "2024-02-26T02:45:00",
						"e": "2024-02-26T03:00:00",
						"v": "conn"
					},
					{
						"id": 12,
						"s": "2024-02-26T03:00:00",
						"e": "2024-02-26T03:15:00",
						"v": "conn"
					},
					{
						"id": 13,
						"s": "2024-02-26T03:15:00",
						"e": "2024-02-26T03:30:00",
						"v": "conn"
					},
					{
						"id": 14,
						"s": "2024-02-26T03:30:00",
						"e": "2024-02-26T03:45:00",
						"v": "conn"
					},
					{
						"id": 15,
						"s": "2024-02-26T03:45:00",
						"e": "2024-02-26T04:00:00",
						"v": "conn"
					},
					{
						"id": 16,
						"s": "2024-02-26T04:00:00",
						"e": "2024-02-26T04:15:00",
						"v": "conn"
					},
					{
						"id": 17,
						"s": "2024-02-26T04:15:00",
						"e": "2024-02-26T04:30:00",
						"v": "conn"
					},
					{
						"id": 18,
						"s": "2024-02-26T04:30:00",
						"e": "2024-02-26T04:45:00",
						"v": "conn"
					},
					{
						"id": 19,
						"s": "2024-02-26T04:45:00",
						"e": "2024-02-26T05:00:00",
						"v": "conn"
					},
					{
						"id": 20,
						"s": "2024-02-26T05:00:00",
						"e": "2024-02-26T05:15:00",
						"v": "conn"
					},
					{
						"id": 21,
						"s": "2024-02-26T05:15:00",
						"e": "2024-02-26T05:30:00",
						"v": "conn"
					},
					{
						"id": 22,
						"s": "2024-02-26T05:30:00",
						"e": "2024-02-26T05:45:00",
						"v": "conn"
					},
					{
						"id": 23,
						"s": "2024-02-26T05:45:00",
						"e": "2024-02-26T06:00:00",
						"v": "conn"
					},
					{
						"id": 24,
						"s": "2024-02-26T06:00:00",
						"e": "2024-02-26T06:15:00",
						"v": "conn"
					},
					{
						"id": 25,
						"s": "2024-02-26T06:15:00",
						"e": "2024-02-26T06:30:00",
						"v": "conn"
					},
					{
						"id": 26,
						"s": "2024-02-26T06:30:00",
						"e": "2024-02-26T06:45:00",
						"v": "conn"
					},
					{
						"id": 27,
						"s": "2024-02-26T06:45:00",
						"e": "2024-02-26T07:00:00",
						"v": "conn"
					},
					{
						"id": 28,
						"s": "2024-02-26T07:00:00",
						"e": "2024-02-26T07:15:00",
						"v": "conn"
					},
					{
						"id": 29,
						"s": "2024-02-26T07:15:00",
						"e": "2024-02-26T07:30:00",
						"v": "conn"
					},
					{
						"id": 30,
						"s": "2024-02-26T07:30:00",
						"e": "2024-02-26T07:45:00",
						"v": "conn"
					},
					{
						"id": 31,
						"s": "2024-02-26T07:45:00",
						"e": "2024-02-26T08:00:00",
						"v": "conn"
					},
					{
						"id": 32,
						"s": "2024-02-26T08:00:00",
						"e": "2024-02-26T08:15:00",
						"v": "conn"
					},
					{
						"id": 33,
						"s": "2024-02-26T08:15:00",
						"e": "2024-02-26T08:30:00",
						"v": "conn"
					},
					{
						"id": 34,
						"s": "2024-02-26T08:30:00",
						"e": "2024-02-26T08:45:00",
						"v": "conn"
					},
					{
						"id": 35,
						"s": "2024-02-26T08:45:00",
						"e": "2024-02-26T09:00:00",
						"v": "conn"
					},
					{
						"id": 36,
						"s": "2024-02-26T09:00:00",
						"e": "2024-02-26T09:15:00",
						"v": "conn"
					},
					{
						"id": 37,
						"s": "2024-02-26T09:15:00",
						"e": "2024-02-26T09:30:00",
						"v": "conn"
					},
					{
						"id": 38,
						"s": "2024-02-26T09:30:00",
						"e": "2024-02-26T09:45:00",
						"v": "conn"
					},
					{
						"id": 39,
						"s": "2024-02-26T09:45:00",
						"e": "2024-02-26T10:00:00",
						"v": "conn"
					},
					{
						"id": 40,
						"s": "2024-02-26T10:00:00",
						"e": "2024-02-26T10:15:00",
						"v": "conn"
					},
					{
						"id": 41,
						"s": "2024-02-26T10:15:00",
						"e": "2024-02-26T10:30:00",
						"v": "conn"
					},
					{
						"id": 42,
						"s": "2024-02-26T10:30:00",
						"e": "2024-02-26T10:45:00",
						"v": "conn"
					},
					{
						"id": 43,
						"s": "2024-02-26T10:45:00",
						"e": "2024-02-26T11:00:00",
						"v": "conn"
					},
					{
						"id": 44,
						"s": "2024-02-26T11:00:00",
						"e": "2024-02-26T11:15:00",
						"v": "conn"
					},
					{
						"id": 45,
						"s": "2024-02-26T11:15:00",
						"e": "2024-02-26T11:30:00",
						"v": "conn"
					},
					{
						"id": 46,
						"s": "2024-02-26T11:30:00",
						"e": "2024-02-26T11:45:00",
						"v": "conn"
					},
					{
						"id": 47,
						"s": "2024-02-26T11:45:00",
						"e": "2024-02-26T12:00:00",
						"v": "conn"
					},
					{
						"id": 48,
						"s": "2024-02-26T12:00:00",
						"e": "2024-02-26T12:15:00",
						"v": "conn"
					},
					{
						"id": 49,
						"s": "2024-02-26T12:15:00",
						"e": "2024-02-26T12:30:00",
						"v": "conn"
					},
					{
						"id": 50,
						"s": "2024-02-26T12:30:00",
						"e": "2024-02-26T12:45:00",
						"v": "conn"
					},
					{
						"id": 51,
						"s": "2024-02-26T12:45:00",
						"e": "2024-02-26T13:00:00",
						"v": "conn"
					},
					{
						"id": 52,
						"s": "2024-02-26T13:00:00",
						"e": "2024-02-26T13:15:00",
						"v": "conn"
					},
					{
						"id": 53,
						"s": "2024-02-26T13:15:00",
						"e": "2024-02-26T13:30:00",
						"v": "conn"
					},
					{
						"id": 54,
						"s": "2024-02-26T13:30:00",
						"e": "2024-02-26T13:45:00",
						"v": "conn"
					},
					{
						"id": 55,
						"s": "2024-02-26T13:45:00",
						"e": "2024-02-26T14:00:00",
						"v": "conn"
					},
					{
						"id": 56,
						"s": "2024-02-26T14:00:00",
						"e": "2024-02-26T14:15:00",
						"v": "conn"
					},
					{
						"id": 57,
						"s": "2024-02-26T14:15:00",
						"e": "2024-02-26T14:30:00",
						"v": "conn"
					},
					{
						"id": 58,
						"s": "2024-02-26T14:30:00",
						"e": "2024-02-26T14:45:00",
						"v": "conn"
					},
					{
						"id": 59,
						"s": "2024-02-26T14:45:00",
						"e": "2024-02-26T15:00:00",
						"v": "conn"
					},
					{
						"id": 60,
						"s": "2024-02-26T15:00:00",
						"e": "2024-02-26T15:15:00",
						"v": "conn"
					},
					{
						"id": 61,
						"s": "2024-02-26T15:15:00",
						"e": "2024-02-26T15:30:00",
						"v": "conn"
					},
					{
						"id": 62,
						"s": "2024-02-26T15:30:00",
						"e": "2024-02-26T15:45:00",
						"v": "conn"
					},
					{
						"id": 63,
						"s": "2024-02-26T15:45:00",
						"e": "2024-02-26T16:00:00",
						"v": "conn"
					},
					{
						"id": 64,
						"s": "2024-02-26T16:00:00",
						"e": "2024-02-26T16:15:00",
						"v": "conn"
					},
					{
						"id": 65,
						"s": "2024-02-26T16:15:00",
						"e": "2024-02-26T16:30:00",
						"v": "conn"
					},
					{
						"id": 66,
						"s": "2024-02-26T16:30:00",
						"e": "2024-02-26T16:45:00",
						"v": "conn"
					},
					{
						"id": 67,
						"s": "2024-02-26T16:45:00",
						"e": "2024-02-26T17:00:00",
						"v": "conn"
					},
					{
						"id": 68,
						"s": "2024-02-26T17:00:00",
						"e": "2024-02-26T17:15:00",
						"v": "conn"
					},
					{
						"id": 69,
						"s": "2024-02-26T17:15:00",
						"e": "2024-02-26T17:30:00",
						"v": "conn"
					},
					{
						"id": 70,
						"s": "2024-02-26T17:30:00",
						"e": "2024-02-26T17:45:00",
						"v": "conn"
					},
					{
						"id": 71,
						"s": "2024-02-26T17:45:00",
						"e": "2024-02-26T18:00:00",
						"v": "conn"
					},
					{
						"id": 72,
						"s": "2024-02-26T18:00:00",
						"e": "2024-02-26T18:15:00",
						"v": "conn"
					},
					{
						"id": 73,
						"s": "2024-02-26T18:15:00",
						"e": "2024-02-26T18:30:00",
						"v": "conn"
					},
					{
						"id": 74,
						"s": "2024-02-26T18:30:00",
						"e": "2024-02-26T18:45:00",
						"v": "conn"
					},
					{
						"id": 75,
						"s": "2024-02-26T18:45:00",
						"e": "2024-02-26T19:00:00",
						"v": "conn"
					},
					{
						"id": 76,
						"s": "2024-02-26T19:00:00",
						"e": "2024-02-26T19:15:00",
						"v": "conn"
					},
					{
						"id": 77,
						"s": "2024-02-26T19:15:00",
						"e": "2024-02-26T19:30:00",
						"v": "conn"
					},
					{
						"id": 78,
						"s": "2024-02-26T19:30:00",
						"e": "2024-02-26T19:45:00",
						"v": "conn"
					},
					{
						"id": 79,
						"s": "2024-02-26T19:45:00",
						"e": "2024-02-26T20:00:00",
						"v": "conn"
					},
					{
						"id": 80,
						"s": "2024-02-26T20:00:00",
						"e": "2024-02-26T20:15:00",
						"v": "conn"
					},
					{
						"id": 81,
						"s": "2024-02-26T20:15:00",
						"e": "2024-02-26T20:30:00",
						"v": "conn"
					},
					{
						"id": 82,
						"s": "2024-02-26T20:30:00",
						"e": "2024-02-26T20:45:00",
						"v": "conn"
					},
					{
						"id": 83,
						"s": "2024-02-26T20:45:00",
						"e": "2024-02-26T21:00:00",
						"v": "conn"
					},
					{
						"id": 84,
						"s": "2024-02-26T21:00:00",
						"e": "2024-02-26T21:15:00",
						"v": "conn"
					},
					{
						"id": 85,
						"s": "2024-02-26T21:15:00",
						"e": "2024-02-26T21:30:00",
						"v": "conn"
					},
					{
						"id": 86,
						"s": "2024-02-26T21:30:00",
						"e": "2024-02-26T21:45:00",
						"v": "conn"
					},
					{
						"id": 87,
						"s": "2024-02-26T21:45:00",
						"e": "2024-02-26T22:00:00",
						"v": "conn"
					},
					{
						"id": 88,
						"s": "2024-02-26T22:00:00",
						"e": "2024-02-26T22:15:00",
						"v": "conn"
					},
					{
						"id": 89,
						"s": "2024-02-26T22:15:00",
						"e": "2024-02-26T22:30:00",
						"v": "conn"
					},
					{
						"id": 90,
						"s": "2024-02-26T22:30:00",
						"e": "2024-02-26T22:45:00",
						"v": "conn"
					},
					{
						"id": 91,
						"s": "2024-02-26T22:45:00",
						"e": "2024-02-26T23:00:00",
						"v": "conn"
					},
					{
						"id": 92,
						"s": "2024-02-26T23:00:00",
						"e": "2024-02-26T23:15:00",
						"v": "conn"
					},
					{
						"id": 93,
						"s": "2024-02-26T23:15:00",
						"e": "2024-02-26T23:30:00",
						"v": "conn"
					},
					{
						"id": 94,
						"s": "2024-02-26T23:30:00",
						"e": "2024-02-26T23:45:00",
						"v": "conn"
					},
					{
						"id": 95,
						"s": "2024-02-26T23:45:00",
						"e": "2024-02-27T00:00:00",
						"v": "conn"
					}
				]
			},
			{
				"id": 10,
				"date": "27 Feb 2024",
				"data": [
					{
						"id": 0,
						"s": "2024-02-27T00:00:00",
						"e": "2024-02-27T00:15:00",
						"v": "conn"
					},
					{
						"id": 1,
						"s": "2024-02-27T00:15:00",
						"e": "2024-02-27T00:30:00",
						"v": "conn"
					},
					{
						"id": 2,
						"s": "2024-02-27T00:30:00",
						"e": "2024-02-27T00:45:00",
						"v": "conn"
					},
					{
						"id": 3,
						"s": "2024-02-27T00:45:00",
						"e": "2024-02-27T01:00:00",
						"v": "conn"
					},
					{
						"id": 4,
						"s": "2024-02-27T01:00:00",
						"e": "2024-02-27T01:15:00",
						"v": "conn"
					},
					{
						"id": 5,
						"s": "2024-02-27T01:15:00",
						"e": "2024-02-27T01:30:00",
						"v": "conn"
					},
					{
						"id": 6,
						"s": "2024-02-27T01:30:00",
						"e": "2024-02-27T01:45:00",
						"v": "conn"
					},
					{
						"id": 7,
						"s": "2024-02-27T01:45:00",
						"e": "2024-02-27T02:00:00",
						"v": "conn"
					},
					{
						"id": 8,
						"s": "2024-02-27T02:00:00",
						"e": "2024-02-27T02:15:00",
						"v": "conn"
					},
					{
						"id": 9,
						"s": "2024-02-27T02:15:00",
						"e": "2024-02-27T02:30:00",
						"v": "conn"
					},
					{
						"id": 10,
						"s": "2024-02-27T02:30:00",
						"e": "2024-02-27T02:45:00",
						"v": "conn"
					},
					{
						"id": 11,
						"s": "2024-02-27T02:45:00",
						"e": "2024-02-27T03:00:00",
						"v": "conn"
					},
					{
						"id": 12,
						"s": "2024-02-27T03:00:00",
						"e": "2024-02-27T03:15:00",
						"v": "conn"
					},
					{
						"id": 13,
						"s": "2024-02-27T03:15:00",
						"e": "2024-02-27T03:30:00",
						"v": "conn"
					},
					{
						"id": 14,
						"s": "2024-02-27T03:30:00",
						"e": "2024-02-27T03:45:00",
						"v": "conn"
					},
					{
						"id": 15,
						"s": "2024-02-27T03:45:00",
						"e": "2024-02-27T04:00:00",
						"v": "conn"
					},
					{
						"id": 16,
						"s": "2024-02-27T04:00:00",
						"e": "2024-02-27T04:15:00",
						"v": "conn"
					},
					{
						"id": 17,
						"s": "2024-02-27T04:15:00",
						"e": "2024-02-27T04:30:00",
						"v": "conn"
					},
					{
						"id": 18,
						"s": "2024-02-27T04:30:00",
						"e": "2024-02-27T04:45:00",
						"v": "conn"
					},
					{
						"id": 19,
						"s": "2024-02-27T04:45:00",
						"e": "2024-02-27T05:00:00",
						"v": "conn"
					},
					{
						"id": 20,
						"s": "2024-02-27T05:00:00",
						"e": "2024-02-27T05:15:00",
						"v": "conn"
					},
					{
						"id": 21,
						"s": "2024-02-27T05:15:00",
						"e": "2024-02-27T05:30:00",
						"v": "conn"
					},
					{
						"id": 22,
						"s": "2024-02-27T05:30:00",
						"e": "2024-02-27T05:45:00",
						"v": "conn"
					},
					{
						"id": 23,
						"s": "2024-02-27T05:45:00",
						"e": "2024-02-27T06:00:00",
						"v": "conn"
					},
					{
						"id": 24,
						"s": "2024-02-27T06:00:00",
						"e": "2024-02-27T06:15:00",
						"v": "conn"
					},
					{
						"id": 25,
						"s": "2024-02-27T06:15:00",
						"e": "2024-02-27T06:30:00",
						"v": "conn"
					},
					{
						"id": 26,
						"s": "2024-02-27T06:30:00",
						"e": "2024-02-27T06:45:00",
						"v": "conn"
					},
					{
						"id": 27,
						"s": "2024-02-27T06:45:00",
						"e": "2024-02-27T07:00:00",
						"v": "conn"
					},
					{
						"id": 28,
						"s": "2024-02-27T07:00:00",
						"e": "2024-02-27T07:15:00",
						"v": "conn"
					},
					{
						"id": 29,
						"s": "2024-02-27T07:15:00",
						"e": "2024-02-27T07:30:00",
						"v": "conn"
					},
					{
						"id": 30,
						"s": "2024-02-27T07:30:00",
						"e": "2024-02-27T07:45:00",
						"v": "conn"
					},
					{
						"id": 31,
						"s": "2024-02-27T07:45:00",
						"e": "2024-02-27T08:00:00",
						"v": "conn"
					},
					{
						"id": 32,
						"s": "2024-02-27T08:00:00",
						"e": "2024-02-27T08:15:00",
						"v": "conn"
					},
					{
						"id": 33,
						"s": "2024-02-27T08:15:00",
						"e": "2024-02-27T08:30:00",
						"v": "conn"
					},
					{
						"id": 34,
						"s": "2024-02-27T08:30:00",
						"e": "2024-02-27T08:45:00",
						"v": "conn"
					},
					{
						"id": 35,
						"s": "2024-02-27T08:45:00",
						"e": "2024-02-27T09:00:00",
						"v": "conn"
					},
					{
						"id": 36,
						"s": "2024-02-27T09:00:00",
						"e": "2024-02-27T09:15:00",
						"v": "conn"
					},
					{
						"id": 37,
						"s": "2024-02-27T09:15:00",
						"e": "2024-02-27T09:30:00",
						"v": "conn"
					},
					{
						"id": 38,
						"s": "2024-02-27T09:30:00",
						"e": "2024-02-27T09:45:00",
						"v": "conn"
					},
					{
						"id": 39,
						"s": "2024-02-27T09:45:00",
						"e": "2024-02-27T10:00:00",
						"v": "conn"
					},
					{
						"id": 40,
						"s": "2024-02-27T10:00:00",
						"e": "2024-02-27T10:15:00",
						"v": "conn"
					},
					{
						"id": 41,
						"s": "2024-02-27T10:15:00",
						"e": "2024-02-27T10:30:00",
						"v": "conn"
					},
					{
						"id": 42,
						"s": "2024-02-27T10:30:00",
						"e": "2024-02-27T10:45:00",
						"v": "conn"
					},
					{
						"id": 43,
						"s": "2024-02-27T10:45:00",
						"e": "2024-02-27T11:00:00",
						"v": "conn"
					},
					{
						"id": 44,
						"s": "2024-02-27T11:00:00",
						"e": "2024-02-27T11:15:00",
						"v": "conn"
					},
					{
						"id": 45,
						"s": "2024-02-27T11:15:00",
						"e": "2024-02-27T11:30:00",
						"v": "conn"
					},
					{
						"id": 46,
						"s": "2024-02-27T11:30:00",
						"e": "2024-02-27T11:45:00",
						"v": "conn"
					},
					{
						"id": 47,
						"s": "2024-02-27T11:45:00",
						"e": "2024-02-27T12:00:00",
						"v": "conn"
					},
					{
						"id": 48,
						"s": "2024-02-27T12:00:00",
						"e": "2024-02-27T12:15:00",
						"v": "conn"
					},
					{
						"id": 49,
						"s": "2024-02-27T12:15:00",
						"e": "2024-02-27T12:30:00",
						"v": "conn"
					},
					{
						"id": 50,
						"s": "2024-02-27T12:30:00",
						"e": "2024-02-27T12:45:00",
						"v": "conn"
					},
					{
						"id": 51,
						"s": "2024-02-27T12:45:00",
						"e": "2024-02-27T13:00:00",
						"v": "conn"
					},
					{
						"id": 52,
						"s": "2024-02-27T13:00:00",
						"e": "2024-02-27T13:15:00",
						"v": "conn"
					},
					{
						"id": 53,
						"s": "2024-02-27T13:15:00",
						"e": "2024-02-27T13:30:00",
						"v": "conn"
					},
					{
						"id": 54,
						"s": "2024-02-27T13:30:00",
						"e": "2024-02-27T13:45:00",
						"v": "conn"
					},
					{
						"id": 55,
						"s": "2024-02-27T13:45:00",
						"e": "2024-02-27T14:00:00",
						"v": "conn"
					},
					{
						"id": 56,
						"s": "2024-02-27T14:00:00",
						"e": "2024-02-27T14:15:00",
						"v": "conn"
					},
					{
						"id": 57,
						"s": "2024-02-27T14:15:00",
						"e": "2024-02-27T14:30:00",
						"v": "conn"
					},
					{
						"id": 58,
						"s": "2024-02-27T14:30:00",
						"e": "2024-02-27T14:45:00",
						"v": "conn"
					},
					{
						"id": 59,
						"s": "2024-02-27T14:45:00",
						"e": "2024-02-27T15:00:00",
						"v": "conn"
					},
					{
						"id": 60,
						"s": "2024-02-27T15:00:00",
						"e": "2024-02-27T15:15:00",
						"v": "conn"
					},
					{
						"id": 61,
						"s": "2024-02-27T15:15:00",
						"e": "2024-02-27T15:30:00",
						"v": "conn"
					},
					{
						"id": 62,
						"s": "2024-02-27T15:30:00",
						"e": "2024-02-27T15:45:00",
						"v": "conn"
					},
					{
						"id": 63,
						"s": "2024-02-27T15:45:00",
						"e": "2024-02-27T16:00:00",
						"v": "conn"
					},
					{
						"id": 64,
						"s": "2024-02-27T16:00:00",
						"e": "2024-02-27T16:15:00",
						"v": "conn"
					},
					{
						"id": 65,
						"s": "2024-02-27T16:15:00",
						"e": "2024-02-27T16:30:00",
						"v": "conn"
					},
					{
						"id": 66,
						"s": "2024-02-27T16:30:00",
						"e": "2024-02-27T16:45:00",
						"v": "conn"
					},
					{
						"id": 67,
						"s": "2024-02-27T16:45:00",
						"e": "2024-02-27T17:00:00",
						"v": "conn"
					},
					{
						"id": 68,
						"s": "2024-02-27T17:00:00",
						"e": "2024-02-27T17:15:00",
						"v": "conn"
					},
					{
						"id": 69,
						"s": "2024-02-27T17:15:00",
						"e": "2024-02-27T17:30:00",
						"v": "conn"
					},
					{
						"id": 70,
						"s": "2024-02-27T17:30:00",
						"e": "2024-02-27T17:45:00",
						"v": "conn"
					},
					{
						"id": 71,
						"s": "2024-02-27T17:45:00",
						"e": "2024-02-27T18:00:00",
						"v": "conn"
					},
					{
						"id": 72,
						"s": "2024-02-27T18:00:00",
						"e": "2024-02-27T18:15:00",
						"v": "conn"
					},
					{
						"id": 73,
						"s": "2024-02-27T18:15:00",
						"e": "2024-02-27T18:30:00",
						"v": "conn"
					},
					{
						"id": 74,
						"s": "2024-02-27T18:30:00",
						"e": "2024-02-27T18:45:00",
						"v": "conn"
					},
					{
						"id": 75,
						"s": "2024-02-27T18:45:00",
						"e": "2024-02-27T19:00:00",
						"v": "conn"
					},
					{
						"id": 76,
						"s": "2024-02-27T19:00:00",
						"e": "2024-02-27T19:15:00",
						"v": "conn"
					},
					{
						"id": 77,
						"s": "2024-02-27T19:15:00",
						"e": "2024-02-27T19:30:00",
						"v": "conn"
					},
					{
						"id": 78,
						"s": "2024-02-27T19:30:00",
						"e": "2024-02-27T19:45:00",
						"v": "conn"
					},
					{
						"id": 79,
						"s": "2024-02-27T19:45:00",
						"e": "2024-02-27T20:00:00",
						"v": "conn"
					},
					{
						"id": 80,
						"s": "2024-02-27T20:00:00",
						"e": "2024-02-27T20:15:00",
						"v": "conn"
					},
					{
						"id": 81,
						"s": "2024-02-27T20:15:00",
						"e": "2024-02-27T20:30:00",
						"v": "conn"
					},
					{
						"id": 82,
						"s": "2024-02-27T20:30:00",
						"e": "2024-02-27T20:45:00",
						"v": "conn"
					},
					{
						"id": 83,
						"s": "2024-02-27T20:45:00",
						"e": "2024-02-27T21:00:00",
						"v": "conn"
					},
					{
						"id": 84,
						"s": "2024-02-27T21:00:00",
						"e": "2024-02-27T21:15:00",
						"v": "conn"
					},
					{
						"id": 85,
						"s": "2024-02-27T21:15:00",
						"e": "2024-02-27T21:30:00",
						"v": "conn"
					},
					{
						"id": 86,
						"s": "2024-02-27T21:30:00",
						"e": "2024-02-27T21:45:00",
						"v": "conn"
					},
					{
						"id": 87,
						"s": "2024-02-27T21:45:00",
						"e": "2024-02-27T22:00:00",
						"v": "conn"
					},
					{
						"id": 88,
						"s": "2024-02-27T22:00:00",
						"e": "2024-02-27T22:15:00",
						"v": "conn"
					},
					{
						"id": 89,
						"s": "2024-02-27T22:15:00",
						"e": "2024-02-27T22:30:00",
						"v": "conn"
					},
					{
						"id": 90,
						"s": "2024-02-27T22:30:00",
						"e": "2024-02-27T22:45:00",
						"v": "conn"
					},
					{
						"id": 91,
						"s": "2024-02-27T22:45:00",
						"e": "2024-02-27T23:00:00",
						"v": "conn"
					},
					{
						"id": 92,
						"s": "2024-02-27T23:00:00",
						"e": "2024-02-27T23:15:00",
						"v": "conn"
					},
					{
						"id": 93,
						"s": "2024-02-27T23:15:00",
						"e": "2024-02-27T23:30:00",
						"v": "conn"
					},
					{
						"id": 94,
						"s": "2024-02-27T23:30:00",
						"e": "2024-02-27T23:45:00",
						"v": "conn"
					},
					{
						"id": 95,
						"s": "2024-02-27T23:45:00",
						"e": "2024-02-28T00:00:00",
						"v": "conn"
					}
				]
			},
			{
				"id": 11,
				"date": "28 Feb 2024",
				"data": [
					{
						"id": 0,
						"s": "2024-02-28T00:00:00",
						"e": "2024-02-28T00:15:00",
						"v": "conn"
					},
					{
						"id": 1,
						"s": "2024-02-28T00:15:00",
						"e": "2024-02-28T00:30:00",
						"v": "conn"
					},
					{
						"id": 2,
						"s": "2024-02-28T00:30:00",
						"e": "2024-02-28T00:45:00",
						"v": "conn"
					},
					{
						"id": 3,
						"s": "2024-02-28T00:45:00",
						"e": "2024-02-28T01:00:00",
						"v": "conn"
					},
					{
						"id": 4,
						"s": "2024-02-28T01:00:00",
						"e": "2024-02-28T01:15:00",
						"v": "conn"
					},
					{
						"id": 5,
						"s": "2024-02-28T01:15:00",
						"e": "2024-02-28T01:30:00",
						"v": "conn"
					},
					{
						"id": 6,
						"s": "2024-02-28T01:30:00",
						"e": "2024-02-28T01:45:00",
						"v": "conn"
					},
					{
						"id": 7,
						"s": "2024-02-28T01:45:00",
						"e": "2024-02-28T02:00:00",
						"v": "conn"
					},
					{
						"id": 8,
						"s": "2024-02-28T02:00:00",
						"e": "2024-02-28T02:15:00",
						"v": "conn"
					},
					{
						"id": 9,
						"s": "2024-02-28T02:15:00",
						"e": "2024-02-28T02:30:00",
						"v": "conn"
					},
					{
						"id": 10,
						"s": "2024-02-28T02:30:00",
						"e": "2024-02-28T02:45:00",
						"v": "conn"
					},
					{
						"id": 11,
						"s": "2024-02-28T02:45:00",
						"e": "2024-02-28T03:00:00",
						"v": "conn"
					},
					{
						"id": 12,
						"s": "2024-02-28T03:00:00",
						"e": "2024-02-28T03:15:00",
						"v": "conn"
					},
					{
						"id": 13,
						"s": "2024-02-28T03:15:00",
						"e": "2024-02-28T03:30:00",
						"v": "conn"
					},
					{
						"id": 14,
						"s": "2024-02-28T03:30:00",
						"e": "2024-02-28T03:45:00",
						"v": "conn"
					},
					{
						"id": 15,
						"s": "2024-02-28T03:45:00",
						"e": "2024-02-28T04:00:00",
						"v": "conn"
					},
					{
						"id": 16,
						"s": "2024-02-28T04:00:00",
						"e": "2024-02-28T04:15:00",
						"v": "conn"
					},
					{
						"id": 17,
						"s": "2024-02-28T04:15:00",
						"e": "2024-02-28T04:30:00",
						"v": "conn"
					},
					{
						"id": 18,
						"s": "2024-02-28T04:30:00",
						"e": "2024-02-28T04:45:00",
						"v": "conn"
					},
					{
						"id": 19,
						"s": "2024-02-28T04:45:00",
						"e": "2024-02-28T05:00:00",
						"v": "conn"
					},
					{
						"id": 20,
						"s": "2024-02-28T05:00:00",
						"e": "2024-02-28T05:15:00",
						"v": "conn"
					},
					{
						"id": 21,
						"s": "2024-02-28T05:15:00",
						"e": "2024-02-28T05:30:00",
						"v": "conn"
					},
					{
						"id": 22,
						"s": "2024-02-28T05:30:00",
						"e": "2024-02-28T05:45:00",
						"v": "conn"
					},
					{
						"id": 23,
						"s": "2024-02-28T05:45:00",
						"e": "2024-02-28T06:00:00",
						"v": "conn"
					},
					{
						"id": 24,
						"s": "2024-02-28T06:00:00",
						"e": "2024-02-28T06:15:00",
						"v": "conn"
					},
					{
						"id": 25,
						"s": "2024-02-28T06:15:00",
						"e": "2024-02-28T06:30:00",
						"v": "conn"
					},
					{
						"id": 26,
						"s": "2024-02-28T06:30:00",
						"e": "2024-02-28T06:45:00",
						"v": "conn"
					},
					{
						"id": 27,
						"s": "2024-02-28T06:45:00",
						"e": "2024-02-28T07:00:00",
						"v": "conn"
					},
					{
						"id": 28,
						"s": "2024-02-28T07:00:00",
						"e": "2024-02-28T07:15:00",
						"v": "conn"
					},
					{
						"id": 29,
						"s": "2024-02-28T07:15:00",
						"e": "2024-02-28T07:30:00",
						"v": "conn"
					},
					{
						"id": 30,
						"s": "2024-02-28T07:30:00",
						"e": "2024-02-28T07:45:00",
						"v": "conn"
					},
					{
						"id": 31,
						"s": "2024-02-28T07:45:00",
						"e": "2024-02-28T08:00:00",
						"v": "conn"
					},
					{
						"id": 32,
						"s": "2024-02-28T08:00:00",
						"e": "2024-02-28T08:15:00",
						"v": "conn"
					},
					{
						"id": 33,
						"s": "2024-02-28T08:15:00",
						"e": "2024-02-28T08:30:00",
						"v": "conn"
					},
					{
						"id": 34,
						"s": "2024-02-28T08:30:00",
						"e": "2024-02-28T08:45:00",
						"v": "conn"
					},
					{
						"id": 35,
						"s": "2024-02-28T08:45:00",
						"e": "2024-02-28T09:00:00",
						"v": "conn"
					},
					{
						"id": 36,
						"s": "2024-02-28T09:00:00",
						"e": "2024-02-28T09:15:00",
						"v": "conn"
					},
					{
						"id": 37,
						"s": "2024-02-28T09:15:00",
						"e": "2024-02-28T09:30:00",
						"v": "conn"
					},
					{
						"id": 38,
						"s": "2024-02-28T09:30:00",
						"e": "2024-02-28T09:45:00",
						"v": "conn"
					},
					{
						"id": 39,
						"s": "2024-02-28T09:45:00",
						"e": "2024-02-28T10:00:00",
						"v": "conn"
					},
					{
						"id": 40,
						"s": "2024-02-28T10:00:00",
						"e": "2024-02-28T10:15:00",
						"v": "conn"
					},
					{
						"id": 41,
						"s": "2024-02-28T10:15:00",
						"e": "2024-02-28T10:30:00",
						"v": "conn"
					},
					{
						"id": 42,
						"s": "2024-02-28T10:30:00",
						"e": "2024-02-28T10:45:00",
						"v": "conn"
					},
					{
						"id": 43,
						"s": "2024-02-28T10:45:00",
						"e": "2024-02-28T11:00:00",
						"v": "conn"
					},
					{
						"id": 44,
						"s": "2024-02-28T11:00:00",
						"e": "2024-02-28T11:15:00",
						"v": "conn"
					},
					{
						"id": 45,
						"s": "2024-02-28T11:15:00",
						"e": "2024-02-28T11:30:00",
						"v": "conn"
					},
					{
						"id": 46,
						"s": "2024-02-28T11:30:00",
						"e": "2024-02-28T11:45:00",
						"v": "conn"
					},
					{
						"id": 47,
						"s": "2024-02-28T11:45:00",
						"e": "2024-02-28T12:00:00",
						"v": "conn"
					},
					{
						"id": 48,
						"s": "2024-02-28T12:00:00",
						"e": "2024-02-28T12:15:00",
						"v": "conn"
					},
					{
						"id": 49,
						"s": "2024-02-28T12:15:00",
						"e": "2024-02-28T12:30:00",
						"v": "conn"
					},
					{
						"id": 50,
						"s": "2024-02-28T12:30:00",
						"e": "2024-02-28T12:45:00",
						"v": "conn"
					},
					{
						"id": 51,
						"s": "2024-02-28T12:45:00",
						"e": "2024-02-28T13:00:00",
						"v": "conn"
					},
					{
						"id": 52,
						"s": "2024-02-28T13:00:00",
						"e": "2024-02-28T13:15:00",
						"v": "conn"
					},
					{
						"id": 53,
						"s": "2024-02-28T13:15:00",
						"e": "2024-02-28T13:30:00",
						"v": "conn"
					},
					{
						"id": 54,
						"s": "2024-02-28T13:30:00",
						"e": "2024-02-28T13:45:00",
						"v": "conn"
					},
					{
						"id": 55,
						"s": "2024-02-28T13:45:00",
						"e": "2024-02-28T14:00:00",
						"v": "conn"
					},
					{
						"id": 56,
						"s": "2024-02-28T14:00:00",
						"e": "2024-02-28T14:15:00",
						"v": "conn"
					},
					{
						"id": 57,
						"s": "2024-02-28T14:15:00",
						"e": "2024-02-28T14:30:00",
						"v": "conn"
					},
					{
						"id": 58,
						"s": "2024-02-28T14:30:00",
						"e": "2024-02-28T14:45:00",
						"v": "conn"
					},
					{
						"id": 59,
						"s": "2024-02-28T14:45:00",
						"e": "2024-02-28T15:00:00",
						"v": "conn"
					},
					{
						"id": 60,
						"s": "2024-02-28T15:00:00",
						"e": "2024-02-28T15:15:00",
						"v": "conn"
					},
					{
						"id": 61,
						"s": "2024-02-28T15:15:00",
						"e": "2024-02-28T15:30:00",
						"v": "conn"
					},
					{
						"id": 62,
						"s": "2024-02-28T15:30:00",
						"e": "2024-02-28T15:45:00",
						"v": "conn"
					},
					{
						"id": 63,
						"s": "2024-02-28T15:45:00",
						"e": "2024-02-28T16:00:00",
						"v": "conn"
					},
					{
						"id": 64,
						"s": "2024-02-28T16:00:00",
						"e": "2024-02-28T16:15:00",
						"v": "conn"
					},
					{
						"id": 65,
						"s": "2024-02-28T16:15:00",
						"e": "2024-02-28T16:30:00",
						"v": "conn"
					},
					{
						"id": 66,
						"s": "2024-02-28T16:30:00",
						"e": "2024-02-28T16:45:00",
						"v": "conn"
					},
					{
						"id": 67,
						"s": "2024-02-28T16:45:00",
						"e": "2024-02-28T17:00:00",
						"v": "conn"
					},
					{
						"id": 68,
						"s": "2024-02-28T17:00:00",
						"e": "2024-02-28T17:15:00",
						"v": "conn"
					},
					{
						"id": 69,
						"s": "2024-02-28T17:15:00",
						"e": "2024-02-28T17:30:00",
						"v": "conn"
					},
					{
						"id": 70,
						"s": "2024-02-28T17:30:00",
						"e": "2024-02-28T17:45:00",
						"v": "conn"
					},
					{
						"id": 71,
						"s": "2024-02-28T17:45:00",
						"e": "2024-02-28T18:00:00",
						"v": "conn"
					},
					{
						"id": 72,
						"s": "2024-02-28T18:00:00",
						"e": "2024-02-28T18:15:00",
						"v": "conn"
					},
					{
						"id": 73,
						"s": "2024-02-28T18:15:00",
						"e": "2024-02-28T18:30:00",
						"v": "conn"
					},
					{
						"id": 74,
						"s": "2024-02-28T18:30:00",
						"e": "2024-02-28T18:45:00",
						"v": "conn"
					},
					{
						"id": 75,
						"s": "2024-02-28T18:45:00",
						"e": "2024-02-28T19:00:00",
						"v": "conn"
					},
					{
						"id": 76,
						"s": "2024-02-28T19:00:00",
						"e": "2024-02-28T19:15:00",
						"v": "conn"
					},
					{
						"id": 77,
						"s": "2024-02-28T19:15:00",
						"e": "2024-02-28T19:30:00",
						"v": "conn"
					},
					{
						"id": 78,
						"s": "2024-02-28T19:30:00",
						"e": "2024-02-28T19:45:00",
						"v": "conn"
					},
					{
						"id": 79,
						"s": "2024-02-28T19:45:00",
						"e": "2024-02-28T20:00:00",
						"v": "conn"
					},
					{
						"id": 80,
						"s": "2024-02-28T20:00:00",
						"e": "2024-02-28T20:15:00",
						"v": "conn"
					},
					{
						"id": 81,
						"s": "2024-02-28T20:15:00",
						"e": "2024-02-28T20:30:00",
						"v": "conn"
					},
					{
						"id": 82,
						"s": "2024-02-28T20:30:00",
						"e": "2024-02-28T20:45:00",
						"v": "conn"
					},
					{
						"id": 83,
						"s": "2024-02-28T20:45:00",
						"e": "2024-02-28T21:00:00",
						"v": "conn"
					},
					{
						"id": 84,
						"s": "2024-02-28T21:00:00",
						"e": "2024-02-28T21:15:00",
						"v": "conn"
					},
					{
						"id": 85,
						"s": "2024-02-28T21:15:00",
						"e": "2024-02-28T21:30:00",
						"v": "conn"
					},
					{
						"id": 86,
						"s": "2024-02-28T21:30:00",
						"e": "2024-02-28T21:45:00",
						"v": "conn"
					},
					{
						"id": 87,
						"s": "2024-02-28T21:45:00",
						"e": "2024-02-28T22:00:00",
						"v": "conn"
					},
					{
						"id": 88,
						"s": "2024-02-28T22:00:00",
						"e": "2024-02-28T22:15:00",
						"v": "conn"
					},
					{
						"id": 89,
						"s": "2024-02-28T22:15:00",
						"e": "2024-02-28T22:30:00",
						"v": "conn"
					},
					{
						"id": 90,
						"s": "2024-02-28T22:30:00",
						"e": "2024-02-28T22:45:00",
						"v": "conn"
					},
					{
						"id": 91,
						"s": "2024-02-28T22:45:00",
						"e": "2024-02-28T23:00:00",
						"v": "conn"
					},
					{
						"id": 92,
						"s": "2024-02-28T23:00:00",
						"e": "2024-02-28T23:15:00",
						"v": "conn"
					},
					{
						"id": 93,
						"s": "2024-02-28T23:15:00",
						"e": "2024-02-28T23:30:00",
						"v": "conn"
					},
					{
						"id": 94,
						"s": "2024-02-28T23:30:00",
						"e": "2024-02-28T23:45:00",
						"v": "conn"
					},
					{
						"id": 95,
						"s": "2024-02-28T23:45:00",
						"e": "2024-02-29T00:00:00",
						"v": "conn"
					}
				]
			},
			{
				"id": 12,
				"date": "29 Feb 2024",
				"data": [
					{
						"id": 0,
						"s": "2024-02-29T00:00:00",
						"e": "2024-02-29T00:15:00",
						"v": "conn"
					},
					{
						"id": 1,
						"s": "2024-02-29T00:15:00",
						"e": "2024-02-29T00:30:00",
						"v": "conn"
					},
					{
						"id": 2,
						"s": "2024-02-29T00:30:00",
						"e": "2024-02-29T00:45:00",
						"v": "conn"
					},
					{
						"id": 3,
						"s": "2024-02-29T00:45:00",
						"e": "2024-02-29T01:00:00",
						"v": "conn"
					},
					{
						"id": 4,
						"s": "2024-02-29T01:00:00",
						"e": "2024-02-29T01:15:00",
						"v": "conn"
					},
					{
						"id": 5,
						"s": "2024-02-29T01:15:00",
						"e": "2024-02-29T01:30:00",
						"v": "conn"
					},
					{
						"id": 6,
						"s": "2024-02-29T01:30:00",
						"e": "2024-02-29T01:45:00",
						"v": "conn"
					},
					{
						"id": 7,
						"s": "2024-02-29T01:45:00",
						"e": "2024-02-29T02:00:00",
						"v": "conn"
					},
					{
						"id": 8,
						"s": "2024-02-29T02:00:00",
						"e": "2024-02-29T02:15:00",
						"v": "conn"
					},
					{
						"id": 9,
						"s": "2024-02-29T02:15:00",
						"e": "2024-02-29T02:30:00",
						"v": "conn"
					},
					{
						"id": 10,
						"s": "2024-02-29T02:30:00",
						"e": "2024-02-29T02:45:00",
						"v": "conn"
					},
					{
						"id": 11,
						"s": "2024-02-29T02:45:00",
						"e": "2024-02-29T03:00:00",
						"v": "conn"
					},
					{
						"id": 12,
						"s": "2024-02-29T03:00:00",
						"e": "2024-02-29T03:15:00",
						"v": "conn"
					},
					{
						"id": 13,
						"s": "2024-02-29T03:15:00",
						"e": "2024-02-29T03:30:00",
						"v": "conn"
					},
					{
						"id": 14,
						"s": "2024-02-29T03:30:00",
						"e": "2024-02-29T03:45:00",
						"v": "conn"
					},
					{
						"id": 15,
						"s": "2024-02-29T03:45:00",
						"e": "2024-02-29T04:00:00",
						"v": "conn"
					},
					{
						"id": 16,
						"s": "2024-02-29T04:00:00",
						"e": "2024-02-29T04:15:00",
						"v": "conn"
					},
					{
						"id": 17,
						"s": "2024-02-29T04:15:00",
						"e": "2024-02-29T04:30:00",
						"v": "conn"
					},
					{
						"id": 18,
						"s": "2024-02-29T04:30:00",
						"e": "2024-02-29T04:45:00",
						"v": "conn"
					},
					{
						"id": 19,
						"s": "2024-02-29T04:45:00",
						"e": "2024-02-29T05:00:00",
						"v": "conn"
					},
					{
						"id": 20,
						"s": "2024-02-29T05:00:00",
						"e": "2024-02-29T05:15:00",
						"v": "conn"
					},
					{
						"id": 21,
						"s": "2024-02-29T05:15:00",
						"e": "2024-02-29T05:30:00",
						"v": "conn"
					},
					{
						"id": 22,
						"s": "2024-02-29T05:30:00",
						"e": "2024-02-29T05:45:00",
						"v": "conn"
					},
					{
						"id": 23,
						"s": "2024-02-29T05:45:00",
						"e": "2024-02-29T06:00:00",
						"v": "conn"
					},
					{
						"id": 24,
						"s": "2024-02-29T06:00:00",
						"e": "2024-02-29T06:15:00",
						"v": "conn"
					},
					{
						"id": 25,
						"s": "2024-02-29T06:15:00",
						"e": "2024-02-29T06:30:00",
						"v": "conn"
					},
					{
						"id": 26,
						"s": "2024-02-29T06:30:00",
						"e": "2024-02-29T06:45:00",
						"v": "conn"
					},
					{
						"id": 27,
						"s": "2024-02-29T06:45:00",
						"e": "2024-02-29T07:00:00",
						"v": "conn"
					},
					{
						"id": 28,
						"s": "2024-02-29T07:00:00",
						"e": "2024-02-29T07:15:00",
						"v": "conn"
					},
					{
						"id": 29,
						"s": "2024-02-29T07:15:00",
						"e": "2024-02-29T07:30:00",
						"v": "conn"
					},
					{
						"id": 30,
						"s": "2024-02-29T07:30:00",
						"e": "2024-02-29T07:45:00",
						"v": "conn"
					},
					{
						"id": 31,
						"s": "2024-02-29T07:45:00",
						"e": "2024-02-29T08:00:00",
						"v": "conn"
					},
					{
						"id": 32,
						"s": "2024-02-29T08:00:00",
						"e": "2024-02-29T08:15:00",
						"v": "conn"
					},
					{
						"id": 33,
						"s": "2024-02-29T08:15:00",
						"e": "2024-02-29T08:30:00",
						"v": "conn"
					},
					{
						"id": 34,
						"s": "2024-02-29T08:30:00",
						"e": "2024-02-29T08:45:00",
						"v": "conn"
					},
					{
						"id": 35,
						"s": "2024-02-29T08:45:00",
						"e": "2024-02-29T09:00:00",
						"v": "conn"
					},
					{
						"id": 36,
						"s": "2024-02-29T09:00:00",
						"e": "2024-02-29T09:15:00",
						"v": "conn"
					},
					{
						"id": 37,
						"s": "2024-02-29T09:15:00",
						"e": "2024-02-29T09:30:00",
						"v": "conn"
					},
					{
						"id": 38,
						"s": "2024-02-29T09:30:00",
						"e": "2024-02-29T09:45:00",
						"v": "conn"
					},
					{
						"id": 39,
						"s": "2024-02-29T09:45:00",
						"e": "2024-02-29T10:00:00",
						"v": "conn"
					},
					{
						"id": 40,
						"s": "2024-02-29T10:00:00",
						"e": "2024-02-29T10:15:00",
						"v": "conn"
					},
					{
						"id": 41,
						"s": "2024-02-29T10:15:00",
						"e": "2024-02-29T10:30:00",
						"v": "conn"
					},
					{
						"id": 42,
						"s": "2024-02-29T10:30:00",
						"e": "2024-02-29T10:45:00",
						"v": "conn"
					},
					{
						"id": 43,
						"s": "2024-02-29T10:45:00",
						"e": "2024-02-29T11:00:00",
						"v": "conn"
					},
					{
						"id": 44,
						"s": "2024-02-29T11:00:00",
						"e": "2024-02-29T11:15:00",
						"v": "conn"
					},
					{
						"id": 45,
						"s": "2024-02-29T11:15:00",
						"e": "2024-02-29T11:30:00",
						"v": "conn"
					},
					{
						"id": 46,
						"s": "2024-02-29T11:30:00",
						"e": "2024-02-29T11:45:00",
						"v": "conn"
					},
					{
						"id": 47,
						"s": "2024-02-29T11:45:00",
						"e": "2024-02-29T12:00:00",
						"v": "conn"
					},
					{
						"id": 48,
						"s": "2024-02-29T12:00:00",
						"e": "2024-02-29T12:15:00",
						"v": "conn"
					},
					{
						"id": 49,
						"s": "2024-02-29T12:15:00",
						"e": "2024-02-29T12:30:00",
						"v": "conn"
					},
					{
						"id": 50,
						"s": "2024-02-29T12:30:00",
						"e": "2024-02-29T12:45:00",
						"v": "conn"
					},
					{
						"id": 51,
						"s": "2024-02-29T12:45:00",
						"e": "2024-02-29T13:00:00",
						"v": "conn"
					},
					{
						"id": 52,
						"s": "2024-02-29T13:00:00",
						"e": "2024-02-29T13:15:00",
						"v": "conn"
					},
					{
						"id": 53,
						"s": "2024-02-29T13:15:00",
						"e": "2024-02-29T13:30:00",
						"v": "conn"
					},
					{
						"id": 54,
						"s": "2024-02-29T13:30:00",
						"e": "2024-02-29T13:45:00",
						"v": "conn"
					},
					{
						"id": 55,
						"s": "2024-02-29T13:45:00",
						"e": "2024-02-29T14:00:00",
						"v": "conn"
					},
					{
						"id": 56,
						"s": "2024-02-29T14:00:00",
						"e": "2024-02-29T14:15:00",
						"v": "conn"
					},
					{
						"id": 57,
						"s": "2024-02-29T14:15:00",
						"e": "2024-02-29T14:30:00",
						"v": "conn"
					},
					{
						"id": 58,
						"s": "2024-02-29T14:30:00",
						"e": "2024-02-29T14:45:00",
						"v": "conn"
					},
					{
						"id": 59,
						"s": "2024-02-29T14:45:00",
						"e": "2024-02-29T15:00:00",
						"v": "conn"
					},
					{
						"id": 60,
						"s": "2024-02-29T15:00:00",
						"e": "2024-02-29T15:15:00",
						"v": "conn"
					},
					{
						"id": 61,
						"s": "2024-02-29T15:15:00",
						"e": "2024-02-29T15:30:00",
						"v": "conn"
					},
					{
						"id": 62,
						"s": "2024-02-29T15:30:00",
						"e": "2024-02-29T15:45:00",
						"v": "conn"
					},
					{
						"id": 63,
						"s": "2024-02-29T15:45:00",
						"e": "2024-02-29T16:00:00",
						"v": "conn"
					},
					{
						"id": 64,
						"s": "2024-02-29T16:00:00",
						"e": "2024-02-29T16:15:00",
						"v": "conn"
					},
					{
						"id": 65,
						"s": "2024-02-29T16:15:00",
						"e": "2024-02-29T16:30:00",
						"v": "conn"
					},
					{
						"id": 66,
						"s": "2024-02-29T16:30:00",
						"e": "2024-02-29T16:45:00",
						"v": "conn"
					},
					{
						"id": 67,
						"s": "2024-02-29T16:45:00",
						"e": "2024-02-29T17:00:00",
						"v": "conn"
					},
					{
						"id": 68,
						"s": "2024-02-29T17:00:00",
						"e": "2024-02-29T17:15:00",
						"v": "conn"
					},
					{
						"id": 69,
						"s": "2024-02-29T17:15:00",
						"e": "2024-02-29T17:30:00",
						"v": "conn"
					},
					{
						"id": 70,
						"s": "2024-02-29T17:30:00",
						"e": "2024-02-29T17:45:00",
						"v": "conn"
					},
					{
						"id": 71,
						"s": "2024-02-29T17:45:00",
						"e": "2024-02-29T18:00:00",
						"v": "conn"
					},
					{
						"id": 72,
						"s": "2024-02-29T18:00:00",
						"e": "2024-02-29T18:15:00",
						"v": "conn"
					},
					{
						"id": 73,
						"s": "2024-02-29T18:15:00",
						"e": "2024-02-29T18:30:00",
						"v": "conn"
					},
					{
						"id": 74,
						"s": "2024-02-29T18:30:00",
						"e": "2024-02-29T18:45:00",
						"v": "conn"
					},
					{
						"id": 75,
						"s": "2024-02-29T18:45:00",
						"e": "2024-02-29T19:00:00",
						"v": "conn"
					},
					{
						"id": 76,
						"s": "2024-02-29T19:00:00",
						"e": "2024-02-29T19:15:00",
						"v": "conn"
					},
					{
						"id": 77,
						"s": "2024-02-29T19:15:00",
						"e": "2024-02-29T19:30:00",
						"v": "conn"
					},
					{
						"id": 78,
						"s": "2024-02-29T19:30:00",
						"e": "2024-02-29T19:45:00",
						"v": "conn"
					},
					{
						"id": 79,
						"s": "2024-02-29T19:45:00",
						"e": "2024-02-29T20:00:00",
						"v": "conn"
					},
					{
						"id": 80,
						"s": "2024-02-29T20:00:00",
						"e": "2024-02-29T20:15:00",
						"v": "conn"
					},
					{
						"id": 81,
						"s": "2024-02-29T20:15:00",
						"e": "2024-02-29T20:30:00",
						"v": "conn"
					},
					{
						"id": 82,
						"s": "2024-02-29T20:30:00",
						"e": "2024-02-29T20:45:00",
						"v": "conn"
					},
					{
						"id": 83,
						"s": "2024-02-29T20:45:00",
						"e": "2024-02-29T21:00:00",
						"v": "conn"
					},
					{
						"id": 84,
						"s": "2024-02-29T21:00:00",
						"e": "2024-02-29T21:15:00",
						"v": "conn"
					},
					{
						"id": 85,
						"s": "2024-02-29T21:15:00",
						"e": "2024-02-29T21:30:00",
						"v": "conn"
					},
					{
						"id": 86,
						"s": "2024-02-29T21:30:00",
						"e": "2024-02-29T21:45:00",
						"v": "conn"
					},
					{
						"id": 87,
						"s": "2024-02-29T21:45:00",
						"e": "2024-02-29T22:00:00",
						"v": "conn"
					},
					{
						"id": 88,
						"s": "2024-02-29T22:00:00",
						"e": "2024-02-29T22:15:00",
						"v": "conn"
					},
					{
						"id": 89,
						"s": "2024-02-29T22:15:00",
						"e": "2024-02-29T22:30:00",
						"v": "conn"
					},
					{
						"id": 90,
						"s": "2024-02-29T22:30:00",
						"e": "2024-02-29T22:45:00",
						"v": "conn"
					},
					{
						"id": 91,
						"s": "2024-02-29T22:45:00",
						"e": "2024-02-29T23:00:00",
						"v": "conn"
					},
					{
						"id": 92,
						"s": "2024-02-29T23:00:00",
						"e": "2024-02-29T23:15:00",
						"v": "conn"
					},
					{
						"id": 93,
						"s": "2024-02-29T23:15:00",
						"e": "2024-02-29T23:30:00",
						"v": "conn"
					},
					{
						"id": 94,
						"s": "2024-02-29T23:30:00",
						"e": "2024-02-29T23:45:00",
						"v": "conn"
					},
					{
						"id": 95,
						"s": "2024-02-29T23:45:00",
						"e": "2024-03-01T00:00:00",
						"v": "conn"
					}
				]
			},
			{
				"id": 13,
				"date": "1 Mar 2024",
				"data": [
					{
						"id": 0,
						"s": "2024-03-01T00:00:00",
						"e": "2024-03-01T00:15:00",
						"v": "conn"
					},
					{
						"id": 1,
						"s": "2024-03-01T00:15:00",
						"e": "2024-03-01T00:30:00",
						"v": "conn"
					},
					{
						"id": 2,
						"s": "2024-03-01T00:30:00",
						"e": "2024-03-01T00:45:00",
						"v": "conn"
					},
					{
						"id": 3,
						"s": "2024-03-01T00:45:00",
						"e": "2024-03-01T01:00:00",
						"v": "conn"
					},
					{
						"id": 4,
						"s": "2024-03-01T01:00:00",
						"e": "2024-03-01T01:15:00",
						"v": "conn"
					},
					{
						"id": 5,
						"s": "2024-03-01T01:15:00",
						"e": "2024-03-01T01:30:00",
						"v": "conn"
					},
					{
						"id": 6,
						"s": "2024-03-01T01:30:00",
						"e": "2024-03-01T01:45:00",
						"v": "conn"
					},
					{
						"id": 7,
						"s": "2024-03-01T01:45:00",
						"e": "2024-03-01T02:00:00",
						"v": "conn"
					},
					{
						"id": 8,
						"s": "2024-03-01T02:00:00",
						"e": "2024-03-01T02:15:00",
						"v": "conn"
					},
					{
						"id": 9,
						"s": "2024-03-01T02:15:00",
						"e": "2024-03-01T02:30:00",
						"v": "conn"
					},
					{
						"id": 10,
						"s": "2024-03-01T02:30:00",
						"e": "2024-03-01T02:45:00",
						"v": "conn"
					},
					{
						"id": 11,
						"s": "2024-03-01T02:45:00",
						"e": "2024-03-01T03:00:00",
						"v": "conn"
					},
					{
						"id": 12,
						"s": "2024-03-01T03:00:00",
						"e": "2024-03-01T03:15:00",
						"v": "conn"
					},
					{
						"id": 13,
						"s": "2024-03-01T03:15:00",
						"e": "2024-03-01T03:30:00",
						"v": "conn"
					},
					{
						"id": 14,
						"s": "2024-03-01T03:30:00",
						"e": "2024-03-01T03:45:00",
						"v": "conn"
					},
					{
						"id": 15,
						"s": "2024-03-01T03:45:00",
						"e": "2024-03-01T04:00:00",
						"v": "conn"
					},
					{
						"id": 16,
						"s": "2024-03-01T04:00:00",
						"e": "2024-03-01T04:15:00",
						"v": "conn"
					},
					{
						"id": 17,
						"s": "2024-03-01T04:15:00",
						"e": "2024-03-01T04:30:00",
						"v": "conn"
					},
					{
						"id": 18,
						"s": "2024-03-01T04:30:00",
						"e": "2024-03-01T04:45:00",
						"v": "conn"
					},
					{
						"id": 19,
						"s": "2024-03-01T04:45:00",
						"e": "2024-03-01T05:00:00",
						"v": "conn"
					},
					{
						"id": 20,
						"s": "2024-03-01T05:00:00",
						"e": "2024-03-01T05:15:00",
						"v": "conn"
					},
					{
						"id": 21,
						"s": "2024-03-01T05:15:00",
						"e": "2024-03-01T05:30:00",
						"v": "conn"
					},
					{
						"id": 22,
						"s": "2024-03-01T05:30:00",
						"e": "2024-03-01T05:45:00",
						"v": "conn"
					},
					{
						"id": 23,
						"s": "2024-03-01T05:45:00",
						"e": "2024-03-01T06:00:00",
						"v": "conn"
					},
					{
						"id": 24,
						"s": "2024-03-01T06:00:00",
						"e": "2024-03-01T06:15:00",
						"v": "conn"
					},
					{
						"id": 25,
						"s": "2024-03-01T06:15:00",
						"e": "2024-03-01T06:30:00",
						"v": "conn"
					},
					{
						"id": 26,
						"s": "2024-03-01T06:30:00",
						"e": "2024-03-01T06:45:00",
						"v": "conn"
					},
					{
						"id": 27,
						"s": "2024-03-01T06:45:00",
						"e": "2024-03-01T07:00:00",
						"v": "conn"
					},
					{
						"id": 28,
						"s": "2024-03-01T07:00:00",
						"e": "2024-03-01T07:15:00",
						"v": "conn"
					},
					{
						"id": 29,
						"s": "2024-03-01T07:15:00",
						"e": "2024-03-01T07:30:00",
						"v": "conn"
					},
					{
						"id": 30,
						"s": "2024-03-01T07:30:00",
						"e": "2024-03-01T07:45:00",
						"v": "conn"
					},
					{
						"id": 31,
						"s": "2024-03-01T07:45:00",
						"e": "2024-03-01T08:00:00",
						"v": "conn"
					},
					{
						"id": 32,
						"s": "2024-03-01T08:00:00",
						"e": "2024-03-01T08:15:00",
						"v": "conn"
					},
					{
						"id": 33,
						"s": "2024-03-01T08:15:00",
						"e": "2024-03-01T08:30:00",
						"v": "conn"
					},
					{
						"id": 34,
						"s": "2024-03-01T08:30:00",
						"e": "2024-03-01T08:45:00",
						"v": "conn"
					},
					{
						"id": 35,
						"s": "2024-03-01T08:45:00",
						"e": "2024-03-01T09:00:00",
						"v": "conn"
					},
					{
						"id": 36,
						"s": "2024-03-01T09:00:00",
						"e": "2024-03-01T09:15:00",
						"v": "conn"
					},
					{
						"id": 37,
						"s": "2024-03-01T09:15:00",
						"e": "2024-03-01T09:30:00",
						"v": "conn"
					},
					{
						"id": 38,
						"s": "2024-03-01T09:30:00",
						"e": "2024-03-01T09:45:00",
						"v": "conn"
					},
					{
						"id": 39,
						"s": "2024-03-01T09:45:00",
						"e": "2024-03-01T10:00:00",
						"v": "conn"
					},
					{
						"id": 40,
						"s": "2024-03-01T10:00:00",
						"e": "2024-03-01T10:15:00",
						"v": "conn"
					},
					{
						"id": 41,
						"s": "2024-03-01T10:15:00",
						"e": "2024-03-01T10:30:00",
						"v": "conn"
					},
					{
						"id": 42,
						"s": "2024-03-01T10:30:00",
						"e": "2024-03-01T10:45:00",
						"v": "conn"
					},
					{
						"id": 43,
						"s": "2024-03-01T10:45:00",
						"e": "2024-03-01T11:00:00",
						"v": "conn"
					},
					{
						"id": 44,
						"s": "2024-03-01T11:00:00",
						"e": "2024-03-01T11:15:00",
						"v": "conn"
					},
					{
						"id": 45,
						"s": "2024-03-01T11:15:00",
						"e": "2024-03-01T11:30:00",
						"v": "conn"
					},
					{
						"id": 46,
						"s": "2024-03-01T11:30:00",
						"e": "2024-03-01T11:45:00",
						"v": "conn"
					},
					{
						"id": 47,
						"s": "2024-03-01T11:45:00",
						"e": "2024-03-01T12:00:00",
						"v": "conn"
					},
					{
						"id": 48,
						"s": "2024-03-01T12:00:00",
						"e": "2024-03-01T12:15:00",
						"v": "conn"
					},
					{
						"id": 49,
						"s": "2024-03-01T12:15:00",
						"e": "2024-03-01T12:30:00",
						"v": "conn"
					},
					{
						"id": 50,
						"s": "2024-03-01T12:30:00",
						"e": "2024-03-01T12:45:00",
						"v": "conn"
					},
					{
						"id": 51,
						"s": "2024-03-01T12:45:00",
						"e": "2024-03-01T13:00:00",
						"v": "conn"
					},
					{
						"id": 52,
						"s": "2024-03-01T13:00:00",
						"e": "2024-03-01T13:15:00",
						"v": "conn"
					},
					{
						"id": 53,
						"s": "2024-03-01T13:15:00",
						"e": "2024-03-01T13:30:00",
						"v": "conn"
					},
					{
						"id": 54,
						"s": "2024-03-01T13:30:00",
						"e": "2024-03-01T13:45:00",
						"v": "conn"
					},
					{
						"id": 55,
						"s": "2024-03-01T13:45:00",
						"e": "2024-03-01T14:00:00",
						"v": "conn"
					},
					{
						"id": 56,
						"s": "2024-03-01T14:00:00",
						"e": "2024-03-01T14:15:00",
						"v": "conn"
					},
					{
						"id": 57,
						"s": "2024-03-01T14:15:00",
						"e": "2024-03-01T14:30:00",
						"v": "conn"
					},
					{
						"id": 58,
						"s": "2024-03-01T14:30:00",
						"e": "2024-03-01T14:45:00",
						"v": "conn"
					},
					{
						"id": 59,
						"s": "2024-03-01T14:45:00",
						"e": "2024-03-01T15:00:00",
						"v": "conn"
					},
					{
						"id": 60,
						"s": "2024-03-01T15:00:00",
						"e": "2024-03-01T15:15:00",
						"v": "conn"
					},
					{
						"id": 61,
						"s": "2024-03-01T15:15:00",
						"e": "2024-03-01T15:30:00",
						"v": "conn"
					},
					{
						"id": 62,
						"s": "2024-03-01T15:30:00",
						"e": "2024-03-01T15:45:00",
						"v": "conn"
					},
					{
						"id": 63,
						"s": "2024-03-01T15:45:00",
						"e": "2024-03-01T16:00:00",
						"v": "conn"
					},
					{
						"id": 64,
						"s": "2024-03-01T16:00:00",
						"e": "2024-03-01T16:15:00",
						"v": "conn"
					},
					{
						"id": 65,
						"s": "2024-03-01T16:15:00",
						"e": "2024-03-01T16:30:00",
						"v": "conn"
					},
					{
						"id": 66,
						"s": "2024-03-01T16:30:00",
						"e": "2024-03-01T16:45:00",
						"v": "conn"
					},
					{
						"id": 67,
						"s": "2024-03-01T16:45:00",
						"e": "2024-03-01T17:00:00",
						"v": "conn"
					},
					{
						"id": 68,
						"s": "2024-03-01T17:00:00",
						"e": "2024-03-01T17:15:00",
						"v": "conn"
					},
					{
						"id": 69,
						"s": "2024-03-01T17:15:00",
						"e": "2024-03-01T17:30:00",
						"v": "conn"
					},
					{
						"id": 70,
						"s": "2024-03-01T17:30:00",
						"e": "2024-03-01T17:45:00",
						"v": "conn"
					},
					{
						"id": 71,
						"s": "2024-03-01T17:45:00",
						"e": "2024-03-01T18:00:00",
						"v": "conn"
					},
					{
						"id": 72,
						"s": "2024-03-01T18:00:00",
						"e": "2024-03-01T18:15:00",
						"v": "conn"
					},
					{
						"id": 73,
						"s": "2024-03-01T18:15:00",
						"e": "2024-03-01T18:30:00",
						"v": "conn"
					},
					{
						"id": 74,
						"s": "2024-03-01T18:30:00",
						"e": "2024-03-01T18:45:00",
						"v": "conn"
					},
					{
						"id": 75,
						"s": "2024-03-01T18:45:00",
						"e": "2024-03-01T19:00:00",
						"v": "conn"
					},
					{
						"id": 76,
						"s": "2024-03-01T19:00:00",
						"e": "2024-03-01T19:15:00",
						"v": "conn"
					},
					{
						"id": 77,
						"s": "2024-03-01T19:15:00",
						"e": "2024-03-01T19:30:00",
						"v": "conn"
					},
					{
						"id": 78,
						"s": "2024-03-01T19:30:00",
						"e": "2024-03-01T19:45:00",
						"v": "conn"
					},
					{
						"id": 79,
						"s": "2024-03-01T19:45:00",
						"e": "2024-03-01T20:00:00",
						"v": "conn"
					},
					{
						"id": 80,
						"s": "2024-03-01T20:00:00",
						"e": "2024-03-01T20:15:00",
						"v": "conn"
					},
					{
						"id": 81,
						"s": "2024-03-01T20:15:00",
						"e": "2024-03-01T20:30:00",
						"v": "conn"
					},
					{
						"id": 82,
						"s": "2024-03-01T20:30:00",
						"e": "2024-03-01T20:45:00",
						"v": "conn"
					},
					{
						"id": 83,
						"s": "2024-03-01T20:45:00",
						"e": "2024-03-01T21:00:00",
						"v": "conn"
					},
					{
						"id": 84,
						"s": "2024-03-01T21:00:00",
						"e": "2024-03-01T21:15:00",
						"v": "conn"
					},
					{
						"id": 85,
						"s": "2024-03-01T21:15:00",
						"e": "2024-03-01T21:30:00",
						"v": "conn"
					},
					{
						"id": 86,
						"s": "2024-03-01T21:30:00",
						"e": "2024-03-01T21:45:00",
						"v": "conn"
					},
					{
						"id": 87,
						"s": "2024-03-01T21:45:00",
						"e": "2024-03-01T22:00:00",
						"v": "conn"
					},
					{
						"id": 88,
						"s": "2024-03-01T22:00:00",
						"e": "2024-03-01T22:15:00",
						"v": "conn"
					},
					{
						"id": 89,
						"s": "2024-03-01T22:15:00",
						"e": "2024-03-01T22:30:00",
						"v": "conn"
					},
					{
						"id": 90,
						"s": "2024-03-01T22:30:00",
						"e": "2024-03-01T22:45:00",
						"v": "conn"
					},
					{
						"id": 91,
						"s": "2024-03-01T22:45:00",
						"e": "2024-03-01T23:00:00",
						"v": "conn"
					},
					{
						"id": 92,
						"s": "2024-03-01T23:00:00",
						"e": "2024-03-01T23:15:00",
						"v": "conn"
					},
					{
						"id": 93,
						"s": "2024-03-01T23:15:00",
						"e": "2024-03-01T23:30:00",
						"v": "conn"
					},
					{
						"id": 94,
						"s": "2024-03-01T23:30:00",
						"e": "2024-03-01T23:45:00",
						"v": "conn"
					},
					{
						"id": 95,
						"s": "2024-03-01T23:45:00",
						"e": "2024-03-02T00:00:00",
						"v": "conn"
					}
				]
			},
			{
				"id": 14,
				"date": "2 Mar 2024",
				"data": [
					{
						"id": 0,
						"s": "2024-03-02T00:00:00",
						"e": "2024-03-02T00:15:00",
						"v": "conn"
					},
					{
						"id": 1,
						"s": "2024-03-02T00:15:00",
						"e": "2024-03-02T00:30:00",
						"v": "conn"
					},
					{
						"id": 2,
						"s": "2024-03-02T00:30:00",
						"e": "2024-03-02T00:45:00",
						"v": "conn"
					},
					{
						"id": 3,
						"s": "2024-03-02T00:45:00",
						"e": "2024-03-02T01:00:00",
						"v": "conn"
					},
					{
						"id": 4,
						"s": "2024-03-02T01:00:00",
						"e": "2024-03-02T01:15:00",
						"v": "conn"
					},
					{
						"id": 5,
						"s": "2024-03-02T01:15:00",
						"e": "2024-03-02T01:30:00",
						"v": "conn"
					},
					{
						"id": 6,
						"s": "2024-03-02T01:30:00",
						"e": "2024-03-02T01:45:00",
						"v": "conn"
					},
					{
						"id": 7,
						"s": "2024-03-02T01:45:00",
						"e": "2024-03-02T02:00:00",
						"v": "conn"
					},
					{
						"id": 8,
						"s": "2024-03-02T02:00:00",
						"e": "2024-03-02T02:15:00",
						"v": "conn"
					},
					{
						"id": 9,
						"s": "2024-03-02T02:15:00",
						"e": "2024-03-02T02:30:00",
						"v": "conn"
					},
					{
						"id": 10,
						"s": "2024-03-02T02:30:00",
						"e": "2024-03-02T02:45:00",
						"v": "conn"
					},
					{
						"id": 11,
						"s": "2024-03-02T02:45:00",
						"e": "2024-03-02T03:00:00",
						"v": "conn"
					},
					{
						"id": 12,
						"s": "2024-03-02T03:00:00",
						"e": "2024-03-02T03:15:00",
						"v": "conn"
					},
					{
						"id": 13,
						"s": "2024-03-02T03:15:00",
						"e": "2024-03-02T03:30:00",
						"v": "conn"
					},
					{
						"id": 14,
						"s": "2024-03-02T03:30:00",
						"e": "2024-03-02T03:45:00",
						"v": "conn"
					},
					{
						"id": 15,
						"s": "2024-03-02T03:45:00",
						"e": "2024-03-02T04:00:00",
						"v": "conn"
					},
					{
						"id": 16,
						"s": "2024-03-02T04:00:00",
						"e": "2024-03-02T04:15:00",
						"v": "conn"
					},
					{
						"id": 17,
						"s": "2024-03-02T04:15:00",
						"e": "2024-03-02T04:30:00",
						"v": "conn"
					},
					{
						"id": 18,
						"s": "2024-03-02T04:30:00",
						"e": "2024-03-02T04:45:00",
						"v": "conn"
					},
					{
						"id": 19,
						"s": "2024-03-02T04:45:00",
						"e": "2024-03-02T05:00:00",
						"v": "conn"
					},
					{
						"id": 20,
						"s": "2024-03-02T05:00:00",
						"e": "2024-03-02T05:15:00",
						"v": "conn"
					},
					{
						"id": 21,
						"s": "2024-03-02T05:15:00",
						"e": "2024-03-02T05:30:00",
						"v": "conn"
					},
					{
						"id": 22,
						"s": "2024-03-02T05:30:00",
						"e": "2024-03-02T05:45:00",
						"v": "conn"
					},
					{
						"id": 23,
						"s": "2024-03-02T05:45:00",
						"e": "2024-03-02T06:00:00",
						"v": "conn"
					},
					{
						"id": 24,
						"s": "2024-03-02T06:00:00",
						"e": "2024-03-02T06:15:00",
						"v": "conn"
					},
					{
						"id": 25,
						"s": "2024-03-02T06:15:00",
						"e": "2024-03-02T06:30:00",
						"v": "conn"
					},
					{
						"id": 26,
						"s": "2024-03-02T06:30:00",
						"e": "2024-03-02T06:45:00",
						"v": "conn"
					},
					{
						"id": 27,
						"s": "2024-03-02T06:45:00",
						"e": "2024-03-02T07:00:00",
						"v": "conn"
					},
					{
						"id": 28,
						"s": "2024-03-02T07:00:00",
						"e": "2024-03-02T07:15:00",
						"v": "conn"
					},
					{
						"id": 29,
						"s": "2024-03-02T07:15:00",
						"e": "2024-03-02T07:30:00",
						"v": "conn"
					},
					{
						"id": 30,
						"s": "2024-03-02T07:30:00",
						"e": "2024-03-02T07:45:00",
						"v": "conn"
					},
					{
						"id": 31,
						"s": "2024-03-02T07:45:00",
						"e": "2024-03-02T08:00:00",
						"v": "conn"
					},
					{
						"id": 32,
						"s": "2024-03-02T08:00:00",
						"e": "2024-03-02T08:15:00",
						"v": "conn"
					},
					{
						"id": 33,
						"s": "2024-03-02T08:15:00",
						"e": "2024-03-02T08:30:00",
						"v": "conn"
					},
					{
						"id": 34,
						"s": "2024-03-02T08:30:00",
						"e": "2024-03-02T08:45:00",
						"v": "conn"
					},
					{
						"id": 35,
						"s": "2024-03-02T08:45:00",
						"e": "2024-03-02T09:00:00",
						"v": "conn"
					},
					{
						"id": 36,
						"s": "2024-03-02T09:00:00",
						"e": "2024-03-02T09:15:00",
						"v": "conn"
					},
					{
						"id": 37,
						"s": "2024-03-02T09:15:00",
						"e": "2024-03-02T09:30:00",
						"v": "conn"
					},
					{
						"id": 38,
						"s": "2024-03-02T09:30:00",
						"e": "2024-03-02T09:45:00",
						"v": "conn"
					},
					{
						"id": 39,
						"s": "2024-03-02T09:45:00",
						"e": "2024-03-02T10:00:00",
						"v": "conn"
					},
					{
						"id": 40,
						"s": "2024-03-02T10:00:00",
						"e": "2024-03-02T10:15:00",
						"v": "conn"
					},
					{
						"id": 41,
						"s": "2024-03-02T10:15:00",
						"e": "2024-03-02T10:30:00",
						"v": "conn"
					},
					{
						"id": 42,
						"s": "2024-03-02T10:30:00",
						"e": "2024-03-02T10:45:00",
						"v": "conn"
					},
					{
						"id": 43,
						"s": "2024-03-02T10:45:00",
						"e": "2024-03-02T11:00:00",
						"v": "conn"
					},
					{
						"id": 44,
						"s": "2024-03-02T11:00:00",
						"e": "2024-03-02T11:15:00",
						"v": "conn"
					},
					{
						"id": 45,
						"s": "2024-03-02T11:15:00",
						"e": "2024-03-02T11:30:00",
						"v": "conn"
					},
					{
						"id": 46,
						"s": "2024-03-02T11:30:00",
						"e": "2024-03-02T11:45:00",
						"v": "conn"
					},
					{
						"id": 47,
						"s": "2024-03-02T11:45:00",
						"e": "2024-03-02T12:00:00",
						"v": "conn"
					},
					{
						"id": 48,
						"s": "2024-03-02T12:00:00",
						"e": "2024-03-02T12:15:00",
						"v": "conn"
					},
					{
						"id": 49,
						"s": "2024-03-02T12:15:00",
						"e": "2024-03-02T12:30:00",
						"v": "conn"
					},
					{
						"id": 50,
						"s": "2024-03-02T12:30:00",
						"e": "2024-03-02T12:45:00",
						"v": "conn"
					},
					{
						"id": 51,
						"s": "2024-03-02T12:45:00",
						"e": "2024-03-02T13:00:00",
						"v": "conn"
					},
					{
						"id": 52,
						"s": "2024-03-02T13:00:00",
						"e": "2024-03-02T13:15:00",
						"v": "conn"
					},
					{
						"id": 53,
						"s": "2024-03-02T13:15:00",
						"e": "2024-03-02T13:30:00",
						"v": "conn"
					},
					{
						"id": 54,
						"s": "2024-03-02T13:30:00",
						"e": "2024-03-02T13:45:00",
						"v": "conn"
					},
					{
						"id": 55,
						"s": "2024-03-02T13:45:00",
						"e": "2024-03-02T14:00:00",
						"v": "conn"
					},
					{
						"id": 56,
						"s": "2024-03-02T14:00:00",
						"e": "2024-03-02T14:15:00",
						"v": "conn"
					},
					{
						"id": 57,
						"s": "2024-03-02T14:15:00",
						"e": "2024-03-02T14:30:00",
						"v": "conn"
					},
					{
						"id": 58,
						"s": "2024-03-02T14:30:00",
						"e": "2024-03-02T14:45:00",
						"v": "conn"
					},
					{
						"id": 59,
						"s": "2024-03-02T14:45:00",
						"e": "2024-03-02T15:00:00",
						"v": "conn"
					},
					{
						"id": 60,
						"s": "2024-03-02T15:00:00",
						"e": "2024-03-02T15:15:00",
						"v": "conn"
					},
					{
						"id": 61,
						"s": "2024-03-02T15:15:00",
						"e": "2024-03-02T15:30:00",
						"v": "conn"
					},
					{
						"id": 62,
						"s": "2024-03-02T15:30:00",
						"e": "2024-03-02T15:45:00",
						"v": "conn"
					},
					{
						"id": 63,
						"s": "2024-03-02T15:45:00",
						"e": "2024-03-02T16:00:00",
						"v": "conn"
					},
					{
						"id": 64,
						"s": "2024-03-02T16:00:00",
						"e": "2024-03-02T16:15:00",
						"v": "conn"
					},
					{
						"id": 65,
						"s": "2024-03-02T16:15:00",
						"e": "2024-03-02T16:30:00",
						"v": "conn"
					},
					{
						"id": 66,
						"s": "2024-03-02T16:30:00",
						"e": "2024-03-02T16:45:00",
						"v": "conn"
					},
					{
						"id": 67,
						"s": "2024-03-02T16:45:00",
						"e": "2024-03-02T17:00:00",
						"v": "conn"
					},
					{
						"id": 68,
						"s": "2024-03-02T17:00:00",
						"e": "2024-03-02T17:15:00",
						"v": "conn"
					},
					{
						"id": 69,
						"s": "2024-03-02T17:15:00",
						"e": "2024-03-02T17:30:00",
						"v": "conn"
					},
					{
						"id": 70,
						"s": "2024-03-02T17:30:00",
						"e": "2024-03-02T17:45:00",
						"v": "conn"
					},
					{
						"id": 71,
						"s": "2024-03-02T17:45:00",
						"e": "2024-03-02T18:00:00",
						"v": "conn"
					},
					{
						"id": 72,
						"s": "2024-03-02T18:00:00",
						"e": "2024-03-02T18:15:00",
						"v": "conn"
					},
					{
						"id": 73,
						"s": "2024-03-02T18:15:00",
						"e": "2024-03-02T18:30:00",
						"v": "conn"
					},
					{
						"id": 74,
						"s": "2024-03-02T18:30:00",
						"e": "2024-03-02T18:45:00",
						"v": "conn"
					},
					{
						"id": 75,
						"s": "2024-03-02T18:45:00",
						"e": "2024-03-02T19:00:00",
						"v": "conn"
					},
					{
						"id": 76,
						"s": "2024-03-02T19:00:00",
						"e": "2024-03-02T19:15:00",
						"v": "conn"
					},
					{
						"id": 77,
						"s": "2024-03-02T19:15:00",
						"e": "2024-03-02T19:30:00",
						"v": "conn"
					},
					{
						"id": 78,
						"s": "2024-03-02T19:30:00",
						"e": "2024-03-02T19:45:00",
						"v": "conn"
					},
					{
						"id": 79,
						"s": "2024-03-02T19:45:00",
						"e": "2024-03-02T20:00:00",
						"v": "conn"
					},
					{
						"id": 80,
						"s": "2024-03-02T20:00:00",
						"e": "2024-03-02T20:15:00",
						"v": "conn"
					},
					{
						"id": 81,
						"s": "2024-03-02T20:15:00",
						"e": "2024-03-02T20:30:00",
						"v": "conn"
					},
					{
						"id": 82,
						"s": "2024-03-02T20:30:00",
						"e": "2024-03-02T20:45:00",
						"v": "conn"
					},
					{
						"id": 83,
						"s": "2024-03-02T20:45:00",
						"e": "2024-03-02T21:00:00",
						"v": "conn"
					},
					{
						"id": 84,
						"s": "2024-03-02T21:00:00",
						"e": "2024-03-02T21:15:00",
						"v": "conn"
					},
					{
						"id": 85,
						"s": "2024-03-02T21:15:00",
						"e": "2024-03-02T21:30:00",
						"v": "conn"
					},
					{
						"id": 86,
						"s": "2024-03-02T21:30:00",
						"e": "2024-03-02T21:45:00",
						"v": "conn"
					},
					{
						"id": 87,
						"s": "2024-03-02T21:45:00",
						"e": "2024-03-02T22:00:00",
						"v": "conn"
					},
					{
						"id": 88,
						"s": "2024-03-02T22:00:00",
						"e": "2024-03-02T22:15:00",
						"v": "conn"
					},
					{
						"id": 89,
						"s": "2024-03-02T22:15:00",
						"e": "2024-03-02T22:30:00",
						"v": "conn"
					},
					{
						"id": 90,
						"s": "2024-03-02T22:30:00",
						"e": "2024-03-02T22:45:00",
						"v": "conn"
					},
					{
						"id": 91,
						"s": "2024-03-02T22:45:00",
						"e": "2024-03-02T23:00:00",
						"v": "conn"
					},
					{
						"id": 92,
						"s": "2024-03-02T23:00:00",
						"e": "2024-03-02T23:15:00",
						"v": "conn"
					},
					{
						"id": 93,
						"s": "2024-03-02T23:15:00",
						"e": "2024-03-02T23:30:00",
						"v": "conn"
					},
					{
						"id": 94,
						"s": "2024-03-02T23:30:00",
						"e": "2024-03-02T23:45:00",
						"v": "conn"
					},
					{
						"id": 95,
						"s": "2024-03-02T23:45:00",
						"e": "2024-03-03T00:00:00",
						"v": "conn"
					}
				]
			},
			{
				"id": 15,
				"date": "3 Mar 2024",
				"data": [
					{
						"id": 0,
						"s": "2024-03-03T00:00:00",
						"e": "2024-03-03T00:15:00",
						"v": "conn"
					},
					{
						"id": 1,
						"s": "2024-03-03T00:15:00",
						"e": "2024-03-03T00:30:00",
						"v": "conn"
					},
					{
						"id": 2,
						"s": "2024-03-03T00:30:00",
						"e": "2024-03-03T00:45:00",
						"v": "conn"
					},
					{
						"id": 3,
						"s": "2024-03-03T00:45:00",
						"e": "2024-03-03T01:00:00",
						"v": "conn"
					},
					{
						"id": 4,
						"s": "2024-03-03T01:00:00",
						"e": "2024-03-03T01:15:00",
						"v": "conn"
					},
					{
						"id": 5,
						"s": "2024-03-03T01:15:00",
						"e": "2024-03-03T01:30:00",
						"v": "conn"
					},
					{
						"id": 6,
						"s": "2024-03-03T01:30:00",
						"e": "2024-03-03T01:45:00",
						"v": "conn"
					},
					{
						"id": 7,
						"s": "2024-03-03T01:45:00",
						"e": "2024-03-03T02:00:00",
						"v": "conn"
					},
					{
						"id": 8,
						"s": "2024-03-03T02:00:00",
						"e": "2024-03-03T02:15:00",
						"v": "conn"
					},
					{
						"id": 9,
						"s": "2024-03-03T02:15:00",
						"e": "2024-03-03T02:30:00",
						"v": "conn"
					},
					{
						"id": 10,
						"s": "2024-03-03T02:30:00",
						"e": "2024-03-03T02:45:00",
						"v": "conn"
					},
					{
						"id": 11,
						"s": "2024-03-03T02:45:00",
						"e": "2024-03-03T03:00:00",
						"v": "conn"
					},
					{
						"id": 12,
						"s": "2024-03-03T03:00:00",
						"e": "2024-03-03T03:15:00",
						"v": "conn"
					},
					{
						"id": 13,
						"s": "2024-03-03T03:15:00",
						"e": "2024-03-03T03:30:00",
						"v": "conn"
					},
					{
						"id": 14,
						"s": "2024-03-03T03:30:00",
						"e": "2024-03-03T03:45:00",
						"v": "conn"
					},
					{
						"id": 15,
						"s": "2024-03-03T03:45:00",
						"e": "2024-03-03T04:00:00",
						"v": "conn"
					},
					{
						"id": 16,
						"s": "2024-03-03T04:00:00",
						"e": "2024-03-03T04:15:00",
						"v": "conn"
					},
					{
						"id": 17,
						"s": "2024-03-03T04:15:00",
						"e": "2024-03-03T04:30:00",
						"v": "conn"
					},
					{
						"id": 18,
						"s": "2024-03-03T04:30:00",
						"e": "2024-03-03T04:45:00",
						"v": "conn"
					},
					{
						"id": 19,
						"s": "2024-03-03T04:45:00",
						"e": "2024-03-03T05:00:00",
						"v": "conn"
					},
					{
						"id": 20,
						"s": "2024-03-03T05:00:00",
						"e": "2024-03-03T05:15:00",
						"v": "conn"
					},
					{
						"id": 21,
						"s": "2024-03-03T05:15:00",
						"e": "2024-03-03T05:30:00",
						"v": "conn"
					},
					{
						"id": 22,
						"s": "2024-03-03T05:30:00",
						"e": "2024-03-03T05:45:00",
						"v": "conn"
					},
					{
						"id": 23,
						"s": "2024-03-03T05:45:00",
						"e": "2024-03-03T06:00:00",
						"v": "conn"
					},
					{
						"id": 24,
						"s": "2024-03-03T06:00:00",
						"e": "2024-03-03T06:15:00",
						"v": "conn"
					},
					{
						"id": 25,
						"s": "2024-03-03T06:15:00",
						"e": "2024-03-03T06:30:00",
						"v": "conn"
					},
					{
						"id": 26,
						"s": "2024-03-03T06:30:00",
						"e": "2024-03-03T06:45:00",
						"v": "conn"
					},
					{
						"id": 27,
						"s": "2024-03-03T06:45:00",
						"e": "2024-03-03T07:00:00",
						"v": "conn"
					},
					{
						"id": 28,
						"s": "2024-03-03T07:00:00",
						"e": "2024-03-03T07:15:00",
						"v": "conn"
					},
					{
						"id": 29,
						"s": "2024-03-03T07:15:00",
						"e": "2024-03-03T07:30:00",
						"v": "conn"
					},
					{
						"id": 30,
						"s": "2024-03-03T07:30:00",
						"e": "2024-03-03T07:45:00",
						"v": "conn"
					},
					{
						"id": 31,
						"s": "2024-03-03T07:45:00",
						"e": "2024-03-03T08:00:00",
						"v": "conn"
					},
					{
						"id": 32,
						"s": "2024-03-03T08:00:00",
						"e": "2024-03-03T08:15:00",
						"v": "conn"
					},
					{
						"id": 33,
						"s": "2024-03-03T08:15:00",
						"e": "2024-03-03T08:30:00",
						"v": "conn"
					},
					{
						"id": 34,
						"s": "2024-03-03T08:30:00",
						"e": "2024-03-03T08:45:00",
						"v": "conn"
					},
					{
						"id": 35,
						"s": "2024-03-03T08:45:00",
						"e": "2024-03-03T09:00:00",
						"v": "conn"
					},
					{
						"id": 36,
						"s": "2024-03-03T09:00:00",
						"e": "2024-03-03T09:15:00",
						"v": "conn"
					},
					{
						"id": 37,
						"s": "2024-03-03T09:15:00",
						"e": "2024-03-03T09:30:00",
						"v": "conn"
					},
					{
						"id": 38,
						"s": "2024-03-03T09:30:00",
						"e": "2024-03-03T09:45:00",
						"v": "conn"
					},
					{
						"id": 39,
						"s": "2024-03-03T09:45:00",
						"e": "2024-03-03T10:00:00",
						"v": "conn"
					},
					{
						"id": 40,
						"s": "2024-03-03T10:00:00",
						"e": "2024-03-03T10:15:00",
						"v": "conn"
					},
					{
						"id": 41,
						"s": "2024-03-03T10:15:00",
						"e": "2024-03-03T10:30:00",
						"v": "conn"
					},
					{
						"id": 42,
						"s": "2024-03-03T10:30:00",
						"e": "2024-03-03T10:45:00",
						"v": "conn"
					},
					{
						"id": 43,
						"s": "2024-03-03T10:45:00",
						"e": "2024-03-03T11:00:00",
						"v": "conn"
					},
					{
						"id": 44,
						"s": "2024-03-03T11:00:00",
						"e": "2024-03-03T11:15:00",
						"v": "conn"
					},
					{
						"id": 45,
						"s": "2024-03-03T11:15:00",
						"e": "2024-03-03T11:30:00",
						"v": "conn"
					},
					{
						"id": 46,
						"s": "2024-03-03T11:30:00",
						"e": "2024-03-03T11:45:00",
						"v": "conn"
					},
					{
						"id": 47,
						"s": "2024-03-03T11:45:00",
						"e": "2024-03-03T12:00:00",
						"v": "conn"
					},
					{
						"id": 48,
						"s": "2024-03-03T12:00:00",
						"e": "2024-03-03T12:15:00",
						"v": "conn"
					},
					{
						"id": 49,
						"s": "2024-03-03T12:15:00",
						"e": "2024-03-03T12:30:00",
						"v": "conn"
					},
					{
						"id": 50,
						"s": "2024-03-03T12:30:00",
						"e": "2024-03-03T12:45:00",
						"v": "conn"
					},
					{
						"id": 51,
						"s": "2024-03-03T12:45:00",
						"e": "2024-03-03T13:00:00",
						"v": "conn"
					},
					{
						"id": 52,
						"s": "2024-03-03T13:00:00",
						"e": "2024-03-03T13:15:00",
						"v": "conn"
					},
					{
						"id": 53,
						"s": "2024-03-03T13:15:00",
						"e": "2024-03-03T13:30:00",
						"v": "conn"
					},
					{
						"id": 54,
						"s": "2024-03-03T13:30:00",
						"e": "2024-03-03T13:45:00",
						"v": "conn"
					},
					{
						"id": 55,
						"s": "2024-03-03T13:45:00",
						"e": "2024-03-03T14:00:00",
						"v": "conn"
					},
					{
						"id": 56,
						"s": "2024-03-03T14:00:00",
						"e": "2024-03-03T14:15:00",
						"v": "conn"
					},
					{
						"id": 57,
						"s": "2024-03-03T14:15:00",
						"e": "2024-03-03T14:30:00",
						"v": "conn"
					},
					{
						"id": 58,
						"s": "2024-03-03T14:30:00",
						"e": "2024-03-03T14:45:00",
						"v": "conn"
					},
					{
						"id": 59,
						"s": "2024-03-03T14:45:00",
						"e": "2024-03-03T15:00:00",
						"v": "conn"
					},
					{
						"id": 60,
						"s": "2024-03-03T15:00:00",
						"e": "2024-03-03T15:15:00",
						"v": "conn"
					},
					{
						"id": 61,
						"s": "2024-03-03T15:15:00",
						"e": "2024-03-03T15:30:00",
						"v": "conn"
					},
					{
						"id": 62,
						"s": "2024-03-03T15:30:00",
						"e": "2024-03-03T15:45:00",
						"v": "conn"
					},
					{
						"id": 63,
						"s": "2024-03-03T15:45:00",
						"e": "2024-03-03T16:00:00",
						"v": "conn"
					},
					{
						"id": 64,
						"s": "2024-03-03T16:00:00",
						"e": "2024-03-03T16:15:00",
						"v": "conn"
					},
					{
						"id": 65,
						"s": "2024-03-03T16:15:00",
						"e": "2024-03-03T16:30:00",
						"v": "conn"
					},
					{
						"id": 66,
						"s": "2024-03-03T16:30:00",
						"e": "2024-03-03T16:45:00",
						"v": "conn"
					},
					{
						"id": 67,
						"s": "2024-03-03T16:45:00",
						"e": "2024-03-03T17:00:00",
						"v": "conn"
					},
					{
						"id": 68,
						"s": "2024-03-03T17:00:00",
						"e": "2024-03-03T17:15:00",
						"v": "conn"
					},
					{
						"id": 69,
						"s": "2024-03-03T17:15:00",
						"e": "2024-03-03T17:30:00",
						"v": "conn"
					},
					{
						"id": 70,
						"s": "2024-03-03T17:30:00",
						"e": "2024-03-03T17:45:00",
						"v": "conn"
					},
					{
						"id": 71,
						"s": "2024-03-03T17:45:00",
						"e": "2024-03-03T18:00:00",
						"v": "conn"
					},
					{
						"id": 72,
						"s": "2024-03-03T18:00:00",
						"e": "2024-03-03T18:15:00",
						"v": "conn"
					},
					{
						"id": 73,
						"s": "2024-03-03T18:15:00",
						"e": "2024-03-03T18:30:00",
						"v": "conn"
					},
					{
						"id": 74,
						"s": "2024-03-03T18:30:00",
						"e": "2024-03-03T18:45:00",
						"v": "conn"
					},
					{
						"id": 75,
						"s": "2024-03-03T18:45:00",
						"e": "2024-03-03T19:00:00",
						"v": "conn"
					},
					{
						"id": 76,
						"s": "2024-03-03T19:00:00",
						"e": "2024-03-03T19:15:00",
						"v": "conn"
					},
					{
						"id": 77,
						"s": "2024-03-03T19:15:00",
						"e": "2024-03-03T19:30:00",
						"v": "conn"
					},
					{
						"id": 78,
						"s": "2024-03-03T19:30:00",
						"e": "2024-03-03T19:45:00",
						"v": "conn"
					},
					{
						"id": 79,
						"s": "2024-03-03T19:45:00",
						"e": "2024-03-03T20:00:00",
						"v": "conn"
					},
					{
						"id": 80,
						"s": "2024-03-03T20:00:00",
						"e": "2024-03-03T20:15:00",
						"v": "conn"
					},
					{
						"id": 81,
						"s": "2024-03-03T20:15:00",
						"e": "2024-03-03T20:30:00",
						"v": "conn"
					},
					{
						"id": 82,
						"s": "2024-03-03T20:30:00",
						"e": "2024-03-03T20:45:00",
						"v": "conn"
					},
					{
						"id": 83,
						"s": "2024-03-03T20:45:00",
						"e": "2024-03-03T21:00:00",
						"v": "conn"
					},
					{
						"id": 84,
						"s": "2024-03-03T21:00:00",
						"e": "2024-03-03T21:15:00",
						"v": "conn"
					},
					{
						"id": 85,
						"s": "2024-03-03T21:15:00",
						"e": "2024-03-03T21:30:00",
						"v": "conn"
					},
					{
						"id": 86,
						"s": "2024-03-03T21:30:00",
						"e": "2024-03-03T21:45:00",
						"v": "conn"
					},
					{
						"id": 87,
						"s": "2024-03-03T21:45:00",
						"e": "2024-03-03T22:00:00",
						"v": "conn"
					},
					{
						"id": 88,
						"s": "2024-03-03T22:00:00",
						"e": "2024-03-03T22:15:00",
						"v": "conn"
					},
					{
						"id": 89,
						"s": "2024-03-03T22:15:00",
						"e": "2024-03-03T22:30:00",
						"v": "conn"
					},
					{
						"id": 90,
						"s": "2024-03-03T22:30:00",
						"e": "2024-03-03T22:45:00",
						"v": "conn"
					},
					{
						"id": 91,
						"s": "2024-03-03T22:45:00",
						"e": "2024-03-03T23:00:00",
						"v": "conn"
					},
					{
						"id": 92,
						"s": "2024-03-03T23:00:00",
						"e": "2024-03-03T23:15:00",
						"v": "conn"
					},
					{
						"id": 93,
						"s": "2024-03-03T23:15:00",
						"e": "2024-03-03T23:30:00",
						"v": "conn"
					},
					{
						"id": 94,
						"s": "2024-03-03T23:30:00",
						"e": "2024-03-03T23:45:00",
						"v": "conn"
					},
					{
						"id": 95,
						"s": "2024-03-03T23:45:00",
						"e": "2024-03-04T00:00:00",
						"v": "conn"
					}
				]
			},
			{
				"id": 16,
				"date": "4 Mar 2024",
				"data": [
					{
						"id": 0,
						"s": "2024-03-04T00:00:00",
						"e": "2024-03-04T00:15:00",
						"v": "conn"
					},
					{
						"id": 1,
						"s": "2024-03-04T00:15:00",
						"e": "2024-03-04T00:30:00",
						"v": "conn"
					},
					{
						"id": 2,
						"s": "2024-03-04T00:30:00",
						"e": "2024-03-04T00:45:00",
						"v": "conn"
					},
					{
						"id": 3,
						"s": "2024-03-04T00:45:00",
						"e": "2024-03-04T01:00:00",
						"v": "conn"
					},
					{
						"id": 4,
						"s": "2024-03-04T01:00:00",
						"e": "2024-03-04T01:15:00",
						"v": "conn"
					},
					{
						"id": 5,
						"s": "2024-03-04T01:15:00",
						"e": "2024-03-04T01:30:00",
						"v": "reco"
					},
					{
						"id": 6,
						"s": "2024-03-04T01:30:00",
						"e": "2024-03-04T01:45:00",
						"v": "conn"
					},
					{
						"id": 7,
						"s": "2024-03-04T01:45:00",
						"e": "2024-03-04T02:00:00",
						"v": "conn"
					},
					{
						"id": 8,
						"s": "2024-03-04T02:00:00",
						"e": "2024-03-04T02:15:00",
						"v": "conn"
					},
					{
						"id": 9,
						"s": "2024-03-04T02:15:00",
						"e": "2024-03-04T02:30:00",
						"v": "conn"
					},
					{
						"id": 10,
						"s": "2024-03-04T02:30:00",
						"e": "2024-03-04T02:45:00",
						"v": "conn"
					},
					{
						"id": 11,
						"s": "2024-03-04T02:45:00",
						"e": "2024-03-04T03:00:00",
						"v": "conn"
					},
					{
						"id": 12,
						"s": "2024-03-04T03:00:00",
						"e": "2024-03-04T03:15:00",
						"v": "conn"
					},
					{
						"id": 13,
						"s": "2024-03-04T03:15:00",
						"e": "2024-03-04T03:30:00",
						"v": "conn"
					},
					{
						"id": 14,
						"s": "2024-03-04T03:30:00",
						"e": "2024-03-04T03:45:00",
						"v": "conn"
					},
					{
						"id": 15,
						"s": "2024-03-04T03:45:00",
						"e": "2024-03-04T04:00:00",
						"v": "conn"
					},
					{
						"id": 16,
						"s": "2024-03-04T04:00:00",
						"e": "2024-03-04T04:15:00",
						"v": "conn"
					},
					{
						"id": 17,
						"s": "2024-03-04T04:15:00",
						"e": "2024-03-04T04:30:00",
						"v": "conn"
					},
					{
						"id": 18,
						"s": "2024-03-04T04:30:00",
						"e": "2024-03-04T04:45:00",
						"v": "conn"
					},
					{
						"id": 19,
						"s": "2024-03-04T04:45:00",
						"e": "2024-03-04T05:00:00",
						"v": "conn"
					},
					{
						"id": 20,
						"s": "2024-03-04T05:00:00",
						"e": "2024-03-04T05:15:00",
						"v": "conn"
					},
					{
						"id": 21,
						"s": "2024-03-04T05:15:00",
						"e": "2024-03-04T05:30:00",
						"v": "conn"
					},
					{
						"id": 22,
						"s": "2024-03-04T05:30:00",
						"e": "2024-03-04T05:45:00",
						"v": "conn"
					},
					{
						"id": 23,
						"s": "2024-03-04T05:45:00",
						"e": "2024-03-04T06:00:00",
						"v": "conn"
					},
					{
						"id": 24,
						"s": "2024-03-04T06:00:00",
						"e": "2024-03-04T06:15:00",
						"v": "conn"
					},
					{
						"id": 25,
						"s": "2024-03-04T06:15:00",
						"e": "2024-03-04T06:30:00",
						"v": "conn"
					},
					{
						"id": 26,
						"s": "2024-03-04T06:30:00",
						"e": "2024-03-04T06:45:00",
						"v": "conn"
					},
					{
						"id": 27,
						"s": "2024-03-04T06:45:00",
						"e": "2024-03-04T07:00:00",
						"v": "conn"
					},
					{
						"id": 28,
						"s": "2024-03-04T07:00:00",
						"e": "2024-03-04T07:15:00",
						"v": "conn"
					},
					{
						"id": 29,
						"s": "2024-03-04T07:15:00",
						"e": "2024-03-04T07:30:00",
						"v": "conn"
					},
					{
						"id": 30,
						"s": "2024-03-04T07:30:00",
						"e": "2024-03-04T07:45:00",
						"v": "conn"
					},
					{
						"id": 31,
						"s": "2024-03-04T07:45:00",
						"e": "2024-03-04T08:00:00",
						"v": "conn"
					},
					{
						"id": 32,
						"s": "2024-03-04T08:00:00",
						"e": "2024-03-04T08:15:00",
						"v": "conn"
					},
					{
						"id": 33,
						"s": "2024-03-04T08:15:00",
						"e": "2024-03-04T08:30:00",
						"v": "conn"
					},
					{
						"id": 34,
						"s": "2024-03-04T08:30:00",
						"e": "2024-03-04T08:45:00",
						"v": "conn"
					},
					{
						"id": 35,
						"s": "2024-03-04T08:45:00",
						"e": "2024-03-04T09:00:00",
						"v": "conn"
					},
					{
						"id": 36,
						"s": "2024-03-04T09:00:00",
						"e": "2024-03-04T09:15:00",
						"v": "conn"
					},
					{
						"id": 37,
						"s": "2024-03-04T09:15:00",
						"e": "2024-03-04T09:30:00",
						"v": "conn"
					},
					{
						"id": 38,
						"s": "2024-03-04T09:30:00",
						"e": "2024-03-04T09:45:00",
						"v": "conn"
					},
					{
						"id": 39,
						"s": "2024-03-04T09:45:00",
						"e": "2024-03-04T10:00:00",
						"v": "conn"
					},
					{
						"id": 40,
						"s": "2024-03-04T10:00:00",
						"e": "2024-03-04T10:15:00",
						"v": "conn"
					},
					{
						"id": 41,
						"s": "2024-03-04T10:15:00",
						"e": "2024-03-04T10:30:00",
						"v": "conn"
					},
					{
						"id": 42,
						"s": "2024-03-04T10:30:00",
						"e": "2024-03-04T10:45:00",
						"v": "conn"
					},
					{
						"id": 43,
						"s": "2024-03-04T10:45:00",
						"e": "2024-03-04T11:00:00",
						"v": "conn"
					},
					{
						"id": 44,
						"s": "2024-03-04T11:00:00",
						"e": "2024-03-04T11:15:00",
						"v": "conn"
					},
					{
						"id": 45,
						"s": "2024-03-04T11:15:00",
						"e": "2024-03-04T11:30:00",
						"v": "conn"
					},
					{
						"id": 46,
						"s": "2024-03-04T11:30:00",
						"e": "2024-03-04T11:45:00",
						"v": "conn"
					},
					{
						"id": 47,
						"s": "2024-03-04T11:45:00",
						"e": "2024-03-04T12:00:00",
						"v": "conn"
					},
					{
						"id": 48,
						"s": "2024-03-04T12:00:00",
						"e": "2024-03-04T12:15:00",
						"v": "conn"
					},
					{
						"id": 49,
						"s": "2024-03-04T12:15:00",
						"e": "2024-03-04T12:30:00",
						"v": "conn"
					},
					{
						"id": 50,
						"s": "2024-03-04T12:30:00",
						"e": "2024-03-04T12:45:00",
						"v": "conn"
					},
					{
						"id": 51,
						"s": "2024-03-04T12:45:00",
						"e": "2024-03-04T13:00:00",
						"v": "conn"
					},
					{
						"id": 52,
						"s": "2024-03-04T13:00:00",
						"e": "2024-03-04T13:15:00",
						"v": "conn"
					},
					{
						"id": 53,
						"s": "2024-03-04T13:15:00",
						"e": "2024-03-04T13:30:00",
						"v": "conn"
					},
					{
						"id": 54,
						"s": "2024-03-04T13:30:00",
						"e": "2024-03-04T13:45:00",
						"v": "conn"
					},
					{
						"id": 55,
						"s": "2024-03-04T13:45:00",
						"e": "2024-03-04T14:00:00",
						"v": "conn"
					},
					{
						"id": 56,
						"s": "2024-03-04T14:00:00",
						"e": "2024-03-04T14:15:00",
						"v": "conn"
					},
					{
						"id": 57,
						"s": "2024-03-04T14:15:00",
						"e": "2024-03-04T14:30:00",
						"v": "conn"
					},
					{
						"id": 58,
						"s": "2024-03-04T14:30:00",
						"e": "2024-03-04T14:45:00",
						"v": "conn"
					},
					{
						"id": 59,
						"s": "2024-03-04T14:45:00",
						"e": "2024-03-04T15:00:00",
						"v": "conn"
					},
					{
						"id": 60,
						"s": "2024-03-04T15:00:00",
						"e": "2024-03-04T15:15:00",
						"v": "conn"
					},
					{
						"id": 61,
						"s": "2024-03-04T15:15:00",
						"e": "2024-03-04T15:30:00",
						"v": "conn"
					},
					{
						"id": 62,
						"s": "2024-03-04T15:30:00",
						"e": "2024-03-04T15:45:00",
						"v": "conn"
					},
					{
						"id": 63,
						"s": "2024-03-04T15:45:00",
						"e": "2024-03-04T16:00:00",
						"v": "conn"
					},
					{
						"id": 64,
						"s": "2024-03-04T16:00:00",
						"e": "2024-03-04T16:15:00",
						"v": "conn"
					},
					{
						"id": 65,
						"s": "2024-03-04T16:15:00",
						"e": "2024-03-04T16:30:00",
						"v": "conn"
					},
					{
						"id": 66,
						"s": "2024-03-04T16:30:00",
						"e": "2024-03-04T16:45:00",
						"v": "conn"
					},
					{
						"id": 67,
						"s": "2024-03-04T16:45:00",
						"e": "2024-03-04T17:00:00",
						"v": "conn"
					},
					{
						"id": 68,
						"s": "2024-03-04T17:00:00",
						"e": "2024-03-04T17:15:00",
						"v": "conn"
					},
					{
						"id": 69,
						"s": "2024-03-04T17:15:00",
						"e": "2024-03-04T17:30:00",
						"v": "conn"
					},
					{
						"id": 70,
						"s": "2024-03-04T17:30:00",
						"e": "2024-03-04T17:45:00",
						"v": "conn"
					},
					{
						"id": 71,
						"s": "2024-03-04T17:45:00",
						"e": "2024-03-04T18:00:00",
						"v": "conn"
					},
					{
						"id": 72,
						"s": "2024-03-04T18:00:00",
						"e": "2024-03-04T18:15:00",
						"v": "conn"
					},
					{
						"id": 73,
						"s": "2024-03-04T18:15:00",
						"e": "2024-03-04T18:30:00",
						"v": "conn"
					},
					{
						"id": 74,
						"s": "2024-03-04T18:30:00",
						"e": "2024-03-04T18:45:00",
						"v": "conn"
					},
					{
						"id": 75,
						"s": "2024-03-04T18:45:00",
						"e": "2024-03-04T19:00:00",
						"v": "conn"
					},
					{
						"id": 76,
						"s": "2024-03-04T19:00:00",
						"e": "2024-03-04T19:15:00",
						"v": "conn"
					},
					{
						"id": 77,
						"s": "2024-03-04T19:15:00",
						"e": "2024-03-04T19:30:00",
						"v": "conn"
					},
					{
						"id": 78,
						"s": "2024-03-04T19:30:00",
						"e": "2024-03-04T19:45:00",
						"v": "conn"
					},
					{
						"id": 79,
						"s": "2024-03-04T19:45:00",
						"e": "2024-03-04T20:00:00",
						"v": "conn"
					},
					{
						"id": 80,
						"s": "2024-03-04T20:00:00",
						"e": "2024-03-04T20:15:00",
						"v": "conn"
					},
					{
						"id": 81,
						"s": "2024-03-04T20:15:00",
						"e": "2024-03-04T20:30:00",
						"v": "conn"
					},
					{
						"id": 82,
						"s": "2024-03-04T20:30:00",
						"e": "2024-03-04T20:45:00",
						"v": "conn"
					},
					{
						"id": 83,
						"s": "2024-03-04T20:45:00",
						"e": "2024-03-04T21:00:00",
						"v": "conn"
					},
					{
						"id": 84,
						"s": "2024-03-04T21:00:00",
						"e": "2024-03-04T21:15:00",
						"v": "conn"
					},
					{
						"id": 85,
						"s": "2024-03-04T21:15:00",
						"e": "2024-03-04T21:30:00",
						"v": "conn"
					},
					{
						"id": 86,
						"s": "2024-03-04T21:30:00",
						"e": "2024-03-04T21:45:00",
						"v": "conn"
					},
					{
						"id": 87,
						"s": "2024-03-04T21:45:00",
						"e": "2024-03-04T22:00:00",
						"v": "conn"
					},
					{
						"id": 88,
						"s": "2024-03-04T22:00:00",
						"e": "2024-03-04T22:15:00",
						"v": "conn"
					},
					{
						"id": 89,
						"s": "2024-03-04T22:15:00",
						"e": "2024-03-04T22:30:00",
						"v": "conn"
					},
					{
						"id": 90,
						"s": "2024-03-04T22:30:00",
						"e": "2024-03-04T22:45:00",
						"v": "conn"
					},
					{
						"id": 91,
						"s": "2024-03-04T22:45:00",
						"e": "2024-03-04T23:00:00",
						"v": "conn"
					},
					{
						"id": 92,
						"s": "2024-03-04T23:00:00",
						"e": "2024-03-04T23:15:00",
						"v": "conn"
					},
					{
						"id": 93,
						"s": "2024-03-04T23:15:00",
						"e": "2024-03-04T23:30:00",
						"v": "conn"
					},
					{
						"id": 94,
						"s": "2024-03-04T23:30:00",
						"e": "2024-03-04T23:45:00",
						"v": "conn"
					},
					{
						"id": 95,
						"s": "2024-03-04T23:45:00",
						"e": "2024-03-05T00:00:00",
						"v": "conn"
					}
				]
			},
			{
				"id": 17,
				"date": "5 Mar 2024",
				"data": [
					{
						"id": 0,
						"s": "2024-03-05T00:00:00",
						"e": "2024-03-05T00:15:00",
						"v": "conn"
					},
					{
						"id": 1,
						"s": "2024-03-05T00:15:00",
						"e": "2024-03-05T00:30:00",
						"v": "conn"
					},
					{
						"id": 2,
						"s": "2024-03-05T00:30:00",
						"e": "2024-03-05T00:45:00",
						"v": "conn"
					},
					{
						"id": 3,
						"s": "2024-03-05T00:45:00",
						"e": "2024-03-05T01:00:00",
						"v": "conn"
					},
					{
						"id": 4,
						"s": "2024-03-05T01:00:00",
						"e": "2024-03-05T01:15:00",
						"v": "conn"
					},
					{
						"id": 5,
						"s": "2024-03-05T01:15:00",
						"e": "2024-03-05T01:30:00",
						"v": "conn"
					},
					{
						"id": 6,
						"s": "2024-03-05T01:30:00",
						"e": "2024-03-05T01:45:00",
						"v": "conn"
					},
					{
						"id": 7,
						"s": "2024-03-05T01:45:00",
						"e": "2024-03-05T02:00:00",
						"v": "conn"
					},
					{
						"id": 8,
						"s": "2024-03-05T02:00:00",
						"e": "2024-03-05T02:15:00",
						"v": "conn"
					},
					{
						"id": 9,
						"s": "2024-03-05T02:15:00",
						"e": "2024-03-05T02:30:00",
						"v": "conn"
					},
					{
						"id": 10,
						"s": "2024-03-05T02:30:00",
						"e": "2024-03-05T02:45:00",
						"v": "conn"
					},
					{
						"id": 11,
						"s": "2024-03-05T02:45:00",
						"e": "2024-03-05T03:00:00",
						"v": "conn"
					},
					{
						"id": 12,
						"s": "2024-03-05T03:00:00",
						"e": "2024-03-05T03:15:00",
						"v": "conn"
					},
					{
						"id": 13,
						"s": "2024-03-05T03:15:00",
						"e": "2024-03-05T03:30:00",
						"v": "conn"
					},
					{
						"id": 14,
						"s": "2024-03-05T03:30:00",
						"e": "2024-03-05T03:45:00",
						"v": "conn"
					},
					{
						"id": 15,
						"s": "2024-03-05T03:45:00",
						"e": "2024-03-05T04:00:00",
						"v": "conn"
					},
					{
						"id": 16,
						"s": "2024-03-05T04:00:00",
						"e": "2024-03-05T04:15:00",
						"v": "conn"
					},
					{
						"id": 17,
						"s": "2024-03-05T04:15:00",
						"e": "2024-03-05T04:30:00",
						"v": "conn"
					},
					{
						"id": 18,
						"s": "2024-03-05T04:30:00",
						"e": "2024-03-05T04:45:00",
						"v": "conn"
					},
					{
						"id": 19,
						"s": "2024-03-05T04:45:00",
						"e": "2024-03-05T05:00:00",
						"v": "conn"
					},
					{
						"id": 20,
						"s": "2024-03-05T05:00:00",
						"e": "2024-03-05T05:15:00",
						"v": "conn"
					},
					{
						"id": 21,
						"s": "2024-03-05T05:15:00",
						"e": "2024-03-05T05:30:00",
						"v": "conn"
					},
					{
						"id": 22,
						"s": "2024-03-05T05:30:00",
						"e": "2024-03-05T05:45:00",
						"v": "conn"
					},
					{
						"id": 23,
						"s": "2024-03-05T05:45:00",
						"e": "2024-03-05T06:00:00",
						"v": "conn"
					},
					{
						"id": 24,
						"s": "2024-03-05T06:00:00",
						"e": "2024-03-05T06:15:00",
						"v": "conn"
					},
					{
						"id": 25,
						"s": "2024-03-05T06:15:00",
						"e": "2024-03-05T06:30:00",
						"v": "conn"
					},
					{
						"id": 26,
						"s": "2024-03-05T06:30:00",
						"e": "2024-03-05T06:45:00",
						"v": "conn"
					},
					{
						"id": 27,
						"s": "2024-03-05T06:45:00",
						"e": "2024-03-05T07:00:00",
						"v": "conn"
					},
					{
						"id": 28,
						"s": "2024-03-05T07:00:00",
						"e": "2024-03-05T07:15:00",
						"v": "conn"
					},
					{
						"id": 29,
						"s": "2024-03-05T07:15:00",
						"e": "2024-03-05T07:30:00",
						"v": "conn"
					},
					{
						"id": 30,
						"s": "2024-03-05T07:30:00",
						"e": "2024-03-05T07:45:00",
						"v": "conn"
					},
					{
						"id": 31,
						"s": "2024-03-05T07:45:00",
						"e": "2024-03-05T08:00:00",
						"v": "conn"
					},
					{
						"id": 32,
						"s": "2024-03-05T08:00:00",
						"e": "2024-03-05T08:15:00",
						"v": "conn"
					},
					{
						"id": 33,
						"s": "2024-03-05T08:15:00",
						"e": "2024-03-05T08:30:00",
						"v": "conn"
					},
					{
						"id": 34,
						"s": "2024-03-05T08:30:00",
						"e": "2024-03-05T08:45:00",
						"v": "conn"
					},
					{
						"id": 35,
						"s": "2024-03-05T08:45:00",
						"e": "2024-03-05T09:00:00",
						"v": "conn"
					},
					{
						"id": 36,
						"s": "2024-03-05T09:00:00",
						"e": "2024-03-05T09:15:00",
						"v": "conn"
					},
					{
						"id": 37,
						"s": "2024-03-05T09:15:00",
						"e": "2024-03-05T09:30:00",
						"v": "conn"
					},
					{
						"id": 38,
						"s": "2024-03-05T09:30:00",
						"e": "2024-03-05T09:45:00",
						"v": "conn"
					},
					{
						"id": 39,
						"s": "2024-03-05T09:45:00",
						"e": "2024-03-05T10:00:00",
						"v": "conn"
					},
					{
						"id": 40,
						"s": "2024-03-05T10:00:00",
						"e": "2024-03-05T10:15:00",
						"v": "conn"
					},
					{
						"id": 41,
						"s": "2024-03-05T10:15:00",
						"e": "2024-03-05T10:30:00",
						"v": "conn"
					},
					{
						"id": 42,
						"s": "2024-03-05T10:30:00",
						"e": "2024-03-05T10:45:00",
						"v": "conn"
					},
					{
						"id": 43,
						"s": "2024-03-05T10:45:00",
						"e": "2024-03-05T11:00:00",
						"v": "conn"
					},
					{
						"id": 44,
						"s": "2024-03-05T11:00:00",
						"e": "2024-03-05T11:15:00",
						"v": "conn"
					},
					{
						"id": 45,
						"s": "2024-03-05T11:15:00",
						"e": "2024-03-05T11:30:00",
						"v": "conn"
					},
					{
						"id": 46,
						"s": "2024-03-05T11:30:00",
						"e": "2024-03-05T11:45:00",
						"v": "conn"
					},
					{
						"id": 47,
						"s": "2024-03-05T11:45:00",
						"e": "2024-03-05T12:00:00",
						"v": "conn"
					},
					{
						"id": 48,
						"s": "2024-03-05T12:00:00",
						"e": "2024-03-05T12:15:00",
						"v": "conn"
					},
					{
						"id": 49,
						"s": "2024-03-05T12:15:00",
						"e": "2024-03-05T12:30:00",
						"v": "conn"
					},
					{
						"id": 50,
						"s": "2024-03-05T12:30:00",
						"e": "2024-03-05T12:45:00",
						"v": "conn"
					},
					{
						"id": 51,
						"s": "2024-03-05T12:45:00",
						"e": "2024-03-05T13:00:00",
						"v": "conn"
					},
					{
						"id": 52,
						"s": "2024-03-05T13:00:00",
						"e": "2024-03-05T13:15:00",
						"v": "conn"
					},
					{
						"id": 53,
						"s": "2024-03-05T13:15:00",
						"e": "2024-03-05T13:30:00",
						"v": "conn"
					},
					{
						"id": 54,
						"s": "2024-03-05T13:30:00",
						"e": "2024-03-05T13:45:00",
						"v": "conn"
					},
					{
						"id": 55,
						"s": "2024-03-05T13:45:00",
						"e": "2024-03-05T14:00:00",
						"v": "conn"
					},
					{
						"id": 56,
						"s": "2024-03-05T14:00:00",
						"e": "2024-03-05T14:15:00",
						"v": "conn"
					},
					{
						"id": 57,
						"s": "2024-03-05T14:15:00",
						"e": "2024-03-05T14:30:00",
						"v": "conn"
					},
					{
						"id": 58,
						"s": "2024-03-05T14:30:00",
						"e": "2024-03-05T14:45:00",
						"v": "conn"
					},
					{
						"id": 59,
						"s": "2024-03-05T14:45:00",
						"e": "2024-03-05T15:00:00",
						"v": "conn"
					},
					{
						"id": 60,
						"s": "2024-03-05T15:00:00",
						"e": "2024-03-05T15:15:00",
						"v": "conn"
					},
					{
						"id": 61,
						"s": "2024-03-05T15:15:00",
						"e": "2024-03-05T15:30:00",
						"v": "conn"
					},
					{
						"id": 62,
						"s": "2024-03-05T15:30:00",
						"e": "2024-03-05T15:45:00",
						"v": "conn"
					},
					{
						"id": 63,
						"s": "2024-03-05T15:45:00",
						"e": "2024-03-05T16:00:00",
						"v": "conn"
					},
					{
						"id": 64,
						"s": "2024-03-05T16:00:00",
						"e": "2024-03-05T16:15:00",
						"v": "conn"
					},
					{
						"id": 65,
						"s": "2024-03-05T16:15:00",
						"e": "2024-03-05T16:30:00",
						"v": "conn"
					},
					{
						"id": 66,
						"s": "2024-03-05T16:30:00",
						"e": "2024-03-05T16:45:00",
						"v": "conn"
					},
					{
						"id": 67,
						"s": "2024-03-05T16:45:00",
						"e": "2024-03-05T17:00:00",
						"v": "conn"
					},
					{
						"id": 68,
						"s": "2024-03-05T17:00:00",
						"e": "2024-03-05T17:15:00",
						"v": "conn"
					},
					{
						"id": 69,
						"s": "2024-03-05T17:15:00",
						"e": "2024-03-05T17:30:00",
						"v": "conn"
					},
					{
						"id": 70,
						"s": "2024-03-05T17:30:00",
						"e": "2024-03-05T17:45:00",
						"v": "conn"
					},
					{
						"id": 71,
						"s": "2024-03-05T17:45:00",
						"e": "2024-03-05T18:00:00",
						"v": "conn"
					},
					{
						"id": 72,
						"s": "2024-03-05T18:00:00",
						"e": "2024-03-05T18:15:00",
						"v": "conn"
					},
					{
						"id": 73,
						"s": "2024-03-05T18:15:00",
						"e": "2024-03-05T18:30:00",
						"v": "conn"
					},
					{
						"id": 74,
						"s": "2024-03-05T18:30:00",
						"e": "2024-03-05T18:45:00",
						"v": "conn"
					},
					{
						"id": 75,
						"s": "2024-03-05T18:45:00",
						"e": "2024-03-05T19:00:00",
						"v": "conn"
					},
					{
						"id": 76,
						"s": "2024-03-05T19:00:00",
						"e": "2024-03-05T19:15:00",
						"v": "conn"
					},
					{
						"id": 77,
						"s": "2024-03-05T19:15:00",
						"e": "2024-03-05T19:30:00",
						"v": "conn"
					},
					{
						"id": 78,
						"s": "2024-03-05T19:30:00",
						"e": "2024-03-05T19:45:00",
						"v": "conn"
					},
					{
						"id": 79,
						"s": "2024-03-05T19:45:00",
						"e": "2024-03-05T20:00:00",
						"v": "conn"
					},
					{
						"id": 80,
						"s": "2024-03-05T20:00:00",
						"e": "2024-03-05T20:15:00",
						"v": "conn"
					},
					{
						"id": 81,
						"s": "2024-03-05T20:15:00",
						"e": "2024-03-05T20:30:00",
						"v": "conn"
					},
					{
						"id": 82,
						"s": "2024-03-05T20:30:00",
						"e": "2024-03-05T20:45:00",
						"v": "conn"
					},
					{
						"id": 83,
						"s": "2024-03-05T20:45:00",
						"e": "2024-03-05T21:00:00",
						"v": "conn"
					},
					{
						"id": 84,
						"s": "2024-03-05T21:00:00",
						"e": "2024-03-05T21:15:00",
						"v": "conn"
					},
					{
						"id": 85,
						"s": "2024-03-05T21:15:00",
						"e": "2024-03-05T21:30:00",
						"v": "conn"
					},
					{
						"id": 86,
						"s": "2024-03-05T21:30:00",
						"e": "2024-03-05T21:45:00",
						"v": "conn"
					},
					{
						"id": 87,
						"s": "2024-03-05T21:45:00",
						"e": "2024-03-05T22:00:00",
						"v": "conn"
					},
					{
						"id": 88,
						"s": "2024-03-05T22:00:00",
						"e": "2024-03-05T22:15:00",
						"v": "conn"
					},
					{
						"id": 89,
						"s": "2024-03-05T22:15:00",
						"e": "2024-03-05T22:30:00",
						"v": "conn"
					},
					{
						"id": 90,
						"s": "2024-03-05T22:30:00",
						"e": "2024-03-05T22:45:00",
						"v": "conn"
					},
					{
						"id": 91,
						"s": "2024-03-05T22:45:00",
						"e": "2024-03-05T23:00:00",
						"v": "conn"
					},
					{
						"id": 92,
						"s": "2024-03-05T23:00:00",
						"e": "2024-03-05T23:15:00",
						"v": "conn"
					},
					{
						"id": 93,
						"s": "2024-03-05T23:15:00",
						"e": "2024-03-05T23:30:00",
						"v": "conn"
					},
					{
						"id": 94,
						"s": "2024-03-05T23:30:00",
						"e": "2024-03-05T23:45:00",
						"v": "conn"
					},
					{
						"id": 95,
						"s": "2024-03-05T23:45:00",
						"e": "2024-03-06T00:00:00",
						"v": "conn"
					}
				]
			},
			{
				"id": 18,
				"date": "6 Mar 2024",
				"data": [
					{
						"id": 0,
						"s": "2024-03-06T00:00:00",
						"e": "2024-03-06T00:15:00",
						"v": "conn"
					},
					{
						"id": 1,
						"s": "2024-03-06T00:15:00",
						"e": "2024-03-06T00:30:00",
						"v": "conn"
					},
					{
						"id": 2,
						"s": "2024-03-06T00:30:00",
						"e": "2024-03-06T00:45:00",
						"v": "conn"
					},
					{
						"id": 3,
						"s": "2024-03-06T00:45:00",
						"e": "2024-03-06T01:00:00",
						"v": "conn"
					},
					{
						"id": 4,
						"s": "2024-03-06T01:00:00",
						"e": "2024-03-06T01:15:00",
						"v": "conn"
					},
					{
						"id": 5,
						"s": "2024-03-06T01:15:00",
						"e": "2024-03-06T01:30:00",
						"v": "conn"
					},
					{
						"id": 6,
						"s": "2024-03-06T01:30:00",
						"e": "2024-03-06T01:45:00",
						"v": "conn"
					},
					{
						"id": 7,
						"s": "2024-03-06T01:45:00",
						"e": "2024-03-06T02:00:00",
						"v": "conn"
					},
					{
						"id": 8,
						"s": "2024-03-06T02:00:00",
						"e": "2024-03-06T02:15:00",
						"v": "conn"
					},
					{
						"id": 9,
						"s": "2024-03-06T02:15:00",
						"e": "2024-03-06T02:30:00",
						"v": "conn"
					},
					{
						"id": 10,
						"s": "2024-03-06T02:30:00",
						"e": "2024-03-06T02:45:00",
						"v": "conn"
					},
					{
						"id": 11,
						"s": "2024-03-06T02:45:00",
						"e": "2024-03-06T03:00:00",
						"v": "conn"
					},
					{
						"id": 12,
						"s": "2024-03-06T03:00:00",
						"e": "2024-03-06T03:15:00",
						"v": "conn"
					},
					{
						"id": 13,
						"s": "2024-03-06T03:15:00",
						"e": "2024-03-06T03:30:00",
						"v": "conn"
					},
					{
						"id": 14,
						"s": "2024-03-06T03:30:00",
						"e": "2024-03-06T03:45:00",
						"v": "conn"
					},
					{
						"id": 15,
						"s": "2024-03-06T03:45:00",
						"e": "2024-03-06T04:00:00",
						"v": "conn"
					},
					{
						"id": 16,
						"s": "2024-03-06T04:00:00",
						"e": "2024-03-06T04:15:00",
						"v": "conn"
					},
					{
						"id": 17,
						"s": "2024-03-06T04:15:00",
						"e": "2024-03-06T04:30:00",
						"v": "conn"
					},
					{
						"id": 18,
						"s": "2024-03-06T04:30:00",
						"e": "2024-03-06T04:45:00",
						"v": "conn"
					},
					{
						"id": 19,
						"s": "2024-03-06T04:45:00",
						"e": "2024-03-06T05:00:00",
						"v": "conn"
					},
					{
						"id": 20,
						"s": "2024-03-06T05:00:00",
						"e": "2024-03-06T05:15:00",
						"v": "conn"
					},
					{
						"id": 21,
						"s": "2024-03-06T05:15:00",
						"e": "2024-03-06T05:30:00",
						"v": "conn"
					},
					{
						"id": 22,
						"s": "2024-03-06T05:30:00",
						"e": "2024-03-06T05:45:00",
						"v": "conn"
					},
					{
						"id": 23,
						"s": "2024-03-06T05:45:00",
						"e": "2024-03-06T06:00:00",
						"v": "conn"
					},
					{
						"id": 24,
						"s": "2024-03-06T06:00:00",
						"e": "2024-03-06T06:15:00",
						"v": "conn"
					},
					{
						"id": 25,
						"s": "2024-03-06T06:15:00",
						"e": "2024-03-06T06:30:00",
						"v": "conn"
					},
					{
						"id": 26,
						"s": "2024-03-06T06:30:00",
						"e": "2024-03-06T06:45:00",
						"v": "conn"
					},
					{
						"id": 27,
						"s": "2024-03-06T06:45:00",
						"e": "2024-03-06T07:00:00",
						"v": "conn"
					},
					{
						"id": 28,
						"s": "2024-03-06T07:00:00",
						"e": "2024-03-06T07:15:00",
						"v": "conn"
					},
					{
						"id": 29,
						"s": "2024-03-06T07:15:00",
						"e": "2024-03-06T07:30:00",
						"v": "conn"
					},
					{
						"id": 30,
						"s": "2024-03-06T07:30:00",
						"e": "2024-03-06T07:45:00",
						"v": "conn"
					},
					{
						"id": 31,
						"s": "2024-03-06T07:45:00",
						"e": "2024-03-06T08:00:00",
						"v": "conn"
					},
					{
						"id": 32,
						"s": "2024-03-06T08:00:00",
						"e": "2024-03-06T08:15:00",
						"v": "conn"
					},
					{
						"id": 33,
						"s": "2024-03-06T08:15:00",
						"e": "2024-03-06T08:30:00",
						"v": "conn"
					},
					{
						"id": 34,
						"s": "2024-03-06T08:30:00",
						"e": "2024-03-06T08:45:00",
						"v": "conn"
					},
					{
						"id": 35,
						"s": "2024-03-06T08:45:00",
						"e": "2024-03-06T09:00:00",
						"v": "conn"
					},
					{
						"id": 36,
						"s": "2024-03-06T09:00:00",
						"e": "2024-03-06T09:15:00",
						"v": "conn"
					},
					{
						"id": 37,
						"s": "2024-03-06T09:15:00",
						"e": "2024-03-06T09:30:00",
						"v": "conn"
					},
					{
						"id": 38,
						"s": "2024-03-06T09:30:00",
						"e": "2024-03-06T09:45:00",
						"v": "conn"
					},
					{
						"id": 39,
						"s": "2024-03-06T09:45:00",
						"e": "2024-03-06T10:00:00",
						"v": "conn"
					},
					{
						"id": 40,
						"s": "2024-03-06T10:00:00",
						"e": "2024-03-06T10:15:00",
						"v": "conn"
					},
					{
						"id": 41,
						"s": "2024-03-06T10:15:00",
						"e": "2024-03-06T10:30:00",
						"v": "conn"
					},
					{
						"id": 42,
						"s": "2024-03-06T10:30:00",
						"e": "2024-03-06T10:45:00",
						"v": "conn"
					},
					{
						"id": 43,
						"s": "2024-03-06T10:45:00",
						"e": "2024-03-06T11:00:00",
						"v": "conn"
					},
					{
						"id": 44,
						"s": "2024-03-06T11:00:00",
						"e": "2024-03-06T11:15:00",
						"v": "conn"
					},
					{
						"id": 45,
						"s": "2024-03-06T11:15:00",
						"e": "2024-03-06T11:30:00",
						"v": "conn"
					},
					{
						"id": 46,
						"s": "2024-03-06T11:30:00",
						"e": "2024-03-06T11:45:00",
						"v": "conn"
					},
					{
						"id": 47,
						"s": "2024-03-06T11:45:00",
						"e": "2024-03-06T12:00:00",
						"v": "conn"
					},
					{
						"id": 48,
						"s": "2024-03-06T12:00:00",
						"e": "2024-03-06T12:15:00",
						"v": "conn"
					},
					{
						"id": 49,
						"s": "2024-03-06T12:15:00",
						"e": "2024-03-06T12:30:00",
						"v": "conn"
					},
					{
						"id": 50,
						"s": "2024-03-06T12:30:00",
						"e": "2024-03-06T12:45:00",
						"v": "conn"
					},
					{
						"id": 51,
						"s": "2024-03-06T12:45:00",
						"e": "2024-03-06T13:00:00",
						"v": "conn"
					},
					{
						"id": 52,
						"s": "2024-03-06T13:00:00",
						"e": "2024-03-06T13:15:00",
						"v": "conn"
					},
					{
						"id": 53,
						"s": "2024-03-06T13:15:00",
						"e": "2024-03-06T13:30:00",
						"v": "conn"
					},
					{
						"id": 54,
						"s": "2024-03-06T13:30:00",
						"e": "2024-03-06T13:45:00",
						"v": "conn"
					},
					{
						"id": 55,
						"s": "2024-03-06T13:45:00",
						"e": "2024-03-06T14:00:00",
						"v": "conn"
					},
					{
						"id": 56,
						"s": "2024-03-06T14:00:00",
						"e": "2024-03-06T14:15:00",
						"v": "conn"
					},
					{
						"id": 57,
						"s": "2024-03-06T14:15:00",
						"e": "2024-03-06T14:30:00",
						"v": "conn"
					},
					{
						"id": 58,
						"s": "2024-03-06T14:30:00",
						"e": "2024-03-06T14:45:00",
						"v": "conn"
					},
					{
						"id": 59,
						"s": "2024-03-06T14:45:00",
						"e": "2024-03-06T15:00:00",
						"v": "conn"
					},
					{
						"id": 60,
						"s": "2024-03-06T15:00:00",
						"e": "2024-03-06T15:15:00",
						"v": "conn"
					},
					{
						"id": 61,
						"s": "2024-03-06T15:15:00",
						"e": "2024-03-06T15:30:00",
						"v": "conn"
					},
					{
						"id": 62,
						"s": "2024-03-06T15:30:00",
						"e": "2024-03-06T15:45:00",
						"v": "conn"
					},
					{
						"id": 63,
						"s": "2024-03-06T15:45:00",
						"e": "2024-03-06T16:00:00",
						"v": "conn"
					},
					{
						"id": 64,
						"s": "2024-03-06T16:00:00",
						"e": "2024-03-06T16:15:00",
						"v": "conn"
					},
					{
						"id": 65,
						"s": "2024-03-06T16:15:00",
						"e": "2024-03-06T16:30:00",
						"v": "conn"
					},
					{
						"id": 66,
						"s": "2024-03-06T16:30:00",
						"e": "2024-03-06T16:45:00",
						"v": "conn"
					},
					{
						"id": 67,
						"s": "2024-03-06T16:45:00",
						"e": "2024-03-06T17:00:00",
						"v": "conn"
					},
					{
						"id": 68,
						"s": "2024-03-06T17:00:00",
						"e": "2024-03-06T17:15:00",
						"v": "conn"
					},
					{
						"id": 69,
						"s": "2024-03-06T17:15:00",
						"e": "2024-03-06T17:30:00",
						"v": "conn"
					},
					{
						"id": 70,
						"s": "2024-03-06T17:30:00",
						"e": "2024-03-06T17:45:00",
						"v": "conn"
					},
					{
						"id": 71,
						"s": "2024-03-06T17:45:00",
						"e": "2024-03-06T18:00:00",
						"v": "conn"
					},
					{
						"id": 72,
						"s": "2024-03-06T18:00:00",
						"e": "2024-03-06T18:15:00",
						"v": "conn"
					},
					{
						"id": 73,
						"s": "2024-03-06T18:15:00",
						"e": "2024-03-06T18:30:00",
						"v": "conn"
					},
					{
						"id": 74,
						"s": "2024-03-06T18:30:00",
						"e": "2024-03-06T18:45:00",
						"v": "conn"
					},
					{
						"id": 75,
						"s": "2024-03-06T18:45:00",
						"e": "2024-03-06T19:00:00",
						"v": "conn"
					},
					{
						"id": 76,
						"s": "2024-03-06T19:00:00",
						"e": "2024-03-06T19:15:00",
						"v": "conn"
					},
					{
						"id": 77,
						"s": "2024-03-06T19:15:00",
						"e": "2024-03-06T19:30:00",
						"v": "conn"
					},
					{
						"id": 78,
						"s": "2024-03-06T19:30:00",
						"e": "2024-03-06T19:45:00",
						"v": "conn"
					},
					{
						"id": 79,
						"s": "2024-03-06T19:45:00",
						"e": "2024-03-06T20:00:00",
						"v": "conn"
					},
					{
						"id": 80,
						"s": "2024-03-06T20:00:00",
						"e": "2024-03-06T20:15:00",
						"v": "conn"
					},
					{
						"id": 81,
						"s": "2024-03-06T20:15:00",
						"e": "2024-03-06T20:30:00",
						"v": "conn"
					},
					{
						"id": 82,
						"s": "2024-03-06T20:30:00",
						"e": "2024-03-06T20:45:00",
						"v": "conn"
					},
					{
						"id": 83,
						"s": "2024-03-06T20:45:00",
						"e": "2024-03-06T21:00:00",
						"v": "conn"
					},
					{
						"id": 84,
						"s": "2024-03-06T21:00:00",
						"e": "2024-03-06T21:15:00",
						"v": "conn"
					},
					{
						"id": 85,
						"s": "2024-03-06T21:15:00",
						"e": "2024-03-06T21:30:00",
						"v": "conn"
					},
					{
						"id": 86,
						"s": "2024-03-06T21:30:00",
						"e": "2024-03-06T21:45:00",
						"v": "conn"
					},
					{
						"id": 87,
						"s": "2024-03-06T21:45:00",
						"e": "2024-03-06T22:00:00",
						"v": "conn"
					},
					{
						"id": 88,
						"s": "2024-03-06T22:00:00",
						"e": "2024-03-06T22:15:00",
						"v": "conn"
					},
					{
						"id": 89,
						"s": "2024-03-06T22:15:00",
						"e": "2024-03-06T22:30:00",
						"v": "conn"
					},
					{
						"id": 90,
						"s": "2024-03-06T22:30:00",
						"e": "2024-03-06T22:45:00",
						"v": "conn"
					},
					{
						"id": 91,
						"s": "2024-03-06T22:45:00",
						"e": "2024-03-06T23:00:00",
						"v": "conn"
					},
					{
						"id": 92,
						"s": "2024-03-06T23:00:00",
						"e": "2024-03-06T23:15:00",
						"v": "conn"
					},
					{
						"id": 93,
						"s": "2024-03-06T23:15:00",
						"e": "2024-03-06T23:30:00",
						"v": "conn"
					},
					{
						"id": 94,
						"s": "2024-03-06T23:30:00",
						"e": "2024-03-06T23:45:00",
						"v": "conn"
					},
					{
						"id": 95,
						"s": "2024-03-06T23:45:00",
						"e": "2024-03-07T00:00:00",
						"v": "conn"
					}
				]
			},
			{
				"id": 19,
				"date": "7 Mar 2024",
				"data": [
					{
						"id": 0,
						"s": "2024-03-07T00:00:00",
						"e": "2024-03-07T00:15:00",
						"v": "conn"
					},
					{
						"id": 1,
						"s": "2024-03-07T00:15:00",
						"e": "2024-03-07T00:30:00",
						"v": "conn"
					},
					{
						"id": 2,
						"s": "2024-03-07T00:30:00",
						"e": "2024-03-07T00:45:00",
						"v": "conn"
					},
					{
						"id": 3,
						"s": "2024-03-07T00:45:00",
						"e": "2024-03-07T01:00:00",
						"v": "conn"
					},
					{
						"id": 4,
						"s": "2024-03-07T01:00:00",
						"e": "2024-03-07T01:15:00",
						"v": "conn"
					},
					{
						"id": 5,
						"s": "2024-03-07T01:15:00",
						"e": "2024-03-07T01:30:00",
						"v": "conn"
					},
					{
						"id": 6,
						"s": "2024-03-07T01:30:00",
						"e": "2024-03-07T01:45:00",
						"v": "conn"
					},
					{
						"id": 7,
						"s": "2024-03-07T01:45:00",
						"e": "2024-03-07T02:00:00",
						"v": "conn"
					},
					{
						"id": 8,
						"s": "2024-03-07T02:00:00",
						"e": "2024-03-07T02:15:00",
						"v": "conn"
					},
					{
						"id": 9,
						"s": "2024-03-07T02:15:00",
						"e": "2024-03-07T02:30:00",
						"v": "conn"
					},
					{
						"id": 10,
						"s": "2024-03-07T02:30:00",
						"e": "2024-03-07T02:45:00",
						"v": "conn"
					},
					{
						"id": 11,
						"s": "2024-03-07T02:45:00",
						"e": "2024-03-07T03:00:00",
						"v": "conn"
					},
					{
						"id": 12,
						"s": "2024-03-07T03:00:00",
						"e": "2024-03-07T03:15:00",
						"v": "conn"
					},
					{
						"id": 13,
						"s": "2024-03-07T03:15:00",
						"e": "2024-03-07T03:30:00",
						"v": "conn"
					},
					{
						"id": 14,
						"s": "2024-03-07T03:30:00",
						"e": "2024-03-07T03:45:00",
						"v": "conn"
					},
					{
						"id": 15,
						"s": "2024-03-07T03:45:00",
						"e": "2024-03-07T04:00:00",
						"v": "conn"
					},
					{
						"id": 16,
						"s": "2024-03-07T04:00:00",
						"e": "2024-03-07T04:15:00",
						"v": "conn"
					},
					{
						"id": 17,
						"s": "2024-03-07T04:15:00",
						"e": "2024-03-07T04:30:00",
						"v": "conn"
					},
					{
						"id": 18,
						"s": "2024-03-07T04:30:00",
						"e": "2024-03-07T04:45:00",
						"v": "conn"
					},
					{
						"id": 19,
						"s": "2024-03-07T04:45:00",
						"e": "2024-03-07T05:00:00",
						"v": "conn"
					},
					{
						"id": 20,
						"s": "2024-03-07T05:00:00",
						"e": "2024-03-07T05:15:00",
						"v": "conn"
					},
					{
						"id": 21,
						"s": "2024-03-07T05:15:00",
						"e": "2024-03-07T05:30:00",
						"v": "conn"
					},
					{
						"id": 22,
						"s": "2024-03-07T05:30:00",
						"e": "2024-03-07T05:45:00",
						"v": "conn"
					},
					{
						"id": 23,
						"s": "2024-03-07T05:45:00",
						"e": "2024-03-07T06:00:00",
						"v": "conn"
					},
					{
						"id": 24,
						"s": "2024-03-07T06:00:00",
						"e": "2024-03-07T06:15:00",
						"v": "conn"
					},
					{
						"id": 25,
						"s": "2024-03-07T06:15:00",
						"e": "2024-03-07T06:30:00",
						"v": "conn"
					},
					{
						"id": 26,
						"s": "2024-03-07T06:30:00",
						"e": "2024-03-07T06:45:00",
						"v": "conn"
					},
					{
						"id": 27,
						"s": "2024-03-07T06:45:00",
						"e": "2024-03-07T07:00:00",
						"v": "conn"
					},
					{
						"id": 28,
						"s": "2024-03-07T07:00:00",
						"e": "2024-03-07T07:15:00",
						"v": "conn"
					},
					{
						"id": 29,
						"s": "2024-03-07T07:15:00",
						"e": "2024-03-07T07:30:00",
						"v": "conn"
					},
					{
						"id": 30,
						"s": "2024-03-07T07:30:00",
						"e": "2024-03-07T07:45:00",
						"v": "conn"
					},
					{
						"id": 31,
						"s": "2024-03-07T07:45:00",
						"e": "2024-03-07T08:00:00",
						"v": "conn"
					},
					{
						"id": 32,
						"s": "2024-03-07T08:00:00",
						"e": "2024-03-07T08:15:00",
						"v": "conn"
					},
					{
						"id": 33,
						"s": "2024-03-07T08:15:00",
						"e": "2024-03-07T08:30:00",
						"v": "conn"
					},
					{
						"id": 34,
						"s": "2024-03-07T08:30:00",
						"e": "2024-03-07T08:45:00",
						"v": "conn"
					},
					{
						"id": 35,
						"s": "2024-03-07T08:45:00",
						"e": "2024-03-07T09:00:00",
						"v": "conn"
					},
					{
						"id": 36,
						"s": "2024-03-07T09:00:00",
						"e": "2024-03-07T09:15:00",
						"v": "conn"
					},
					{
						"id": 37,
						"s": "2024-03-07T09:15:00",
						"e": "2024-03-07T09:30:00",
						"v": "conn"
					},
					{
						"id": 38,
						"s": "2024-03-07T09:30:00",
						"e": "2024-03-07T09:45:00",
						"v": "conn"
					},
					{
						"id": 39,
						"s": "2024-03-07T09:45:00",
						"e": "2024-03-07T10:00:00",
						"v": "conn"
					},
					{
						"id": 40,
						"s": "2024-03-07T10:00:00",
						"e": "2024-03-07T10:15:00",
						"v": "conn"
					},
					{
						"id": 41,
						"s": "2024-03-07T10:15:00",
						"e": "2024-03-07T10:30:00",
						"v": "conn"
					},
					{
						"id": 42,
						"s": "2024-03-07T10:30:00",
						"e": "2024-03-07T10:45:00",
						"v": "conn"
					},
					{
						"id": 43,
						"s": "2024-03-07T10:45:00",
						"e": "2024-03-07T11:00:00",
						"v": "conn"
					},
					{
						"id": 44,
						"s": "2024-03-07T11:00:00",
						"e": "2024-03-07T11:15:00",
						"v": "conn"
					},
					{
						"id": 45,
						"s": "2024-03-07T11:15:00",
						"e": "2024-03-07T11:30:00",
						"v": "conn"
					},
					{
						"id": 46,
						"s": "2024-03-07T11:30:00",
						"e": "2024-03-07T11:45:00",
						"v": "conn"
					},
					{
						"id": 47,
						"s": "2024-03-07T11:45:00",
						"e": "2024-03-07T12:00:00",
						"v": "conn"
					},
					{
						"id": 48,
						"s": "2024-03-07T12:00:00",
						"e": "2024-03-07T12:15:00",
						"v": "conn"
					},
					{
						"id": 49,
						"s": "2024-03-07T12:15:00",
						"e": "2024-03-07T12:30:00",
						"v": "conn"
					},
					{
						"id": 50,
						"s": "2024-03-07T12:30:00",
						"e": "2024-03-07T12:45:00",
						"v": "conn"
					},
					{
						"id": 51,
						"s": "2024-03-07T12:45:00",
						"e": "2024-03-07T13:00:00",
						"v": "conn"
					},
					{
						"id": 52,
						"s": "2024-03-07T13:00:00",
						"e": "2024-03-07T13:15:00",
						"v": "conn"
					},
					{
						"id": 53,
						"s": "2024-03-07T13:15:00",
						"e": "2024-03-07T13:30:00",
						"v": "conn"
					},
					{
						"id": 54,
						"s": "2024-03-07T13:30:00",
						"e": "2024-03-07T13:45:00",
						"v": "conn"
					},
					{
						"id": 55,
						"s": "2024-03-07T13:45:00",
						"e": "2024-03-07T14:00:00",
						"v": "conn"
					},
					{
						"id": 56,
						"s": "2024-03-07T14:00:00",
						"e": "2024-03-07T14:15:00",
						"v": "conn"
					},
					{
						"id": 57,
						"s": "2024-03-07T14:15:00",
						"e": "2024-03-07T14:30:00",
						"v": "conn"
					},
					{
						"id": 58,
						"s": "2024-03-07T14:30:00",
						"e": "2024-03-07T14:45:00",
						"v": "conn"
					},
					{
						"id": 59,
						"s": "2024-03-07T14:45:00",
						"e": "2024-03-07T15:00:00",
						"v": "conn"
					},
					{
						"id": 60,
						"s": "2024-03-07T15:00:00",
						"e": "2024-03-07T15:15:00",
						"v": "conn"
					},
					{
						"id": 61,
						"s": "2024-03-07T15:15:00",
						"e": "2024-03-07T15:30:00",
						"v": "conn"
					},
					{
						"id": 62,
						"s": "2024-03-07T15:30:00",
						"e": "2024-03-07T15:45:00",
						"v": "conn"
					},
					{
						"id": 63,
						"s": "2024-03-07T15:45:00",
						"e": "2024-03-07T16:00:00",
						"v": "conn"
					},
					{
						"id": 64,
						"s": "2024-03-07T16:00:00",
						"e": "2024-03-07T16:15:00",
						"v": "conn"
					},
					{
						"id": 65,
						"s": "2024-03-07T16:15:00",
						"e": "2024-03-07T16:30:00",
						"v": "conn"
					},
					{
						"id": 66,
						"s": "2024-03-07T16:30:00",
						"e": "2024-03-07T16:45:00",
						"v": "conn"
					},
					{
						"id": 67,
						"s": "2024-03-07T16:45:00",
						"e": "2024-03-07T17:00:00",
						"v": "conn"
					},
					{
						"id": 68,
						"s": "2024-03-07T17:00:00",
						"e": "2024-03-07T17:15:00",
						"v": "conn"
					},
					{
						"id": 69,
						"s": "2024-03-07T17:15:00",
						"e": "2024-03-07T17:30:00",
						"v": "conn"
					},
					{
						"id": 70,
						"s": "2024-03-07T17:30:00",
						"e": "2024-03-07T17:45:00",
						"v": "conn"
					},
					{
						"id": 71,
						"s": "2024-03-07T17:45:00",
						"e": "2024-03-07T18:00:00",
						"v": "conn"
					},
					{
						"id": 72,
						"s": "2024-03-07T18:00:00",
						"e": "2024-03-07T18:15:00",
						"v": "conn"
					},
					{
						"id": 73,
						"s": "2024-03-07T18:15:00",
						"e": "2024-03-07T18:30:00",
						"v": "conn"
					},
					{
						"id": 74,
						"s": "2024-03-07T18:30:00",
						"e": "2024-03-07T18:45:00",
						"v": "conn"
					},
					{
						"id": 75,
						"s": "2024-03-07T18:45:00",
						"e": "2024-03-07T19:00:00",
						"v": "conn"
					},
					{
						"id": 76,
						"s": "2024-03-07T19:00:00",
						"e": "2024-03-07T19:15:00",
						"v": "conn"
					},
					{
						"id": 77,
						"s": "2024-03-07T19:15:00",
						"e": "2024-03-07T19:30:00",
						"v": "conn"
					},
					{
						"id": 78,
						"s": "2024-03-07T19:30:00",
						"e": "2024-03-07T19:45:00",
						"v": "conn"
					},
					{
						"id": 79,
						"s": "2024-03-07T19:45:00",
						"e": "2024-03-07T20:00:00",
						"v": "conn"
					},
					{
						"id": 80,
						"s": "2024-03-07T20:00:00",
						"e": "2024-03-07T20:15:00",
						"v": "conn"
					},
					{
						"id": 81,
						"s": "2024-03-07T20:15:00",
						"e": "2024-03-07T20:30:00",
						"v": "conn"
					},
					{
						"id": 82,
						"s": "2024-03-07T20:30:00",
						"e": "2024-03-07T20:45:00",
						"v": "conn"
					},
					{
						"id": 83,
						"s": "2024-03-07T20:45:00",
						"e": "2024-03-07T21:00:00",
						"v": "conn"
					},
					{
						"id": 84,
						"s": "2024-03-07T21:00:00",
						"e": "2024-03-07T21:15:00",
						"v": "conn"
					},
					{
						"id": 85,
						"s": "2024-03-07T21:15:00",
						"e": "2024-03-07T21:30:00",
						"v": "conn"
					},
					{
						"id": 86,
						"s": "2024-03-07T21:30:00",
						"e": "2024-03-07T21:45:00",
						"v": "conn"
					},
					{
						"id": 87,
						"s": "2024-03-07T21:45:00",
						"e": "2024-03-07T22:00:00",
						"v": "conn"
					},
					{
						"id": 88,
						"s": "2024-03-07T22:00:00",
						"e": "2024-03-07T22:15:00",
						"v": "conn"
					},
					{
						"id": 89,
						"s": "2024-03-07T22:15:00",
						"e": "2024-03-07T22:30:00",
						"v": "conn"
					},
					{
						"id": 90,
						"s": "2024-03-07T22:30:00",
						"e": "2024-03-07T22:45:00",
						"v": "conn"
					},
					{
						"id": 91,
						"s": "2024-03-07T22:45:00",
						"e": "2024-03-07T23:00:00",
						"v": "conn"
					},
					{
						"id": 92,
						"s": "2024-03-07T23:00:00",
						"e": "2024-03-07T23:15:00",
						"v": "conn"
					},
					{
						"id": 93,
						"s": "2024-03-07T23:15:00",
						"e": "2024-03-07T23:30:00",
						"v": "conn"
					},
					{
						"id": 94,
						"s": "2024-03-07T23:30:00",
						"e": "2024-03-07T23:45:00",
						"v": "conn"
					},
					{
						"id": 95,
						"s": "2024-03-07T23:45:00",
						"e": "2024-03-08T00:00:00",
						"v": "conn"
					}
				]
			},
			{
				"id": 20,
				"date": "8 Mar 2024",
				"data": [
					{
						"id": 0,
						"s": "2024-03-08T00:00:00",
						"e": "2024-03-08T00:15:00",
						"v": "conn"
					},
					{
						"id": 1,
						"s": "2024-03-08T00:15:00",
						"e": "2024-03-08T00:30:00",
						"v": "conn"
					},
					{
						"id": 2,
						"s": "2024-03-08T00:30:00",
						"e": "2024-03-08T00:45:00",
						"v": "conn"
					},
					{
						"id": 3,
						"s": "2024-03-08T00:45:00",
						"e": "2024-03-08T01:00:00",
						"v": "conn"
					},
					{
						"id": 4,
						"s": "2024-03-08T01:00:00",
						"e": "2024-03-08T01:15:00",
						"v": "conn"
					},
					{
						"id": 5,
						"s": "2024-03-08T01:15:00",
						"e": "2024-03-08T01:30:00",
						"v": "conn"
					},
					{
						"id": 6,
						"s": "2024-03-08T01:30:00",
						"e": "2024-03-08T01:45:00",
						"v": "conn"
					},
					{
						"id": 7,
						"s": "2024-03-08T01:45:00",
						"e": "2024-03-08T02:00:00",
						"v": "conn"
					},
					{
						"id": 8,
						"s": "2024-03-08T02:00:00",
						"e": "2024-03-08T02:15:00",
						"v": "conn"
					},
					{
						"id": 9,
						"s": "2024-03-08T02:15:00",
						"e": "2024-03-08T02:30:00",
						"v": "conn"
					},
					{
						"id": 10,
						"s": "2024-03-08T02:30:00",
						"e": "2024-03-08T02:45:00",
						"v": "conn"
					},
					{
						"id": 11,
						"s": "2024-03-08T02:45:00",
						"e": "2024-03-08T03:00:00",
						"v": "conn"
					},
					{
						"id": 12,
						"s": "2024-03-08T03:00:00",
						"e": "2024-03-08T03:15:00",
						"v": "conn"
					},
					{
						"id": 13,
						"s": "2024-03-08T03:15:00",
						"e": "2024-03-08T03:30:00",
						"v": "conn"
					},
					{
						"id": 14,
						"s": "2024-03-08T03:30:00",
						"e": "2024-03-08T03:45:00",
						"v": "conn"
					},
					{
						"id": 15,
						"s": "2024-03-08T03:45:00",
						"e": "2024-03-08T04:00:00",
						"v": "conn"
					},
					{
						"id": 16,
						"s": "2024-03-08T04:00:00",
						"e": "2024-03-08T04:15:00",
						"v": "conn"
					},
					{
						"id": 17,
						"s": "2024-03-08T04:15:00",
						"e": "2024-03-08T04:30:00",
						"v": "conn"
					},
					{
						"id": 18,
						"s": "2024-03-08T04:30:00",
						"e": "2024-03-08T04:45:00",
						"v": "conn"
					},
					{
						"id": 19,
						"s": "2024-03-08T04:45:00",
						"e": "2024-03-08T05:00:00",
						"v": "conn"
					},
					{
						"id": 20,
						"s": "2024-03-08T05:00:00",
						"e": "2024-03-08T05:15:00",
						"v": "conn"
					},
					{
						"id": 21,
						"s": "2024-03-08T05:15:00",
						"e": "2024-03-08T05:30:00",
						"v": "conn"
					},
					{
						"id": 22,
						"s": "2024-03-08T05:30:00",
						"e": "2024-03-08T05:45:00",
						"v": "conn"
					},
					{
						"id": 23,
						"s": "2024-03-08T05:45:00",
						"e": "2024-03-08T06:00:00",
						"v": "conn"
					},
					{
						"id": 24,
						"s": "2024-03-08T06:00:00",
						"e": "2024-03-08T06:15:00",
						"v": "conn"
					},
					{
						"id": 25,
						"s": "2024-03-08T06:15:00",
						"e": "2024-03-08T06:30:00",
						"v": "conn"
					},
					{
						"id": 26,
						"s": "2024-03-08T06:30:00",
						"e": "2024-03-08T06:45:00",
						"v": "conn"
					},
					{
						"id": 27,
						"s": "2024-03-08T06:45:00",
						"e": "2024-03-08T07:00:00",
						"v": "conn"
					},
					{
						"id": 28,
						"s": "2024-03-08T07:00:00",
						"e": "2024-03-08T07:15:00",
						"v": "conn"
					},
					{
						"id": 29,
						"s": "2024-03-08T07:15:00",
						"e": "2024-03-08T07:30:00",
						"v": "conn"
					},
					{
						"id": 30,
						"s": "2024-03-08T07:30:00",
						"e": "2024-03-08T07:45:00",
						"v": "conn"
					},
					{
						"id": 31,
						"s": "2024-03-08T07:45:00",
						"e": "2024-03-08T08:00:00",
						"v": "conn"
					},
					{
						"id": 32,
						"s": "2024-03-08T08:00:00",
						"e": "2024-03-08T08:15:00",
						"v": "conn"
					},
					{
						"id": 33,
						"s": "2024-03-08T08:15:00",
						"e": "2024-03-08T08:30:00",
						"v": "conn"
					},
					{
						"id": 34,
						"s": "2024-03-08T08:30:00",
						"e": "2024-03-08T08:45:00",
						"v": "conn"
					},
					{
						"id": 35,
						"s": "2024-03-08T08:45:00",
						"e": "2024-03-08T09:00:00",
						"v": "conn"
					},
					{
						"id": 36,
						"s": "2024-03-08T09:00:00",
						"e": "2024-03-08T09:15:00",
						"v": "conn"
					},
					{
						"id": 37,
						"s": "2024-03-08T09:15:00",
						"e": "2024-03-08T09:30:00",
						"v": "conn"
					},
					{
						"id": 38,
						"s": "2024-03-08T09:30:00",
						"e": "2024-03-08T09:45:00",
						"v": "conn"
					},
					{
						"id": 39,
						"s": "2024-03-08T09:45:00",
						"e": "2024-03-08T10:00:00",
						"v": "conn"
					},
					{
						"id": 40,
						"s": "2024-03-08T10:00:00",
						"e": "2024-03-08T10:15:00",
						"v": "conn"
					},
					{
						"id": 41,
						"s": "2024-03-08T10:15:00",
						"e": "2024-03-08T10:30:00",
						"v": "conn"
					},
					{
						"id": 42,
						"s": "2024-03-08T10:30:00",
						"e": "2024-03-08T10:45:00",
						"v": "conn"
					},
					{
						"id": 43,
						"s": "2024-03-08T10:45:00",
						"e": "2024-03-08T11:00:00",
						"v": "conn"
					},
					{
						"id": 44,
						"s": "2024-03-08T11:00:00",
						"e": "2024-03-08T11:15:00",
						"v": "conn"
					},
					{
						"id": 45,
						"s": "2024-03-08T11:15:00",
						"e": "2024-03-08T11:30:00",
						"v": "conn"
					},
					{
						"id": 46,
						"s": "2024-03-08T11:30:00",
						"e": "2024-03-08T11:45:00",
						"v": "conn"
					},
					{
						"id": 47,
						"s": "2024-03-08T11:45:00",
						"e": "2024-03-08T12:00:00",
						"v": "conn"
					},
					{
						"id": 48,
						"s": "2024-03-08T12:00:00",
						"e": "2024-03-08T12:15:00",
						"v": "conn"
					},
					{
						"id": 49,
						"s": "2024-03-08T12:15:00",
						"e": "2024-03-08T12:30:00",
						"v": "conn"
					},
					{
						"id": 50,
						"s": "2024-03-08T12:30:00",
						"e": "2024-03-08T12:45:00",
						"v": "conn"
					},
					{
						"id": 51,
						"s": "2024-03-08T12:45:00",
						"e": "2024-03-08T13:00:00",
						"v": "conn"
					},
					{
						"id": 52,
						"s": "2024-03-08T13:00:00",
						"e": "2024-03-08T13:15:00",
						"v": "conn"
					},
					{
						"id": 53,
						"s": "2024-03-08T13:15:00",
						"e": "2024-03-08T13:30:00",
						"v": "conn"
					},
					{
						"id": 54,
						"s": "2024-03-08T13:30:00",
						"e": "2024-03-08T13:45:00",
						"v": "conn"
					},
					{
						"id": 55,
						"s": "2024-03-08T13:45:00",
						"e": "2024-03-08T14:00:00",
						"v": "conn"
					},
					{
						"id": 56,
						"s": "2024-03-08T14:00:00",
						"e": "2024-03-08T14:15:00",
						"v": "conn"
					},
					{
						"id": 57,
						"s": "2024-03-08T14:15:00",
						"e": "2024-03-08T14:30:00",
						"v": "conn"
					},
					{
						"id": 58,
						"s": "2024-03-08T14:30:00",
						"e": "2024-03-08T14:45:00",
						"v": "conn"
					},
					{
						"id": 59,
						"s": "2024-03-08T14:45:00",
						"e": "2024-03-08T15:00:00",
						"v": "conn"
					},
					{
						"id": 60,
						"s": "2024-03-08T15:00:00",
						"e": "2024-03-08T15:15:00",
						"v": "conn"
					},
					{
						"id": 61,
						"s": "2024-03-08T15:15:00",
						"e": "2024-03-08T15:30:00",
						"v": "conn"
					},
					{
						"id": 62,
						"s": "2024-03-08T15:30:00",
						"e": "2024-03-08T15:45:00",
						"v": "conn"
					},
					{
						"id": 63,
						"s": "2024-03-08T15:45:00",
						"e": "2024-03-08T16:00:00",
						"v": "conn"
					},
					{
						"id": 64,
						"s": "2024-03-08T16:00:00",
						"e": "2024-03-08T16:15:00",
						"v": "conn"
					},
					{
						"id": 65,
						"s": "2024-03-08T16:15:00",
						"e": "2024-03-08T16:30:00",
						"v": "conn"
					},
					{
						"id": 66,
						"s": "2024-03-08T16:30:00",
						"e": "2024-03-08T16:45:00",
						"v": "conn"
					},
					{
						"id": 67,
						"s": "2024-03-08T16:45:00",
						"e": "2024-03-08T17:00:00",
						"v": "conn"
					},
					{
						"id": 68,
						"s": "2024-03-08T17:00:00",
						"e": "2024-03-08T17:15:00",
						"v": "conn"
					},
					{
						"id": 69,
						"s": "2024-03-08T17:15:00",
						"e": "2024-03-08T17:30:00",
						"v": "conn"
					},
					{
						"id": 70,
						"s": "2024-03-08T17:30:00",
						"e": "2024-03-08T17:45:00",
						"v": "conn"
					},
					{
						"id": 71,
						"s": "2024-03-08T17:45:00",
						"e": "2024-03-08T18:00:00",
						"v": "conn"
					},
					{
						"id": 72,
						"s": "2024-03-08T18:00:00",
						"e": "2024-03-08T18:15:00",
						"v": "conn"
					},
					{
						"id": 73,
						"s": "2024-03-08T18:15:00",
						"e": "2024-03-08T18:30:00",
						"v": "conn"
					},
					{
						"id": 74,
						"s": "2024-03-08T18:30:00",
						"e": "2024-03-08T18:45:00",
						"v": "conn"
					},
					{
						"id": 75,
						"s": "2024-03-08T18:45:00",
						"e": "2024-03-08T19:00:00",
						"v": "conn"
					},
					{
						"id": 76,
						"s": "2024-03-08T19:00:00",
						"e": "2024-03-08T19:15:00",
						"v": "conn"
					},
					{
						"id": 77,
						"s": "2024-03-08T19:15:00",
						"e": "2024-03-08T19:30:00",
						"v": "conn"
					},
					{
						"id": 78,
						"s": "2024-03-08T19:30:00",
						"e": "2024-03-08T19:45:00",
						"v": "conn"
					},
					{
						"id": 79,
						"s": "2024-03-08T19:45:00",
						"e": "2024-03-08T20:00:00",
						"v": "conn"
					},
					{
						"id": 80,
						"s": "2024-03-08T20:00:00",
						"e": "2024-03-08T20:15:00",
						"v": "conn"
					},
					{
						"id": 81,
						"s": "2024-03-08T20:15:00",
						"e": "2024-03-08T20:30:00",
						"v": "conn"
					},
					{
						"id": 82,
						"s": "2024-03-08T20:30:00",
						"e": "2024-03-08T20:45:00",
						"v": "conn"
					},
					{
						"id": 83,
						"s": "2024-03-08T20:45:00",
						"e": "2024-03-08T21:00:00",
						"v": "conn"
					},
					{
						"id": 84,
						"s": "2024-03-08T21:00:00",
						"e": "2024-03-08T21:15:00",
						"v": "conn"
					},
					{
						"id": 85,
						"s": "2024-03-08T21:15:00",
						"e": "2024-03-08T21:30:00",
						"v": "conn"
					},
					{
						"id": 86,
						"s": "2024-03-08T21:30:00",
						"e": "2024-03-08T21:45:00",
						"v": "conn"
					},
					{
						"id": 87,
						"s": "2024-03-08T21:45:00",
						"e": "2024-03-08T22:00:00",
						"v": "conn"
					},
					{
						"id": 88,
						"s": "2024-03-08T22:00:00",
						"e": "2024-03-08T22:15:00",
						"v": "conn"
					},
					{
						"id": 89,
						"s": "2024-03-08T22:15:00",
						"e": "2024-03-08T22:30:00",
						"v": "conn"
					},
					{
						"id": 90,
						"s": "2024-03-08T22:30:00",
						"e": "2024-03-08T22:45:00",
						"v": "conn"
					},
					{
						"id": 91,
						"s": "2024-03-08T22:45:00",
						"e": "2024-03-08T23:00:00",
						"v": "conn"
					},
					{
						"id": 92,
						"s": "2024-03-08T23:00:00",
						"e": "2024-03-08T23:15:00",
						"v": "conn"
					},
					{
						"id": 93,
						"s": "2024-03-08T23:15:00",
						"e": "2024-03-08T23:30:00",
						"v": "conn"
					},
					{
						"id": 94,
						"s": "2024-03-08T23:30:00",
						"e": "2024-03-08T23:45:00",
						"v": "conn"
					},
					{
						"id": 95,
						"s": "2024-03-08T23:45:00",
						"e": "2024-03-09T00:00:00",
						"v": "conn"
					}
				]
			},
			{
				"id": 21,
				"date": "9 Mar 2024",
				"data": [
					{
						"id": 0,
						"s": "2024-03-09T00:00:00",
						"e": "2024-03-09T00:15:00",
						"v": "conn"
					},
					{
						"id": 1,
						"s": "2024-03-09T00:15:00",
						"e": "2024-03-09T00:30:00",
						"v": "conn"
					},
					{
						"id": 2,
						"s": "2024-03-09T00:30:00",
						"e": "2024-03-09T00:45:00",
						"v": "conn"
					},
					{
						"id": 3,
						"s": "2024-03-09T00:45:00",
						"e": "2024-03-09T01:00:00",
						"v": "conn"
					},
					{
						"id": 4,
						"s": "2024-03-09T01:00:00",
						"e": "2024-03-09T01:15:00",
						"v": "conn"
					},
					{
						"id": 5,
						"s": "2024-03-09T01:15:00",
						"e": "2024-03-09T01:30:00",
						"v": "conn"
					},
					{
						"id": 6,
						"s": "2024-03-09T01:30:00",
						"e": "2024-03-09T01:45:00",
						"v": "conn"
					},
					{
						"id": 7,
						"s": "2024-03-09T01:45:00",
						"e": "2024-03-09T02:00:00",
						"v": "conn"
					},
					{
						"id": 8,
						"s": "2024-03-09T02:00:00",
						"e": "2024-03-09T02:15:00",
						"v": "conn"
					},
					{
						"id": 9,
						"s": "2024-03-09T02:15:00",
						"e": "2024-03-09T02:30:00",
						"v": "conn"
					},
					{
						"id": 10,
						"s": "2024-03-09T02:30:00",
						"e": "2024-03-09T02:45:00",
						"v": "conn"
					},
					{
						"id": 11,
						"s": "2024-03-09T02:45:00",
						"e": "2024-03-09T03:00:00",
						"v": "conn"
					},
					{
						"id": 12,
						"s": "2024-03-09T03:00:00",
						"e": "2024-03-09T03:15:00",
						"v": "conn"
					},
					{
						"id": 13,
						"s": "2024-03-09T03:15:00",
						"e": "2024-03-09T03:30:00",
						"v": "conn"
					},
					{
						"id": 14,
						"s": "2024-03-09T03:30:00",
						"e": "2024-03-09T03:45:00",
						"v": "conn"
					},
					{
						"id": 15,
						"s": "2024-03-09T03:45:00",
						"e": "2024-03-09T04:00:00",
						"v": "conn"
					},
					{
						"id": 16,
						"s": "2024-03-09T04:00:00",
						"e": "2024-03-09T04:15:00",
						"v": "conn"
					},
					{
						"id": 17,
						"s": "2024-03-09T04:15:00",
						"e": "2024-03-09T04:30:00",
						"v": "conn"
					},
					{
						"id": 18,
						"s": "2024-03-09T04:30:00",
						"e": "2024-03-09T04:45:00",
						"v": "conn"
					},
					{
						"id": 19,
						"s": "2024-03-09T04:45:00",
						"e": "2024-03-09T05:00:00",
						"v": "conn"
					},
					{
						"id": 20,
						"s": "2024-03-09T05:00:00",
						"e": "2024-03-09T05:15:00",
						"v": "conn"
					},
					{
						"id": 21,
						"s": "2024-03-09T05:15:00",
						"e": "2024-03-09T05:30:00",
						"v": "conn"
					},
					{
						"id": 22,
						"s": "2024-03-09T05:30:00",
						"e": "2024-03-09T05:45:00",
						"v": "conn"
					},
					{
						"id": 23,
						"s": "2024-03-09T05:45:00",
						"e": "2024-03-09T06:00:00",
						"v": "conn"
					},
					{
						"id": 24,
						"s": "2024-03-09T06:00:00",
						"e": "2024-03-09T06:15:00",
						"v": "conn"
					},
					{
						"id": 25,
						"s": "2024-03-09T06:15:00",
						"e": "2024-03-09T06:30:00",
						"v": "conn"
					},
					{
						"id": 26,
						"s": "2024-03-09T06:30:00",
						"e": "2024-03-09T06:45:00",
						"v": "conn"
					},
					{
						"id": 27,
						"s": "2024-03-09T06:45:00",
						"e": "2024-03-09T07:00:00",
						"v": "conn"
					},
					{
						"id": 28,
						"s": "2024-03-09T07:00:00",
						"e": "2024-03-09T07:15:00",
						"v": "conn"
					},
					{
						"id": 29,
						"s": "2024-03-09T07:15:00",
						"e": "2024-03-09T07:30:00",
						"v": "conn"
					},
					{
						"id": 30,
						"s": "2024-03-09T07:30:00",
						"e": "2024-03-09T07:45:00",
						"v": "conn"
					},
					{
						"id": 31,
						"s": "2024-03-09T07:45:00",
						"e": "2024-03-09T08:00:00",
						"v": "conn"
					},
					{
						"id": 32,
						"s": "2024-03-09T08:00:00",
						"e": "2024-03-09T08:15:00",
						"v": "conn"
					},
					{
						"id": 33,
						"s": "2024-03-09T08:15:00",
						"e": "2024-03-09T08:30:00",
						"v": "conn"
					},
					{
						"id": 34,
						"s": "2024-03-09T08:30:00",
						"e": "2024-03-09T08:45:00",
						"v": "conn"
					},
					{
						"id": 35,
						"s": "2024-03-09T08:45:00",
						"e": "2024-03-09T09:00:00",
						"v": "conn"
					},
					{
						"id": 36,
						"s": "2024-03-09T09:00:00",
						"e": "2024-03-09T09:15:00",
						"v": "conn"
					},
					{
						"id": 37,
						"s": "2024-03-09T09:15:00",
						"e": "2024-03-09T09:30:00",
						"v": "conn"
					},
					{
						"id": 38,
						"s": "2024-03-09T09:30:00",
						"e": "2024-03-09T09:45:00",
						"v": "conn"
					},
					{
						"id": 39,
						"s": "2024-03-09T09:45:00",
						"e": "2024-03-09T10:00:00",
						"v": "conn"
					},
					{
						"id": 40,
						"s": "2024-03-09T10:00:00",
						"e": "2024-03-09T10:15:00",
						"v": "conn"
					},
					{
						"id": 41,
						"s": "2024-03-09T10:15:00",
						"e": "2024-03-09T10:30:00",
						"v": "conn"
					},
					{
						"id": 42,
						"s": "2024-03-09T10:30:00",
						"e": "2024-03-09T10:45:00",
						"v": "conn"
					},
					{
						"id": 43,
						"s": "2024-03-09T10:45:00",
						"e": "2024-03-09T11:00:00",
						"v": "conn"
					},
					{
						"id": 44,
						"s": "2024-03-09T11:00:00",
						"e": "2024-03-09T11:15:00",
						"v": "conn"
					},
					{
						"id": 45,
						"s": "2024-03-09T11:15:00",
						"e": "2024-03-09T11:30:00",
						"v": "conn"
					},
					{
						"id": 46,
						"s": "2024-03-09T11:30:00",
						"e": "2024-03-09T11:45:00",
						"v": "conn"
					},
					{
						"id": 47,
						"s": "2024-03-09T11:45:00",
						"e": "2024-03-09T12:00:00",
						"v": "conn"
					},
					{
						"id": 48,
						"s": "2024-03-09T12:00:00",
						"e": "2024-03-09T12:15:00",
						"v": "conn"
					},
					{
						"id": 49,
						"s": "2024-03-09T12:15:00",
						"e": "2024-03-09T12:30:00",
						"v": "conn"
					},
					{
						"id": 50,
						"s": "2024-03-09T12:30:00",
						"e": "2024-03-09T12:45:00",
						"v": "conn"
					},
					{
						"id": 51,
						"s": "2024-03-09T12:45:00",
						"e": "2024-03-09T13:00:00",
						"v": "conn"
					},
					{
						"id": 52,
						"s": "2024-03-09T13:00:00",
						"e": "2024-03-09T13:15:00",
						"v": "conn"
					},
					{
						"id": 53,
						"s": "2024-03-09T13:15:00",
						"e": "2024-03-09T13:30:00",
						"v": "conn"
					},
					{
						"id": 54,
						"s": "2024-03-09T13:30:00",
						"e": "2024-03-09T13:45:00",
						"v": "conn"
					},
					{
						"id": 55,
						"s": "2024-03-09T13:45:00",
						"e": "2024-03-09T14:00:00",
						"v": "conn"
					},
					{
						"id": 56,
						"s": "2024-03-09T14:00:00",
						"e": "2024-03-09T14:15:00",
						"v": "conn"
					},
					{
						"id": 57,
						"s": "2024-03-09T14:15:00",
						"e": "2024-03-09T14:30:00",
						"v": "conn"
					},
					{
						"id": 58,
						"s": "2024-03-09T14:30:00",
						"e": "2024-03-09T14:45:00",
						"v": "conn"
					},
					{
						"id": 59,
						"s": "2024-03-09T14:45:00",
						"e": "2024-03-09T15:00:00",
						"v": "conn"
					},
					{
						"id": 60,
						"s": "2024-03-09T15:00:00",
						"e": "2024-03-09T15:15:00",
						"v": "conn"
					},
					{
						"id": 61,
						"s": "2024-03-09T15:15:00",
						"e": "2024-03-09T15:30:00",
						"v": "conn"
					},
					{
						"id": 62,
						"s": "2024-03-09T15:30:00",
						"e": "2024-03-09T15:45:00",
						"v": "conn"
					},
					{
						"id": 63,
						"s": "2024-03-09T15:45:00",
						"e": "2024-03-09T16:00:00",
						"v": "conn"
					},
					{
						"id": 64,
						"s": "2024-03-09T16:00:00",
						"e": "2024-03-09T16:15:00",
						"v": "conn"
					},
					{
						"id": 65,
						"s": "2024-03-09T16:15:00",
						"e": "2024-03-09T16:30:00",
						"v": "conn"
					},
					{
						"id": 66,
						"s": "2024-03-09T16:30:00",
						"e": "2024-03-09T16:45:00",
						"v": "conn"
					},
					{
						"id": 67,
						"s": "2024-03-09T16:45:00",
						"e": "2024-03-09T17:00:00",
						"v": "conn"
					},
					{
						"id": 68,
						"s": "2024-03-09T17:00:00",
						"e": "2024-03-09T17:15:00",
						"v": "conn"
					},
					{
						"id": 69,
						"s": "2024-03-09T17:15:00",
						"e": "2024-03-09T17:30:00",
						"v": "conn"
					},
					{
						"id": 70,
						"s": "2024-03-09T17:30:00",
						"e": "2024-03-09T17:45:00",
						"v": "conn"
					},
					{
						"id": 71,
						"s": "2024-03-09T17:45:00",
						"e": "2024-03-09T18:00:00",
						"v": "conn"
					},
					{
						"id": 72,
						"s": "2024-03-09T18:00:00",
						"e": "2024-03-09T18:15:00",
						"v": "conn"
					},
					{
						"id": 73,
						"s": "2024-03-09T18:15:00",
						"e": "2024-03-09T18:30:00",
						"v": "conn"
					},
					{
						"id": 74,
						"s": "2024-03-09T18:30:00",
						"e": "2024-03-09T18:45:00",
						"v": "conn"
					},
					{
						"id": 75,
						"s": "2024-03-09T18:45:00",
						"e": "2024-03-09T19:00:00",
						"v": "conn"
					},
					{
						"id": 76,
						"s": "2024-03-09T19:00:00",
						"e": "2024-03-09T19:15:00",
						"v": "conn"
					},
					{
						"id": 77,
						"s": "2024-03-09T19:15:00",
						"e": "2024-03-09T19:30:00",
						"v": "conn"
					},
					{
						"id": 78,
						"s": "2024-03-09T19:30:00",
						"e": "2024-03-09T19:45:00",
						"v": "conn"
					},
					{
						"id": 79,
						"s": "2024-03-09T19:45:00",
						"e": "2024-03-09T20:00:00",
						"v": "conn"
					},
					{
						"id": 80,
						"s": "2024-03-09T20:00:00",
						"e": "2024-03-09T20:15:00",
						"v": "conn"
					},
					{
						"id": 81,
						"s": "2024-03-09T20:15:00",
						"e": "2024-03-09T20:30:00",
						"v": "conn"
					},
					{
						"id": 82,
						"s": "2024-03-09T20:30:00",
						"e": "2024-03-09T20:45:00",
						"v": "conn"
					},
					{
						"id": 83,
						"s": "2024-03-09T20:45:00",
						"e": "2024-03-09T21:00:00",
						"v": "conn"
					},
					{
						"id": 84,
						"s": "2024-03-09T21:00:00",
						"e": "2024-03-09T21:15:00",
						"v": "conn"
					},
					{
						"id": 85,
						"s": "2024-03-09T21:15:00",
						"e": "2024-03-09T21:30:00",
						"v": "conn"
					},
					{
						"id": 86,
						"s": "2024-03-09T21:30:00",
						"e": "2024-03-09T21:45:00",
						"v": "conn"
					},
					{
						"id": 87,
						"s": "2024-03-09T21:45:00",
						"e": "2024-03-09T22:00:00",
						"v": "conn"
					},
					{
						"id": 88,
						"s": "2024-03-09T22:00:00",
						"e": "2024-03-09T22:15:00",
						"v": "conn"
					},
					{
						"id": 89,
						"s": "2024-03-09T22:15:00",
						"e": "2024-03-09T22:30:00",
						"v": "conn"
					},
					{
						"id": 90,
						"s": "2024-03-09T22:30:00",
						"e": "2024-03-09T22:45:00",
						"v": "conn"
					},
					{
						"id": 91,
						"s": "2024-03-09T22:45:00",
						"e": "2024-03-09T23:00:00",
						"v": "conn"
					},
					{
						"id": 92,
						"s": "2024-03-09T23:00:00",
						"e": "2024-03-09T23:15:00",
						"v": "conn"
					},
					{
						"id": 93,
						"s": "2024-03-09T23:15:00",
						"e": "2024-03-09T23:30:00",
						"v": "conn"
					},
					{
						"id": 94,
						"s": "2024-03-09T23:30:00",
						"e": "2024-03-09T23:45:00",
						"v": "conn"
					},
					{
						"id": 95,
						"s": "2024-03-09T23:45:00",
						"e": "2024-03-10T00:00:00",
						"v": "conn"
					}
				]
			},
			{
				"id": 22,
				"date": "10 Mar 2024",
				"data": [
					{
						"id": 0,
						"s": "2024-03-10T00:00:00",
						"e": "2024-03-10T00:15:00",
						"v": "conn"
					},
					{
						"id": 1,
						"s": "2024-03-10T00:15:00",
						"e": "2024-03-10T00:30:00",
						"v": "conn"
					},
					{
						"id": 2,
						"s": "2024-03-10T00:30:00",
						"e": "2024-03-10T00:45:00",
						"v": "conn"
					},
					{
						"id": 3,
						"s": "2024-03-10T00:45:00",
						"e": "2024-03-10T01:00:00",
						"v": "conn"
					},
					{
						"id": 4,
						"s": "2024-03-10T01:00:00",
						"e": "2024-03-10T01:15:00",
						"v": "conn"
					},
					{
						"id": 5,
						"s": "2024-03-10T01:15:00",
						"e": "2024-03-10T01:30:00",
						"v": "conn"
					},
					{
						"id": 6,
						"s": "2024-03-10T01:30:00",
						"e": "2024-03-10T01:45:00",
						"v": "conn"
					},
					{
						"id": 7,
						"s": "2024-03-10T01:45:00",
						"e": "2024-03-10T02:00:00",
						"v": "conn"
					},
					{
						"id": 8,
						"s": "2024-03-10T02:00:00",
						"e": "2024-03-10T02:15:00",
						"v": "conn"
					},
					{
						"id": 9,
						"s": "2024-03-10T02:15:00",
						"e": "2024-03-10T02:30:00",
						"v": "conn"
					},
					{
						"id": 10,
						"s": "2024-03-10T02:30:00",
						"e": "2024-03-10T02:45:00",
						"v": "conn"
					},
					{
						"id": 11,
						"s": "2024-03-10T02:45:00",
						"e": "2024-03-10T03:00:00",
						"v": "conn"
					},
					{
						"id": 12,
						"s": "2024-03-10T03:00:00",
						"e": "2024-03-10T03:15:00",
						"v": "conn"
					},
					{
						"id": 13,
						"s": "2024-03-10T03:15:00",
						"e": "2024-03-10T03:30:00",
						"v": "conn"
					},
					{
						"id": 14,
						"s": "2024-03-10T03:30:00",
						"e": "2024-03-10T03:45:00",
						"v": "conn"
					},
					{
						"id": 15,
						"s": "2024-03-10T03:45:00",
						"e": "2024-03-10T04:00:00",
						"v": "conn"
					},
					{
						"id": 16,
						"s": "2024-03-10T04:00:00",
						"e": "2024-03-10T04:15:00",
						"v": "conn"
					},
					{
						"id": 17,
						"s": "2024-03-10T04:15:00",
						"e": "2024-03-10T04:30:00",
						"v": "conn"
					},
					{
						"id": 18,
						"s": "2024-03-10T04:30:00",
						"e": "2024-03-10T04:45:00",
						"v": "conn"
					},
					{
						"id": 19,
						"s": "2024-03-10T04:45:00",
						"e": "2024-03-10T05:00:00",
						"v": "conn"
					},
					{
						"id": 20,
						"s": "2024-03-10T05:00:00",
						"e": "2024-03-10T05:15:00",
						"v": "conn"
					},
					{
						"id": 21,
						"s": "2024-03-10T05:15:00",
						"e": "2024-03-10T05:30:00",
						"v": "conn"
					},
					{
						"id": 22,
						"s": "2024-03-10T05:30:00",
						"e": "2024-03-10T05:45:00",
						"v": "conn"
					},
					{
						"id": 23,
						"s": "2024-03-10T05:45:00",
						"e": "2024-03-10T06:00:00",
						"v": "conn"
					},
					{
						"id": 24,
						"s": "2024-03-10T06:00:00",
						"e": "2024-03-10T06:15:00",
						"v": "conn"
					},
					{
						"id": 25,
						"s": "2024-03-10T06:15:00",
						"e": "2024-03-10T06:30:00",
						"v": "conn"
					},
					{
						"id": 26,
						"s": "2024-03-10T06:30:00",
						"e": "2024-03-10T06:45:00",
						"v": "conn"
					},
					{
						"id": 27,
						"s": "2024-03-10T06:45:00",
						"e": "2024-03-10T07:00:00",
						"v": "conn"
					},
					{
						"id": 28,
						"s": "2024-03-10T07:00:00",
						"e": "2024-03-10T07:15:00",
						"v": "conn"
					},
					{
						"id": 29,
						"s": "2024-03-10T07:15:00",
						"e": "2024-03-10T07:30:00",
						"v": "conn"
					},
					{
						"id": 30,
						"s": "2024-03-10T07:30:00",
						"e": "2024-03-10T07:45:00",
						"v": "conn"
					},
					{
						"id": 31,
						"s": "2024-03-10T07:45:00",
						"e": "2024-03-10T08:00:00",
						"v": "conn"
					},
					{
						"id": 32,
						"s": "2024-03-10T08:00:00",
						"e": "2024-03-10T08:15:00",
						"v": "conn"
					},
					{
						"id": 33,
						"s": "2024-03-10T08:15:00",
						"e": "2024-03-10T08:30:00",
						"v": "conn"
					},
					{
						"id": 34,
						"s": "2024-03-10T08:30:00",
						"e": "2024-03-10T08:45:00",
						"v": "conn"
					},
					{
						"id": 35,
						"s": "2024-03-10T08:45:00",
						"e": "2024-03-10T09:00:00",
						"v": "conn"
					},
					{
						"id": 36,
						"s": "2024-03-10T09:00:00",
						"e": "2024-03-10T09:15:00",
						"v": "conn"
					},
					{
						"id": 37,
						"s": "2024-03-10T09:15:00",
						"e": "2024-03-10T09:30:00",
						"v": "conn"
					},
					{
						"id": 38,
						"s": "2024-03-10T09:30:00",
						"e": "2024-03-10T09:45:00",
						"v": "conn"
					},
					{
						"id": 39,
						"s": "2024-03-10T09:45:00",
						"e": "2024-03-10T10:00:00",
						"v": "conn"
					},
					{
						"id": 40,
						"s": "2024-03-10T10:00:00",
						"e": "2024-03-10T10:15:00",
						"v": "conn"
					},
					{
						"id": 41,
						"s": "2024-03-10T10:15:00",
						"e": "2024-03-10T10:30:00",
						"v": "conn"
					},
					{
						"id": 42,
						"s": "2024-03-10T10:30:00",
						"e": "2024-03-10T10:45:00",
						"v": "conn"
					},
					{
						"id": 43,
						"s": "2024-03-10T10:45:00",
						"e": "2024-03-10T11:00:00",
						"v": "conn"
					},
					{
						"id": 44,
						"s": "2024-03-10T11:00:00",
						"e": "2024-03-10T11:15:00",
						"v": "conn"
					},
					{
						"id": 45,
						"s": "2024-03-10T11:15:00",
						"e": "2024-03-10T11:30:00",
						"v": "conn"
					},
					{
						"id": 46,
						"s": "2024-03-10T11:30:00",
						"e": "2024-03-10T11:45:00",
						"v": "conn"
					},
					{
						"id": 47,
						"s": "2024-03-10T11:45:00",
						"e": "2024-03-10T12:00:00",
						"v": "conn"
					},
					{
						"id": 48,
						"s": "2024-03-10T12:00:00",
						"e": "2024-03-10T12:15:00",
						"v": "conn"
					},
					{
						"id": 49,
						"s": "2024-03-10T12:15:00",
						"e": "2024-03-10T12:30:00",
						"v": "conn"
					},
					{
						"id": 50,
						"s": "2024-03-10T12:30:00",
						"e": "2024-03-10T12:45:00",
						"v": "conn"
					},
					{
						"id": 51,
						"s": "2024-03-10T12:45:00",
						"e": "2024-03-10T13:00:00",
						"v": "conn"
					},
					{
						"id": 52,
						"s": "2024-03-10T13:00:00",
						"e": "2024-03-10T13:15:00",
						"v": "conn"
					},
					{
						"id": 53,
						"s": "2024-03-10T13:15:00",
						"e": "2024-03-10T13:30:00",
						"v": "conn"
					},
					{
						"id": 54,
						"s": "2024-03-10T13:30:00",
						"e": "2024-03-10T13:45:00",
						"v": "conn"
					},
					{
						"id": 55,
						"s": "2024-03-10T13:45:00",
						"e": "2024-03-10T14:00:00",
						"v": "conn"
					},
					{
						"id": 56,
						"s": "2024-03-10T14:00:00",
						"e": "2024-03-10T14:15:00",
						"v": "conn"
					},
					{
						"id": 57,
						"s": "2024-03-10T14:15:00",
						"e": "2024-03-10T14:30:00",
						"v": "conn"
					},
					{
						"id": 58,
						"s": "2024-03-10T14:30:00",
						"e": "2024-03-10T14:45:00",
						"v": "conn"
					},
					{
						"id": 59,
						"s": "2024-03-10T14:45:00",
						"e": "2024-03-10T15:00:00",
						"v": "conn"
					},
					{
						"id": 60,
						"s": "2024-03-10T15:00:00",
						"e": "2024-03-10T15:15:00",
						"v": "conn"
					},
					{
						"id": 61,
						"s": "2024-03-10T15:15:00",
						"e": "2024-03-10T15:30:00",
						"v": "conn"
					},
					{
						"id": 62,
						"s": "2024-03-10T15:30:00",
						"e": "2024-03-10T15:45:00",
						"v": "conn"
					},
					{
						"id": 63,
						"s": "2024-03-10T15:45:00",
						"e": "2024-03-10T16:00:00",
						"v": "conn"
					},
					{
						"id": 64,
						"s": "2024-03-10T16:00:00",
						"e": "2024-03-10T16:15:00",
						"v": "conn"
					},
					{
						"id": 65,
						"s": "2024-03-10T16:15:00",
						"e": "2024-03-10T16:30:00",
						"v": "conn"
					},
					{
						"id": 66,
						"s": "2024-03-10T16:30:00",
						"e": "2024-03-10T16:45:00",
						"v": "conn"
					},
					{
						"id": 67,
						"s": "2024-03-10T16:45:00",
						"e": "2024-03-10T17:00:00",
						"v": "conn"
					},
					{
						"id": 68,
						"s": "2024-03-10T17:00:00",
						"e": "2024-03-10T17:15:00",
						"v": "conn"
					},
					{
						"id": 69,
						"s": "2024-03-10T17:15:00",
						"e": "2024-03-10T17:30:00",
						"v": "conn"
					},
					{
						"id": 70,
						"s": "2024-03-10T17:30:00",
						"e": "2024-03-10T17:45:00",
						"v": "conn"
					},
					{
						"id": 71,
						"s": "2024-03-10T17:45:00",
						"e": "2024-03-10T18:00:00",
						"v": "conn"
					},
					{
						"id": 72,
						"s": "2024-03-10T18:00:00",
						"e": "2024-03-10T18:15:00",
						"v": "reco"
					},
					{
						"id": 73,
						"s": "2024-03-10T18:15:00",
						"e": "2024-03-10T18:30:00",
						"v": "conn"
					},
					{
						"id": 74,
						"s": "2024-03-10T18:30:00",
						"e": "2024-03-10T18:45:00",
						"v": "conn"
					},
					{
						"id": 75,
						"s": "2024-03-10T18:45:00",
						"e": "2024-03-10T19:00:00",
						"v": "conn"
					},
					{
						"id": 76,
						"s": "2024-03-10T19:00:00",
						"e": "2024-03-10T19:15:00",
						"v": "conn"
					},
					{
						"id": 77,
						"s": "2024-03-10T19:15:00",
						"e": "2024-03-10T19:30:00",
						"v": "conn"
					},
					{
						"id": 78,
						"s": "2024-03-10T19:30:00",
						"e": "2024-03-10T19:45:00",
						"v": "conn"
					},
					{
						"id": 79,
						"s": "2024-03-10T19:45:00",
						"e": "2024-03-10T20:00:00",
						"v": "conn"
					},
					{
						"id": 80,
						"s": "2024-03-10T20:00:00",
						"e": "2024-03-10T20:15:00",
						"v": "conn"
					},
					{
						"id": 81,
						"s": "2024-03-10T20:15:00",
						"e": "2024-03-10T20:30:00",
						"v": "conn"
					},
					{
						"id": 82,
						"s": "2024-03-10T20:30:00",
						"e": "2024-03-10T20:45:00",
						"v": "conn"
					},
					{
						"id": 83,
						"s": "2024-03-10T20:45:00",
						"e": "2024-03-10T21:00:00",
						"v": "conn"
					},
					{
						"id": 84,
						"s": "2024-03-10T21:00:00",
						"e": "2024-03-10T21:15:00",
						"v": "conn"
					},
					{
						"id": 85,
						"s": "2024-03-10T21:15:00",
						"e": "2024-03-10T21:30:00",
						"v": "conn"
					},
					{
						"id": 86,
						"s": "2024-03-10T21:30:00",
						"e": "2024-03-10T21:45:00",
						"v": "conn"
					},
					{
						"id": 87,
						"s": "2024-03-10T21:45:00",
						"e": "2024-03-10T22:00:00",
						"v": "conn"
					},
					{
						"id": 88,
						"s": "2024-03-10T22:00:00",
						"e": "2024-03-10T22:15:00",
						"v": "conn"
					},
					{
						"id": 89,
						"s": "2024-03-10T22:15:00",
						"e": "2024-03-10T22:30:00",
						"v": "conn"
					},
					{
						"id": 90,
						"s": "2024-03-10T22:30:00",
						"e": "2024-03-10T22:45:00",
						"v": "conn"
					},
					{
						"id": 91,
						"s": "2024-03-10T22:45:00",
						"e": "2024-03-10T23:00:00",
						"v": "conn"
					},
					{
						"id": 92,
						"s": "2024-03-10T23:00:00",
						"e": "2024-03-10T23:15:00",
						"v": "conn"
					},
					{
						"id": 93,
						"s": "2024-03-10T23:15:00",
						"e": "2024-03-10T23:30:00",
						"v": "conn"
					},
					{
						"id": 94,
						"s": "2024-03-10T23:30:00",
						"e": "2024-03-10T23:45:00",
						"v": "conn"
					},
					{
						"id": 95,
						"s": "2024-03-10T23:45:00",
						"e": "2024-03-11T00:00:00",
						"v": "conn"
					}
				]
			},
			{
				"id": 23,
				"date": "11 Mar 2024",
				"data": [
					{
						"id": 0,
						"s": "2024-03-11T00:00:00",
						"e": "2024-03-11T00:15:00",
						"v": "conn"
					},
					{
						"id": 1,
						"s": "2024-03-11T00:15:00",
						"e": "2024-03-11T00:30:00",
						"v": "conn"
					},
					{
						"id": 2,
						"s": "2024-03-11T00:30:00",
						"e": "2024-03-11T00:45:00",
						"v": "conn"
					},
					{
						"id": 3,
						"s": "2024-03-11T00:45:00",
						"e": "2024-03-11T01:00:00",
						"v": "conn"
					},
					{
						"id": 4,
						"s": "2024-03-11T01:00:00",
						"e": "2024-03-11T01:15:00",
						"v": "conn"
					},
					{
						"id": 5,
						"s": "2024-03-11T01:15:00",
						"e": "2024-03-11T01:30:00",
						"v": "conn"
					},
					{
						"id": 6,
						"s": "2024-03-11T01:30:00",
						"e": "2024-03-11T01:45:00",
						"v": "conn"
					},
					{
						"id": 7,
						"s": "2024-03-11T01:45:00",
						"e": "2024-03-11T02:00:00",
						"v": "conn"
					},
					{
						"id": 8,
						"s": "2024-03-11T02:00:00",
						"e": "2024-03-11T02:15:00",
						"v": "conn"
					},
					{
						"id": 9,
						"s": "2024-03-11T02:15:00",
						"e": "2024-03-11T02:30:00",
						"v": "conn"
					},
					{
						"id": 10,
						"s": "2024-03-11T02:30:00",
						"e": "2024-03-11T02:45:00",
						"v": "conn"
					},
					{
						"id": 11,
						"s": "2024-03-11T02:45:00",
						"e": "2024-03-11T03:00:00",
						"v": "conn"
					},
					{
						"id": 12,
						"s": "2024-03-11T03:00:00",
						"e": "2024-03-11T03:15:00",
						"v": "conn"
					},
					{
						"id": 13,
						"s": "2024-03-11T03:15:00",
						"e": "2024-03-11T03:30:00",
						"v": "conn"
					},
					{
						"id": 14,
						"s": "2024-03-11T03:30:00",
						"e": "2024-03-11T03:45:00",
						"v": "conn"
					},
					{
						"id": 15,
						"s": "2024-03-11T03:45:00",
						"e": "2024-03-11T04:00:00",
						"v": "conn"
					},
					{
						"id": 16,
						"s": "2024-03-11T04:00:00",
						"e": "2024-03-11T04:15:00",
						"v": "conn"
					},
					{
						"id": 17,
						"s": "2024-03-11T04:15:00",
						"e": "2024-03-11T04:30:00",
						"v": "conn"
					},
					{
						"id": 18,
						"s": "2024-03-11T04:30:00",
						"e": "2024-03-11T04:45:00",
						"v": "conn"
					},
					{
						"id": 19,
						"s": "2024-03-11T04:45:00",
						"e": "2024-03-11T05:00:00",
						"v": "conn"
					},
					{
						"id": 20,
						"s": "2024-03-11T05:00:00",
						"e": "2024-03-11T05:15:00",
						"v": "conn"
					},
					{
						"id": 21,
						"s": "2024-03-11T05:15:00",
						"e": "2024-03-11T05:30:00",
						"v": "conn"
					},
					{
						"id": 22,
						"s": "2024-03-11T05:30:00",
						"e": "2024-03-11T05:45:00",
						"v": "conn"
					},
					{
						"id": 23,
						"s": "2024-03-11T05:45:00",
						"e": "2024-03-11T06:00:00",
						"v": "conn"
					},
					{
						"id": 24,
						"s": "2024-03-11T06:00:00",
						"e": "2024-03-11T06:15:00",
						"v": "conn"
					},
					{
						"id": 25,
						"s": "2024-03-11T06:15:00",
						"e": "2024-03-11T06:30:00",
						"v": "conn"
					},
					{
						"id": 26,
						"s": "2024-03-11T06:30:00",
						"e": "2024-03-11T06:45:00",
						"v": "conn"
					},
					{
						"id": 27,
						"s": "2024-03-11T06:45:00",
						"e": "2024-03-11T07:00:00",
						"v": "conn"
					},
					{
						"id": 28,
						"s": "2024-03-11T07:00:00",
						"e": "2024-03-11T07:15:00",
						"v": "conn"
					},
					{
						"id": 29,
						"s": "2024-03-11T07:15:00",
						"e": "2024-03-11T07:30:00",
						"v": "conn"
					},
					{
						"id": 30,
						"s": "2024-03-11T07:30:00",
						"e": "2024-03-11T07:45:00",
						"v": "conn"
					},
					{
						"id": 31,
						"s": "2024-03-11T07:45:00",
						"e": "2024-03-11T08:00:00",
						"v": "conn"
					},
					{
						"id": 32,
						"s": "2024-03-11T08:00:00",
						"e": "2024-03-11T08:15:00",
						"v": "conn"
					},
					{
						"id": 33,
						"s": "2024-03-11T08:15:00",
						"e": "2024-03-11T08:30:00",
						"v": "conn"
					},
					{
						"id": 34,
						"s": "2024-03-11T08:30:00",
						"e": "2024-03-11T08:45:00",
						"v": "conn"
					},
					{
						"id": 35,
						"s": "2024-03-11T08:45:00",
						"e": "2024-03-11T09:00:00",
						"v": "conn"
					},
					{
						"id": 36,
						"s": "2024-03-11T09:00:00",
						"e": "2024-03-11T09:15:00",
						"v": "conn"
					},
					{
						"id": 37,
						"s": "2024-03-11T09:15:00",
						"e": "2024-03-11T09:30:00",
						"v": "conn"
					},
					{
						"id": 38,
						"s": "2024-03-11T09:30:00",
						"e": "2024-03-11T09:45:00",
						"v": "conn"
					},
					{
						"id": 39,
						"s": "2024-03-11T09:45:00",
						"e": "2024-03-11T10:00:00",
						"v": "conn"
					},
					{
						"id": 40,
						"s": "2024-03-11T10:00:00",
						"e": "2024-03-11T10:15:00",
						"v": "conn"
					},
					{
						"id": 41,
						"s": "2024-03-11T10:15:00",
						"e": "2024-03-11T10:30:00",
						"v": "conn"
					},
					{
						"id": 42,
						"s": "2024-03-11T10:30:00",
						"e": "2024-03-11T10:45:00",
						"v": "conn"
					},
					{
						"id": 43,
						"s": "2024-03-11T10:45:00",
						"e": "2024-03-11T11:00:00",
						"v": "conn"
					},
					{
						"id": 44,
						"s": "2024-03-11T11:00:00",
						"e": "2024-03-11T11:15:00",
						"v": "conn"
					},
					{
						"id": 45,
						"s": "2024-03-11T11:15:00",
						"e": "2024-03-11T11:30:00",
						"v": "conn"
					},
					{
						"id": 46,
						"s": "2024-03-11T11:30:00",
						"e": "2024-03-11T11:45:00",
						"v": "conn"
					},
					{
						"id": 47,
						"s": "2024-03-11T11:45:00",
						"e": "2024-03-11T12:00:00",
						"v": "conn"
					},
					{
						"id": 48,
						"s": "2024-03-11T12:00:00",
						"e": "2024-03-11T12:15:00",
						"v": "conn"
					},
					{
						"id": 49,
						"s": "2024-03-11T12:15:00",
						"e": "2024-03-11T12:30:00",
						"v": "conn"
					},
					{
						"id": 50,
						"s": "2024-03-11T12:30:00",
						"e": "2024-03-11T12:45:00",
						"v": "conn"
					},
					{
						"id": 51,
						"s": "2024-03-11T12:45:00",
						"e": "2024-03-11T13:00:00",
						"v": "conn"
					},
					{
						"id": 52,
						"s": "2024-03-11T13:00:00",
						"e": "2024-03-11T13:15:00",
						"v": "conn"
					},
					{
						"id": 53,
						"s": "2024-03-11T13:15:00",
						"e": "2024-03-11T13:30:00",
						"v": "conn"
					},
					{
						"id": 54,
						"s": "2024-03-11T13:30:00",
						"e": "2024-03-11T13:45:00",
						"v": "conn"
					},
					{
						"id": 55,
						"s": "2024-03-11T13:45:00",
						"e": "2024-03-11T14:00:00",
						"v": "conn"
					},
					{
						"id": 56,
						"s": "2024-03-11T14:00:00",
						"e": "2024-03-11T14:15:00",
						"v": "conn"
					},
					{
						"id": 57,
						"s": "2024-03-11T14:15:00",
						"e": "2024-03-11T14:30:00",
						"v": "conn"
					},
					{
						"id": 58,
						"s": "2024-03-11T14:30:00",
						"e": "2024-03-11T14:45:00",
						"v": "conn"
					},
					{
						"id": 59,
						"s": "2024-03-11T14:45:00",
						"e": "2024-03-11T15:00:00",
						"v": "conn"
					},
					{
						"id": 60,
						"s": "2024-03-11T15:00:00",
						"e": "2024-03-11T15:15:00",
						"v": "conn"
					},
					{
						"id": 61,
						"s": "2024-03-11T15:15:00",
						"e": "2024-03-11T15:30:00",
						"v": "conn"
					},
					{
						"id": 62,
						"s": "2024-03-11T15:30:00",
						"e": "2024-03-11T15:45:00",
						"v": "conn"
					},
					{
						"id": 63,
						"s": "2024-03-11T15:45:00",
						"e": "2024-03-11T16:00:00",
						"v": "conn"
					},
					{
						"id": 64,
						"s": "2024-03-11T16:00:00",
						"e": "2024-03-11T16:15:00",
						"v": "conn"
					},
					{
						"id": 65,
						"s": "2024-03-11T16:15:00",
						"e": "2024-03-11T16:30:00",
						"v": "conn"
					},
					{
						"id": 66,
						"s": "2024-03-11T16:30:00",
						"e": "2024-03-11T16:45:00",
						"v": "conn"
					},
					{
						"id": 67,
						"s": "2024-03-11T16:45:00",
						"e": "2024-03-11T17:00:00",
						"v": "conn"
					},
					{
						"id": 68,
						"s": "2024-03-11T17:00:00",
						"e": "2024-03-11T17:15:00",
						"v": "conn"
					},
					{
						"id": 69,
						"s": "2024-03-11T17:15:00",
						"e": "2024-03-11T17:30:00",
						"v": "conn"
					},
					{
						"id": 70,
						"s": "2024-03-11T17:30:00",
						"e": "2024-03-11T17:45:00",
						"v": "conn"
					},
					{
						"id": 71,
						"s": "2024-03-11T17:45:00",
						"e": "2024-03-11T18:00:00",
						"v": "conn"
					},
					{
						"id": 72,
						"s": "2024-03-11T18:00:00",
						"e": "2024-03-11T18:15:00",
						"v": "conn"
					},
					{
						"id": 73,
						"s": "2024-03-11T18:15:00",
						"e": "2024-03-11T18:30:00",
						"v": "conn"
					},
					{
						"id": 74,
						"s": "2024-03-11T18:30:00",
						"e": "2024-03-11T18:45:00",
						"v": "conn"
					},
					{
						"id": 75,
						"s": "2024-03-11T18:45:00",
						"e": "2024-03-11T19:00:00",
						"v": "conn"
					},
					{
						"id": 76,
						"s": "2024-03-11T19:00:00",
						"e": "2024-03-11T19:15:00",
						"v": "conn"
					},
					{
						"id": 77,
						"s": "2024-03-11T19:15:00",
						"e": "2024-03-11T19:30:00",
						"v": "conn"
					},
					{
						"id": 78,
						"s": "2024-03-11T19:30:00",
						"e": "2024-03-11T19:45:00",
						"v": "conn"
					},
					{
						"id": 79,
						"s": "2024-03-11T19:45:00",
						"e": "2024-03-11T20:00:00",
						"v": "conn"
					},
					{
						"id": 80,
						"s": "2024-03-11T20:00:00",
						"e": "2024-03-11T20:15:00",
						"v": "conn"
					},
					{
						"id": 81,
						"s": "2024-03-11T20:15:00",
						"e": "2024-03-11T20:30:00",
						"v": "conn"
					},
					{
						"id": 82,
						"s": "2024-03-11T20:30:00",
						"e": "2024-03-11T20:45:00",
						"v": "conn"
					},
					{
						"id": 83,
						"s": "2024-03-11T20:45:00",
						"e": "2024-03-11T21:00:00",
						"v": "conn"
					},
					{
						"id": 84,
						"s": "2024-03-11T21:00:00",
						"e": "2024-03-11T21:15:00",
						"v": "conn"
					},
					{
						"id": 85,
						"s": "2024-03-11T21:15:00",
						"e": "2024-03-11T21:30:00",
						"v": "conn"
					},
					{
						"id": 86,
						"s": "2024-03-11T21:30:00",
						"e": "2024-03-11T21:45:00",
						"v": "conn"
					},
					{
						"id": 87,
						"s": "2024-03-11T21:45:00",
						"e": "2024-03-11T22:00:00",
						"v": "conn"
					},
					{
						"id": 88,
						"s": "2024-03-11T22:00:00",
						"e": "2024-03-11T22:15:00",
						"v": "conn"
					},
					{
						"id": 89,
						"s": "2024-03-11T22:15:00",
						"e": "2024-03-11T22:30:00",
						"v": "conn"
					},
					{
						"id": 90,
						"s": "2024-03-11T22:30:00",
						"e": "2024-03-11T22:45:00",
						"v": "conn"
					},
					{
						"id": 91,
						"s": "2024-03-11T22:45:00",
						"e": "2024-03-11T23:00:00",
						"v": "conn"
					},
					{
						"id": 92,
						"s": "2024-03-11T23:00:00",
						"e": "2024-03-11T23:15:00",
						"v": "conn"
					},
					{
						"id": 93,
						"s": "2024-03-11T23:15:00",
						"e": "2024-03-11T23:30:00",
						"v": "conn"
					},
					{
						"id": 94,
						"s": "2024-03-11T23:30:00",
						"e": "2024-03-11T23:45:00",
						"v": "conn"
					},
					{
						"id": 95,
						"s": "2024-03-11T23:45:00",
						"e": "2024-03-12T00:00:00",
						"v": "conn"
					}
				]
			},
			{
				"id": 24,
				"date": "12 Mar 2024",
				"data": [
					{
						"id": 0,
						"s": "2024-03-12T00:00:00",
						"e": "2024-03-12T00:15:00",
						"v": "conn"
					},
					{
						"id": 1,
						"s": "2024-03-12T00:15:00",
						"e": "2024-03-12T00:30:00",
						"v": "conn"
					},
					{
						"id": 2,
						"s": "2024-03-12T00:30:00",
						"e": "2024-03-12T00:45:00",
						"v": "conn"
					},
					{
						"id": 3,
						"s": "2024-03-12T00:45:00",
						"e": "2024-03-12T01:00:00",
						"v": "conn"
					},
					{
						"id": 4,
						"s": "2024-03-12T01:00:00",
						"e": "2024-03-12T01:15:00",
						"v": "conn"
					},
					{
						"id": 5,
						"s": "2024-03-12T01:15:00",
						"e": "2024-03-12T01:30:00",
						"v": "conn"
					},
					{
						"id": 6,
						"s": "2024-03-12T01:30:00",
						"e": "2024-03-12T01:45:00",
						"v": "conn"
					},
					{
						"id": 7,
						"s": "2024-03-12T01:45:00",
						"e": "2024-03-12T02:00:00",
						"v": "conn"
					},
					{
						"id": 8,
						"s": "2024-03-12T02:00:00",
						"e": "2024-03-12T02:15:00",
						"v": "conn"
					},
					{
						"id": 9,
						"s": "2024-03-12T02:15:00",
						"e": "2024-03-12T02:30:00",
						"v": "conn"
					},
					{
						"id": 10,
						"s": "2024-03-12T02:30:00",
						"e": "2024-03-12T02:45:00",
						"v": "conn"
					},
					{
						"id": 11,
						"s": "2024-03-12T02:45:00",
						"e": "2024-03-12T03:00:00",
						"v": "conn"
					},
					{
						"id": 12,
						"s": "2024-03-12T03:00:00",
						"e": "2024-03-12T03:15:00",
						"v": "conn"
					},
					{
						"id": 13,
						"s": "2024-03-12T03:15:00",
						"e": "2024-03-12T03:30:00",
						"v": "conn"
					},
					{
						"id": 14,
						"s": "2024-03-12T03:30:00",
						"e": "2024-03-12T03:45:00",
						"v": "conn"
					},
					{
						"id": 15,
						"s": "2024-03-12T03:45:00",
						"e": "2024-03-12T04:00:00",
						"v": "conn"
					},
					{
						"id": 16,
						"s": "2024-03-12T04:00:00",
						"e": "2024-03-12T04:15:00",
						"v": "conn"
					},
					{
						"id": 17,
						"s": "2024-03-12T04:15:00",
						"e": "2024-03-12T04:30:00",
						"v": "conn"
					},
					{
						"id": 18,
						"s": "2024-03-12T04:30:00",
						"e": "2024-03-12T04:45:00",
						"v": "conn"
					},
					{
						"id": 19,
						"s": "2024-03-12T04:45:00",
						"e": "2024-03-12T05:00:00",
						"v": "conn"
					},
					{
						"id": 20,
						"s": "2024-03-12T05:00:00",
						"e": "2024-03-12T05:15:00",
						"v": "conn"
					},
					{
						"id": 21,
						"s": "2024-03-12T05:15:00",
						"e": "2024-03-12T05:30:00",
						"v": "conn"
					},
					{
						"id": 22,
						"s": "2024-03-12T05:30:00",
						"e": "2024-03-12T05:45:00",
						"v": "conn"
					},
					{
						"id": 23,
						"s": "2024-03-12T05:45:00",
						"e": "2024-03-12T06:00:00",
						"v": "conn"
					},
					{
						"id": 24,
						"s": "2024-03-12T06:00:00",
						"e": "2024-03-12T06:15:00",
						"v": "conn"
					},
					{
						"id": 25,
						"s": "2024-03-12T06:15:00",
						"e": "2024-03-12T06:30:00",
						"v": "conn"
					},
					{
						"id": 26,
						"s": "2024-03-12T06:30:00",
						"e": "2024-03-12T06:45:00",
						"v": "conn"
					},
					{
						"id": 27,
						"s": "2024-03-12T06:45:00",
						"e": "2024-03-12T07:00:00",
						"v": "conn"
					},
					{
						"id": 28,
						"s": "2024-03-12T07:00:00",
						"e": "2024-03-12T07:15:00",
						"v": "conn"
					},
					{
						"id": 29,
						"s": "2024-03-12T07:15:00",
						"e": "2024-03-12T07:30:00",
						"v": "conn"
					},
					{
						"id": 30,
						"s": "2024-03-12T07:30:00",
						"e": "2024-03-12T07:45:00",
						"v": "conn"
					},
					{
						"id": 31,
						"s": "2024-03-12T07:45:00",
						"e": "2024-03-12T08:00:00",
						"v": "conn"
					},
					{
						"id": 32,
						"s": "2024-03-12T08:00:00",
						"e": "2024-03-12T08:15:00",
						"v": "conn"
					},
					{
						"id": 33,
						"s": "2024-03-12T08:15:00",
						"e": "2024-03-12T08:30:00",
						"v": "conn"
					},
					{
						"id": 34,
						"s": "2024-03-12T08:30:00",
						"e": "2024-03-12T08:45:00",
						"v": "conn"
					},
					{
						"id": 35,
						"s": "2024-03-12T08:45:00",
						"e": "2024-03-12T09:00:00",
						"v": "conn"
					},
					{
						"id": 36,
						"s": "2024-03-12T09:00:00",
						"e": "2024-03-12T09:15:00",
						"v": "conn"
					},
					{
						"id": 37,
						"s": "2024-03-12T09:15:00",
						"e": "2024-03-12T09:30:00",
						"v": "conn"
					},
					{
						"id": 38,
						"s": "2024-03-12T09:30:00",
						"e": "2024-03-12T09:45:00",
						"v": "conn"
					},
					{
						"id": 39,
						"s": "2024-03-12T09:45:00",
						"e": "2024-03-12T10:00:00",
						"v": "conn"
					},
					{
						"id": 40,
						"s": "2024-03-12T10:00:00",
						"e": "2024-03-12T10:15:00",
						"v": "conn"
					},
					{
						"id": 41,
						"s": "2024-03-12T10:15:00",
						"e": "2024-03-12T10:30:00",
						"v": "conn"
					},
					{
						"id": 42,
						"s": "2024-03-12T10:30:00",
						"e": "2024-03-12T10:45:00",
						"v": "conn"
					},
					{
						"id": 43,
						"s": "2024-03-12T10:45:00",
						"e": "2024-03-12T11:00:00",
						"v": "conn"
					},
					{
						"id": 44,
						"s": "2024-03-12T11:00:00",
						"e": "2024-03-12T11:15:00",
						"v": "conn"
					},
					{
						"id": 45,
						"s": "2024-03-12T11:15:00",
						"e": "2024-03-12T11:30:00",
						"v": "conn"
					},
					{
						"id": 46,
						"s": "2024-03-12T11:30:00",
						"e": "2024-03-12T11:45:00",
						"v": "conn"
					},
					{
						"id": 47,
						"s": "2024-03-12T11:45:00",
						"e": "2024-03-12T12:00:00",
						"v": "conn"
					},
					{
						"id": 48,
						"s": "2024-03-12T12:00:00",
						"e": "2024-03-12T12:15:00",
						"v": "conn"
					},
					{
						"id": 49,
						"s": "2024-03-12T12:15:00",
						"e": "2024-03-12T12:30:00",
						"v": "conn"
					},
					{
						"id": 50,
						"s": "2024-03-12T12:30:00",
						"e": "2024-03-12T12:45:00",
						"v": "conn"
					},
					{
						"id": 51,
						"s": "2024-03-12T12:45:00",
						"e": "2024-03-12T13:00:00",
						"v": "conn"
					},
					{
						"id": 52,
						"s": "2024-03-12T13:00:00",
						"e": "2024-03-12T13:15:00",
						"v": "conn"
					},
					{
						"id": 53,
						"s": "2024-03-12T13:15:00",
						"e": "2024-03-12T13:30:00",
						"v": "conn"
					},
					{
						"id": 54,
						"s": "2024-03-12T13:30:00",
						"e": "2024-03-12T13:45:00",
						"v": "conn"
					},
					{
						"id": 55,
						"s": "2024-03-12T13:45:00",
						"e": "2024-03-12T14:00:00",
						"v": "conn"
					},
					{
						"id": 56,
						"s": "2024-03-12T14:00:00",
						"e": "2024-03-12T14:15:00",
						"v": "conn"
					},
					{
						"id": 57,
						"s": "2024-03-12T14:15:00",
						"e": "2024-03-12T14:30:00",
						"v": "conn"
					},
					{
						"id": 58,
						"s": "2024-03-12T14:30:00",
						"e": "2024-03-12T14:45:00",
						"v": "conn"
					},
					{
						"id": 59,
						"s": "2024-03-12T14:45:00",
						"e": "2024-03-12T15:00:00",
						"v": "conn"
					},
					{
						"id": 60,
						"s": "2024-03-12T15:00:00",
						"e": "2024-03-12T15:15:00",
						"v": "conn"
					},
					{
						"id": 61,
						"s": "2024-03-12T15:15:00",
						"e": "2024-03-12T15:30:00",
						"v": "conn"
					},
					{
						"id": 62,
						"s": "2024-03-12T15:30:00",
						"e": "2024-03-12T15:45:00",
						"v": "conn"
					},
					{
						"id": 63,
						"s": "2024-03-12T15:45:00",
						"e": "2024-03-12T16:00:00",
						"v": "conn"
					},
					{
						"id": 64,
						"s": "2024-03-12T16:00:00",
						"e": "2024-03-12T16:15:00",
						"v": "conn"
					},
					{
						"id": 65,
						"s": "2024-03-12T16:15:00",
						"e": "2024-03-12T16:30:00",
						"v": "conn"
					},
					{
						"id": 66,
						"s": "2024-03-12T16:30:00",
						"e": "2024-03-12T16:45:00",
						"v": "conn"
					},
					{
						"id": 67,
						"s": "2024-03-12T16:45:00",
						"e": "2024-03-12T17:00:00",
						"v": "conn"
					},
					{
						"id": 68,
						"s": "2024-03-12T17:00:00",
						"e": "2024-03-12T17:15:00",
						"v": "conn"
					},
					{
						"id": 69,
						"s": "2024-03-12T17:15:00",
						"e": "2024-03-12T17:30:00",
						"v": "conn"
					},
					{
						"id": 70,
						"s": "2024-03-12T17:30:00",
						"e": "2024-03-12T17:45:00",
						"v": "conn"
					},
					{
						"id": 71,
						"s": "2024-03-12T17:45:00",
						"e": "2024-03-12T18:00:00",
						"v": "conn"
					},
					{
						"id": 72,
						"s": "2024-03-12T18:00:00",
						"e": "2024-03-12T18:15:00",
						"v": "conn"
					},
					{
						"id": 73,
						"s": "2024-03-12T18:15:00",
						"e": "2024-03-12T18:30:00",
						"v": "conn"
					},
					{
						"id": 74,
						"s": "2024-03-12T18:30:00",
						"e": "2024-03-12T18:45:00",
						"v": "conn"
					},
					{
						"id": 75,
						"s": "2024-03-12T18:45:00",
						"e": "2024-03-12T19:00:00",
						"v": "conn"
					},
					{
						"id": 76,
						"s": "2024-03-12T19:00:00",
						"e": "2024-03-12T19:15:00",
						"v": "conn"
					},
					{
						"id": 77,
						"s": "2024-03-12T19:15:00",
						"e": "2024-03-12T19:30:00",
						"v": "conn"
					},
					{
						"id": 78,
						"s": "2024-03-12T19:30:00",
						"e": "2024-03-12T19:45:00",
						"v": "conn"
					},
					{
						"id": 79,
						"s": "2024-03-12T19:45:00",
						"e": "2024-03-12T20:00:00",
						"v": "conn"
					},
					{
						"id": 80,
						"s": "2024-03-12T20:00:00",
						"e": "2024-03-12T20:15:00",
						"v": "conn"
					},
					{
						"id": 81,
						"s": "2024-03-12T20:15:00",
						"e": "2024-03-12T20:30:00",
						"v": "conn"
					},
					{
						"id": 82,
						"s": "2024-03-12T20:30:00",
						"e": "2024-03-12T20:45:00",
						"v": "conn"
					},
					{
						"id": 83,
						"s": "2024-03-12T20:45:00",
						"e": "2024-03-12T21:00:00",
						"v": "conn"
					},
					{
						"id": 84,
						"s": "2024-03-12T21:00:00",
						"e": "2024-03-12T21:15:00",
						"v": "conn"
					},
					{
						"id": 85,
						"s": "2024-03-12T21:15:00",
						"e": "2024-03-12T21:30:00",
						"v": "conn"
					},
					{
						"id": 86,
						"s": "2024-03-12T21:30:00",
						"e": "2024-03-12T21:45:00",
						"v": "conn"
					},
					{
						"id": 87,
						"s": "2024-03-12T21:45:00",
						"e": "2024-03-12T22:00:00",
						"v": "conn"
					},
					{
						"id": 88,
						"s": "2024-03-12T22:00:00",
						"e": "2024-03-12T22:15:00",
						"v": "conn"
					},
					{
						"id": 89,
						"s": "2024-03-12T22:15:00",
						"e": "2024-03-12T22:30:00",
						"v": "conn"
					},
					{
						"id": 90,
						"s": "2024-03-12T22:30:00",
						"e": "2024-03-12T22:45:00",
						"v": "conn"
					},
					{
						"id": 91,
						"s": "2024-03-12T22:45:00",
						"e": "2024-03-12T23:00:00",
						"v": "conn"
					},
					{
						"id": 92,
						"s": "2024-03-12T23:00:00",
						"e": "2024-03-12T23:15:00",
						"v": "conn"
					},
					{
						"id": 93,
						"s": "2024-03-12T23:15:00",
						"e": "2024-03-12T23:30:00",
						"v": "conn"
					},
					{
						"id": 94,
						"s": "2024-03-12T23:30:00",
						"e": "2024-03-12T23:45:00",
						"v": "conn"
					},
					{
						"id": 95,
						"s": "2024-03-12T23:45:00",
						"e": "2024-03-13T00:00:00",
						"v": "conn"
					}
				]
			},
			{
				"id": 25,
				"date": "13 Mar 2024",
				"data": [
					{
						"id": 0,
						"s": "2024-03-13T00:00:00",
						"e": "2024-03-13T00:15:00",
						"v": "conn"
					},
					{
						"id": 1,
						"s": "2024-03-13T00:15:00",
						"e": "2024-03-13T00:30:00",
						"v": "conn"
					},
					{
						"id": 2,
						"s": "2024-03-13T00:30:00",
						"e": "2024-03-13T00:45:00",
						"v": "conn"
					},
					{
						"id": 3,
						"s": "2024-03-13T00:45:00",
						"e": "2024-03-13T01:00:00",
						"v": "conn"
					},
					{
						"id": 4,
						"s": "2024-03-13T01:00:00",
						"e": "2024-03-13T01:15:00",
						"v": "conn"
					},
					{
						"id": 5,
						"s": "2024-03-13T01:15:00",
						"e": "2024-03-13T01:30:00",
						"v": "conn"
					},
					{
						"id": 6,
						"s": "2024-03-13T01:30:00",
						"e": "2024-03-13T01:45:00",
						"v": "conn"
					},
					{
						"id": 7,
						"s": "2024-03-13T01:45:00",
						"e": "2024-03-13T02:00:00",
						"v": "conn"
					},
					{
						"id": 8,
						"s": "2024-03-13T02:00:00",
						"e": "2024-03-13T02:15:00",
						"v": "conn"
					},
					{
						"id": 9,
						"s": "2024-03-13T02:15:00",
						"e": "2024-03-13T02:30:00",
						"v": "conn"
					},
					{
						"id": 10,
						"s": "2024-03-13T02:30:00",
						"e": "2024-03-13T02:45:00",
						"v": "conn"
					},
					{
						"id": 11,
						"s": "2024-03-13T02:45:00",
						"e": "2024-03-13T03:00:00",
						"v": "conn"
					},
					{
						"id": 12,
						"s": "2024-03-13T03:00:00",
						"e": "2024-03-13T03:15:00",
						"v": "conn"
					},
					{
						"id": 13,
						"s": "2024-03-13T03:15:00",
						"e": "2024-03-13T03:30:00",
						"v": "conn"
					},
					{
						"id": 14,
						"s": "2024-03-13T03:30:00",
						"e": "2024-03-13T03:45:00",
						"v": "conn"
					},
					{
						"id": 15,
						"s": "2024-03-13T03:45:00",
						"e": "2024-03-13T04:00:00",
						"v": "conn"
					},
					{
						"id": 16,
						"s": "2024-03-13T04:00:00",
						"e": "2024-03-13T04:15:00",
						"v": "conn"
					},
					{
						"id": 17,
						"s": "2024-03-13T04:15:00",
						"e": "2024-03-13T04:30:00",
						"v": "conn"
					},
					{
						"id": 18,
						"s": "2024-03-13T04:30:00",
						"e": "2024-03-13T04:45:00",
						"v": "conn"
					},
					{
						"id": 19,
						"s": "2024-03-13T04:45:00",
						"e": "2024-03-13T05:00:00",
						"v": "conn"
					},
					{
						"id": 20,
						"s": "2024-03-13T05:00:00",
						"e": "2024-03-13T05:15:00",
						"v": "conn"
					},
					{
						"id": 21,
						"s": "2024-03-13T05:15:00",
						"e": "2024-03-13T05:30:00",
						"v": "conn"
					},
					{
						"id": 22,
						"s": "2024-03-13T05:30:00",
						"e": "2024-03-13T05:45:00",
						"v": "conn"
					},
					{
						"id": 23,
						"s": "2024-03-13T05:45:00",
						"e": "2024-03-13T06:00:00",
						"v": "conn"
					},
					{
						"id": 24,
						"s": "2024-03-13T06:00:00",
						"e": "2024-03-13T06:15:00",
						"v": "conn"
					},
					{
						"id": 25,
						"s": "2024-03-13T06:15:00",
						"e": "2024-03-13T06:30:00",
						"v": "conn"
					},
					{
						"id": 26,
						"s": "2024-03-13T06:30:00",
						"e": "2024-03-13T06:45:00",
						"v": "conn"
					},
					{
						"id": 27,
						"s": "2024-03-13T06:45:00",
						"e": "2024-03-13T07:00:00",
						"v": "conn"
					},
					{
						"id": 28,
						"s": "2024-03-13T07:00:00",
						"e": "2024-03-13T07:15:00",
						"v": "conn"
					},
					{
						"id": 29,
						"s": "2024-03-13T07:15:00",
						"e": "2024-03-13T07:30:00",
						"v": "conn"
					},
					{
						"id": 30,
						"s": "2024-03-13T07:30:00",
						"e": "2024-03-13T07:45:00",
						"v": "conn"
					},
					{
						"id": 31,
						"s": "2024-03-13T07:45:00",
						"e": "2024-03-13T08:00:00",
						"v": "conn"
					},
					{
						"id": 32,
						"s": "2024-03-13T08:00:00",
						"e": "2024-03-13T08:15:00",
						"v": "conn"
					},
					{
						"id": 33,
						"s": "2024-03-13T08:15:00",
						"e": "2024-03-13T08:30:00",
						"v": "conn"
					},
					{
						"id": 34,
						"s": "2024-03-13T08:30:00",
						"e": "2024-03-13T08:45:00",
						"v": "conn"
					},
					{
						"id": 35,
						"s": "2024-03-13T08:45:00",
						"e": "2024-03-13T09:00:00",
						"v": "conn"
					},
					{
						"id": 36,
						"s": "2024-03-13T09:00:00",
						"e": "2024-03-13T09:15:00",
						"v": "conn"
					},
					{
						"id": 37,
						"s": "2024-03-13T09:15:00",
						"e": "2024-03-13T09:30:00",
						"v": "conn"
					},
					{
						"id": 38,
						"s": "2024-03-13T09:30:00",
						"e": "2024-03-13T09:45:00",
						"v": "conn"
					},
					{
						"id": 39,
						"s": "2024-03-13T09:45:00",
						"e": "2024-03-13T10:00:00",
						"v": "conn"
					},
					{
						"id": 40,
						"s": "2024-03-13T10:00:00",
						"e": "2024-03-13T10:15:00",
						"v": "conn"
					},
					{
						"id": 41,
						"s": "2024-03-13T10:15:00",
						"e": "2024-03-13T10:30:00",
						"v": "conn"
					},
					{
						"id": 42,
						"s": "2024-03-13T10:30:00",
						"e": "2024-03-13T10:45:00",
						"v": "conn"
					},
					{
						"id": 43,
						"s": "2024-03-13T10:45:00",
						"e": "2024-03-13T11:00:00",
						"v": "conn"
					},
					{
						"id": 44,
						"s": "2024-03-13T11:00:00",
						"e": "2024-03-13T11:15:00",
						"v": "conn"
					},
					{
						"id": 45,
						"s": "2024-03-13T11:15:00",
						"e": "2024-03-13T11:30:00",
						"v": "conn"
					},
					{
						"id": 46,
						"s": "2024-03-13T11:30:00",
						"e": "2024-03-13T11:45:00",
						"v": "conn"
					},
					{
						"id": 47,
						"s": "2024-03-13T11:45:00",
						"e": "2024-03-13T12:00:00",
						"v": "conn"
					},
					{
						"id": 48,
						"s": "2024-03-13T12:00:00",
						"e": "2024-03-13T12:15:00",
						"v": "conn"
					},
					{
						"id": 49,
						"s": "2024-03-13T12:15:00",
						"e": "2024-03-13T12:30:00",
						"v": "conn"
					},
					{
						"id": 50,
						"s": "2024-03-13T12:30:00",
						"e": "2024-03-13T12:45:00",
						"v": "conn"
					},
					{
						"id": 51,
						"s": "2024-03-13T12:45:00",
						"e": "2024-03-13T13:00:00",
						"v": "conn"
					},
					{
						"id": 52,
						"s": "2024-03-13T13:00:00",
						"e": "2024-03-13T13:15:00",
						"v": "conn"
					},
					{
						"id": 53,
						"s": "2024-03-13T13:15:00",
						"e": "2024-03-13T13:30:00",
						"v": "conn"
					},
					{
						"id": 54,
						"s": "2024-03-13T13:30:00",
						"e": "2024-03-13T13:45:00",
						"v": "conn"
					},
					{
						"id": 55,
						"s": "2024-03-13T13:45:00",
						"e": "2024-03-13T14:00:00",
						"v": "conn"
					},
					{
						"id": 56,
						"s": "2024-03-13T14:00:00",
						"e": "2024-03-13T14:15:00",
						"v": "conn"
					},
					{
						"id": 57,
						"s": "2024-03-13T14:15:00",
						"e": "2024-03-13T14:30:00",
						"v": "conn"
					},
					{
						"id": 58,
						"s": "2024-03-13T14:30:00",
						"e": "2024-03-13T14:45:00",
						"v": "conn"
					},
					{
						"id": 59,
						"s": "2024-03-13T14:45:00",
						"e": "2024-03-13T15:00:00",
						"v": "conn"
					},
					{
						"id": 60,
						"s": "2024-03-13T15:00:00",
						"e": "2024-03-13T15:15:00",
						"v": "conn"
					},
					{
						"id": 61,
						"s": "2024-03-13T15:15:00",
						"e": "2024-03-13T15:30:00",
						"v": "conn"
					},
					{
						"id": 62,
						"s": "2024-03-13T15:30:00",
						"e": "2024-03-13T15:45:00",
						"v": "conn"
					},
					{
						"id": 63,
						"s": "2024-03-13T15:45:00",
						"e": "2024-03-13T16:00:00",
						"v": "conn"
					},
					{
						"id": 64,
						"s": "2024-03-13T16:00:00",
						"e": "2024-03-13T16:15:00",
						"v": "conn"
					},
					{
						"id": 65,
						"s": "2024-03-13T16:15:00",
						"e": "2024-03-13T16:30:00",
						"v": "conn"
					},
					{
						"id": 66,
						"s": "2024-03-13T16:30:00",
						"e": "2024-03-13T16:45:00",
						"v": "conn"
					},
					{
						"id": 67,
						"s": "2024-03-13T16:45:00",
						"e": "2024-03-13T17:00:00",
						"v": "conn"
					},
					{
						"id": 68,
						"s": "2024-03-13T17:00:00",
						"e": "2024-03-13T17:15:00",
						"v": "conn"
					},
					{
						"id": 69,
						"s": "2024-03-13T17:15:00",
						"e": "2024-03-13T17:30:00",
						"v": "conn"
					},
					{
						"id": 70,
						"s": "2024-03-13T17:30:00",
						"e": "2024-03-13T17:45:00",
						"v": "conn"
					},
					{
						"id": 71,
						"s": "2024-03-13T17:45:00",
						"e": "2024-03-13T18:00:00",
						"v": "conn"
					},
					{
						"id": 72,
						"s": "2024-03-13T18:00:00",
						"e": "2024-03-13T18:15:00",
						"v": "conn"
					},
					{
						"id": 73,
						"s": "2024-03-13T18:15:00",
						"e": "2024-03-13T18:30:00",
						"v": "conn"
					},
					{
						"id": 74,
						"s": "2024-03-13T18:30:00",
						"e": "2024-03-13T18:45:00",
						"v": "conn"
					},
					{
						"id": 75,
						"s": "2024-03-13T18:45:00",
						"e": "2024-03-13T19:00:00",
						"v": "conn"
					},
					{
						"id": 76,
						"s": "2024-03-13T19:00:00",
						"e": "2024-03-13T19:15:00",
						"v": "conn"
					},
					{
						"id": 77,
						"s": "2024-03-13T19:15:00",
						"e": "2024-03-13T19:30:00",
						"v": "conn"
					},
					{
						"id": 78,
						"s": "2024-03-13T19:30:00",
						"e": "2024-03-13T19:45:00",
						"v": "conn"
					},
					{
						"id": 79,
						"s": "2024-03-13T19:45:00",
						"e": "2024-03-13T20:00:00",
						"v": "conn"
					},
					{
						"id": 80,
						"s": "2024-03-13T20:00:00",
						"e": "2024-03-13T20:15:00",
						"v": "conn"
					},
					{
						"id": 81,
						"s": "2024-03-13T20:15:00",
						"e": "2024-03-13T20:30:00",
						"v": "conn"
					},
					{
						"id": 82,
						"s": "2024-03-13T20:30:00",
						"e": "2024-03-13T20:45:00",
						"v": "conn"
					},
					{
						"id": 83,
						"s": "2024-03-13T20:45:00",
						"e": "2024-03-13T21:00:00",
						"v": "conn"
					},
					{
						"id": 84,
						"s": "2024-03-13T21:00:00",
						"e": "2024-03-13T21:15:00",
						"v": "conn"
					},
					{
						"id": 85,
						"s": "2024-03-13T21:15:00",
						"e": "2024-03-13T21:30:00",
						"v": "conn"
					},
					{
						"id": 86,
						"s": "2024-03-13T21:30:00",
						"e": "2024-03-13T21:45:00",
						"v": "conn"
					},
					{
						"id": 87,
						"s": "2024-03-13T21:45:00",
						"e": "2024-03-13T22:00:00",
						"v": "conn"
					},
					{
						"id": 88,
						"s": "2024-03-13T22:00:00",
						"e": "2024-03-13T22:15:00",
						"v": "conn"
					},
					{
						"id": 89,
						"s": "2024-03-13T22:15:00",
						"e": "2024-03-13T22:30:00",
						"v": "conn"
					},
					{
						"id": 90,
						"s": "2024-03-13T22:30:00",
						"e": "2024-03-13T22:45:00",
						"v": "conn"
					},
					{
						"id": 91,
						"s": "2024-03-13T22:45:00",
						"e": "2024-03-13T23:00:00",
						"v": "conn"
					},
					{
						"id": 92,
						"s": "2024-03-13T23:00:00",
						"e": "2024-03-13T23:15:00",
						"v": "conn"
					},
					{
						"id": 93,
						"s": "2024-03-13T23:15:00",
						"e": "2024-03-13T23:30:00",
						"v": "conn"
					},
					{
						"id": 94,
						"s": "2024-03-13T23:30:00",
						"e": "2024-03-13T23:45:00",
						"v": "conn"
					},
					{
						"id": 95,
						"s": "2024-03-13T23:45:00",
						"e": "2024-03-14T00:00:00",
						"v": "conn"
					}
				]
			},
			{
				"id": 26,
				"date": "14 Mar 2024",
				"data": [
					{
						"id": 0,
						"s": "2024-03-14T00:00:00",
						"e": "2024-03-14T00:15:00",
						"v": "conn"
					},
					{
						"id": 1,
						"s": "2024-03-14T00:15:00",
						"e": "2024-03-14T00:30:00",
						"v": "conn"
					},
					{
						"id": 2,
						"s": "2024-03-14T00:30:00",
						"e": "2024-03-14T00:45:00",
						"v": "conn"
					},
					{
						"id": 3,
						"s": "2024-03-14T00:45:00",
						"e": "2024-03-14T01:00:00",
						"v": "conn"
					},
					{
						"id": 4,
						"s": "2024-03-14T01:00:00",
						"e": "2024-03-14T01:15:00",
						"v": "conn"
					},
					{
						"id": 5,
						"s": "2024-03-14T01:15:00",
						"e": "2024-03-14T01:30:00",
						"v": "conn"
					},
					{
						"id": 6,
						"s": "2024-03-14T01:30:00",
						"e": "2024-03-14T01:45:00",
						"v": "conn"
					},
					{
						"id": 7,
						"s": "2024-03-14T01:45:00",
						"e": "2024-03-14T02:00:00",
						"v": "conn"
					},
					{
						"id": 8,
						"s": "2024-03-14T02:00:00",
						"e": "2024-03-14T02:15:00",
						"v": "conn"
					},
					{
						"id": 9,
						"s": "2024-03-14T02:15:00",
						"e": "2024-03-14T02:30:00",
						"v": "conn"
					},
					{
						"id": 10,
						"s": "2024-03-14T02:30:00",
						"e": "2024-03-14T02:45:00",
						"v": "conn"
					},
					{
						"id": 11,
						"s": "2024-03-14T02:45:00",
						"e": "2024-03-14T03:00:00",
						"v": "conn"
					},
					{
						"id": 12,
						"s": "2024-03-14T03:00:00",
						"e": "2024-03-14T03:15:00",
						"v": "conn"
					},
					{
						"id": 13,
						"s": "2024-03-14T03:15:00",
						"e": "2024-03-14T03:30:00",
						"v": "conn"
					},
					{
						"id": 14,
						"s": "2024-03-14T03:30:00",
						"e": "2024-03-14T03:45:00",
						"v": "conn"
					},
					{
						"id": 15,
						"s": "2024-03-14T03:45:00",
						"e": "2024-03-14T04:00:00",
						"v": "conn"
					},
					{
						"id": 16,
						"s": "2024-03-14T04:00:00",
						"e": "2024-03-14T04:15:00",
						"v": "conn"
					},
					{
						"id": 17,
						"s": "2024-03-14T04:15:00",
						"e": "2024-03-14T04:30:00",
						"v": "conn"
					},
					{
						"id": 18,
						"s": "2024-03-14T04:30:00",
						"e": "2024-03-14T04:45:00",
						"v": "conn"
					},
					{
						"id": 19,
						"s": "2024-03-14T04:45:00",
						"e": "2024-03-14T05:00:00",
						"v": "conn"
					},
					{
						"id": 20,
						"s": "2024-03-14T05:00:00",
						"e": "2024-03-14T05:15:00",
						"v": "conn"
					},
					{
						"id": 21,
						"s": "2024-03-14T05:15:00",
						"e": "2024-03-14T05:30:00",
						"v": "conn"
					},
					{
						"id": 22,
						"s": "2024-03-14T05:30:00",
						"e": "2024-03-14T05:45:00",
						"v": "conn"
					},
					{
						"id": 23,
						"s": "2024-03-14T05:45:00",
						"e": "2024-03-14T06:00:00",
						"v": "conn"
					},
					{
						"id": 24,
						"s": "2024-03-14T06:00:00",
						"e": "2024-03-14T06:15:00",
						"v": "conn"
					},
					{
						"id": 25,
						"s": "2024-03-14T06:15:00",
						"e": "2024-03-14T06:30:00",
						"v": "conn"
					},
					{
						"id": 26,
						"s": "2024-03-14T06:30:00",
						"e": "2024-03-14T06:45:00",
						"v": "conn"
					},
					{
						"id": 27,
						"s": "2024-03-14T06:45:00",
						"e": "2024-03-14T07:00:00",
						"v": "conn"
					},
					{
						"id": 28,
						"s": "2024-03-14T07:00:00",
						"e": "2024-03-14T07:15:00",
						"v": "conn"
					},
					{
						"id": 29,
						"s": "2024-03-14T07:15:00",
						"e": "2024-03-14T07:30:00",
						"v": "conn"
					},
					{
						"id": 30,
						"s": "2024-03-14T07:30:00",
						"e": "2024-03-14T07:45:00",
						"v": "conn"
					},
					{
						"id": 31,
						"s": "2024-03-14T07:45:00",
						"e": "2024-03-14T08:00:00",
						"v": "conn"
					},
					{
						"id": 32,
						"s": "2024-03-14T08:00:00",
						"e": "2024-03-14T08:15:00",
						"v": "conn"
					},
					{
						"id": 33,
						"s": "2024-03-14T08:15:00",
						"e": "2024-03-14T08:30:00",
						"v": "conn"
					},
					{
						"id": 34,
						"s": "2024-03-14T08:30:00",
						"e": "2024-03-14T08:45:00",
						"v": "conn"
					},
					{
						"id": 35,
						"s": "2024-03-14T08:45:00",
						"e": "2024-03-14T09:00:00",
						"v": "conn"
					},
					{
						"id": 36,
						"s": "2024-03-14T09:00:00",
						"e": "2024-03-14T09:15:00",
						"v": "conn"
					},
					{
						"id": 37,
						"s": "2024-03-14T09:15:00",
						"e": "2024-03-14T09:30:00",
						"v": "conn"
					},
					{
						"id": 38,
						"s": "2024-03-14T09:30:00",
						"e": "2024-03-14T09:45:00",
						"v": "conn"
					},
					{
						"id": 39,
						"s": "2024-03-14T09:45:00",
						"e": "2024-03-14T10:00:00",
						"v": "conn"
					},
					{
						"id": 40,
						"s": "2024-03-14T10:00:00",
						"e": "2024-03-14T10:15:00",
						"v": "conn"
					},
					{
						"id": 41,
						"s": "2024-03-14T10:15:00",
						"e": "2024-03-14T10:30:00",
						"v": "conn"
					},
					{
						"id": 42,
						"s": "2024-03-14T10:30:00",
						"e": "2024-03-14T10:45:00",
						"v": "conn"
					},
					{
						"id": 43,
						"s": "2024-03-14T10:45:00",
						"e": "2024-03-14T11:00:00",
						"v": "conn"
					},
					{
						"id": 44,
						"s": "2024-03-14T11:00:00",
						"e": "2024-03-14T11:15:00",
						"v": "conn"
					},
					{
						"id": 45,
						"s": "2024-03-14T11:15:00",
						"e": "2024-03-14T11:30:00",
						"v": "conn"
					},
					{
						"id": 46,
						"s": "2024-03-14T11:30:00",
						"e": "2024-03-14T11:45:00",
						"v": "conn"
					},
					{
						"id": 47,
						"s": "2024-03-14T11:45:00",
						"e": "2024-03-14T12:00:00",
						"v": "conn"
					},
					{
						"id": 48,
						"s": "2024-03-14T12:00:00",
						"e": "2024-03-14T12:15:00",
						"v": "conn"
					},
					{
						"id": 49,
						"s": "2024-03-14T12:15:00",
						"e": "2024-03-14T12:30:00",
						"v": "conn"
					},
					{
						"id": 50,
						"s": "2024-03-14T12:30:00",
						"e": "2024-03-14T12:45:00",
						"v": "conn"
					},
					{
						"id": 51,
						"s": "2024-03-14T12:45:00",
						"e": "2024-03-14T13:00:00",
						"v": "conn"
					},
					{
						"id": 52,
						"s": "2024-03-14T13:00:00",
						"e": "2024-03-14T13:15:00",
						"v": "conn"
					},
					{
						"id": 53,
						"s": "2024-03-14T13:15:00",
						"e": "2024-03-14T13:30:00",
						"v": "conn"
					},
					{
						"id": 54,
						"s": "2024-03-14T13:30:00",
						"e": "2024-03-14T13:45:00",
						"v": "conn"
					},
					{
						"id": 55,
						"s": "2024-03-14T13:45:00",
						"e": "2024-03-14T14:00:00",
						"v": "conn"
					},
					{
						"id": 56,
						"s": "2024-03-14T14:00:00",
						"e": "2024-03-14T14:15:00",
						"v": "conn"
					},
					{
						"id": 57,
						"s": "2024-03-14T14:15:00",
						"e": "2024-03-14T14:30:00",
						"v": "conn"
					},
					{
						"id": 58,
						"s": "2024-03-14T14:30:00",
						"e": "2024-03-14T14:45:00",
						"v": "conn"
					},
					{
						"id": 59,
						"s": "2024-03-14T14:45:00",
						"e": "2024-03-14T15:00:00",
						"v": "conn"
					},
					{
						"id": 60,
						"s": "2024-03-14T15:00:00",
						"e": "2024-03-14T15:15:00",
						"v": "conn"
					},
					{
						"id": 61,
						"s": "2024-03-14T15:15:00",
						"e": "2024-03-14T15:30:00",
						"v": "conn"
					},
					{
						"id": 62,
						"s": "2024-03-14T15:30:00",
						"e": "2024-03-14T15:45:00",
						"v": "conn"
					},
					{
						"id": 63,
						"s": "2024-03-14T15:45:00",
						"e": "2024-03-14T16:00:00",
						"v": "conn"
					},
					{
						"id": 64,
						"s": "2024-03-14T16:00:00",
						"e": "2024-03-14T16:15:00",
						"v": "conn"
					},
					{
						"id": 65,
						"s": "2024-03-14T16:15:00",
						"e": "2024-03-14T16:30:00",
						"v": "conn"
					},
					{
						"id": 66,
						"s": "2024-03-14T16:30:00",
						"e": "2024-03-14T16:45:00",
						"v": "conn"
					},
					{
						"id": 67,
						"s": "2024-03-14T16:45:00",
						"e": "2024-03-14T17:00:00",
						"v": "conn"
					},
					{
						"id": 68,
						"s": "2024-03-14T17:00:00",
						"e": "2024-03-14T17:15:00",
						"v": "conn"
					},
					{
						"id": 69,
						"s": "2024-03-14T17:15:00",
						"e": "2024-03-14T17:30:00",
						"v": "conn"
					},
					{
						"id": 70,
						"s": "2024-03-14T17:30:00",
						"e": "2024-03-14T17:45:00",
						"v": "conn"
					},
					{
						"id": 71,
						"s": "2024-03-14T17:45:00",
						"e": "2024-03-14T18:00:00",
						"v": "conn"
					},
					{
						"id": 72,
						"s": "2024-03-14T18:00:00",
						"e": "2024-03-14T18:15:00",
						"v": "conn"
					},
					{
						"id": 73,
						"s": "2024-03-14T18:15:00",
						"e": "2024-03-14T18:30:00",
						"v": "conn"
					},
					{
						"id": 74,
						"s": "2024-03-14T18:30:00",
						"e": "2024-03-14T18:45:00",
						"v": "conn"
					},
					{
						"id": 75,
						"s": "2024-03-14T18:45:00",
						"e": "2024-03-14T19:00:00",
						"v": "conn"
					},
					{
						"id": 76,
						"s": "2024-03-14T19:00:00",
						"e": "2024-03-14T19:15:00",
						"v": "conn"
					},
					{
						"id": 77,
						"s": "2024-03-14T19:15:00",
						"e": "2024-03-14T19:30:00",
						"v": "conn"
					},
					{
						"id": 78,
						"s": "2024-03-14T19:30:00",
						"e": "2024-03-14T19:45:00",
						"v": "conn"
					},
					{
						"id": 79,
						"s": "2024-03-14T19:45:00",
						"e": "2024-03-14T20:00:00",
						"v": "conn"
					},
					{
						"id": 80,
						"s": "2024-03-14T20:00:00",
						"e": "2024-03-14T20:15:00",
						"v": "conn"
					},
					{
						"id": 81,
						"s": "2024-03-14T20:15:00",
						"e": "2024-03-14T20:30:00",
						"v": "conn"
					},
					{
						"id": 82,
						"s": "2024-03-14T20:30:00",
						"e": "2024-03-14T20:45:00",
						"v": "conn"
					},
					{
						"id": 83,
						"s": "2024-03-14T20:45:00",
						"e": "2024-03-14T21:00:00",
						"v": "conn"
					},
					{
						"id": 84,
						"s": "2024-03-14T21:00:00",
						"e": "2024-03-14T21:15:00",
						"v": "conn"
					},
					{
						"id": 85,
						"s": "2024-03-14T21:15:00",
						"e": "2024-03-14T21:30:00",
						"v": "conn"
					},
					{
						"id": 86,
						"s": "2024-03-14T21:30:00",
						"e": "2024-03-14T21:45:00",
						"v": "conn"
					},
					{
						"id": 87,
						"s": "2024-03-14T21:45:00",
						"e": "2024-03-14T22:00:00",
						"v": "conn"
					},
					{
						"id": 88,
						"s": "2024-03-14T22:00:00",
						"e": "2024-03-14T22:15:00",
						"v": "conn"
					},
					{
						"id": 89,
						"s": "2024-03-14T22:15:00",
						"e": "2024-03-14T22:30:00",
						"v": "conn"
					},
					{
						"id": 90,
						"s": "2024-03-14T22:30:00",
						"e": "2024-03-14T22:45:00",
						"v": "conn"
					},
					{
						"id": 91,
						"s": "2024-03-14T22:45:00",
						"e": "2024-03-14T23:00:00",
						"v": "conn"
					},
					{
						"id": 92,
						"s": "2024-03-14T23:00:00",
						"e": "2024-03-14T23:15:00",
						"v": "conn"
					},
					{
						"id": 93,
						"s": "2024-03-14T23:15:00",
						"e": "2024-03-14T23:30:00",
						"v": "conn"
					},
					{
						"id": 94,
						"s": "2024-03-14T23:30:00",
						"e": "2024-03-14T23:45:00",
						"v": "conn"
					},
					{
						"id": 95,
						"s": "2024-03-14T23:45:00",
						"e": "2024-03-15T00:00:00",
						"v": "conn"
					}
				]
			},
			{
				"id": 27,
				"date": "15 Mar 2024",
				"data": [
					{
						"id": 0,
						"s": "2024-03-15T00:00:00",
						"e": "2024-03-15T00:15:00",
						"v": "conn"
					},
					{
						"id": 1,
						"s": "2024-03-15T00:15:00",
						"e": "2024-03-15T00:30:00",
						"v": "conn"
					},
					{
						"id": 2,
						"s": "2024-03-15T00:30:00",
						"e": "2024-03-15T00:45:00",
						"v": "conn"
					},
					{
						"id": 3,
						"s": "2024-03-15T00:45:00",
						"e": "2024-03-15T01:00:00",
						"v": "conn"
					},
					{
						"id": 4,
						"s": "2024-03-15T01:00:00",
						"e": "2024-03-15T01:15:00",
						"v": "conn"
					},
					{
						"id": 5,
						"s": "2024-03-15T01:15:00",
						"e": "2024-03-15T01:30:00",
						"v": "conn"
					},
					{
						"id": 6,
						"s": "2024-03-15T01:30:00",
						"e": "2024-03-15T01:45:00",
						"v": "conn"
					},
					{
						"id": 7,
						"s": "2024-03-15T01:45:00",
						"e": "2024-03-15T02:00:00",
						"v": "conn"
					},
					{
						"id": 8,
						"s": "2024-03-15T02:00:00",
						"e": "2024-03-15T02:15:00",
						"v": "conn"
					},
					{
						"id": 9,
						"s": "2024-03-15T02:15:00",
						"e": "2024-03-15T02:30:00",
						"v": "conn"
					},
					{
						"id": 10,
						"s": "2024-03-15T02:30:00",
						"e": "2024-03-15T02:45:00",
						"v": "conn"
					},
					{
						"id": 11,
						"s": "2024-03-15T02:45:00",
						"e": "2024-03-15T03:00:00",
						"v": "conn"
					},
					{
						"id": 12,
						"s": "2024-03-15T03:00:00",
						"e": "2024-03-15T03:15:00",
						"v": "conn"
					},
					{
						"id": 13,
						"s": "2024-03-15T03:15:00",
						"e": "2024-03-15T03:30:00",
						"v": "conn"
					},
					{
						"id": 14,
						"s": "2024-03-15T03:30:00",
						"e": "2024-03-15T03:45:00",
						"v": "conn"
					},
					{
						"id": 15,
						"s": "2024-03-15T03:45:00",
						"e": "2024-03-15T04:00:00",
						"v": "conn"
					},
					{
						"id": 16,
						"s": "2024-03-15T04:00:00",
						"e": "2024-03-15T04:15:00",
						"v": "conn"
					},
					{
						"id": 17,
						"s": "2024-03-15T04:15:00",
						"e": "2024-03-15T04:30:00",
						"v": "conn"
					},
					{
						"id": 18,
						"s": "2024-03-15T04:30:00",
						"e": "2024-03-15T04:45:00",
						"v": "conn"
					},
					{
						"id": 19,
						"s": "2024-03-15T04:45:00",
						"e": "2024-03-15T05:00:00",
						"v": "conn"
					},
					{
						"id": 20,
						"s": "2024-03-15T05:00:00",
						"e": "2024-03-15T05:15:00",
						"v": "conn"
					},
					{
						"id": 21,
						"s": "2024-03-15T05:15:00",
						"e": "2024-03-15T05:30:00",
						"v": "conn"
					},
					{
						"id": 22,
						"s": "2024-03-15T05:30:00",
						"e": "2024-03-15T05:45:00",
						"v": "conn"
					},
					{
						"id": 23,
						"s": "2024-03-15T05:45:00",
						"e": "2024-03-15T06:00:00",
						"v": "conn"
					},
					{
						"id": 24,
						"s": "2024-03-15T06:00:00",
						"e": "2024-03-15T06:15:00",
						"v": "conn"
					},
					{
						"id": 25,
						"s": "2024-03-15T06:15:00",
						"e": "2024-03-15T06:30:00",
						"v": "conn"
					},
					{
						"id": 26,
						"s": "2024-03-15T06:30:00",
						"e": "2024-03-15T06:45:00",
						"v": "conn"
					},
					{
						"id": 27,
						"s": "2024-03-15T06:45:00",
						"e": "2024-03-15T07:00:00",
						"v": "conn"
					},
					{
						"id": 28,
						"s": "2024-03-15T07:00:00",
						"e": "2024-03-15T07:15:00",
						"v": "conn"
					},
					{
						"id": 29,
						"s": "2024-03-15T07:15:00",
						"e": "2024-03-15T07:30:00",
						"v": "conn"
					},
					{
						"id": 30,
						"s": "2024-03-15T07:30:00",
						"e": "2024-03-15T07:45:00",
						"v": "conn"
					},
					{
						"id": 31,
						"s": "2024-03-15T07:45:00",
						"e": "2024-03-15T08:00:00",
						"v": "conn"
					},
					{
						"id": 32,
						"s": "2024-03-15T08:00:00",
						"e": "2024-03-15T08:15:00",
						"v": "conn"
					},
					{
						"id": 33,
						"s": "2024-03-15T08:15:00",
						"e": "2024-03-15T08:30:00",
						"v": "conn"
					},
					{
						"id": 34,
						"s": "2024-03-15T08:30:00",
						"e": "2024-03-15T08:45:00",
						"v": "conn"
					},
					{
						"id": 35,
						"s": "2024-03-15T08:45:00",
						"e": "2024-03-15T09:00:00",
						"v": "conn"
					},
					{
						"id": 36,
						"s": "2024-03-15T09:00:00",
						"e": "2024-03-15T09:15:00",
						"v": "conn"
					},
					{
						"id": 37,
						"s": "2024-03-15T09:15:00",
						"e": "2024-03-15T09:30:00",
						"v": "conn"
					},
					{
						"id": 38,
						"s": "2024-03-15T09:30:00",
						"e": "2024-03-15T09:45:00",
						"v": "conn"
					},
					{
						"id": 39,
						"s": "2024-03-15T09:45:00",
						"e": "2024-03-15T10:00:00",
						"v": "conn"
					},
					{
						"id": 40,
						"s": "2024-03-15T10:00:00",
						"e": "2024-03-15T10:15:00",
						"v": "conn"
					},
					{
						"id": 41,
						"s": "2024-03-15T10:15:00",
						"e": "2024-03-15T10:30:00",
						"v": "conn"
					},
					{
						"id": 42,
						"s": "2024-03-15T10:30:00",
						"e": "2024-03-15T10:45:00",
						"v": "conn"
					},
					{
						"id": 43,
						"s": "2024-03-15T10:45:00",
						"e": "2024-03-15T11:00:00",
						"v": "conn"
					},
					{
						"id": 44,
						"s": "2024-03-15T11:00:00",
						"e": "2024-03-15T11:15:00",
						"v": "conn"
					},
					{
						"id": 45,
						"s": "2024-03-15T11:15:00",
						"e": "2024-03-15T11:30:00",
						"v": "conn"
					},
					{
						"id": 46,
						"s": "2024-03-15T11:30:00",
						"e": "2024-03-15T11:45:00",
						"v": "conn"
					},
					{
						"id": 47,
						"s": "2024-03-15T11:45:00",
						"e": "2024-03-15T12:00:00",
						"v": "conn"
					},
					{
						"id": 48,
						"s": "2024-03-15T12:00:00",
						"e": "2024-03-15T12:15:00",
						"v": "conn"
					},
					{
						"id": 49,
						"s": "2024-03-15T12:15:00",
						"e": "2024-03-15T12:30:00",
						"v": "conn"
					},
					{
						"id": 50,
						"s": "2024-03-15T12:30:00",
						"e": "2024-03-15T12:45:00",
						"v": "conn"
					},
					{
						"id": 51,
						"s": "2024-03-15T12:45:00",
						"e": "2024-03-15T13:00:00",
						"v": "conn"
					},
					{
						"id": 52,
						"s": "2024-03-15T13:00:00",
						"e": "2024-03-15T13:15:00",
						"v": "conn"
					},
					{
						"id": 53,
						"s": "2024-03-15T13:15:00",
						"e": "2024-03-15T13:30:00",
						"v": "conn"
					},
					{
						"id": 54,
						"s": "2024-03-15T13:30:00",
						"e": "2024-03-15T13:45:00",
						"v": "conn"
					},
					{
						"id": 55,
						"s": "2024-03-15T13:45:00",
						"e": "2024-03-15T14:00:00",
						"v": "conn"
					},
					{
						"id": 56,
						"s": "2024-03-15T14:00:00",
						"e": "2024-03-15T14:15:00",
						"v": "conn"
					},
					{
						"id": 57,
						"s": "2024-03-15T14:15:00",
						"e": "2024-03-15T14:30:00",
						"v": "conn"
					},
					{
						"id": 58,
						"s": "2024-03-15T14:30:00",
						"e": "2024-03-15T14:45:00",
						"v": "conn"
					},
					{
						"id": 59,
						"s": "2024-03-15T14:45:00",
						"e": "2024-03-15T15:00:00",
						"v": "conn"
					},
					{
						"id": 60,
						"s": "2024-03-15T15:00:00",
						"e": "2024-03-15T15:15:00",
						"v": "conn"
					},
					{
						"id": 61,
						"s": "2024-03-15T15:15:00",
						"e": "2024-03-15T15:30:00",
						"v": "conn"
					},
					{
						"id": 62,
						"s": "2024-03-15T15:30:00",
						"e": "2024-03-15T15:45:00",
						"v": "conn"
					},
					{
						"id": 63,
						"s": "2024-03-15T15:45:00",
						"e": "2024-03-15T16:00:00",
						"v": "conn"
					},
					{
						"id": 64,
						"s": "2024-03-15T16:00:00",
						"e": "2024-03-15T16:15:00",
						"v": "conn"
					},
					{
						"id": 65,
						"s": "2024-03-15T16:15:00",
						"e": "2024-03-15T16:30:00",
						"v": "conn"
					},
					{
						"id": 66,
						"s": "2024-03-15T16:30:00",
						"e": "2024-03-15T16:45:00",
						"v": "conn"
					},
					{
						"id": 67,
						"s": "2024-03-15T16:45:00",
						"e": "2024-03-15T17:00:00",
						"v": "conn"
					},
					{
						"id": 68,
						"s": "2024-03-15T17:00:00",
						"e": "2024-03-15T17:15:00",
						"v": "conn"
					},
					{
						"id": 69,
						"s": "2024-03-15T17:15:00",
						"e": "2024-03-15T17:30:00",
						"v": "conn"
					},
					{
						"id": 70,
						"s": "2024-03-15T17:30:00",
						"e": "2024-03-15T17:45:00",
						"v": "conn"
					},
					{
						"id": 71,
						"s": "2024-03-15T17:45:00",
						"e": "2024-03-15T18:00:00",
						"v": "conn"
					},
					{
						"id": 72,
						"s": "2024-03-15T18:00:00",
						"e": "2024-03-15T18:15:00",
						"v": "conn"
					},
					{
						"id": 73,
						"s": "2024-03-15T18:15:00",
						"e": "2024-03-15T18:30:00",
						"v": "conn"
					},
					{
						"id": 74,
						"s": "2024-03-15T18:30:00",
						"e": "2024-03-15T18:45:00",
						"v": "conn"
					},
					{
						"id": 75,
						"s": "2024-03-15T18:45:00",
						"e": "2024-03-15T19:00:00",
						"v": "conn"
					},
					{
						"id": 76,
						"s": "2024-03-15T19:00:00",
						"e": "2024-03-15T19:15:00",
						"v": "conn"
					},
					{
						"id": 77,
						"s": "2024-03-15T19:15:00",
						"e": "2024-03-15T19:30:00",
						"v": "conn"
					},
					{
						"id": 78,
						"s": "2024-03-15T19:30:00",
						"e": "2024-03-15T19:45:00",
						"v": "conn"
					},
					{
						"id": 79,
						"s": "2024-03-15T19:45:00",
						"e": "2024-03-15T20:00:00",
						"v": "conn"
					},
					{
						"id": 80,
						"s": "2024-03-15T20:00:00",
						"e": "2024-03-15T20:15:00",
						"v": "conn"
					},
					{
						"id": 81,
						"s": "2024-03-15T20:15:00",
						"e": "2024-03-15T20:30:00",
						"v": "conn"
					},
					{
						"id": 82,
						"s": "2024-03-15T20:30:00",
						"e": "2024-03-15T20:45:00",
						"v": "conn"
					},
					{
						"id": 83,
						"s": "2024-03-15T20:45:00",
						"e": "2024-03-15T21:00:00",
						"v": "conn"
					},
					{
						"id": 84,
						"s": "2024-03-15T21:00:00",
						"e": "2024-03-15T21:15:00",
						"v": "conn"
					},
					{
						"id": 85,
						"s": "2024-03-15T21:15:00",
						"e": "2024-03-15T21:30:00",
						"v": "conn"
					},
					{
						"id": 86,
						"s": "2024-03-15T21:30:00",
						"e": "2024-03-15T21:45:00",
						"v": "conn"
					},
					{
						"id": 87,
						"s": "2024-03-15T21:45:00",
						"e": "2024-03-15T22:00:00",
						"v": "conn"
					},
					{
						"id": 88,
						"s": "2024-03-15T22:00:00",
						"e": "2024-03-15T22:15:00",
						"v": "conn"
					},
					{
						"id": 89,
						"s": "2024-03-15T22:15:00",
						"e": "2024-03-15T22:30:00",
						"v": "conn"
					},
					{
						"id": 90,
						"s": "2024-03-15T22:30:00",
						"e": "2024-03-15T22:45:00",
						"v": "conn"
					},
					{
						"id": 91,
						"s": "2024-03-15T22:45:00",
						"e": "2024-03-15T23:00:00",
						"v": "conn"
					},
					{
						"id": 92,
						"s": "2024-03-15T23:00:00",
						"e": "2024-03-15T23:15:00",
						"v": "conn"
					},
					{
						"id": 93,
						"s": "2024-03-15T23:15:00",
						"e": "2024-03-15T23:30:00",
						"v": "conn"
					},
					{
						"id": 94,
						"s": "2024-03-15T23:30:00",
						"e": "2024-03-15T23:45:00",
						"v": "conn"
					},
					{
						"id": 95,
						"s": "2024-03-15T23:45:00",
						"e": "2024-03-16T00:00:00",
						"v": "conn"
					}
				]
			},
			{
				"id": 28,
				"date": "16 Mar 2024",
				"data": [
					{
						"id": 0,
						"s": "2024-03-16T00:00:00",
						"e": "2024-03-16T00:15:00",
						"v": "conn"
					},
					{
						"id": 1,
						"s": "2024-03-16T00:15:00",
						"e": "2024-03-16T00:30:00",
						"v": "conn"
					},
					{
						"id": 2,
						"s": "2024-03-16T00:30:00",
						"e": "2024-03-16T00:45:00",
						"v": "conn"
					},
					{
						"id": 3,
						"s": "2024-03-16T00:45:00",
						"e": "2024-03-16T01:00:00",
						"v": "conn"
					},
					{
						"id": 4,
						"s": "2024-03-16T01:00:00",
						"e": "2024-03-16T01:15:00",
						"v": "conn"
					},
					{
						"id": 5,
						"s": "2024-03-16T01:15:00",
						"e": "2024-03-16T01:30:00",
						"v": "conn"
					},
					{
						"id": 6,
						"s": "2024-03-16T01:30:00",
						"e": "2024-03-16T01:45:00",
						"v": "conn"
					},
					{
						"id": 7,
						"s": "2024-03-16T01:45:00",
						"e": "2024-03-16T02:00:00",
						"v": "conn"
					},
					{
						"id": 8,
						"s": "2024-03-16T02:00:00",
						"e": "2024-03-16T02:15:00",
						"v": "conn"
					},
					{
						"id": 9,
						"s": "2024-03-16T02:15:00",
						"e": "2024-03-16T02:30:00",
						"v": "conn"
					},
					{
						"id": 10,
						"s": "2024-03-16T02:30:00",
						"e": "2024-03-16T02:45:00",
						"v": "conn"
					},
					{
						"id": 11,
						"s": "2024-03-16T02:45:00",
						"e": "2024-03-16T03:00:00",
						"v": "conn"
					},
					{
						"id": 12,
						"s": "2024-03-16T03:00:00",
						"e": "2024-03-16T03:15:00",
						"v": "conn"
					},
					{
						"id": 13,
						"s": "2024-03-16T03:15:00",
						"e": "2024-03-16T03:30:00",
						"v": "conn"
					},
					{
						"id": 14,
						"s": "2024-03-16T03:30:00",
						"e": "2024-03-16T03:45:00",
						"v": "conn"
					},
					{
						"id": 15,
						"s": "2024-03-16T03:45:00",
						"e": "2024-03-16T04:00:00",
						"v": "conn"
					},
					{
						"id": 16,
						"s": "2024-03-16T04:00:00",
						"e": "2024-03-16T04:15:00",
						"v": "conn"
					},
					{
						"id": 17,
						"s": "2024-03-16T04:15:00",
						"e": "2024-03-16T04:30:00",
						"v": "conn"
					},
					{
						"id": 18,
						"s": "2024-03-16T04:30:00",
						"e": "2024-03-16T04:45:00",
						"v": "conn"
					},
					{
						"id": 19,
						"s": "2024-03-16T04:45:00",
						"e": "2024-03-16T05:00:00",
						"v": "conn"
					},
					{
						"id": 20,
						"s": "2024-03-16T05:00:00",
						"e": "2024-03-16T05:15:00",
						"v": "conn"
					},
					{
						"id": 21,
						"s": "2024-03-16T05:15:00",
						"e": "2024-03-16T05:30:00",
						"v": "conn"
					},
					{
						"id": 22,
						"s": "2024-03-16T05:30:00",
						"e": "2024-03-16T05:45:00",
						"v": "conn"
					},
					{
						"id": 23,
						"s": "2024-03-16T05:45:00",
						"e": "2024-03-16T06:00:00",
						"v": "conn"
					},
					{
						"id": 24,
						"s": "2024-03-16T06:00:00",
						"e": "2024-03-16T06:15:00",
						"v": "conn"
					},
					{
						"id": 25,
						"s": "2024-03-16T06:15:00",
						"e": "2024-03-16T06:30:00",
						"v": "conn"
					},
					{
						"id": 26,
						"s": "2024-03-16T06:30:00",
						"e": "2024-03-16T06:45:00",
						"v": "conn"
					},
					{
						"id": 27,
						"s": "2024-03-16T06:45:00",
						"e": "2024-03-16T07:00:00",
						"v": "conn"
					},
					{
						"id": 28,
						"s": "2024-03-16T07:00:00",
						"e": "2024-03-16T07:15:00",
						"v": "conn"
					},
					{
						"id": 29,
						"s": "2024-03-16T07:15:00",
						"e": "2024-03-16T07:30:00",
						"v": "conn"
					},
					{
						"id": 30,
						"s": "2024-03-16T07:30:00",
						"e": "2024-03-16T07:45:00",
						"v": "conn"
					},
					{
						"id": 31,
						"s": "2024-03-16T07:45:00",
						"e": "2024-03-16T08:00:00",
						"v": "conn"
					},
					{
						"id": 32,
						"s": "2024-03-16T08:00:00",
						"e": "2024-03-16T08:15:00",
						"v": "conn"
					},
					{
						"id": 33,
						"s": "2024-03-16T08:15:00",
						"e": "2024-03-16T08:30:00",
						"v": "conn"
					},
					{
						"id": 34,
						"s": "2024-03-16T08:30:00",
						"e": "2024-03-16T08:45:00",
						"v": "conn"
					},
					{
						"id": 35,
						"s": "2024-03-16T08:45:00",
						"e": "2024-03-16T09:00:00",
						"v": "conn"
					},
					{
						"id": 36,
						"s": "2024-03-16T09:00:00",
						"e": "2024-03-16T09:15:00",
						"v": "conn"
					},
					{
						"id": 37,
						"s": "2024-03-16T09:15:00",
						"e": "2024-03-16T09:30:00",
						"v": "conn"
					},
					{
						"id": 38,
						"s": "2024-03-16T09:30:00",
						"e": "2024-03-16T09:45:00",
						"v": "conn"
					},
					{
						"id": 39,
						"s": "2024-03-16T09:45:00",
						"e": "2024-03-16T10:00:00",
						"v": "conn"
					},
					{
						"id": 40,
						"s": "2024-03-16T10:00:00",
						"e": "2024-03-16T10:15:00",
						"v": "conn"
					},
					{
						"id": 41,
						"s": "2024-03-16T10:15:00",
						"e": "2024-03-16T10:30:00",
						"v": "conn"
					},
					{
						"id": 42,
						"s": "2024-03-16T10:30:00",
						"e": "2024-03-16T10:45:00",
						"v": "conn"
					},
					{
						"id": 43,
						"s": "2024-03-16T10:45:00",
						"e": "2024-03-16T11:00:00",
						"v": "conn"
					},
					{
						"id": 44,
						"s": "2024-03-16T11:00:00",
						"e": "2024-03-16T11:15:00",
						"v": "conn"
					},
					{
						"id": 45,
						"s": "2024-03-16T11:15:00",
						"e": "2024-03-16T11:30:00",
						"v": "conn"
					},
					{
						"id": 46,
						"s": "2024-03-16T11:30:00",
						"e": "2024-03-16T11:45:00",
						"v": "conn"
					},
					{
						"id": 47,
						"s": "2024-03-16T11:45:00",
						"e": "2024-03-16T12:00:00",
						"v": "conn"
					},
					{
						"id": 48,
						"s": "2024-03-16T12:00:00",
						"e": "2024-03-16T12:15:00",
						"v": "conn"
					},
					{
						"id": 49,
						"s": "2024-03-16T12:15:00",
						"e": "2024-03-16T12:30:00",
						"v": "conn"
					},
					{
						"id": 50,
						"s": "2024-03-16T12:30:00",
						"e": "2024-03-16T12:45:00",
						"v": "conn"
					},
					{
						"id": 51,
						"s": "2024-03-16T12:45:00",
						"e": "2024-03-16T13:00:00",
						"v": "conn"
					},
					{
						"id": 52,
						"s": "2024-03-16T13:00:00",
						"e": "2024-03-16T13:15:00",
						"v": "conn"
					},
					{
						"id": 53,
						"s": "2024-03-16T13:15:00",
						"e": "2024-03-16T13:30:00",
						"v": "conn"
					},
					{
						"id": 54,
						"s": "2024-03-16T13:30:00",
						"e": "2024-03-16T13:45:00",
						"v": "conn"
					},
					{
						"id": 55,
						"s": "2024-03-16T13:45:00",
						"e": "2024-03-16T14:00:00",
						"v": "conn"
					},
					{
						"id": 56,
						"s": "2024-03-16T14:00:00",
						"e": "2024-03-16T14:15:00",
						"v": "conn"
					},
					{
						"id": 57,
						"s": "2024-03-16T14:15:00",
						"e": "2024-03-16T14:30:00",
						"v": "conn"
					},
					{
						"id": 58,
						"s": "2024-03-16T14:30:00",
						"e": "2024-03-16T14:45:00",
						"v": "conn"
					},
					{
						"id": 59,
						"s": "2024-03-16T14:45:00",
						"e": "2024-03-16T15:00:00",
						"v": "conn"
					},
					{
						"id": 60,
						"s": "2024-03-16T15:00:00",
						"e": "2024-03-16T15:15:00",
						"v": "conn"
					},
					{
						"id": 61,
						"s": "2024-03-16T15:15:00",
						"e": "2024-03-16T15:30:00",
						"v": "conn"
					},
					{
						"id": 62,
						"s": "2024-03-16T15:30:00",
						"e": "2024-03-16T15:45:00",
						"v": "conn"
					},
					{
						"id": 63,
						"s": "2024-03-16T15:45:00",
						"e": "2024-03-16T16:00:00",
						"v": "conn"
					},
					{
						"id": 64,
						"s": "2024-03-16T16:00:00",
						"e": "2024-03-16T16:15:00",
						"v": "conn"
					},
					{
						"id": 65,
						"s": "2024-03-16T16:15:00",
						"e": "2024-03-16T16:30:00",
						"v": "conn"
					},
					{
						"id": 66,
						"s": "2024-03-16T16:30:00",
						"e": "2024-03-16T16:45:00",
						"v": "conn"
					},
					{
						"id": 67,
						"s": "2024-03-16T16:45:00",
						"e": "2024-03-16T17:00:00",
						"v": "conn"
					},
					{
						"id": 68,
						"s": "2024-03-16T17:00:00",
						"e": "2024-03-16T17:15:00",
						"v": "conn"
					},
					{
						"id": 69,
						"s": "2024-03-16T17:15:00",
						"e": "2024-03-16T17:30:00",
						"v": "conn"
					},
					{
						"id": 70,
						"s": "2024-03-16T17:30:00",
						"e": "2024-03-16T17:45:00",
						"v": "conn"
					},
					{
						"id": 71,
						"s": "2024-03-16T17:45:00",
						"e": "2024-03-16T18:00:00",
						"v": "conn"
					},
					{
						"id": 72,
						"s": "2024-03-16T18:00:00",
						"e": "2024-03-16T18:15:00",
						"v": "conn"
					},
					{
						"id": 73,
						"s": "2024-03-16T18:15:00",
						"e": "2024-03-16T18:30:00",
						"v": "conn"
					},
					{
						"id": 74,
						"s": "2024-03-16T18:30:00",
						"e": "2024-03-16T18:45:00",
						"v": "conn"
					},
					{
						"id": 75,
						"s": "2024-03-16T18:45:00",
						"e": "2024-03-16T19:00:00",
						"v": "conn"
					},
					{
						"id": 76,
						"s": "2024-03-16T19:00:00",
						"e": "2024-03-16T19:15:00",
						"v": "conn"
					},
					{
						"id": 77,
						"s": "2024-03-16T19:15:00",
						"e": "2024-03-16T19:30:00",
						"v": "conn"
					},
					{
						"id": 78,
						"s": "2024-03-16T19:30:00",
						"e": "2024-03-16T19:45:00",
						"v": "conn"
					},
					{
						"id": 79,
						"s": "2024-03-16T19:45:00",
						"e": "2024-03-16T20:00:00",
						"v": "conn"
					},
					{
						"id": 80,
						"s": "2024-03-16T20:00:00",
						"e": "2024-03-16T20:15:00",
						"v": "conn"
					},
					{
						"id": 81,
						"s": "2024-03-16T20:15:00",
						"e": "2024-03-16T20:30:00",
						"v": "conn"
					},
					{
						"id": 82,
						"s": "2024-03-16T20:30:00",
						"e": "2024-03-16T20:45:00",
						"v": "conn"
					},
					{
						"id": 83,
						"s": "2024-03-16T20:45:00",
						"e": "2024-03-16T21:00:00",
						"v": "conn"
					},
					{
						"id": 84,
						"s": "2024-03-16T21:00:00",
						"e": "2024-03-16T21:15:00",
						"v": "conn"
					},
					{
						"id": 85,
						"s": "2024-03-16T21:15:00",
						"e": "2024-03-16T21:30:00",
						"v": "conn"
					},
					{
						"id": 86,
						"s": "2024-03-16T21:30:00",
						"e": "2024-03-16T21:45:00",
						"v": "conn"
					},
					{
						"id": 87,
						"s": "2024-03-16T21:45:00",
						"e": "2024-03-16T22:00:00",
						"v": "conn"
					},
					{
						"id": 88,
						"s": "2024-03-16T22:00:00",
						"e": "2024-03-16T22:15:00",
						"v": "conn"
					},
					{
						"id": 89,
						"s": "2024-03-16T22:15:00",
						"e": "2024-03-16T22:30:00",
						"v": "conn"
					},
					{
						"id": 90,
						"s": "2024-03-16T22:30:00",
						"e": "2024-03-16T22:45:00",
						"v": "conn"
					},
					{
						"id": 91,
						"s": "2024-03-16T22:45:00",
						"e": "2024-03-16T23:00:00",
						"v": "conn"
					},
					{
						"id": 92,
						"s": "2024-03-16T23:00:00",
						"e": "2024-03-16T23:15:00",
						"v": "conn"
					},
					{
						"id": 93,
						"s": "2024-03-16T23:15:00",
						"e": "2024-03-16T23:30:00",
						"v": "conn"
					},
					{
						"id": 94,
						"s": "2024-03-16T23:30:00",
						"e": "2024-03-16T23:45:00",
						"v": "conn"
					},
					{
						"id": 95,
						"s": "2024-03-16T23:45:00",
						"e": "2024-03-17T00:00:00",
						"v": "conn"
					}
				]
			}
		]
	},
	"isSuccess": true,
	"message": null
};

console.log('drops over time', dropsOverTime);

const dropsByTime = {
	model: {
		dateStart: '02/13/2024 00:00:00',
		dateEnd: '03/13/2024 00:00:00',
		payload: /*[*/
			// [0..23].map()
			Array.from({ length: 24 }, (value, index) => index).map(val => {
				return {
					hour: val,
					drops: Math.floor(Math.random() * 6)
				}
			})
	},
	isSuccess: true,
	message: null
}

const dropsByTimeOptions = {
	// title: {
	//   text: "Drops By Time"
	// },
	// theme: 'light1',
	axisY: {
		title: "Drops",
		// titleFontSize: 24,
		includeZero: true
	},
	axisX: {
		title: "Hour",
		// titleFontSize: 24,
		includeZero: true,
		interval: 1,
		// gridColor: '#0060df',
		// tickLength: 1
	},
	data: [{
		type: "column",
		color: "#6D78AD",
		dataPoints: dropsByTime.model.payload.map(val => {
			return {
				label: '' + val.hour,
				y: val.drops
			}
		})
	}]
}

console.log('drops by time', dropsByTime);

const dropsByDate = {
	model: {
		dateStart: '02/13/2024 00:00:00',
		dateEnd: '03/13/2024 00:00:00',
		payload: Array.from({ length: 30 }, (value, index) => index).map(val => {
			return {
				id: val,
				date: val + ' Jan 2024',
				drops: Math.floor(Math.random() * 6),
				weekend: (val % 6 === 0 || val % 7 === 0)
			}
		})
	},
	isSuccess: true,
	message: null
}

console.log('drops by date', dropsByDate);

const dropsByDateOptions = {
	axisY: {
		title: "Drops",
		// titleFontSize: 24,
		includeZero: true
	},
	axisX: {
		title: "Date",
		// titleFontSize: 24,
		includeZero: true,
		interval: 1,
		labelAngle: -65
		// gridColor: '#0060df',
		// tickLength: 1
	},
	data: [{
		type: "column",
		color: "#6D78AD",
		dataPoints: dropsByDate.model.payload.map(val => {
			return {
				label: val.date,
				y: val.drops
			}
		})
	}]
}

